import React, { ChangeEvent, FC, HTMLProps, ReactElement, useCallback } from 'react'

import Field, { CommonProps } from './_Wrapper'
import styles from './index.module.scss'

type CheckboxesProps = Omit<
  HTMLProps<HTMLInputElement>,
  'ref' | 'label' | 'name' | 'value' | 'onChange'
>

type OptionProps = {
  value: string
  children: string
}

export type Props = CommonProps & {
  value: string[]
  onChangeValue: (values: string[]) => void
  children: ReactElement<OptionProps>[]
} & CheckboxesProps

const Checkboxes: FC<Props> = ({ value: selectedValues, onChangeValue, children, ...rest }) => {
  const { label, name, hint, isOptional, error, aside, className, type, ...inputProps } = rest
  const fieldProps = { label, name, hint, isOptional, error, aside, className }

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target
      if (checked) {
        const values = [...selectedValues, value]
        onChangeValue(values)
      } else {
        const idx = selectedValues.indexOf(value)
        if (idx > -1) {
          const values = [...selectedValues.slice(0, idx), ...selectedValues.slice(idx + 1)]
          onChangeValue(values)
        }
      }
    },
    [selectedValues, onChangeValue]
  )

  return (
    <Field {...fieldProps}>
      <ul className={styles.Options}>
        {children.map(({ props: { value, children } }) => (
          <li key={children}>
            <label>
              <input
                type="checkbox"
                name={name}
                value={value}
                checked={selectedValues.includes(value)}
                onChange={handleChange}
                {...inputProps}
              />
              <span>{children}</span>
            </label>
          </li>
        ))}
      </ul>
    </Field>
  )
}

export default Checkboxes
