import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch, useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { adminBootstrap } from 'thunks/api/admin-bootstrap'
import { authenticationsLogout } from 'thunks/api/authentications/logout'

export default function useBootstrap() {
  const [bootstrap] = useAPI(adminBootstrap)
  const [isBootstrapped, setIsBootstrapped] = useState(false)
  const { token } = useAuthSelector()

  const dispatch = useAppDispatch()
  const history = useHistory()
  const handleFailure = useCallback(async () => {
    await dispatch(authenticationsLogout())
    history.push(r.auth.login)
  }, [dispatch, history])

  useEffect(() => {
    if (!token) return
    bootstrap({})
      .then(() => setIsBootstrapped(true))
      .catch(handleFailure)
  }, [token, bootstrap, handleFailure])

  return isBootstrapped
}
