import { User } from 'types/user'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  accountId: string
} & Pick<
  User,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'enrollments'
  | 'devices'
  | 'id'
  | 'locations'
  | 'phoneNumbers'
  | 'uniqueId'
>

export const updateUser = asyncThunk<Request, User>({
  name: 'users/update',
  uri: ({ id }) => `/users/${id}`,
  method: 'PUT',
  orchestrator: false,
  stripId: true,
})
