import { FC } from 'react'
import { useBrandingSelector } from 'store'

import headerLogo from './header-logo.svg'
import s from './index.module.scss'

type Props = {
  brandingLogoPreview?: string
}

const LogoHeader: FC<Props> = ({ brandingLogoPreview }) => {
  const { branding } = useBrandingSelector()

  return (
    <div className={s.LogoHeader}>
      <img src={brandingLogoPreview || branding?.logo || headerLogo} alt="" />
      <div />
    </div>
  )
}

export default LogoHeader
