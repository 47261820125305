import classNames from 'classnames'
import { FC, useCallback, useMemo } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useHistory, useLocation } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch, usePipelineSelector } from 'store'

import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StageType } from '@journeyid/agent/types'

import { t } from 'translation'

import { reorderStages } from 'thunks/api/pipelines/reorder-stages'
import { stageTitleMap } from 'util/stage-titles'

import s from './StageList.module.scss'

type Props = {
  stageTypes: StageType[]
}

const StageList: FC<Props> = ({ stageTypes }) => {
  const history = useHistory()
  const location = useLocation()

  const { pipeline } = usePipelineSelector()

  const activeIdx = useMemo(() => {
    const step = location.pathname.split('/')
    const list = [...(pipeline?.stages || []), { type: 'success-screen' }]
    return list.findIndex(s => s.type === step[5])
  }, [pipeline, location])

  const dispatch = useAppDispatch()
  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination || destination.index === source.index) {
        return
      }

      dispatch(
        reorderStages({
          id: pipeline.id,
          fromIndex: source.index,
          toIndex: destination.index,
        })
      )
    },
    [pipeline.id, dispatch]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <ul ref={provided.innerRef} {...provided.droppableProps} className={s.StageList}>
            {pipeline.stages.map((stage, idx) => (
              <Draggable key={stage.type} draggableId={stage.type} index={idx}>
                {provided => (
                  <li
                    key={stage.type}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={classNames(idx === activeIdx && s.Active)}
                    onClick={() =>
                      history.push(r.pipelines.create.configure.stage(pipeline.id, stage.type))
                    }
                  >
                    <div {...provided.dragHandleProps}>
                      <FontAwesomeIcon icon={faGripDotsVertical} />
                    </div>
                    <label>{stageTitleMap[stage.type]}</label>
                  </li>
                )}
              </Draggable>
            ))}

            {provided.placeholder}

            <li
              className={classNames(activeIdx === (pipeline?.stages || []).length && s.Active)}
              onClick={() => history.push(r.pipelines.create.configure.successScreen(pipeline.id))}
            >
              <div>
                <FontAwesomeIcon
                  icon={faGripDotsVertical}
                  className="text-gray-300 cursor-not-allowed"
                />
              </div>
              <label>{t('Success Screen')}</label>
            </li>

            <li
              className={s.AddAction}
              onClick={() => history.push(r.pipelines.create.configure.stages(pipeline.id))}
            >
              <FontAwesomeIcon icon={faPlus} />
              <label>{t('Add Action')}</label>
            </li>
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default StageList
