import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

type Response = {
  clientId: string
  clientSecret: string
}

export const updateClientCredentials = asyncThunk<Request, Response>({
  name: 'account/update-client-credentials',
  uri: ({ id }) => `/accounts/${id}/client-credentials`,
  method: 'PUT',
  orchestrator: false,
  stripId: true,
})
