import { FC } from 'react'

import PhoneNumberRegistrationPreview from 'components/templates/stages/PhoneNumberRegistration.Preview'
import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'

const PhoneNumberRegistration: FC = () => {
  return (
    <NoSettingsStage
      stageType="phone-number-registration"
      preview={PhoneNumberRegistrationPreview}
    />
  )
}

export default PhoneNumberRegistration
