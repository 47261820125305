import { useCallback } from 'react'
import { useAppDispatch } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import useActionTimer from 'hooks/useActionTimer'
import { magicLinksAuthenticate, Request } from 'thunks/api/magic_links/authenticate'

export default function useMagicLinkAuthenticate() {
  const timer = useActionTimer()
  const dispatch = useAppDispatch()

  const authenticate = useCallback(
    async (req: Request) => {
      try {
        timer.start()

        const resp = await dispatch(magicLinksAuthenticate(req)).then(unwrapResult)

        timer.succeeded()

        return resp
      } catch (err) {
        timer.failed()
        throw err
      }
    },
    [timer, dispatch]
  )

  return [authenticate, { timer }] as const
}
