import { HTTPCreated } from '@journeyid/agent/common/statuses'

import { Admin, AdminRole } from 'types/admin'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  email: string
  role: AdminRole
  groups: string[]
  accountId: string
  language: 'en'
}

export type Response = Admin

export const createInvitation = asyncThunk<Request, Response>({
  name: 'invitations/create',
  method: 'POST',
  uri: '/invitations',
  orchestrator: false,
  successStatus: HTTPCreated,
})
