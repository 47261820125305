import { useCallback } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { Request, updateAgentAuth } from 'thunks/api/accounts/update-agent-auth'

export function useUpdateAgentAuth() {
  const [run, { timer }] = useAPI(updateAgentAuth)
  const { accountId } = useAuthSelector()

  const update = useCallback(
    async (req: Omit<Request, 'id'>) => {
      if (!accountId) return
      await run({ ...req, id: accountId })
    },
    [accountId, run]
  )

  return [update, { timer }] as const
}
