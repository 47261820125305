export type DeploymentModel = 'global-cloud' | 'local-cloud' | 'single-tenant' | 'on-premise'

export const partnerDiscount = 0.2

export const deploymentModelCosts: Record<DeploymentModel, number> = {
  'global-cloud': 0,
  'local-cloud': 5000,
  'single-tenant': 10000,
  'on-premise': 20000,
}

export type AgentAuthentication = {
  enrollments: number
  ssoAuthentications: number
  continuousAuthenticationAgents: number
}

export const agentAuthenticationCosts: Record<keyof AgentAuthentication, number> = {
  enrollments: 8.5,
  ssoAuthentications: 0.25,
  continuousAuthenticationAgents: 15,
}

export type CustomerInteractions = {
  standard: number
  advanced: number
  voiceBiometricEnrollments: number
  voiceBiometricAuthentications: number
}

export const customerInteractionCosts: Record<keyof CustomerInteractions, [number, number][]> = {
  standard: [
    [0, 0],
    [10_000, 0.13],
    [25_000, 0.125],
    [50_000, 0.125],
    [100_000, 0.12],
    [250_000, 0.11],
    [500_000, 0.105],
    [1_000_000, 0.09],
  ],
  advanced: [
    [0, 0],
    [10_000, 0.44],
    [25_000, 0.42],
    [50_000, 0.4],
    [100_000, 0.38],
    [250_000, 0.36],
    [500_000, 0.33],
    [1_000_000, 0.31],
  ],
  voiceBiometricEnrollments: [
    [0, 0],
    [2_500, 1.25],
    [5_000, 1.1],
    [10_000, 0.95],
    [25_000, 0.8],
  ],
  voiceBiometricAuthentications: [
    [0, 0],
    [2_500, 0.25],
    [5_000, 0.24],
    [10_000, 0.23],
    [25_000, 0.22],
    [50_000, 0.21],
    [100_000, 0.19],
    [250_000, 0.17],
    [500_000, 0.15],
  ],
}
