import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Language } from '@journeyid/agent/common/types/language'

import { t } from 'translation'

import PhoneNumberInputConnected from 'components/atoms/PhoneNumberInputConnected'
import CodeBlock from 'components/molecules/CodeBlock'
import Field, { Option } from 'components/molecules/Field'

import codeTemplate from './codeTemplate'

type Props = {
  pipelineKey: string
}

const deliveryMethodOptions = [
  { id: 'sms', label: 'SMS' },
  { id: 'email', label: 'Email' },
]

const languageOptions: {
  id: Language
  label: string
}[] = [
  { id: 'en-US', label: t('English (US)') },
  { id: 'es-MX', label: t('Spanish (MX)') },
  { id: 'pt-BR', label: t('Portuguese (BR)') },
  { id: 'ja-JP', label: t('Japanese') },
]

type Inputs = {
  deliveryMethod: 'sms' | 'email'
  phoneNumber: number
  email: string
  customerId: string
  sessionRef: string
  callbackUrl: string
  language: Language
}

const ApiRequestBuilder: FC<Props> = ({ pipelineKey }) => {
  const form = useForm<Inputs>({
    defaultValues: {
      deliveryMethod: 'sms',
      customerId: 'john-smith-123',
      sessionRef: 'call-id-456',
      callbackUrl: 'https://example.com/path/to/webhook',
      language: 'en-US',
    },
  })
  const { handleSubmit, register, watch } = form

  const submit = (data: Inputs) => {
    console.log(data)
  }

  const { deliveryMethod, phoneNumber, email, customerId, sessionRef, callbackUrl, language } =
    watch([
      'deliveryMethod',
      'phoneNumber',
      'email',
      'sessionRef',
      'customerId',
      'callbackUrl',
      'language',
    ])

  return (
    <>
      <h4 className="mb-4 std">{t('API Request Builder')}</h4>
      <div>
        <p className="text-sm font-semibold !text-gray-800">
          {t('How the request will be delivered:')}
        </p>
        <FormProvider {...form}>
          <form onChange={handleSubmit(submit)}>
            <div className="flex gap-3">
              <Field
                ref={register}
                name="deliveryMethod"
                as="select"
                placeholder={t('Enter URL here')}
                className="w-24 mb-4"
              >
                {deliveryMethodOptions.map((deliveryMethod, idx) => (
                  <Option key={idx} value={deliveryMethod.id}>
                    {deliveryMethod.label}
                  </Option>
                ))}
              </Field>

              {deliveryMethod === 'sms' && (
                <div className="flex flex-1">
                  <Field name="phoneNumber" className="w-full">
                    <PhoneNumberInputConnected name="phoneNumber" className="pl-16" />
                  </Field>
                </div>
              )}

              {deliveryMethod === 'email' && (
                <Field
                  ref={register}
                  id="email"
                  name="email"
                  autoComplete="off"
                  placeholder="john.smith@company.com"
                  className="flex-grow mb-4"
                  inputClassName="py-2 px-3"
                />
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      <Field
        ref={register}
        id="customerId"
        name="customerId"
        autoComplete="off"
        label={t('Customer identifier')}
        placeholder="john-smith-123"
        className="flex-grow mb-4"
        inputClassName="py-2 px-3"
      />
      <Field
        ref={register}
        id="sessionRef"
        name="sessionRef"
        autoComplete="off"
        label={t('Session Reference')}
        placeholder="call-id-456"
        className="flex-grow mb-4"
        inputClassName="py-2 px-3"
      />
      <Field
        ref={register}
        id="callbackUrl"
        name="callbackUrl"
        autoComplete="off"
        label={t('Webhook callback URL')}
        placeholder="https://example.com/path/to/webhook"
        className="flex-grow mb-4"
        inputClassName="py-2 px-3"
      />
      <Field
        ref={register}
        name="language"
        label="Language"
        as="select"
        placeholder={t('Enter URL here')}
        className="mb-8"
      >
        {languageOptions.map((option, idx) => (
          <Option key={idx} value={option.id}>
            {option.label}
          </Option>
        ))}
      </Field>
      <CodeBlock
        template={codeTemplate(
          pipelineKey,
          deliveryMethod,
          phoneNumber ? `${phoneNumber}` : '1##########',
          email ? email : 'john.smith@company.com',
          customerId,
          sessionRef,
          callbackUrl,
          language
        )}
      />
    </>
  )
}
export default ApiRequestBuilder
