import cogoToast from 'cogo-toast'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSet } from 'react-use'
import r from 'routes'
import { t } from 'translation'

import Button from 'components/atoms/Button'
import Checkbox from 'components/atoms/Checkbox'
import Modal from 'components/organisms/Modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal, useModal } from 'hooks/useModal'
import { updateAdminGroups } from 'thunks/api/admins/groups'
import { Admin } from 'types/admin'
import { Group } from 'types/group'

type Props = {
  admin: Admin
  groups: Group[]
  onUpdate: (groups: Group[]) => void
}

const SupervisorGroupsModal: FC<Props> = ({ admin, groups, onUpdate }) => {
  const [isOpen] = useModal('SupervisorGroups')
  const [groupIds, { has, toggle }] = useSet<string>(new Set(admin.groups.map(group => group.id)))
  const history = useHistory()

  const [update, { timer }] = useAPI(updateAdminGroups)
  const handleSubmit = useCallback(async () => {
    await update({
      adminId: admin.id,
      groups: Array.from(groupIds),
    })

    const theGroups = groups.filter(group => groupIds.has(group.id))
    onUpdate(theGroups)

    cogoToast.success(`The admin's groups were updated`)
    closeModal()
  }, [update, onUpdate, admin, groupIds, groups])

  return (
    <Modal isOpen={isOpen} closeButton onClose={closeModal}>
      <Modal.Header>{t(`Update supervisor's agent groups`)}</Modal.Header>
      <Modal.Body className="py-4 space-y-1">
        {groups.map(group => (
          <Checkbox
            key={group.id}
            name={group.id}
            type="checkbox"
            checked={has(group.id)}
            onChange={() => toggle(group.id)}
          >
            {group.name}
          </Checkbox>
        ))}
        {groups.length === 0 && <p>No groups have been added yet.</p>}
      </Modal.Body>
      <Modal.Actions>
        {groups.length > 0 ? (
          <>
            <Button disabled={timer.isLoading} onClick={handleSubmit}>
              {timer.isLoading ? t('Saving...') : t('Save')}
            </Button>
            <Button variant="tertiary" onClick={closeModal}>
              {t('Cancel')}
            </Button>
          </>
        ) : (
          <Button onClick={() => history.push(r.settings.groups)}>Add a Group</Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default SupervisorGroupsModal
