import cogoToast from 'cogo-toast'
import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Pipeline } from 'types'
import * as yup from 'yup'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import Button from 'components/atoms/Button'
import InputGroup from 'components/atoms/InputGroup'
import Field, { Option } from 'components/molecules/Field'
import { useAPI } from 'hooks/useAPI'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { createDesktop } from 'thunks/api/desktops/create'
import { listIFrames } from 'thunks/api/iframes/list'
import { listPipelines } from 'thunks/api/pipelines/list'
import { Desktop } from 'types/desktop'

import s from './Form.module.scss'

const schema = yup.object().shape({
  identifier: yup.string().required('A URL identifier is required'),
  pipelineId: yup.string().required('A pipeline is required'),
  iframeId: yup.string().required('An IFrame is required'),
})

type FormData = {
  identifier: string
  pipelineId: string
  iframeId: string
  loginPrompt: string
}

type Props = {
  onCreate: (desktop: Desktop) => void
}

const AgentDesktopForm: FC<Props> = ({ onCreate }) => {
  const resolver = useYupValidationResolver(schema)
  const { register, handleSubmit, setError, errors, reset } = useForm<FormData>({ resolver })

  const [list] = useAPI(listPipelines)
  const [pipelines, setPipelines] = useState<Pipeline[]>()
  useEffect(() => {
    list({ published: true }).then(setPipelines)
  }, [list])

  const [listI] = useAPI(listIFrames)
  const [iframes, setIFrames] = useState<{ id: string; title: string }[]>()
  useEffect(() => {
    listI({}).then(setIFrames)
  }, [listI])

  const [create, { timer }] = useAPI(createDesktop, {
    setError,
  })
  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        const desktop = await create(data)

        onCreate(desktop)
        reset()
      } catch (err) {
        if (!isAPIErrorResponse(err)) {
          cogoToast.error('Unable to create the desktop')
        }
      }
    },
    [create, onCreate, reset]
  )

  if (!pipelines || !iframes) return null

  return (
    <>
      <form className={s.Form} onSubmit={handleSubmit(onSubmit)}>
        <Field label="Access URL" name="identifier" error={errors.identifier}>
          <InputGroup ref={register} name="identifier" prefix={`${_env_.AUTH_ORIGIN}/desktop/`} />
        </Field>

        <Field
          ref={register}
          as="select"
          label="Authentication Pipeline"
          name="pipelineId"
          placeholder=""
          error={errors.pipelineId}
        >
          {pipelines.map(pipeline => (
            <Option key={pipeline.id} value={pipeline.id}>
              {pipeline.title}
            </Option>
          ))}
        </Field>

        <Field
          ref={register}
          as="select"
          label="Desktop IFrame"
          name="iframeId"
          error={errors.iframeId}
          placeholder=""
        >
          {iframes.map(iframe => (
            <Option key={iframe.id} value={iframe.id}>
              {iframe.title}
            </Option>
          ))}
        </Field>

        <Field
          ref={register}
          label="Login prompt"
          name="loginPrompt"
          placeholder="Enter your ..."
          hint="This will be shown to the agent when authenticating. Examples are 'Enter your email address' or 'Enter your employee ID'"
        />

        <Button type="submit" isLoading={timer.isLoading} disabled={timer.isLoading}>
          {timer.isLoading ? 'Adding' : 'Add'}
        </Button>
      </form>
    </>
  )
}

export default AgentDesktopForm
