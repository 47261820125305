import { forwardRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import PhoneNumberInput from 'components/atoms/PhoneNumberInput'

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'ref'> & {
  name: string
}

const PhoneNumberInputConnected = forwardRef<HTMLInputElement, Props>(
  ({ name, className, ...rest }, ref) => {
    const { control } = useFormContext()

    return (
      <Controller
        name={name}
        defaultValue={null}
        control={control}
        render={({ value, onChange }: { value: string; onChange: (value: string) => void }) => (
          <PhoneNumberInput ref={ref} name={name} value={value} onChange={onChange} {...rest} />
        )}
      />
    )
  }
)

export default PhoneNumberInputConnected
