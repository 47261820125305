import { FC } from 'react'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import { ActionTimer } from 'hooks/useActionTimer'

import s from './Footer.module.scss'

type Props = {
  showValidationError?: boolean
  timer?: ActionTimer
}

const Footer: FC<Props> = ({ showValidationError, timer }) => (
  <div className={s.Footer}>
    {showValidationError && (
      <Alert className="mr-4" type="error">
        {t('There were validation errors in your submission')}
      </Alert>
    )}
    {timer && (
      <Button type="submit" size="xl" isLoading={timer.isLoading} disabled={timer.isLoading}>
        {timer.didSucceed ? t('Saved!') : t('Save')}
      </Button>
    )}
  </div>
)

export default Footer
