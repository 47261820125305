import { Account } from 'types/account'
import { OIDCConfiguration } from 'types/oidc'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
} & OIDCConfiguration

export type Response = {
  id: Account['id']
  discoveryUri: string
}

export const createOIDC = asyncThunk<Request, Response>({
  name: 'accounts/sso/oidc/create',
  uri: ({ id }) => `/accounts/${id}/sso/oidc`,
  method: 'POST',
  orchestrator: false,
  stripId: true,
})
