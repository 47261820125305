import { FC, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePipelineSelector } from 'store'
import * as yup from 'yup'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Field from 'components/molecules/Field'
import { idleTimer } from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { updateOAuth } from 'thunks/api/pipelines/oauth'

import AuthorizedRedirectUrlList from './AuthorizedRedirectUrlList'
import s from './AuthorizedRedirectUrls.module.scss'

type FormData = {
  redirectURI: string
}

const schema = yup.object().shape({
  redirectURI: yup
    .string()
    .matches(/^https:\/\/.+$/, 'Redirect URI must begin with https://')
    .required(''),
})

const AuthorizedRedirectUrls: FC = () => {
  const { pipeline } = usePipelineSelector()
  const [update, { timer }] = useAPI(updateOAuth)
  const [actionMode, setActionMode] = useState<'add' | 'remove'>()

  const resolver = useYupValidationResolver(schema)
  const { register, errors, handleSubmit, setValue } = useForm<FormData>({
    resolver,
  })

  const onSubmit = useCallback(
    async (data: FormData) => {
      const redirectURIs = [...(pipeline.oauth?.redirectURIs || []), data.redirectURI]

      setActionMode('add')

      await update({
        id: pipeline.id,
        redirectURIs,
      })

      setValue('redirectURI', '')
    },
    [pipeline, update, setValue]
  )

  const handleRemove = useCallback(
    async (idx: number) => {
      if (!pipeline.oauth?.redirectURIs) return

      const redirectURIs = pipeline.oauth.redirectURIs.filter((_, i) => i !== idx)

      setActionMode('remove')

      await update({
        id: pipeline.id,
        redirectURIs,
      })
    },
    [pipeline, update]
  )

  return (
    <>
      <h4 className="mb-4 std">{t('Authorized Redirect URLs')}</h4>
      <form className={s.AuthorizedRedirectUrls} onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="redirectURI"
          label={t('Redirect URI')}
          wrapperClassName="w-full !max-w-lg space-x-4 flex"
          className="flex-1"
          error={errors.redirectURI}
        >
          <Input ref={register} name="redirectURI" placeholder="https://" />
          <Button
            type="submit"
            variant="secondary"
            size="lg"
            isLoading={timer.isLoading && actionMode === 'add'}
          >
            {timer.isLoading && actionMode === 'add' ? t('Adding...') : t('Add')}
          </Button>
        </Field>
      </form>
      <AuthorizedRedirectUrlList
        redirectUris={pipeline.oauth?.redirectURIs || []}
        timer={actionMode === 'remove' ? timer : idleTimer}
        onRemove={handleRemove}
      />
    </>
  )
}
export default AuthorizedRedirectUrls
