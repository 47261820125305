import React, { FC, FormEvent, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import useMagicLinkCreate from 'hooks/api/useMagicLinkCreate'

import s from './index.module.scss'

const MagicLink: FC = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string>()
  const [wasSent, setWasSent] = useState(false)
  const [create, { timer }] = useMagicLinkCreate()

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (!email) return
      try {
        setError(undefined)

        await create({ email })
        setWasSent(true)
      } catch (err) {
        if (isAPIErrorResponse(err)) {
          if (err.errors && 'email' in err.errors) {
            setError(err.errors.email)
          } else {
            setError(err.error)
          }
        } else {
          setError(t('unable to send a magic link'))
        }
      }
    },
    [email, create]
  )

  if (wasSent) {
    return (
      <div className={s.Wrapper}>
        <div className={s.MagicLinkSent}>
          <h1>{t('A Magic Link was sent to your inbox')}</h1>
          <p>{t('The link sent to {{email}} will be active for 15 minutes.', { email })}</p>
        </div>
      </div>
    )
  }

  return (
    <div className={s.Wrapper}>
      <div className={s.MagicLink}>
        <h1>{t('Please enter your email address')}</h1>
        <p>{t("We'll send you an email with a link that instantly logs you into your account.")}</p>
        <form onSubmit={handleSubmit}>
          <input
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus
            placeholder="email@example.com"
          />

          {error && <div className={s.Error}>{error}</div>}

          <div>
            <Button isLoading={timer.isLoading} type="submit" className={s.ConfirmBtn}>
              {t('Send a Magic Link')}
            </Button>

            <button className={s.CancelBtn} onClick={() => history.push(r.auth.login)}>
              {t('Cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MagicLink
