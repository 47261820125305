import classNames from 'classnames'
import React, { FC } from 'react'

type Props = {
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const Divider: FC<Props> = ({ size = 'md', className }) => {
  return (
    <div
      className={classNames(
        'bg-gray-300 h-px',
        {
          'my-2': size === 'sm',
          'my-4': size === 'md',
          'my-6': size === 'lg',
        },
        className
      )}
    />
  )
}

export default Divider
