import { StageType } from '@journeyid/agent/types'

type ActionGroup = {
  label: string
  stageTypes: StageType[]
}

const groups: ActionGroup[] = [
  {
    label: 'Enrollment',
    stageTypes: [
      'face-enrollment',
      'active-voice-enrollment',
      'webauthn-registration',
      'device-registration',
      'totp-registration',
      'phone-number-registration',
      'pindrop-enrollment',
    ],
  },
  {
    label: 'Authentication',
    stageTypes: [
      'face-authentication',
      'device-authentication',
      'active-voice-authentication',
      'webauthn-authentication',
      'external-authentication',
      'totp-authentication',
      'phone-number-verification',
      'government-id-selfie',
      'random-code',
      'knowledge-based-authentication',
      'pindrop-authentication',
    ],
  },
  {
    label: 'Identity Verification',
    stageTypes: [
      'drivers-license',
      'passport',
      'social-security-number',
      'government-id',
      'national-id-card',
      'custom-id',
    ],
  },
  {
    label: 'Interaction',
    stageTypes: [
      'document-signature',
      'credit-card-payment',
      'address-collection',
      'document-collection',
      'form',
      'survey',
      'schedule',
      'outbound-scheduling',
      'payment-details',
      'docusign',
      'blank',
      'ocr',
    ],
  },
]

export default groups
