import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { Iframe } from 'types'

import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Divider from 'components/organisms/Preview/Divider'
import InputModal from 'components/templates/InputModal'
import { idleTimer } from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import AddCategoryButton from 'pages/IFrames/Steps/Pipelines/AddCategoryButton'
import DeleteCategoryModal from 'pages/IFrames/Steps/Pipelines/DeleteCategoryModal'
import { createIFrameCategory } from 'thunks/api/iframes/categories/create'
import { reorderIFrameCategories } from 'thunks/api/iframes/categories/reorder'
import { updateIframeCategory } from 'thunks/api/iframes/categories/update'
import { deleteIFramePipeline } from 'thunks/api/iframes/pipelines/delete'
import { reorderIFramePipelines } from 'thunks/api/iframes/pipelines/reorder'

import DashboardCategory from './DashboardCategory'
import s from './index.module.scss'
import { useAppDispatch } from 'store'
import { updateLocalIframe } from 'slices/iframe'

type Props = {
  iframe: Iframe
  className?: string
}

const PipelineCategories: FC<Props> = ({ iframe, className }) => {
  const dispatch = useAppDispatch()

  const [add, { timer }] = useAPI(createIFrameCategory)

  const handleCreateCategory = useCallback(
    async (title: string) => {
      await add({
        id: iframe.id,
        title,
      })
      closeModal()
    },
    [iframe.id, add]
  )

  const history = useHistory()
  const handleAdd = useCallback(
    (id: string | undefined) => {
      const phase = history.location.pathname.split('/')[3]
      const route = r.iframes[phase === 'create' ? 'create' : 'edit'].configure
      history.push(route.addPipeline(iframe.id, id))
    },
    [iframe.id, history]
  )

  const [remove] = useAPI(deleteIFramePipeline)
  const handleRemove = useCallback(
    (pipelineId: string) => {
      return remove({
        id: iframe.id,
        type: 'categories',
        pipelineId,
      })
    },
    [iframe.id, remove]
  )

  const [updateCategory, { timer: categoryTimer }] = useAPI(updateIframeCategory)
  const handleChange = useCallback(
    (categoryId: string, title: string) => {
      return updateCategory({
        id: iframe.id,
        categoryId,
        title,
      })
    },
    [iframe.id, updateCategory]
  )

  const [reorderPipelines] = useAPI(reorderIFramePipelines)
  const handleReorder = useCallback(
    (
      categoryId: string | undefined,
      fromIndex: number,
      toIndex: number,
      destinationCategoryId?: string
    ) => {
      reorderPipelines({
        id: iframe.id,
        type: 'categories',
        categoryId,
        destinationCategoryId,
        fromIndex,
        toIndex,
      })
    },
    [iframe.id, reorderPipelines]
  )

  const [reorderCategories] = useAPI(reorderIFrameCategories)
  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (
        !destination ||
        (source.droppableId === destination.droppableId && source.index === destination.index)
      ) {
        return
      }

      const sourceCat = source.droppableId
      const destCat = destination.droppableId

      console.log(sourceCat, destCat)

      if (sourceCat === 'categories' && destCat === 'categories') {
        reorderCategories({
          id: iframe.id,
          fromIndex: source.index + 1,
          toIndex: destination.index + 1,
        })
      } else if (sourceCat === destCat) {
        handleReorder(sourceCat, source.index, destination.index)
      } else {
        console.log(iframe.categories.map(c => c.id))
        const srcCatIdx =
          sourceCat === 'uncategorized'
            ? 0
            : iframe.categories
                .map((c, i) => [c, i] as const)
                .filter(([c, i]) => c.id === sourceCat)
                .map(([c, i]) => i)[0]
        const dstCatIdx =
          destCat === 'uncategorized'
            ? 0
            : iframe.categories
                .map((c, i) => [c, i] as const)
                .filter(([c, i]) => c.id === destCat)
                .map(([c, i]) => i)[0]

        console.log(srcCatIdx, dstCatIdx)

        const srcPipelines = iframe.categories[srcCatIdx].pipelines
        const pipeline = srcPipelines[source.index]
        const dstPipelines = iframe.categories[dstCatIdx].pipelines

        const srcCat = {
          ...iframe.categories[srcCatIdx],
          pipelines: [
            ...srcPipelines.slice(0, source.index),
            ...srcPipelines.slice(source.index + 1),
          ],
        }

        const dstCat = {
          ...iframe.categories[dstCatIdx],
          pipelines: [
            ...dstPipelines.slice(0, destination.index),
            pipeline,
            ...dstPipelines.slice(destination.index),
          ],
        }

        const categories = [
          ...iframe.categories.slice(0, Math.min(srcCatIdx, dstCatIdx)),
          srcCatIdx < dstCatIdx ? srcCat : dstCat,
          ...iframe.categories.slice(
            Math.min(srcCatIdx, dstCatIdx) + 1,
            Math.max(srcCatIdx, dstCatIdx)
          ),
          srcCatIdx < dstCatIdx ? dstCat : srcCat,
          ...iframe.categories.slice(Math.max(srcCatIdx, dstCatIdx) + 1),
        ]

        dispatch(updateLocalIframe({ ...iframe, categories }))

        handleReorder(
          sourceCat,
          source.index,
          destination.index,
          destCat ? destCat : 'uncategorized'
        )
      }
    },
    [iframe, dispatch, reorderCategories, handleReorder]
  )

  return (
    <>
      <div className={classNames(s.PipelineCategories, className)}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <DashboardCategory
            category={iframe.categories[0]}
            categoryTimer={idleTimer}
            onAdd={() => handleAdd(iframe.categories[0].id)}
            onRemove={handleRemove}
            onChange={() => {}}
            onDelete={() => {}}
          />
          <Divider size="sm" className="mt-4" />
          <AddCategoryButton onClick={openModal('Input')} />
          {iframe.categories.length > 1 && <div className={s.Categories}>{t('Categories')}</div>}
          <Droppable droppableId="categories" type="category">
            {provided => (
              <div ref={provided.innerRef}>
                {iframe.categories.slice(1).map((category, idx) => (
                  <Draggable key={category.id} draggableId={category.id} index={idx}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={s.Category}
                      >
                        <div {...provided.dragHandleProps}>
                          <FontAwesomeIcon icon={faGripDotsVertical} />
                        </div>
                        <DashboardCategory
                          key={`${idx}-${category.id}`}
                          category={category}
                          categoryTimer={categoryTimer}
                          onAdd={() => handleAdd(category.id)}
                          onRemove={handleRemove}
                          onChange={title => handleChange(category.id, title)}
                          onDelete={openModal('DeleteCategory', category.id)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <InputModal
        title={t('Add a category')}
        buttonText={t('Save')}
        label={t('Category Title')}
        loadingText={t('Saving...')}
        successText={t('Saved!')}
        timer={timer}
        onClick={handleCreateCategory}
      >
        {t(
          'By grouping similar pipelines together, agents will be able to save time by finding the right pipeline quickly.'
        )}
      </InputModal>
      <DeleteCategoryModal />
    </>
  )
}

export default PipelineCategories
