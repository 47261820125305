import asyncThunk from 'util/asyncThunk'
import { HTTPArchived } from 'util/statuses'

export type Request = {
  id: string
}

type Response = {
  id: string
}

export const archiveGroup = asyncThunk<Request, Response>({
  name: 'groups/archive',
  method: 'DELETE',
  uri: ({ id }) => `/groups/${id}`,
  successStatus: HTTPArchived,
  orchestrator: false,
})
