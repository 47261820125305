import { useEffect, useState } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { retrieveAccount } from 'thunks/api/accounts/retrieve'
import { Account } from 'types/account'

export function useAccount() {
  const [account, setAccount] = useState<Account>()
  const [run, { timer }] = useAPI(retrieveAccount)
  const { accountId } = useAuthSelector()

  useEffect(() => {
    if (!accountId) return
    run({ id: accountId }).then(setAccount)
  }, [accountId, setAccount, run])

  return [account, { timer }] as const
}
