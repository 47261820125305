import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { faCheckCircle, faExclamationCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './index.module.scss'

const icons = {
  success: faCheckCircle,
  info: faInfoCircle,
  error: faExclamationCircle,
} as const

interface Props {
  type?: 'info' | 'success' | 'error'
  icon?: boolean
  actions?: ReactNode
  className?: string
  children: ReactNode
}

const Alert: FC<Props> = ({ type = 'info', icon = false, actions, className, children }) => (
  <div
    className={classNames(
      '__Alert',
      {
        [s.Error]: type === 'error',
        [s.Success]: type === 'success',
        [s.Info]: type === 'info',
      },
      className
    )}
  >
    {icon && (
      <FontAwesomeIcon
        icon={icons[type]}
        className={classNames({
          'text-red-600': type === 'error',
          'text-green-600': type === 'success',
          'text-blue-600': type === 'info',
        })}
      />
    )}
    <p>{children}</p>
    {actions}
  </div>
)

export default Alert
