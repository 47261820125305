import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { Switch } from '@headlessui/react'

import s from './index.module.scss'

type Props = {
  enabled: boolean
  children: ReactNode
  className?: string
  onChange: (enabled: boolean) => void
}

const Toggle: FC<Props> = ({ enabled, children, className, onChange }) => {
  return (
    <div className={classNames('__Toggle', s.Toggle, className)}>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={enabled ? 'bg-theme' : 'bg-gray-200'}
      >
        <span className={classNames(enabled ? 'translate-x-5' : 'translate-x-0')}>
          <span
            className={classNames(
              enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'
            )}
            aria-hidden="true"
          >
            <svg className="w-3 h-3 text-gray-400" fill="none" viewBox="0 0 12 12">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={classNames(
              enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'
            )}
            aria-hidden="true"
          >
            <svg className="w-3 h-3 text-theme" fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
      <label onClick={() => onChange(!enabled)}>{children}</label>
    </div>
  )
}

export default Toggle
