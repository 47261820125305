import React, { FC, HTMLProps, ReactElement } from 'react'

import Select from 'components/atoms/Select'

import Field, { CommonProps } from './_Wrapper'

type SelectProps = Omit<
  HTMLProps<HTMLSelectElement>,
  'ref' | 'label' | 'name' | 'value' | 'onChange' | 'children'
>

type OptionProps = {
  value: string
  children: string
}

export type Props = CommonProps & {
  value: string
  onChangeValue: (value: string) => void
  children: ReactElement<OptionProps>[]
} & SelectProps

const Dropdown: FC<Props> = ({ value, onChangeValue, children, ...rest }) => {
  const { label, name, hint, isOptional, error, aside, className, ...inputProps } = rest
  const fieldProps = { label, name, hint, isOptional, error, aside, className }

  return (
    <Field {...fieldProps}>
      <Select value={value} onChangeText={onChangeValue} {...inputProps}>
        <option value="" />
        {children}
      </Select>
    </Field>
  )
}

export default Dropdown
