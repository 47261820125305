import classNames from 'classnames'
import { FC, ReactNode, useMemo } from 'react'
import { Pipeline } from 'types'

import PipelineList from 'components/organisms/PipelineList'
import { ActionTimer } from 'hooks/useActionTimer'

import s from './index.module.scss'
import PipelineSelect from './PipelineSelect'

type Props = {
  label: string
  pipelines: Pick<Pipeline, 'id' | 'title'>[]
  allPipelines: Pick<Pipeline, 'id' | 'title'>[]
  addTimer: ActionTimer
  removeTimer: ActionTimer
  children: ReactNode
  className?: string
  onAdd: (pipelineId: string) => void
  onRemove: (pipelineId: string) => void
  onReorder: (fromIndex: number, toIndex: number) => void
}

const PipelineSet: FC<Props> = ({
  label,
  pipelines,
  allPipelines,
  addTimer,
  removeTimer,
  children,
  className,
  onAdd,
  onRemove,
  onReorder,
}) => {
  const availablePipelines = useMemo(
    () => allPipelines.filter(p => pipelines.every(pi => pi.id !== p.id)),
    [pipelines, allPipelines]
  )

  return (
    <div className={classNames(s.PipelineSet, className)}>
      <p className="mb-1 text-lg font-semibold">{label}</p>
      <p className="mb-4 text-gray-500">{children}</p>

      <PipelineList
        pipelines={pipelines}
        timer={removeTimer}
        onReorder={onReorder}
        onRemove={onRemove}
      />

      <PipelineSelect
        key={pipelines.length}
        showSelect={pipelines.length === 0}
        pipelines={availablePipelines}
        timer={addTimer}
        onSelect={onAdd}
      />
    </div>
  )
}

export default PipelineSet
