import { HTTPRetrievedMultiple } from '@journeyid/agent/common/statuses'

import { User, UserType } from 'types/user'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  accountId: string
  userType: UserType
}

export const listUsers = asyncThunk<Request, User[]>({
  name: 'users/list',
  uri: '/users',
  orchestrator: false,
  successStatus: HTTPRetrievedMultiple,
})
