import { FC } from 'react'

import { ReactComponent as DocumentCollection } from './illustrations/document-collection.svg'
import { ReactComponent as FacialBiometrics } from './illustrations/facial-biometrics.svg'
import { ReactComponent as SuccessScreen } from './illustrations/success-screen.svg'
import { ReactComponent as TOTPAuthentication } from './illustrations/totp-authentication.svg'
import { ReactComponent as Webauthn } from './illustrations/webauthn.svg'
import s from './index.module.scss'

const illustrations = {
  'facial-biometrics': FacialBiometrics,
  'document-collection': DocumentCollection,
  'success-screen': SuccessScreen,
  'totp-authentication': TOTPAuthentication,
  webauthn: Webauthn,
} as const

type Props = {
  type: keyof typeof illustrations
}

const Illustration: FC<Props> = ({ type }) => {
  const I = illustrations[type]

  return (
    <main className={s.Main}>
      <I className={s.Illustration} />
    </main>
  )
}

export default Illustration
