import { FC } from 'react'
import { Link } from 'react-router-dom'
import r from 'routes'

import Button from 'components/atoms/Button'
import { ActionTimer } from 'hooks/useActionTimer'
import { Desktop } from 'types/desktop'

import s from './List.module.scss'

type Props = {
  timer: ActionTimer
  desktops: Desktop[]
  onDelete: (id: string) => void
}

const List: FC<Props> = ({ timer, desktops, onDelete }) => {
  return (
    <ul className={s.List}>
      {desktops.map(desktop => (
        <li key={desktop.id}>
          <div>
            <a
              href={`${_env_.AUTH_ORIGIN}/desktop/${desktop.identifier}`}
              target="_blank"
              rel="noreferrer"
            >
              {_env_.AUTH_ORIGIN}/desktop/{desktop.identifier}
            </a>
            <div>
              <Link to={r.pipelines.edit.root(desktop.pipeline.id)}>{desktop.pipeline.title}</Link>
              <Link to={r.iframes.edit.root(desktop.iframe.id)}>{desktop.iframe.title}</Link>
            </div>
          </div>
          <Button
            isLoading={timer.isLoading && timer.id === desktop.id}
            variant="secondary"
            onClick={() => onDelete(desktop.id)}
          >
            {timer.isLoading && timer.id === desktop.id ? 'Deleting' : 'Delete'}
          </Button>
        </li>
      ))}
    </ul>
  )
}

export default List
