import { useEffect, useState } from 'react'

export function useWatchLocalStorage(key: string): string | null {
  const startingValue = localStorage.getItem(key)
  const [value, setValue] = useState(startingValue)

  useEffect(() => {
    const onChange = ({ key: evKey, newValue }: StorageEvent) => {
      if (key !== evKey) return

      if (newValue !== value) {
        setValue(newValue)
      }
    }

    window.addEventListener('storage', onChange)
    return () => {
      window.removeEventListener('storage', onChange)
    }
  }, [key, value])

  return value
}
