import { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelinesSelector } from 'store'

import { faPlus, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Header from 'components/molecules/AdminHeader'
import { useAPI } from 'hooks/useAPI'
import PipelineList from 'pages/Pipelines/List/PipelineList'
import { listPipelines } from 'thunks/api/pipelines/list'

import s from './index.module.scss'

const PipelinesList: FC = () => {
  const history = useHistory()
  const { isLoaded } = usePipelinesSelector()

  const [pipelineList] = useAPI(listPipelines)

  const handleGetPipelines = useCallback(async () => {
    try {
      await pipelineList({})
    } catch (err) {
      console.error('Unable to get pipelines:', err)
    }
  }, [pipelineList])

  useEffect(() => {
    handleGetPipelines()
  }, [handleGetPipelines, pipelineList])

  if (!isLoaded) {
    return (
      <div className="flex justify-center">
        <FontAwesomeIcon icon={faSpinnerThird} className="mt-32 text-gray-300" size="6x" spin />
      </div>
    )
  }

  return (
    <ContentWrapper>
      <Header title={t('Pipelines')}>
        <button
          className={s.CreateUserBtn}
          onClick={() => {
            history.push(r.pipelines.create.useCase())
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          {t('Create new')}
        </button>
      </Header>

      <div className={s.ListWrapper}>
        <div>
          <div>
            <PipelineList />
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default PipelinesList
