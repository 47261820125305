import { FC } from 'react'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import IntegrationLayout from '../IntegrationLayout'
import ApiRequestBuilder from './components/ApiRequestBuilder'
import PipelineKey from './components/PipelineKey'

const Api: FC = () => {
  const { pipeline } = usePipelineSelector()

  return (
    <IntegrationLayout
      id="api-page"
      title={t('API')}
      description={t(
        'Follow these instructions if you wish to initiate a request via the Journey API.'
      )}
    >
      <PipelineKey pipelineKey={pipeline.key || ''} />
      <hr />
      <ApiRequestBuilder pipelineKey={pipeline.key || ''} />
    </IntegrationLayout>
  )
}

export default Api
