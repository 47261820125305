import { FC } from 'react'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

const TOTPAuthenticationPreview: FC = () => {
  return (
    <>
      <LogoHeader />

      <Header headline={t('One-Time Password Authentication')}>
        {t('Enter a One-Time Password from your authenticator app')}
      </Header>

      <main className="px-4 pt-8 w-[324px]">
        <div className="flex items-center space-x-2 pointer-events-none">
          <Input />
          <Input />
          <Input />
          <div>-</div>
          <Input />
          <Input />
          <Input />
        </div>
      </main>

      <footer>
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Submit')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default TOTPAuthenticationPreview
