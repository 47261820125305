import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Pipeline } from 'types'
import * as yup from 'yup'

import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Input from 'components/atoms/Input'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import Toggle from 'components/atoms/Toggle'
import Header from 'components/molecules/AdminHeader'
import Field from 'components/molecules/Field'
import PipelineSelect from 'components/organisms/PipelineSelect'
import { useRetrieveOIDC } from 'hooks/api/useRetrieveOIDC'
import { useSendOIDCEmail } from 'hooks/api/useSendOIDCEmail'
import { useCreateOIDC } from 'hooks/api/useCreateOIDC'
import { useUpdateOIDC, ValidationErrorCallback } from 'hooks/api/useUpdateOIDC'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'

import s from './CustomerRegistration.module.scss'

type FormData = {
  referenceIdentifier: string
  discoveryUri: string
  scopes: string
  clientId: string
  clientSecret: string
  redirectUri: string
  urlScheme: string
  identifyingClaim: 'sub' | 'email' | 'preferred_username'
}

const schema = yup.object().shape({
  referenceIdentifier: yup.string().required(t('The reference identifier must be specified')),
  discoveryUri: yup
    .string()
    .url(t('The discovery URI must be a valid URL'))
    .required(t('The discovery URI must be specified')),
  scopes: yup.string().required(t('The OAuth scopes must be specified')),
  clientId: yup.string().required(t('The client ID must be specified')),
  clientSecret: yup.string().required(t('The client secret must be specified')),
  redirectUri: yup
    .string()
    .url(t('The redirect URI must be a valid URL'))
    .required(t('The redirect URI must be specified')),
  urlScheme: yup.string().required(t('The URL scheme must be specified')),
  identifyingClaim: yup
    .string()
    .oneOf(['sub', 'email', 'preferred_username'])
    .required(
      t("The identifying claim must be specified as one of 'sub', 'email', or 'preferred_username'")
    ),
})

const CustomerRegistration: FC = () => {
  const [enrollEmail, setEnrollEmail] = useState<string>('')

  const [send, { timer: sendTimer }] = useSendOIDCEmail()

  const sendEmail = useCallback(() => {
    if (sendTimer.isLoading) return
    send(enrollEmail).then(() => setEnrollEmail(''))
  }, [send, enrollEmail, sendTimer])

  const resolver = useYupValidationResolver(schema)
  const form = useForm<FormData>({ resolver, shouldUnregister: false, reValidateMode: 'onSubmit' })
  const { handleSubmit, register, errors, setError, setValue } = form

  const [oidc, { timer: retrieveTimer }] = useRetrieveOIDC()
  const [ssoId, setSSOId] = useState<string>('')
  const [initPipelineId, setInitPipelineId] = useState<string>()
  const [pipeline, setPipeline] = useState<Pipeline>()
  const [phoneId, setPhoneId] = useState<boolean>(false)

  useEffect(() => {
    if (!oidc) return
    setSSOId(oidc.ssoId)
    if (oidc.oidc) {
      setValue('referenceIdentifier', oidc.oidc.referenceIdentifier)
      setValue('discoveryUri', oidc.oidc.discoveryUri)
      setValue('scopes', oidc.oidc.scopes)
      setValue('clientId', oidc.oidc.clientId)
      setValue('clientSecret', oidc.oidc.clientSecret)
      setValue('redirectUri', oidc.oidc.redirectUri)
      setValue('urlScheme', oidc.oidc.urlScheme)
      setValue('identifyingClaim', oidc.oidc.identifyingClaim)
      setInitPipelineId(oidc.oidc.pipelineId)
      setPhoneId(oidc.oidc.usePhoneNumberAsUniqueID ? true : false)
    }
  }, [oidc, setValue])

  const [create, { timer: createTimer }] = useCreateOIDC(setError as ValidationErrorCallback)
  const [update, { timer: updateTimer }] = useUpdateOIDC(setError as ValidationErrorCallback)

  const hasPhoneReg = useMemo(
    () =>
      pipeline !== undefined &&
      pipeline.stages.filter(s => s.type === 'phone-number-registration').length > 0,
    [pipeline]
  )

  const onSubmit = useCallback(
    (req: FormData) => {
      const o = {
        ...req,
        pipelineId: pipeline?.id,
        usePhoneNumberAsUniqueID: pipeline ? hasPhoneReg && phoneId : undefined,
      }
      if (oidc) {
        update(o)
      } else {
        create(o)
      }
    },
    [update, pipeline, hasPhoneReg, phoneId]
  )

  return (
    <FormProvider {...form}>
      <form className="h-full" onSubmit={handleSubmit(onSubmit)}>
        <ContentWrapper withFooter>
          <Header title={t('Settings')} />
          <div className={s.Content}>
            <Menu />
            <div>
              <p className="mt-4 mb-8 text-4xl font-semibold">{t('Customer Registration')}</p>
              <p className="max-w-4xl mb-4 text-gray-500">
                {t(
                  "Using OIDC and the Journey API, customers can be registered by client devices such as mobile apps. When an authorization code is provided, we'll use the OIDC user info endpoint to retrieve basic details about the user including name and contact methods to provision a customer account."
                )}
              </p>
              <a
                href="https://developer.journeyid.com/docs/single-sign-on-oauth-2"
                target="_blank"
                className={s.Link}
                rel="noreferrer"
              >
                {t('View the OIDC API documentation here')} {'>'}
              </a>

              {retrieveTimer.isLoading ? (
                <div>
                  <Loading className="mt-8" />
                </div>
              ) : (
                <>
                  <div className={s.Redirect}>
                    <FontAwesomeIcon className="text-blue-600" icon={faInfoCircle} />
                    <p>
                      {t('{{- url}} must be one of the registered redirect URIs', {
                        url: `${window.location.origin}/sso/oidc/redirect`,
                      })}
                    </p>
                  </div>
                  <SettingsCard>
                    <p className="mb-2 text-sm font-bold text-gray-900">
                      {t('Send an enrollment email:')}
                    </p>
                    <div className={s.Enroll}>
                      <Input
                        className={s.SendInput}
                        onChangeText={setEnrollEmail}
                        placeholder="coworker@example.com"
                        value={enrollEmail}
                      />
                      <button className={s.SendBtn} onClick={sendEmail}>
                        {sendTimer.isLoading ? (
                          <Loading className="text-white" size="2x" />
                        ) : (
                          t('Send')
                        )}
                      </button>
                    </div>
                  </SettingsCard>
                  <SettingsCard>
                    <p className="mb-2 text-sm font-bold text-gray-900">
                      {t('Enrollment Identifier')}
                    </p>
                    <Field
                      ref={register}
                      id="referenceIdentifier"
                      name="referenceIdentifier"
                      className={s.Input}
                      placeholder={t('Elevate Company Login')}
                      error={errors.referenceIdentifier}
                    />
                    <p className="mb-2 text-sm text-gray-500">
                      {t(
                        'This reference will be in enrollment text messages and emails. It should be short and only contain letters, numbers, and dashes.'
                      )}
                    </p>
                    <hr className="mb-4" />
                    <p className="mb-2 text-sm font-bold text-gray-900">
                      {t('OpenID Connect Discovery Document URI')}
                    </p>
                    <Field
                      ref={register}
                      id="discoveryUri"
                      name="discoveryUri"
                      className={s.Input}
                      placeholder="https://company.com/.well-known/openid-configuration"
                      error={errors.discoveryUri}
                    />
                    <hr className="my-4" />
                    <p className="mb-2 text-sm font-bold text-gray-900">{t('OAuth Scopes')}</p>
                    <Field
                      ref={register}
                      id="scopes"
                      name="scopes"
                      className={s.Input}
                      placeholder="openid email"
                      error={errors.scopes}
                    />
                    <p className="mb-2 text-sm text-gray-500">
                      {t('To use the required Google scopes click')}{' '}
                      <span
                        className={s.Link}
                        onClick={() => setValue('scopes', 'openid email profile')}
                      >
                        {t('here')}
                      </span>
                    </p>
                    <hr className="mb-4" />
                    <p className="mb-2 text-sm font-bold text-gray-900">{t('Client ID')}</p>
                    <Field
                      ref={register}
                      id="clientId"
                      name="clientId"
                      className={s.Input}
                      placeholder={t('OIDC Client ID')}
                      error={errors.clientId}
                    />
                    <hr className="my-4" />
                    <p className="mb-2 text-sm font-bold text-gray-900">{t('Client Secret')}</p>
                    <Field
                      ref={register}
                      id="clientSecret"
                      name="clientSecret"
                      className={s.Input}
                      placeholder={t('OIDC Client Secret')}
                      error={errors.clientSecret}
                    />
                    <hr className="my-4" />
                    <p className="mb-2 text-sm font-bold text-gray-900">{t('Redirect URI')}</p>
                    <Field
                      ref={register}
                      id="redirectUri"
                      name="redirectUri"
                      className={s.Input}
                      placeholder="https://company.com/auth/callback"
                      error={errors.redirectUri}
                    />
                    <hr className="my-4" />
                    <p className="mb-2 text-sm font-bold text-gray-900">{t('URL Scheme')}</p>
                    <Field
                      ref={register}
                      id="urlScheme"
                      name="urlScheme"
                      className={s.Input}
                      error={errors.urlScheme}
                    />
                    <hr className="my-4" />
                    <p className="mb-2 text-sm font-bold text-gray-900">
                      {t('OIDC Identifying Claim')}
                    </p>
                    <Field
                      ref={register}
                      id="identifyingClaim"
                      name="identifyingClaim"
                      className={s.Input}
                      placeholder="email"
                      error={errors.identifyingClaim}
                    />
                    <p className="mb-2 text-sm text-gray-500">
                      {t('This is the user info claim that will be used as the unique identifier')}
                    </p>
                    <hr className="mb-4" />
                    <p className="mb-2 text-sm font-bold text-gray-900">
                      {t('Registration Pipeline')}
                    </p>
                    <PipelineSelect initialPipelineId={initPipelineId} onSelect={setPipeline} />
                    {hasPhoneReg && (
                      <Toggle className="mt-4 mb-6" enabled={phoneId} onChange={setPhoneId}>
                        {t('Set user identifier to their phone number')}
                      </Toggle>
                    )}
                  </SettingsCard>
                </>
              )}
            </div>
          </div>
          <Footer timer={oidc ? updateTimer : createTimer} />
        </ContentWrapper>
      </form>
    </FormProvider>
  )
}

export default CustomerRegistration
