import { useAPI } from 'hooks/useAPI'
import { useCallback } from 'react'
import { createBrandStyle, Request } from 'thunks/api/branding/create'

export default function useCreateBrandStyle() {
  const [create, { timer }] = useAPI(createBrandStyle)

  const createBranding = useCallback(
    async (brandStyle: Request) => {
      if (brandStyle.logo === '') delete brandStyle.logo
      const branding = await create(brandStyle)
      return branding.id
    },
    [create]
  )

  return [createBranding, { timer }] as const
}
