import { FC } from 'react'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

import { QRCodeSVG } from 'qrcode.react'

const TOTPRegistrationPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <Header headline={t('One-Time Password Enrollment')}>
        {t('First, scan the QR code in an authenticator app or in a browser extension')}
      </Header>
      <main className="flex items-center justify-center py-4">
        <QRCodeSVG
          value="https://journeyid.com/"
          size={128}
          level="M"
          imageSettings={{
            width: 56,
            height: 56,
            excavate: true,
            src: '/etc/black-shield.svg',
          }}
        />
      </main>
      <footer className="w-[324px]">
        <div className="flex bg-gray-100 rounded">
          <div className="border-l-4 rounded-l border-theme"></div>
          <div className="py-2 pl-3 pr-3 text-gray-900">
            {t(`After scanning the QR code, enter the One-Time Code below`)}
          </div>
        </div>

        <div className="flex items-center space-x-2 pointer-events-none">
          <Input />
          <Input />
          <Input />
          <div>-</div>
          <Input />
          <Input />
          <Input />
        </div>

        <PrimaryButton block className="pointer-events-none" preview>
          {t('Submit')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default TOTPRegistrationPreview
