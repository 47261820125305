import { t } from 'translation'

import { StageType } from '@journeyid/agent/types'

export const stageTitleMap: Record<StageType, string> = {
  'active-voice-authentication': t('Authenticate with Voice Biometrics'),
  'active-voice-enrollment': t('Enroll in Voice Biometrics'),
  'address-collection': t('Collect an Address'),
  blank: t('[development use only]'),
  'credit-card-payment': t('Collect Credit Card Payment'),
  'custom-id': t('Collect Custom ID'),
  'device-authentication': t('Mobile App Authentication (Journey SDK)'),
  'device-registration': t('Mobile App Registration (Journey SDK)'),
  'document-collection': t(`Upload an image or file`),
  'document-signature': t('eSign a Document'),
  docusign: t('DocuSign'),
  'drivers-license': t(`Scan Driver's License`),
  'face-authentication': t(`Authenticate with Cloud Facial Biometrics`),
  'face-enrollment': t(`Enroll in Cloud Facial Biometrics`),
  'external-authentication': t('External Authentication'),
  'facial-authentication-3d': t('Authenticate with 3D Facial Biometrics'),
  'facial-authentication': t('Authenticate with 2D Face Scan'),
  'facial-enrollment': t('Enroll in Facial Biometrics'),
  form: t('Fill out an eForm'),
  'government-id': t("Scan & Verify Passport, National ID or Driver's License"),
  'government-id-selfie': t('Scan Government ID and match photo to selfie'),
  'knowledge-based-authentication': t('Knowledge Based Authentication'),
  'national-id-card': t('Scan & Verify National ID Card'),
  ocr: t('Scan a Document'),
  'outbound-scheduling': t('Schedule a call for an outbound campaign'),
  passport: t('Scan a Passport'),
  'payment-details': t('Collect Payment Details'),
  'phone-number-registration': t('Register Phone Number'),
  'phone-number-verification': t('Verify Phone Number'),
  'pindrop-enrollment': t('CallTrail Enrollment'),
  'pindrop-authentication': t('CallTrail Authentication'),
  'pindrop-protect': t('Pindrop Protect'),
  'pindrop-vericall': t('Pindrop VeriCall®'),
  'random-code': t('Randomly generated one-time code'),
  schedule: t('Schedule a Date & Time'),
  'social-security-number': t('Social Security Number'),
  survey: t('Survey'),
  'totp-authentication': t('Authenticate with One-time Password'),
  'totp-registration': t('Enroll with One-time Password'),
  'webauthn-authentication': t('Authenticate with Device Biometrics'),
  'webauthn-registration': t('Enroll with Device-Based Biometrics'),
}
