import classNames from 'classnames'
import { FC, useCallback } from 'react'

import { faCheck, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { useRealtimeEvents } from 'hooks/api/useRealtimeEvents'
import useActionTimer from 'hooks/useActionTimer'
import { delay } from 'util/delay'

import s from './NewEvents.module.scss'

import type { Event } from 'types/event'
type Props = {
  onImport: (events: Event[]) => void
}

const NewEvents: FC<Props> = ({ onImport }) => {
  const [events, { clear }] = useRealtimeEvents()
  const timer = useActionTimer()
  const onClick = useCallback(async () => {
    timer.start()
    await delay(750)
    onImport(events)
    clear()
    timer.succeeded()
  }, [clear, events, onImport, timer])

  return (
    <div
      className={classNames(s.NewEvents, {
        'opacity-0 pointer-events-none': events.length === 0 && !timer.didSucceed,
        'duration-500': events.length !== 0,
      })}
      onClick={onClick}
    >
      <div>
        {!timer.isLoading && !timer.didSucceed && (
          <>
            <ul className={s.ProfileIcons}>
              {events
                .filter(e => !!e.user.photoUrl)
                .slice(0, 3)
                .map((ev, i) => (
                  <li key={i}>{ev.user.photoUrl && <img src={ev.user.photoUrl} alt="" />}</li>
                ))}
            </ul>
            <div className={s.Text}>
              {t(`{{events}} new event${events.length !== 1 ? 's' : ''}`, {
                events: events.length,
              })}
            </div>
          </>
        )}
        {timer.isLoading && (
          <div className="flex justify-center m-auto">
            <FontAwesomeIcon icon={faSpinnerThird} size="1x" spin className="text-gray-300" />
          </div>
        )}
        {timer.didSucceed && (
          <div className="flex justify-center m-auto">
            <FontAwesomeIcon icon={faCheck} size="1x" className="text-white" />
          </div>
        )}
      </div>
    </div>
  )
}

export default NewEvents
