import { FC, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { usePipelineSelector } from 'store'

import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import PhoneNumberInputConnected from 'components/atoms/PhoneNumberInputConnected'
import ErrorMessage from 'components/molecules/ErrorMessage'
import Field from 'components/molecules/Field'
import { ErrorCallback, useAPI } from 'hooks/useAPI'
import { sendPipelineTest } from 'thunks/api/pipelines/send-test'

import s from './Form.module.scss'

type Inputs = {
  phoneNumber: string
}

const SendTest: FC = () => {
  const { pipeline } = usePipelineSelector()
  const form = useForm<Inputs>()
  const { handleSubmit, watch, errors, setError } = form

  const [status, setStatus] = useState<'sent' | 'ineligible' | string>()
  const onError: ErrorCallback = useCallback((statusCode, response) => {
    console.log('code', statusCode)
    if (response.code === 'INELIGIBLE_USER') {
      setStatus('ineligible')
    } else {
      setStatus(response.error)
    }
  }, [])

  const [submit, { timer }] = useAPI(sendPipelineTest, { setError, onError })
  const onSubmit = useCallback(
    async (data: Inputs) => {
      await submit({
        id: pipeline.id,
        phoneNumber: `+${data.phoneNumber}`,
      })
      setStatus('sent')

      const timeout = setTimeout(() => {
        setStatus(undefined)
      }, 4_000)

      return () => clearTimeout(timeout)
    },
    [pipeline.id, submit]
  )

  const phoneNumber = watch('phoneNumber') || ''

  return (
    <div className="p-6 mb-12 border rounded-2xl">
      <h4 className="std">{t('Send yourself a test:')}</h4>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-1 gap-3">
            <Field name="phoneNumber" error={errors.phoneNumber} className="w-full mt-0">
              <PhoneNumberInputConnected name="phoneNumber" className="pl-16 m-0" />
            </Field>
            <Button
              type="submit"
              isLoading={timer.isLoading}
              disabled={timer.isLoading || phoneNumber.length < 9}
              variant="secondary"
              className={s.SendBtn}
            >
              <FontAwesomeIcon className="mr-1" icon={faPaperPlane} />
              {timer.didSucceed && pipeline.key
                ? t('Sent!')
                : timer.isLoading
                ? t('Sending...')
                : t('Send')}
            </Button>
          </div>
          {(() => {
            switch (status) {
              case 'sent':
                return (
                  <div className="mt-2 text-sm font-semibold text-green-600">
                    {t('The pipeline request was delivered via SMS, please check your phone.')}
                  </div>
                )
              case 'ineligible':
                return (
                  <div className="mt-2 text-sm font-semibold text-red-600">
                    {t(
                      'Because of the actions in this pipeline, the customer attached to this phone number is ineligible to complete it.'
                    )}
                    <br />
                    <br />
                    {t(
                      'To remedy this, first execute the registration/enrollment version of this pipeline. An example would be if this pipeline contains facial biometric authentication, first complete a pipeline with facial biometric enrollment.'
                    )}
                  </div>
                )

              default:
                return <ErrorMessage error={{ type: 'server', message: status }} />
            }
          })()}
        </form>
      </FormProvider>
    </div>
  )
}

export default SendTest
