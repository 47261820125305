import { Pipeline } from 'types'

import { StageType } from '@journeyid/agent/types'

import asyncThunk from 'util/asyncThunk'
import { HTTPDeleted } from 'util/statuses'

export type Request = {
  id: string
  gateway: string
  stageType: StageType
}

type Response = Pipeline

export const removeDestination = asyncThunk<Request, Response>({
  name: 'pipelines/remove-destination',
  uri: ({ id }) => `/pipelines/${id}/destinations`,
  successStatus: HTTPDeleted,
  method: 'DELETE',
})
