import { Pipeline } from 'types'

import { StageType } from '@journeyid/agent/types'

import asyncThunk from 'util/asyncThunk'
import { HTTPDeleted } from 'util/statuses'

export type Request = {
  id: string
  stageType: StageType
}

type Response = Pipeline

export const removeStage = asyncThunk<Request, Response>({
  name: 'pipelines/remove-stage',
  method: 'DELETE',
  uri: ({ id }) => `/pipelines/${id}/stages`,
  successStatus: HTTPDeleted,
})
