import { AccountConfiguration } from 'types/account'
import asyncThunk, { ID } from 'util/asyncThunk'
import { HTTPUpdated } from 'util/statuses'

export type Request = { id: ID } & Partial<
  Pick<
    AccountConfiguration,
    | 'blurAmount'
    | 'enrollmentPipelineId'
    | 'imageRetentionPolicy'
    | 'isLivenessDisabled'
    | 'passiveAuthDuration'
    | 'webhookUrl'
  >
>

type Response = Omit<Request, 'id'>

export const updateAgentAuth = asyncThunk<Request, Response>({
  name: 'accounts/agent-auth',
  method: 'PUT',
  uri: ({ id }) => `/accounts/${id}/agent-auth`,
  orchestrator: false,
  successStatus: HTTPUpdated,
  stripId: true,
})
