import { FC } from 'react'

import { MetadataFor } from '@journeyid/agent/types'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'
import SecondaryButton from 'components/organisms/Preview/SecondaryButton'

type Props = {
  metadata: MetadataFor<'schedule'>
}

const SchedulePreview: FC<Props> = ({ metadata }) => {
  return (
    <>
      <LogoHeader />

      <Header headline={metadata?.title || t(`Unusual activity alert`)}>
        {metadata?.description ||
          t('When would you like us to call to discuss unusual activity on your card?')}
      </Header>

      <main className="flex justify-center px-4">
        <div className="w-full pt-4 space-y-2">
          <SecondaryButton onClick={() => {}}>{t('Now')}</SecondaryButton>
          <SecondaryButton onClick={() => {}}>{t('in 15 minutes')}</SecondaryButton>
          <SecondaryButton onClick={() => {}}>{t('in 1 hour')}</SecondaryButton>
          <SecondaryButton onClick={() => {}}>{t('Tonight at 8pm')}</SecondaryButton>
          <SecondaryButton onClick={() => {}}>{t('Tomorrow at 9am')}</SecondaryButton>
        </div>
      </main>
      <footer>
        <PrimaryButton block preview>
          {t('Confirm')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default SchedulePreview
