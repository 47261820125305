import { FC, ReactElement } from 'react'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import { ActionTimer } from 'hooks/useActionTimer'

import s from './Column.module.scss'

type Props = {
  id: string
  title: string
  description: string
  timer: ActionTimer
  children: ReactElement[]
  onClick: () => void
}

const Column: FC<Props> = ({ id, title, description, timer, children, onClick }) => {
  return (
    <li className={s.Column}>
      <h5 className="std">{title}</h5>
      <p className="std-sm">{description}</p>
      <ul>{children}</ul>
      <Button
        variant="tertiary"
        block
        isLoading={(timer.id === id, timer.isLoading)}
        onClick={onClick}
      >
        {t('Create this Pipeline')}
      </Button>
    </li>
  )
}

export default Column
