import { useCallback, useState } from 'react'

type Output<T> = [T, (val: T) => void]

export function useLocalStorage<T = string>(key: string, fallback?: T): Output<T> {
  const [value, setStateValue] = useState<T>(() => {
    const val = localStorage.getItem(key)
    if (val) {
      return JSON.parse(val)
    } else {
      return fallback
    }
  })

  const setValue = useCallback(
    (value: T) => {
      const serialized = JSON.stringify(value)
      localStorage.setItem(key, serialized)
      setStateValue(value)
    },
    [key]
  )

  return [value, setValue]
}
