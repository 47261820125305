import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { adminBootstrap } from 'thunks/api/admin-bootstrap'
import { authenticationsSwitch } from 'thunks/api/authentications/switch'
import { magicLinksAuthenticate } from 'thunks/api/magic_links/authenticate'
import parseJWT from 'util/parseJWT'

type AdminRole = 'supervisor' | 'admin' | 'owner'

export type AuthState = {
  token?: string
  accountId?: string
  adminId?: string
  role?: AdminRole
}

export type AuthActions = {
  setAuth: (token: string) => void
}

const initialState: AuthState = {}

const setJWT: CaseReducer<AuthState, PayloadAction<string>> = (state, action) => {
  localStorage.setItem('token', action.payload)
  const data = parseJWT(action.payload)

  return {
    token: action.payload,
    accountId: data.acc,
    adminId: data.sub,
    role: data.rol as AdminRole,
  }
}

const stateFromJWT = (token: string): AuthState => {
  const data = parseJWT(token)

  return {
    token,
    accountId: data.acc,
    adminId: data.sub,
    role: data.rol as AdminRole,
  }
}

const reloadJWT: CaseReducer<AuthState, PayloadAction<void>> = () => {
  const token = localStorage.getItem('token')
  if (!token) {
    return {}
  }

  return { token, ...stateFromJWT(token) }
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJWT,
    reloadJWT,
  },
  extraReducers: builder => {
    builder.addCase(authenticationsSwitch.fulfilled, (state, { payload }) => {
      localStorage.setItem('token', payload.token)
      return stateFromJWT(payload.token)
    })
    builder.addCase(magicLinksAuthenticate.fulfilled, (state, { payload }) => {
      localStorage.setItem('token', payload.token)
      return stateFromJWT(payload.token)
    })
    builder.addCase(adminBootstrap.fulfilled, (state, { payload }) => {
      if (!payload.token) return state

      localStorage.setItem('token', payload.token)
      return stateFromJWT(payload.token)
    })
  },
})

export default slice
export const actions = slice.actions
