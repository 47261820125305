import classNames from 'classnames'
import React, { ChangeEvent, forwardRef } from 'react'

import styles from '../Input/index.module.scss'

type Props = {
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  onChangeText?: (text: string) => void
} & Omit<React.HTMLProps<HTMLSelectElement>, 'onChange'>

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ className, children, onChangeText, ...rest }, ref) => {
    if (!rest.onChange && onChangeText) {
      rest.onChange = (e: ChangeEvent<HTMLSelectElement>) =>
        onChangeText && onChangeText(e.target.value)
    }

    return (
      <select ref={ref} {...rest} className={classNames('__Select', styles.Input, className)}>
        {children}
      </select>
    )
  }
)

export default Select
