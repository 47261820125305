import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import s from './index.module.scss'

type Props = {
  title: string
  tip?: ReactNode
  children: ReactNode
}

const PageHeader: FC<Props> = ({ title, tip, children }) => {
  return (
    <header className={classNames('__PageHeader', s.PageHeader)}>
      <div>
        <div>
          <h3 className="std">{title}</h3>
          <p className="std-sm">{children}</p>
        </div>
      </div>
      {tip}
      <hr />
    </header>
  )
}

export default PageHeader
