import { Pipeline } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  entityId: string
  acsUrl: string
}

type Response = Pipeline

export const updateSAML = asyncThunk<Request, Response>({
  name: 'pipelines/saml',
  method: 'PUT',
  uri: ({ id }) => `/pipelines/${id}/saml`,
  stripId: true,
})
