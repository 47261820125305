import classNames from 'classnames'
import { FC } from 'react'

import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import s from './AddCategoryButton.module.scss'

type Props = {
  className?: string
  onClick: () => void
}

const AddCategoryButton: FC<Props> = ({ className, onClick }) => {
  return (
    <div className={classNames(s.AddCategoryButton, className)}>
      <button onClick={onClick}>
        <FontAwesomeIcon icon={faPlus} />
        <span>{t('Add a category')}</span>
      </button>
    </div>
  )
}

export default AddCategoryButton
