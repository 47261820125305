import cogoToast from 'cogo-toast'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'

import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Button from 'components/atoms/Button'
import Loading from 'components/atoms/Loading'
import Header from 'components/molecules/AdminHeader'
import { useRealtimeUserEvents } from 'hooks/api/useRealtimeUserEvents'
import { useRetrieveUser } from 'hooks/api/useRetrieveUser'
import { openModal } from 'hooks/useModal'
import MorphButton from 'pages/shared/Users/Show/_MorphButton'
import { User, UserType } from 'types/user'

import Activity, { Interface } from './Activity'
import s from './index.module.scss'
import SendPipelineModal from './SendPipelineModal'
import UserInfo from './UserInfo'

type Props = {
  type: UserType
}

type RouteParams = {
  id: string
}

const ShowUser = <T extends UserType>({ type }: Props): JSX.Element => {
  const { params } = useRouteMatch<RouteParams>()
  const history = useHistory()

  const [user, setUser] = useState<User>()
  const [retrieve, { timer }] = useRetrieveUser<T>()

  const [events, { clear }] = useRealtimeUserEvents(params.id)
  const ref = useRef<Interface>(null)
  const handleImport = useCallback(() => {
    ref.current?.prependEvents(events)
    clear()
  }, [events, clear])

  useEffect(() => {
    if (!params.id) return
    retrieve({ id: params.id }).then(setUser)
  }, [params.id, retrieve])

  useEffect(() => {
    if (!timer.didFail) return
    cogoToast.error(t(`The {{type}} doesn't exist or was deleted.`, { type }))
    history.push(type === 'customer' ? r.customers.root : r.agents.root)
  }, [timer.didFail, history, type])

  const back = useMemo(() => (type === 'agent' ? r.agents.root : r.customers.root), [type])

  return (
    <ContentWrapper>
      <Header title={type === 'agent' ? t('Agents') : t('Customers')} backRoute={back}>
        {events.length > 0 && (
          <Button variant="secondary" className="relative mr-4" onClick={handleImport}>
            <div
              className="absolute w-2 h-2 rounded-full -top-1 -right-1 bg-theme animate-ping"
              style={{ animationDuration: '1250ms' }}
            />
            {t(`{{events}} new event${events.length !== 1 ? 's' : ''}`, { events: events.length })}
          </Button>
        )}
        <button className={s.SendPipelineRequestBtn} onClick={openModal('SendPipeline')}>
          <FontAwesomeIcon icon={faPaperPlane} />
          {t('Send pipeline request')}
        </button>
        {user?.type === 'agent' && <MorphButton agentId={params.id} />}
      </Header>
      {timer.isLoading || !user ? (
        <Loading className="mt-32" size="5x" />
      ) : (
        <>
          <div className={s.Content}>
            <div>
              <UserInfo back={back} user={user} onChange={setUser} />
              <Activity ref={ref} userId={params.id} />
            </div>
          </div>
          <SendPipelineModal user={user} />
        </>
      )}
    </ContentWrapper>
  )
}

export default ShowUser
