import { FC } from 'react'
import { useBrandingSelector } from 'store'

import headerLogo from 'components/organisms/Preview/LogoHeader/header-logo.svg'

import s from './Logo.module.scss'

const Logo: FC = () => {
  const { branding } = useBrandingSelector()

  return (
    <div className={s.Logo}>
      <img src={branding?.logo || headerLogo} alt="" />
    </div>
  )
}

export default Logo
