// Success Codes
export const HTTPCreated = 260
export const HTTPCreatedMultiple = 261
export const HTTPRetrieved = 262
export const HTTPRetrievedMultiple = 263
export const HTTPUpdated = 264
export const HTTPArchived = 265
export const HTTPDeleted = 266
export const HTTPProcessed = 267
export const HTTPCompleted = 268
export const HTTPAuthenticated = 269

// Bad Request Codes
export const HTTPUnacceptable = 460 // The input format was unacceptable
export const HTTPUnauthenticated = 461
export const HTTPUnauthorized = 462
export const HTTPValidationError = 463 // Returns keyed map of field errors
export const HTTPNonExistant = 464
export const HTTPContextValidationError = 465 // Returns keyed map of field errors
export const HTTPPreconditionFailed = 466
export const HTTPVerificationFailed = 467
export const HTTPRequiresConfiguration = 468

// Internal failure Codes
export const HTTPUnexpected = 560 // This should never happen should cause an alert
export const HTTPRequiresReview = 561 // Something real bad requires human review
export const HTTPDependencyError = 562 // Dependent service inside the VPC unable to fulfill request
export const HTTPExternalError = 563 // Dependent service outside the VPC unable to fulfill request
export const HTTPUnhandled = 564 // A situation that is recognized could occur but not handled
