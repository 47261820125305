import classNames from 'classnames'
import { FC, useMemo, useState } from 'react'
import { Pipeline } from 'types'

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Field from 'components/molecules/Field'
import Modal from 'components/organisms/Modal'
import { ActionTimer } from 'hooks/useActionTimer'
import { closeModal, useModal } from 'hooks/useModal'

type Props = {
  pipeline: Pipeline
  timer: ActionTimer
  onArchive: () => void
  onCancel: () => void
}

const ArchivePipelineModal: FC<Props> = ({ pipeline, timer, onArchive, onCancel }) => {
  const [isOpen] = useModal('ArchivePipeline')

  const [confirmation, setConfirmation] = useState('')

  const archiveText = useMemo(() => t('ARCHIVE'), [])

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>
        <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 mr-3 text-red-600" />
        {t('The pipeline will be disabled immediately.')}
      </Modal.Header>
      <Modal.Body>
        {pipeline.key && (
          <p>
            {t(
              'Any existing request to execute the {{title}} pipeline will become invalid and any future requests will be rejected.',
              { title: pipeline.title }
            )}
          </p>
        )}
        <Field
          label={t('Enter "{{text}}" to archive this pipeline', { text: archiveText })}
          name="confirmation"
          autoFocus
          autoComplete="off"
          onChange={e => setConfirmation(e.target.value)}
          className="my-8"
        />
      </Modal.Body>
      <Modal.Actions>
        <Button
          size="xl"
          disabled={confirmation !== archiveText}
          variant="red-outline"
          isLoading={timer.isLoading}
          onClick={confirmation === archiveText ? onArchive : undefined}
          className={classNames(
            confirmation !== archiveText && 'pointer-events-none text-gray-400'
          )}
        >
          {timer.isLoading ? t('Archiving...') : t('Archive the pipeline')}
        </Button>
        <Button size="xl" variant="secondary" onClick={onCancel}>
          {t('Cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ArchivePipelineModal
