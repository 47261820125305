import classNames from 'classnames'
import { FC } from 'react'
import NumberFormat from 'react-number-format'

import { t } from 'translation'

import Slider from 'components/atoms/Slider'
import { customerInteractionCosts, CustomerInteractions } from 'pages/Settings/tabs/Pricing/costs'

import s from './AgentAuthentication.module.scss'

type VoiceBio = Pick<
  CustomerInteractions,
  'voiceBiometricEnrollments' | 'voiceBiometricAuthentications'
>

type Props = {
  value: VoiceBio
  onChange: (value: VoiceBio) => void
}

const VoiceBiometrics: FC<Props> = ({ value, onChange }) => {
  const idx = customerInteractionCosts['voiceBiometricAuthentications'].findIndex(
    a => a[0] === value.voiceBiometricAuthentications
  )

  return (
    <div
      className={classNames(
        value.voiceBiometricEnrollments > 0 || value.voiceBiometricAuthentications > 0
          ? s.SelectedOption
          : s.Option,
        'cursor-default'
      )}
    >
      <div>
        <header className="flex items-center justify-between mb-2">
          <span>{t('Voice Biometrics')}</span>
          <div className="flex items-center px-2 space-x-2">
            <span className="mr-1">
              {value.voiceBiometricEnrollments + value.voiceBiometricAuthentications === 0 ? (
                t('0 / mo')
              ) : (
                <>
                  <NumberFormat
                    value={value.voiceBiometricEnrollments / 1000}
                    thousandSeparator=","
                    decimalScale={1}
                    displayType="text"
                    className="text-sm"
                  />
                  {t('k enrollments')} &amp;{' '}
                  <NumberFormat
                    value={value.voiceBiometricAuthentications / 1000}
                    thousandSeparator=","
                    decimalScale={1}
                    displayType="text"
                    className="text-sm"
                  />
                  {t('k authentications / mo')}
                </>
              )}
            </span>
            <Slider
              value={idx}
              min={0}
              max={customerInteractionCosts['voiceBiometricAuthentications'].length - 1}
              step={1}
              className="w-[200px] cursor-pointer"
              filledClassName="bg-theme"
              handleClassName="bg-theme border-theme"
              unfilledClassName="bg-gray-300"
              onChangeValue={idx => {
                const voiceBiometricAuthentications =
                  customerInteractionCosts.voiceBiometricAuthentications[idx][0]
                const voiceBiometricEnrollments = voiceBiometricAuthentications / 10
                onChange({
                  ...value,
                  voiceBiometricEnrollments,
                  voiceBiometricAuthentications,
                })
              }}
            />
          </div>
        </header>
        <hr />
        <p>
          {t(
            'Starting with a one-time setup fee, these transactions cover enrollment and authentication events for both active and passive voice biometrics. Active = Enroll and Authenticate through a defined phrase (My voice is my password...) Passive = Enroll and Authenticate through natural language speech patterns without direct instruction to the end user.'
          )}{' '}
        </p>
        <div>
          <NumberFormat
            value={
              customerInteractionCosts.voiceBiometricEnrollments[
                customerInteractionCosts.voiceBiometricEnrollments.length - 1
              ][1] * 100
            }
            thousandSeparator=","
            decimalScale={1}
            displayType="text"
          />
          &cent; - $
          <NumberFormat
            value={customerInteractionCosts.voiceBiometricEnrollments[1][1]}
            thousandSeparator=","
            decimalScale={2}
            fixedDecimalScale
            displayType="text"
          />
          &cent; {t('/ enrollment')} <span className="mx-2" />
          <NumberFormat
            value={
              customerInteractionCosts.voiceBiometricAuthentications[
                customerInteractionCosts.voiceBiometricAuthentications.length - 1
              ][1] * 100
            }
            thousandSeparator=","
            decimalScale={1}
            displayType="text"
          />
          &cent; -{' '}
          <NumberFormat
            value={customerInteractionCosts.voiceBiometricAuthentications[1][1] * 100}
            thousandSeparator=","
            decimalScale={1}
            displayType="text"
          />
          &cent; {t('/ authentication')}
        </div>
      </div>
    </div>
  )
}

export default VoiceBiometrics
