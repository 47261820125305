import classNames from 'classnames'
import React, { FC, MouseEvent, MouseEventHandler } from 'react'
import { useHistory } from 'react-router-dom'

import { faSpinnerThird, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './index.module.scss'

export type ButtonProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'disabled'
    | 'link'
    | 'white'
    | 'green'
    | 'red'
    | 'red-outline'
    | 'gray'
  type?: 'submit' | 'button'
  inline?: boolean
  icon?: IconDefinition
  iconRight?: IconDefinition
  block?: boolean
  isLoading?: boolean
  onClick?: MouseEventHandler
  to?: string
  className?: string
  preview?: boolean
} & Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'onClick'>

const Button: FC<ButtonProps> = ({
  size = 'md',
  variant = 'primary',
  type = 'button',
  inline = true,
  icon,
  iconRight,
  block = false,
  disabled = false,
  isLoading = false,
  preview = false,
  onClick,
  to,
  children,
  className,
  ...rest
}) => {
  const colors = {
    [styles.Primary]: variant === 'primary',
    [styles.Secondary]: variant === 'secondary',
    [styles.Tertiary]: variant === 'tertiary',
    [styles.Disabled]: variant === 'disabled',
    [styles.Link]: variant === 'link',
    [styles.White]: variant === 'white',
    [styles.Green]: variant === 'green',
    [styles.Red]: variant === 'red',
    [styles.RedOutline]: variant === 'red-outline',
    [styles.Gray]: variant === 'gray',
  }

  const sizes = {
    [styles.XSmall]: size === 'xs',
    [styles.Small]: size === 'sm',
    [styles.Medium]: size === 'md',
    [styles.Large]: size === 'lg',
    [styles.XLarge]: size === 'xl',
  }

  const miscStyles = {
    // [styles.Disabled]: disabled,
    [styles.Inline]: inline,
    [styles.Block]: block,
    [styles.Preview]: preview,
  }

  const history = useHistory()
  function handleClick(ev: MouseEvent) {
    if (onClick) {
      ev.preventDefault()
      onClick(ev)
    } else if (to) {
      ev.preventDefault()
      history.push(to)
    }
  }

  return (
    <button
      onClick={handleClick}
      type={type}
      disabled={disabled}
      className={classNames(styles.Button, colors, sizes, miscStyles, className)}
      {...rest}
    >
      {isLoading && <FontAwesomeIcon icon={faSpinnerThird} spin className={styles.LoadingIcon} />}
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{children}</span>
      {iconRight && <FontAwesomeIcon icon={iconRight} />}
    </button>
  )
}

export default Button
