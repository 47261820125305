import classNames from 'classnames'
import { FC, FormEvent, useCallback, useState } from 'react'
import { useIframeSelector } from 'store'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import { useAPI } from 'hooks/useAPI'
import { updateIframe } from 'thunks/api/iframes/update'

import s from './TitleForm.module.scss'

const TitleForm: FC = () => {
  const { iframe } = useIframeSelector()
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState(iframe.title)

  const [save, { timer }] = useAPI(updateIframe)
  const handleSave = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      await save({
        id: iframe.id,
        title,
      })
      setIsEditing(false)
    },
    [iframe, title, save]
  )

  return (
    <div className={classNames(s.TitleForm, isEditing && s.Open)}>
      {isEditing ? (
        <form onSubmit={handleSave}>
          <input value={title} onChange={e => setTitle(e.target.value)} autoFocus />
          <fieldset>
            <Button disabled={timer.isLoading} variant="link" onClick={handleSave}>
              {timer.isLoading ? t('saving') : t('save')}
            </Button>
            <Button variant="link" onClick={() => setIsEditing(false)} className="text-gray-600">
              {t('cancel')}
            </Button>
          </fieldset>
        </form>
      ) : (
        <div>
          <label className="">{iframe.title}</label>
          <Button variant="link" onClick={() => setIsEditing(true)}>
            {t('edit')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default TitleForm
