import classNames from 'classnames'
import { FC, ReactElement, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import r from 'routes'

import s from './index.module.scss'
import Sidebar from './Sidebar'

type Props = {
  children: ReactElement
}

const AdminLayout: FC<Props> = ({ children = false }) => {
  const [isSidebarHovered, setIsSidebarHovered] = useState(false)

  const condensedSidebar = useRouteMatch([
    r.pipelines.create.root(),
    r.pipelines.edit.root(),
    r.iframes.create.root(),
    r.iframes.edit.root(),
  ])

  return (
    <div
      className={classNames(s.AdminLayout, {
        [s.Condensed]: !condensedSidebar,
      })}
    >
      <Sidebar
        isCondensed={Boolean(condensedSidebar)}
        isSidebarHovered={isSidebarHovered}
        setIsSidebarHovered={setIsSidebarHovered}
      />
      <div className={classNames(s.Content)} onMouseOver={() => setIsSidebarHovered(false)}>
        {children}
      </div>
    </div>
  )
}
export default AdminLayout
