import { FC } from 'react'

import { t } from 'translation'

const Links: FC = () => {
  return (
    <div className="p-4 space-y-2 text-sm bg-gray-100 border rounded-lg">
      <p>
        {t('IDP metadata can be found at')}{' '}
        <a
          href={`${window.location.origin}/api/saml2/idp`}
        >{`${window.location.origin}/api/saml2/idp`}</a>
      </p>
      <p>
        {t('The SAML certificate can be found at')}{' '}
        <a
          href={`${window.location.origin}/api/saml2/certificate`}
        >{`${window.location.origin}/api/saml2/certificate`}</a>
      </p>
      <div className="!my-4 border-t border-gray-300" />
      <p>{t('IDP Request URLs:')}</p>
      <p className="pl-2">
        {t('POST:')}{' '}
        <a
          href={`${window.location.origin}/api/saml2/login`}
        >{`${window.location.origin}/api/saml2/login`}</a>
      </p>
      <p className="pl-2">
        {t('Redirect:')}{' '}
        <a href={`${_env_.CLIENT_ORIGIN}/saml2/login`}>{`${_env_.CLIENT_ORIGIN}/saml2/login`}</a>
      </p>
    </div>
  )
}

export default Links
