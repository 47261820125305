import asyncThunk from 'util/asyncThunk'
import { HTTPProcessed } from 'util/statuses'

export type Request = {
  id: string
  phoneNumber: string
}

type Response = {
  userType: 'agent' | 'customer'
  userId: string
}

export const sendPipelineTest = asyncThunk<Request, Response>({
  name: 'pipelines/send-test',
  method: 'POST',
  uri: ({ id }) => `/pipelines/${id}/test`,
  stripId: true,
  successStatus: HTTPProcessed,
})
