import { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useIframeSelector, usePipelinesSelector } from 'store'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Footer from 'components/molecules/Footer'
import { idleTimer } from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import ls from 'pages/IFrames/Steps/shared/LayoutWithFooter.module.scss'
import { createIFramePipeline } from 'thunks/api/iframes/pipelines/create'
import { deleteIFramePipeline } from 'thunks/api/iframes/pipelines/delete'
import { reorderIFramePipelines } from 'thunks/api/iframes/pipelines/reorder'
import { listPipelines } from 'thunks/api/pipelines/list'

import s from './index.module.scss'
import PipelineSet from './PipelineSet'

const EnrollmentAndAuthentication: FC = () => {
  const history = useHistory()

  const { iframe } = useIframeSelector()
  const { pipelines, isLoaded } = usePipelinesSelector()

  const [pipelineList] = useAPI(listPipelines)

  const handleGetPipelines = useCallback(async () => {
    try {
      await pipelineList({
        published: true,
      })
    } catch (err) {
      console.error('Unable to get pipelines:', err)
    }
  }, [pipelineList])

  useEffect(() => {
    handleGetPipelines()
  }, [handleGetPipelines, pipelineList])

  const [add, { timer: addTimer }] = useAPI(createIFramePipeline)
  const handleAdd = useCallback(
    (type: 'enrollment' | 'authentication') => (pipelineId: string) => {
      addTimer.setId(type)
      add({
        id: iframe.id,
        type,
        pipelineId,
      })
    },
    [iframe.id, addTimer, add]
  )

  const [remove, { timer: removeTimer }] = useAPI(deleteIFramePipeline)
  const handleRemove = useCallback(
    (type: 'enrollment' | 'authentication') => (pipelineId: string) => {
      removeTimer.setId(pipelineId)
      remove({
        id: iframe.id,
        type,
        pipelineId,
      })
    },
    [iframe.id, removeTimer, remove]
  )

  const [reorderPipelines] = useAPI(reorderIFramePipelines)
  const handleReorder = useCallback(
    (type: 'enrollment' | 'authentication') => (fromIndex: number, toIndex: number) => {
      reorderPipelines({
        id: iframe.id,
        type,
        fromIndex,
        toIndex,
      })
    },
    [iframe.id, reorderPipelines]
  )

  const handleContinue = () => {
    if (!iframe.id) return
    const phase = history.location.pathname.split('/')[3]
    const route = r.iframes[phase === 'create' ? 'create' : 'edit'].configure
    history.push(route.pipelines(iframe.id))
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center">
        <FontAwesomeIcon
          icon={faSpinnerThird}
          className="mx-auto mt-32 text-theme"
          size="5x"
          spin
        />
      </div>
    )
  }

  return (
    <div className={ls.Wrapper}>
      <div className={ls.ContentWrapper}>
        <div>
          <h1 className={s.Title}>{t('Enrollment & Authentication')}</h1>
          <p className={s.Description}>
            {t('Configure which pipelines will be used for user enrollment and authentication.')}
          </p>

          <PipelineSet
            label={t('Enrollment')}
            pipelines={iframe.enrollment ? iframe.enrollment : []}
            allPipelines={pipelines ? pipelines : []}
            addTimer={addTimer.id === 'enrollment' ? addTimer : idleTimer}
            removeTimer={removeTimer}
            onAdd={handleAdd('enrollment')}
            onRemove={handleRemove('enrollment')}
            onReorder={handleReorder('enrollment')}
            className="mb-6"
          >
            {t('Which pipelines will be used to enroll new customers?')}
          </PipelineSet>

          <PipelineSet
            label={t('Authentication')}
            pipelines={iframe.authentication ? iframe.authentication : []}
            allPipelines={pipelines ? pipelines : []}
            addTimer={addTimer.id === 'authentication' ? addTimer : idleTimer}
            removeTimer={removeTimer}
            onAdd={handleAdd('authentication')}
            onRemove={handleRemove('authentication')}
            onReorder={handleReorder('authentication')}
          >
            {t('Which pipelines will be used to authenticate enrolled customers?')}
          </PipelineSet>
        </div>
      </div>
      <div>
        <Footer
          actions={<Button onClick={handleContinue}>{t('Finished adding Pipelines')}</Button>}
        />
      </div>
    </div>
  )
}

export default EnrollmentAndAuthentication
