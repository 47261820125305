import classNames from 'classnames'
import React, { FC } from 'react'
import { ReactEditor, useSlate } from 'slate-react'

import { t } from 'translation'

import {
  isBlockActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
} from 'components/organisms/SlateEditor/util'

import { Button, ButtonFormat } from './_Button'
import styles from './index.module.scss'
import { insertLink, isLinkActive, unwrapLink } from './links'

interface BaseProps {
  className: string
  [key: string]: unknown
}

const SlateToolbar = React.forwardRef<HTMLDivElement, BaseProps>(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={classNames(styles.Toolbar, className)}>
    <BlockButton format="h1" />
    <BlockButton format="h2" />
    <MarkButton format="bold" />
    <MarkButton format="italic" />
    <MarkButton format="code" />
    <LinkButton />
    <RemoveLinkButton />
    <BlockButton format="numbered-list" />
    <BlockButton format="bulleted-list" />
  </div>
))

export default SlateToolbar

type ButtonProps = {
  format: ButtonFormat
}

const BlockButton: FC<ButtonProps> = ({ format }) => {
  const editor = useSlate() as ReactEditor

  return (
    <Button
      format={format}
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      tabIndex={-1}
    />
  )
}

const MarkButton: FC<ButtonProps> = ({ format }) => {
  const editor = useSlate() as ReactEditor

  return (
    <Button
      format={format}
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      tabIndex={-1}
    />
  )
}

const LinkButton = () => {
  const editor = useSlate() as ReactEditor

  return (
    <Button
      format="link"
      active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt(t('Enter the URL of the link:'))
        if (!url) return
        insertLink(editor, url)
      }}
      tabIndex={-1}
    />
  )
}

const RemoveLinkButton = () => {
  const editor = useSlate() as ReactEditor

  return (
    <Button
      format="unlink"
      active={false}
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        }
      }}
      tabIndex={-1}
    />
  )
}
