import { FC, KeyboardEvent } from 'react'

import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import s from './Search.module.scss'

type Props = {
  search: string
  setSearch: (search: string) => void
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
}

const Search: FC<Props> = ({ search, setSearch, handleKeyDown }) => {
  return (
    <div className={s.Wrapper}>
      <FontAwesomeIcon icon={faMagnifyingGlass} />
      <input
        type="text"
        placeholder={t('Search')}
        value={search}
        onChange={e => setSearch(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}

export default Search
