import { ChangeEvent, FC, useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { Field as FieldType, FieldWithOptions, FormPage, InputField } from '@journeyid/agent/types'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Field from 'components/molecules/Field'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'
import { closeModal, openModal } from 'hooks/useModal'

import FormFieldList from './components/FormFieldList'
import FormFieldsModal from './components/FormFieldsModal'
import FreeformTextModal from './components/FreeformTextModal'
import SelectOptionsModal from './components/SelectOptionsModal'
import FormPreview from './Preview'

const Form: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('form')
  const form = stage.metadata.pages[0]

  const handleFormChange = useCallback(
    (fields: FormPage['fields']) => {
      handleMetadata('pages', [
        {
          ...form,
          fields,
        },
      ])
    },
    [form, handleMetadata]
  )

  const handleField = useCallback(
    (field: FieldType) => {
      if (field.id) {
        const idx = form.fields.findIndex(f => f.id === field.id)
        const fields = [...form.fields]
        fields[idx] = field
        handleFormChange(fields)
      } else {
        field.id = uuid()
        handleFormChange([...form.fields, field])
      }
      closeModal()
    },
    [form, handleFormChange]
  )

  const handleTextChange = useCallback(
    (field: 'title' | 'text') => (e: ChangeEvent<HTMLInputElement>) => {
      handleMetadata('pages', [
        {
          ...form,
          [field]: e.target.value,
        },
      ])
    },
    [form, handleMetadata]
  )

  const [fieldType, setFieldType] = useState<FieldType['type']>()

  const [modalField, setModalField] = useState<FieldType>()
  const handleSelectType = useCallback((type: FieldType['type']) => {
    setFieldType(type)
    switch (type) {
      case 'text':
      case 'money':
      case 'date':
      case 'address':
        openModal('FreeformText')()
        break
      default:
        openModal('SelectOptions')()
        break
    }
  }, [])

  const handleOpenEditModal = useCallback(
    (idx: number) => {
      setModalField(form.fields[idx])
      handleSelectType(form.fields[idx].type)
    },
    [form, handleSelectType]
  )

  const handleClose = useCallback(() => {
    setModalField(undefined)
    closeModal()
  }, [])

  const handleRemove = useCallback(() => {
    if (!modalField?.id) return
    const idx = form.fields.findIndex(f => f.id === modalField.id)
    const fields = [...form.fields]
    fields.splice(idx, 1)
    handleFormChange(fields)
    handleClose()
  }, [form, modalField, handleFormChange, handleClose])

  return (
    <>
      <CreateSettings
        stageType="form"
        preview={<FormPreview metadata={{ pages: [form] }} />}
        timer={timer}
        onSave={handleSave}
      >
        <Settings>
          <Setting title={t('Form Details')}>
            <Field
              label={t('Title')}
              name="title"
              value={form.title}
              onChange={handleTextChange('title')}
              placeholder={t('Short form title')}
              className="mb-3"
            />
            <Field
              label={t('Instructions')}
              as="textarea"
              rows={2}
              name="text"
              value={form.text}
              onChange={handleTextChange('text')}
              placeholder={t('Form instructions')}
              inputClassName="resize-none"
            />
          </Setting>
          <Setting
            title={t('Form Builder')}
            description={t('Add actions to your form below. See the result on the right.')}
          >
            <FormFieldList
              fields={form['fields']}
              onChange={handleFormChange}
              openEditModal={handleOpenEditModal}
            />

            <Button variant="secondary" icon={faPlus} onClick={openModal('FormFields')}>
              {t('Add Field')}
            </Button>
          </Setting>
        </Settings>
      </CreateSettings>
      <FormFieldsModal onSelect={handleSelectType} />

      {/* @ts-ignore */}
      {['text', 'money', 'date', 'address'].includes(fieldType) && (
        <FreeformTextModal
          key={`${form.fields.length}-${modalField?.id}`}
          type={fieldType as InputField['type']}
          defaultValues={modalField}
          onSubmit={handleField}
          onRemove={handleRemove}
          onClose={handleClose}
        />
      )}

      {/* @ts-ignore */}
      {['dropdown', 'radio', 'checkboxes'].includes(fieldType) && (
        <SelectOptionsModal
          key={`${form.fields.length}-${modalField?.id}`}
          type={fieldType as FieldWithOptions['type']}
          defaultValues={modalField as FieldWithOptions}
          onSubmit={handleField}
          onRemove={handleRemove}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default Form
