import { useCallback } from 'react'

import useTimer from 'hooks/useTimer'

export default function useCopied() {
  const [isActive, trigger] = useTimer()

  const copy = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text)
      trigger()
    },
    [trigger]
  )

  return [isActive, copy] as const
}
