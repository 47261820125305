import { useCallback } from 'react'

import { useAPI } from 'hooks/useAPI'
import { deleteUser } from 'thunks/api/users/delete'

export function useDeleteUser() {
  const [run, { timer }] = useAPI(deleteUser)

  const handleDelete = useCallback(
    async (id: string) => {
      await run({ id })
    },
    [run]
  )

  return [handleDelete, { timer }] as const
}
