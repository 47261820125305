import classNames from 'classnames'
import { ReactNode } from 'react'

import s from './index.module.scss'

export type Props<T extends string> = {
  name: string
  label: string
  value: T
  isSelected: boolean
  children: ReactNode
  className?: string
  onClick: () => void
}

const Option = <T extends string>({
  label,
  isSelected,
  children,
  className,
  onClick,
}: Props<T>) => {
  return (
    <li
      className={classNames(
        s.Option,
        isSelected ? s.SelectedOption : s.UnselectedOption,
        className
      )}
      onClick={onClick}
    >
      <div>
        <input type="radio" checked={isSelected} />
      </div>
      <div>
        <header>{label}</header>
        <p>{children}</p>
      </div>
    </li>
  )
}

export default Option
