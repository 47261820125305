import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'
import { HTTPDeleted } from 'util/statuses'

export type Request = {
  id: string
  categoryId: string
}

type Response = Iframe

export const deleteIFrameCategory = asyncThunk<Request, Response>({
  name: 'iframes/categories/delete',
  method: 'DELETE',
  uri: ({ id, categoryId }) => `/iframes/${id}/categories/${categoryId}`,
  stripId: true,
  successStatus: HTTPDeleted,
})
