import { FC, useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { faChevronLeft, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field as FieldType, InputField } from '@journeyid/agent/types'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import ConfirmButton from 'components/atoms/ConfirmButton'
import Field from 'components/molecules/Field'
import Modal from 'components/organisms/Modal'
import { idleTimer } from 'hooks/useActionTimer'
import { openModal, useModal } from 'hooks/useModal'
import useYupValidationResolver from 'hooks/useYupValidationResolver'

import s from './FormModal.module.scss'

const typeTitles = {
  text: 'Freeform text',
  money: 'An amount of money',
  date: 'Date input',
  address: 'Address input',
} as const

type Props = {
  type: InputField['type']
  defaultValues?: FieldType
  onSubmit: (field: FieldType) => void
  onRemove: () => void
  onClose: () => void
}

const schema = yup.object().shape({
  label: yup.string().required(t('A label is required')),
})

const FreeformTextModal: FC<Props> = ({ type, defaultValues, onSubmit, onRemove, onClose }) => {
  const [isOpen] = useModal('FreeformText')
  const formRef = useRef<HTMLFormElement | null>(null)

  const resolver = useYupValidationResolver(schema)
  const form = useForm<FieldType>({
    defaultValues,
    shouldUnregister: false,
    resolver,
  })
  const { register, errors, handleSubmit } = form

  const handleSubmission = useCallback(
    (field: FieldType) => {
      onSubmit({
        ...defaultValues,
        ...field,
        type,
      })
    },
    [type, defaultValues, onSubmit]
  )

  return (
    <Modal
      isOpen={isOpen}
      className="max-w-3xl px-4 py-0 flex flex-col items-center"
      onClose={onClose}
    >
      <form className="w-3/4 pt-20" ref={formRef} onSubmit={handleSubmit(handleSubmission)}>
        <div>
          <button className={s.BackButton} onClick={openModal('FormFields')}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <div className="pb-16">
            <Modal.Header>{t(typeTitles[type])}</Modal.Header>
            <Modal.Body>
              {t(
                'Customize how the form field will be structured, including placeholder & hint text.'
              )}
            </Modal.Body>

            <Field
              ref={register}
              id="label"
              name="label"
              label={t('Field Label')}
              className="mb-4"
              autoComplete="off"
              error={errors.label}
              autoFocus
              tabIndex={0}
            />

            {type !== 'address' && (
              <Field
                ref={register}
                id="placeholder"
                name="placeholder"
                label={t('Placeholder Text')}
                className="mb-4"
                autoComplete="off"
                error={errors.placeholder}
                tabIndex={1}
                hint={t('This text will be shown in the input before the user enters anything.')}
              />
            )}

            <Field
              ref={register}
              id="hint"
              name="hint"
              label={t('Hint Text')}
              placeholder={t('(optional)')}
              className="mb-4"
              autoComplete="off"
              error={errors.hint}
              hint={t('This is an example of hint text. It will be shown below the input.')}
              tabIndex={2}
            />
          </div>

          <div className={s.CloseButton}>
            <button tabIndex={-1} onClick={onClose}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        </div>

        <div className={s.FormFooter}>
          <Modal.Actions>
            <Button type="submit" size="xl" tabIndex={3} className="px-16">
              {t('Save')}
            </Button>
            {/* <Button variant="link" size="xl" tabIndex={4} className="px-0" onClick={saveAndAdd}>
              Save and add another
            </Button> */}
            {defaultValues?.id && (
              <ConfirmButton
                loadingText={t('deleting...')}
                timer={idleTimer}
                color="text-red-600"
                onConfirm={onRemove}
              >
                {t('delete field')}
              </ConfirmButton>
            )}
          </Modal.Actions>
        </div>
      </form>
    </Modal>
  )
}

export default FreeformTextModal
