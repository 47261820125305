import { formatRFC3339 } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { useList } from 'react-use'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { useAuthSelector } from 'store'

import type { ListActions } from 'react-use/lib/useList'
import type { Event } from 'types/event'

type Output = [Event[], Pick<ListActions<Event>, 'clear'>]

export function useRealtimeUserEvents(userId: string): Output {
  const [events, { upsert, clear }] = useList<Event>()
  const auth = useAuthSelector()
  const now = useMemo(() => new Date(), [])

  useEffect(() => {
    const url = `wss://${window.location.host}/api/app/ws/events/user/${userId}`
    const ws = new ReconnectingWebSocket(url)

    ws.addEventListener('open', () => {
      clear()
      console.log(`connected to ${url}`)
      const ts = formatRFC3339(now, { fractionDigits: 3 })
      ws.send(`STREAM ${ts} ${auth.token}`)
    })

    ws.addEventListener('message', ev => {
      const payload = JSON.parse(ev.data) as Event
      console.log('New realtime event', payload)
      upsert((a: Event, b: Event) => a.id === b.id, payload)
    })

    return () => {
      clear()
      ws.close()
    }
  }, [userId, auth, now, upsert, clear])

  return [events, { clear }]
}
