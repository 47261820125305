import { FC } from 'react'
import { t } from 'translation'

import { RandomCodeMetadata } from '@journeyid/agent/types'

import PrimaryButton from 'components/atoms/Button'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

type Props = {
  metadata: RandomCodeMetadata
}

const RandomCodePreview: FC<Props> = ({ metadata }) => {
  return (
    <>
      <LogoHeader />
      <Header>{t("Here's your code:")}</Header>
      <main className="flex !flex-row justify-center px-4 pt-8 text-5xl space-x-2 text-theme">
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        {metadata.digits > 4 && <span>5</span>}
        {metadata.digits > 5 && <span>6</span>}
      </main>
      <footer>
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Continue')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default RandomCodePreview
