import { useCallback } from 'react'
import { useAuthSelector } from 'store'
import { ErrorOption } from 'react-hook-form'

import { useAPI } from 'hooks/useAPI'
import { Request, createOIDC } from 'thunks/api/accounts/create-oidc'
import { APIErrorResponse } from 'util/error'

export type ValidationErrorCallback = (name: string, error: ErrorOption) => void

export function useCreateOIDC(onError: ValidationErrorCallback) {
  const handleValidationError = useCallback(
    (_: number, error: APIErrorResponse) => {
      if (error.errors) {
        Object.entries(error.errors).forEach(([key, val]) => {
          onError(key, { type: 'server', message: val })
        })
      }
    },
    [onError]
  )
  const [run, { timer }] = useAPI(createOIDC, { onError: handleValidationError })
  const { accountId } = useAuthSelector()

  const create = useCallback(
    async (req: Omit<Request, 'id'>) => {
      if (!accountId) return
      await run({ ...req, id: accountId })
    },
    [accountId, run]
  )

  return [create, { timer }] as const
}
