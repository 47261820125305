import { useEffect, useState } from 'react'

import { useAPI } from 'hooks/useAPI'
import { listAccounts } from 'thunks/api/accounts/list'

type Account = {
  id: string
  name: string
}

export default function useListAccounts() {
  const [list] = useAPI(listAccounts)

  const [accounts, setAccounts] = useState<Account[]>()

  useEffect(() => {
    list({}).then(accounts => setAccounts(accounts))
  }, [list])

  return accounts
}
