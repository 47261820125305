import { FC } from 'react'
import { t } from 'translation'

import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'
import SecondaryButton from 'components/organisms/Preview/SecondaryButton'

const GovernmentIDSelfiePreview: FC = () => {
  return (
    <>
      <LogoHeader />

      <Header headline={t('Verify your ID')}>
        {t(
          "Which type of Government ID would you like to use? You'll then be asked to take a selfie and the photos will be matched."
        )}
      </Header>
      <main className="px-4 pt-16">
        <div className="flex flex-col space-y-4">
          <SecondaryButton onClick={() => {}}>{t(`Driver's License`)}</SecondaryButton>
          <SecondaryButton onClick={() => {}}>{t('Passport')}</SecondaryButton>
          <SecondaryButton onClick={() => {}}>{t('National ID Card')}</SecondaryButton>
        </div>
      </main>
    </>
  )
}

export default GovernmentIDSelfiePreview
