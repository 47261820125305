import { Pipeline } from 'types'

import { StageType } from '@journeyid/agent/types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  stageType: StageType
}

type Response = Pipeline

export const addStage = asyncThunk<Request, Response>({
  name: 'pipelines/add-stage',
  method: 'POST',
  uri: ({ id }) => `/pipelines/${id}/stages`,
})
