import { FC } from 'react'
import { useFieldArray } from 'react-hook-form'

import Button from 'components/atoms/Button'
import AccountIDForm from 'pages/Settings/tabs/Zoom/_AccountIDForm'

const AccountIDs: FC = () => {
  const { fields, append, remove } = useFieldArray({
    name: 'accounts',
  })

  return (
    <div className="mb-6">
      <label className="flex items-end text-sm font-medium text-theme-black">Linked Accounts</label>
      <ul className="my-3 max-w-[32rem]">
        {fields.length === 0 && (
          <li>
            <p className="text-xs text-gray-500">No accounts have been linked yet</p>
          </li>
        )}
        {fields.map((field, index) => {
          return (
            <li key={field.id} className="flex group hover:bg-gray-50 py-1.5 px-3">
              <label className="flex items-center flex-1 font-mono text-sm">
                {field['accountId']}{' '}
                <span className="ml-3 text-xs text-gray-500">{field['email']}</span>
              </label>
              <Button
                size="xs"
                variant="secondary"
                className="cursor-pointer"
                onClick={() => remove(index)}
              >
                remove
              </Button>
            </li>
          )
        })}
      </ul>
      <AccountIDForm key={fields.length} onSubmit={accountId => append({ accountId })} />
    </div>
  )
}

export default AccountIDs
