import classNames from 'classnames'
import React, { ChangeEvent, FC, forwardRef, HTMLProps, ReactElement, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import Checkbox from '../../atoms/Checkbox'
import Input from '../../atoms/Input'
import Select from '../../atoms/Select'
import Textarea from '../../atoms/Textarea'
import ErrorMessage from '../ErrorMessage'
import styles from './index.module.scss'

type OptionProps = {
  label?: string
  value: string
  disabled?: boolean
  children?: string | ReactNode
}

type FieldType =
  | {
      as: 'select' | 'radio' | 'checkbox' | 'checkboxes'
      children: ReactElement<OptionProps>[] | ReactElement<OptionProps>
    }
  | {
      as?: 'input' | 'textarea'
    }

type Props = {
  label?: ReactNode
  name: string
  hint?: ReactNode
  type?: HTMLProps<HTMLInputElement>['type']
  error?: FieldError
  className?: string
  inputClassName?: string
  wrapperClassName?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
} & FieldType &
  Omit<HTMLProps<HTMLInputElement>, 'label' | 'onChange'>

const Field = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    hint,
    error,
    name,
    type = 'text',
    as = 'input',
    className,
    inputClassName,
    wrapperClassName,
    children,
    ...rest
  } = props

  return (
    <div className={classNames('__Field', className)}>
      {label && (
        <label htmlFor={name} className={styles.Label}>
          {label}
        </label>
      )}
      <div
        className={classNames(styles.InputWrapper, wrapperClassName, {
          [styles.Options]: as === 'radio' || as === 'checkbox' || as === 'checkboxes',
        })}
      >
        {as === 'select' ? (
          // @ts-ignore
          <Select ref={ref} name={name} {...rest} className={inputClassName}>
            {typeof rest.placeholder === 'string' && <option value="">{rest.placeholder}</option>}
            {children}
          </Select>
        ) : props.as === 'radio' || props.as === 'checkboxes' ? (
          (Array.isArray(props.children) ? props.children : [props.children]).map(
            ({ props: { value, children } }, idx) => (
              <Checkbox
                key={`${name}-${idx}-${value}`}
                // @ts-ignore
                ref={ref}
                type={props.as === 'radio' ? 'radio' : 'checkbox'}
                name={name}
                value={value}
                {...rest}
              >
                {children}
              </Checkbox>
            )
          )
        ) : props.as === 'checkbox' ? (
          <Checkbox
            // @ts-ignore
            ref={ref}
            name={name}
            type={props.as}
            defaultChecked={rest.defaultChecked}
            {...rest}
          >
            {children}
          </Checkbox>
        ) : children ? (
          children
        ) : as === 'input' ? (
          // @ts-ignore
          <Input ref={ref} type={type} name={name} {...rest} className={inputClassName} />
        ) : (
          // @ts-ignore
          <Textarea ref={ref} type={type} name={name} {...rest} className={inputClassName} />
        )}
      </div>
      {error && <ErrorMessage error={error} />}
      {hint && <p className={styles.Hint}>{hint}</p>}
    </div>
  )
})

export const Option: FC<OptionProps> = ({
  label,
  value,
  disabled = false,
  children,
}: OptionProps) => (
  <option value={value} disabled={disabled}>
    {label ?? children}
  </option>
)

export default Field
