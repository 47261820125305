import { useCallback } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import s from './FormFieldList.module.scss'

type Option = {
  label: string
  value: string
}

type Props = {
  options: Option[]
  setOptions: (options: Option[]) => void
}

const reorder = (list: Array<Option>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const SelectOptionList = ({ options, setOptions }: Props) => {
  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination || destination.index === source.index) {
        return
      }

      const updated = reorder(options, source.index, destination.index)
      setOptions(updated)
    },
    [options, setOptions]
  )

  const removeOption = (idx: number) => {
    setOptions(options.filter((option, i) => i !== idx))
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div className={s.FieldList}>
              {options.map((option, idx) => (
                <Draggable key={idx} draggableId={`${idx}`} index={idx}>
                  {provided => (
                    <div key={idx} ref={provided.innerRef} {...provided.draggableProps}>
                      <div>
                        <div {...provided.dragHandleProps}>
                          <FontAwesomeIcon className="mr-4 cursor-grab" icon={faGripDotsVertical} />
                        </div>
                        <p>
                          {option.label}
                          <aside>{option.value}</aside>
                        </p>
                      </div>
                      <button
                        className="text-sm text-red-600"
                        onClick={e => {
                          e.preventDefault()
                          removeOption(idx)
                        }}
                      >
                        {t('remove')}
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SelectOptionList
