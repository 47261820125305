import React, { FC, HTMLProps, ReactElement } from 'react'

import Field, { CommonProps } from './_Wrapper'
import styles from './index.module.scss'

type RadioProps = Omit<HTMLProps<HTMLInputElement>, 'ref' | 'label' | 'name' | 'value' | 'onChange'>

type OptionProps = {
  value: string
  children: string
}

export type Props = CommonProps & {
  value: string
  onChangeValue: (value: string) => void
  children: ReactElement<OptionProps>[]
} & RadioProps

const Radio: FC<Props> = ({ value: selectedValue, onChangeValue, children, ...rest }) => {
  const { label, name, hint, isOptional, error, aside, className, ...inputProps } = rest
  const fieldProps = { label, name, hint, isOptional, error, aside, className }

  return (
    <Field {...fieldProps}>
      <ul className={styles.Options}>
        {children.map(({ props: { value, children } }) => (
          <li key={children}>
            <label>
              <input
                type="radio"
                name={name}
                value={value}
                checked={value === selectedValue}
                onChange={() => onChangeValue(value)}
                {...inputProps}
              />
              <span>{children}</span>
            </label>
          </li>
        ))}
      </ul>
    </Field>
  )
}

export default Radio
