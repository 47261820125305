import { HTTPRetrievedMultiple } from '@journeyid/agent/common/statuses'

import { Branding } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = {}

export type Response = Branding[]

export const listBrandStyles = asyncThunk<Request, Response>({
  name: 'brand-styles/list',
  method: 'GET',
  uri: '/brand-styles/list',
  successStatus: HTTPRetrievedMultiple,
  orchestrator: false,
})
