import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch } from 'store'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import { t } from 'translation'

import Loading from 'components/atoms/Loading'
import useMagicLinkAuthenticate from 'hooks/api/authentication/useMagicLinkAuthenticate'
import useQueryString from 'hooks/useQueryString'
import { authenticationsLogout } from 'thunks/api/authentications/logout'

import s from './index.module.scss'

type QueryParams = {
  token?: string
}

const MagicLinkCallback: FC = () => {
  const { token } = useQueryString<QueryParams>()
  console.log('tok', token)

  const history = useHistory()
  const dispatch = useAppDispatch()
  const [magicLinkAuth] = useMagicLinkAuthenticate()

  const [error, setError] = useState<string>()
  const authenticate = useCallback(async () => {
    if (!token) return
    try {
      await magicLinkAuth({ token })
      history.push(r.root)
    } catch (err) {
      if (isAPIErrorResponse(err)) {
        setError(err.error)
      } else {
        setError(t('Unable to authenticate'))
      }
    }
  }, [token, magicLinkAuth, history])

  useEffect(() => {
    dispatch(authenticationsLogout())

    if (!token) {
      history.push(r.auth.login)
      return
    }

    authenticate()
  }, [token, authenticate, dispatch, history])

  if (error) {
    return (
      <div className={s.Error}>
        <label>{error}</label>
        <button onClick={() => history.push(r.auth.login)}>{t('Try Again')}</button>
      </div>
    )
  }

  return (
    <div>
      <Loading />
    </div>
  )
}

export default MagicLinkCallback
