import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'

import AlertModal from 'components/organisms/AlertModal'
import { openModal } from 'hooks/useModal'
import useQueryString from 'hooks/useQueryString'
import Auth from 'pages/auth/shared/Auth'
import GithubButton from 'pages/auth/shared/buttons/GithubButton'
import GoogleButton from 'pages/auth/shared/buttons/GoogleButton'
import MagicLinkButton from 'pages/auth/shared/buttons/MagicLinkButton'
import MicrosoftButton from 'pages/auth/shared/buttons/MicrosoftButton'
import SSOButton from 'pages/auth/shared/buttons/SSOButton'

type QueryParams = {
  error?: string
}

const Login: FC = () => {
  const query = useQueryString<QueryParams>()
  const history = useHistory()

  useEffect(() => {
    if (!query.error) return
    openModal('Alert')()
  }, [query.error])

  return (
    <>
      <Auth
        info={t('Sign in below to access your Journey Elevate account.')}
        footer={
          <p>
            {t("Don't have an account?")}{' '}
            <button onClick={() => history.push(r.auth.register)}>{t('Sign up')}</button>
          </p>
        }
      >
        <GoogleButton text={t('Sign in with Google')} />
        <GithubButton text={t('Sign in with GitHub')} />
        <MicrosoftButton text={t('Sign in with Microsoft')} />
        <SSOButton text={t('Sign in with Single Sign-On')} />
        <MagicLinkButton text={t('Sign in with a Magic Link')} />
      </Auth>
      {query.error && (
        <AlertModal>
          <div className="font-mono text-sm leading-6">{decodeURI(query.error)}</div>
        </AlertModal>
      )}
    </>
  )
}

export default Login
