import { FC } from 'react'

import { t } from 'translation'

import FormPreview from 'components/templates/stages/Form/Preview'

const KnowledgeBasedAuthenticationPreview: FC = () => {
  return (
    <FormPreview
      metadata={{
        pages: [
          {
            title: t('Knowledge Based Authentication'),
            text: t('Please answer the questions to verify your identity'),
            fields: [
              {
                id: 'maiden-name',
                type: 'text',
                label: t(`What's your mother's maiden name?`),
              },
              {
                id: 'street',
                type: 'radio',
                label: t(`What street did you live on in 1872?`),
                options: [
                  {
                    label: t('Blake St'),
                    value: 'blake-st',
                  },
                  {
                    label: t('Brighton Blvd'),
                    value: 'brighton-blvd',
                  },
                  { label: t('Larimer St'), value: 'larimer-st' },
                  { label: t('Wynkoop St'), value: 'wynkoop-str' },
                ],
              },
            ],
          },
        ],
      }}
    />
  )
}

export default KnowledgeBasedAuthenticationPreview
