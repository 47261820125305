import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { ReactComponent as CondensedLogoImg } from './condensed-logo.svg'
import s from './Logo.module.scss'
import { ReactComponent as LogoImg } from './logo.svg'

type Props = {
  isCondensed: boolean
}

const Logo: FC<Props> = ({ isCondensed }) => {
  const history = useHistory()

  return (
    <div className={s.Wrapper}>
      {isCondensed ? (
        <CondensedLogoImg className={s.CondensedLogo} />
      ) : (
        <LogoImg className={s.Logo} onClick={() => history.push(r.root)} />
      )}
    </div>
  )
}
export default Logo
