import cogoToast from 'cogo-toast'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import Header from 'components/molecules/AdminHeader'
import Field, { Option } from 'components/molecules/Field'
import { useAPI } from 'hooks/useAPI'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'
import { retrievePaymentConfiguration } from 'thunks/api/accounts/retrieve-payment-configuration'
import { updatePaymentConfiguration } from 'thunks/api/accounts/update-payment-configuration'
import { PaymentConfiguration } from 'types/account'
import objectKeys from 'util/object-keys'

import s from './General.module.scss'

const PaymentGateways = () => {
  const form = useForm<PaymentConfiguration>({ shouldUnregister: false })
  const { handleSubmit, register, errors, setValue } = form

  const [run, { timer }] = useAPI(retrievePaymentConfiguration)
  const { accountId } = useAuthSelector()

  const [config, setConfig] = useState<PaymentConfiguration>()
  useEffect(() => {
    if (!accountId) return
    run({ id: accountId }).then(setConfig)
  }, [accountId, setConfig, run])

  useEffect(() => {
    if (!config) return

    setValue('defaultGateway', config.defaultGateway)

    objectKeys(config).forEach(key => {
      // @ts-ignore
      if (key === 'id') return

      // @ts-ignore
      objectKeys(config[key]).forEach(subKey => {
        // @ts-ignore
        setValue(`${key}.${subKey}`, config[key][subKey])
      })
    })
  }, [config, setValue])

  const [update, { timer: updateTimer }] = useAPI(updatePaymentConfiguration)
  const handleUpdate = useCallback(
    async (data: PaymentConfiguration) => {
      if (!accountId) return
      await update({
        id: accountId,
        ...data,
      })
      cogoToast.success(t('Gateway configuration has been updated!'))
    },
    [accountId, update]
  )

  return (
    <FormProvider {...form}>
      <form className="h-full" onSubmit={handleSubmit(handleUpdate)}>
        <ContentWrapper withFooter>
          <Header title={t('Settings')} />
          <div className={s.Content}>
            <Menu />
            <div>
              {timer.isLoading ? (
                <Loading />
              ) : (
                <>
                  <SettingsCard>
                    <Field
                      ref={register}
                      as="select"
                      label="Default Gateway"
                      name="defaultGateway"
                      placeholder=""
                      error={errors.adyen?.environment}
                      className="w-40"
                    >
                      <Option value="braintree" disabled={!form.watch('braintree.merchantId')}>
                        Braintree
                      </Option>
                      <Option value="stripe" disabled={!form.watch('stripe.publishableKey')}>
                        Stripe
                      </Option>
                      <Option value="adyen" disabled={!form.watch('adyen.apiKey')}>
                        Adyen
                      </Option>
                    </Field>
                  </SettingsCard>
                  <SettingsCard title="Braintree">
                    <Field
                      ref={register}
                      id="braintree.merchantId"
                      name="braintree.merchantId"
                      label={t('Merchant ID')}
                      autoComplete="one-time-code"
                      error={errors.braintree?.merchantId}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      id="braintree.publicKey"
                      name="braintree.publicKey"
                      label={t('Public Key')}
                      autoComplete="one-time-code"
                      error={errors.braintree?.publicKey}
                      maxLength={32}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      id="braintree.privateKey"
                      name="braintree.privateKey"
                      label={t('Private Key')}
                      autoComplete="one-time-code"
                      error={errors.braintree?.privateKey}
                      maxLength={32}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      as="select"
                      label="Environment"
                      name="braintree.environment"
                      placeholder=""
                      error={errors.braintree?.environment}
                      className="w-40"
                    >
                      <Option value="sandbox">Sandbox</Option>
                      <Option value="production">Live</Option>
                    </Field>
                  </SettingsCard>
                  <SettingsCard title="Stripe">
                    <Field
                      ref={register}
                      id="stripe.publishableKey"
                      name="stripe.publishableKey"
                      label={t('Publishable Key')}
                      autoComplete="one-time-code"
                      error={errors.stripe?.publishableKey}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      id="stripe.secretKey"
                      name="stripe.secretKey"
                      label={t('Secret Key')}
                      autoComplete="one-time-code"
                      error={errors.stripe?.secretKey}
                      maxLength={64}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                  </SettingsCard>
                  <SettingsCard title="Adyen">
                    <Field
                      ref={register}
                      id="adyen.apiKey"
                      name="adyen.apiKey"
                      label={t('API Key')}
                      autoComplete="one-time-code"
                      error={errors.adyen?.apiKey}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      id="adyen.clientKey"
                      name="adyen.clientKey"
                      label={t('Client Key')}
                      autoComplete="one-time-code"
                      error={errors.adyen?.clientKey}
                      maxLength={64}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      id="adyen.merchantId"
                      name="adyen.merchantId"
                      label={t('Merchant ID')}
                      autoComplete="one-time-code"
                      error={errors.adyen?.merchantId}
                      maxLength={32}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      id="adyen.hmacKey"
                      name="adyen.hmacKey"
                      label={t('HMAC Key')}
                      autoComplete="one-time-code"
                      error={errors.adyen?.hmacKey}
                      maxLength={32}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      as="select"
                      label="Environment"
                      name="adyen.environment"
                      placeholder=""
                      error={errors.adyen?.environment}
                      className="w-40"
                    >
                      <Option value="sandbox">Sandbox</Option>
                      <Option value="production">Live</Option>
                    </Field>
                  </SettingsCard>
                </>
              )}
            </div>
          </div>
          <Footer timer={updateTimer} />
        </ContentWrapper>
      </form>
    </FormProvider>
  )
}

export default PaymentGateways
