import sanitize from 'sanitize-html'
import { BaseElement, BaseText, Text } from 'slate'

export type SlateNodeType =
  | 'link'
  | 'h1'
  | 'h2'
  | 'numbered-list'
  | 'bulleted-list'
  | 'list-item'
  | 'paragraph'

export type SlateValue = SlateNode[]

type SlateElementNode = Omit<BaseElement, 'children'> & {
  type: SlateNodeType
  children: SlateNode[]
} & SlateNodeOptions

type SlateTextNode = BaseText & SlateTextOptions

type SlateNode = SlateElementNode | SlateTextNode

export type SlateTextOptions = {
  bold?: boolean
  italic?: boolean
  code?: boolean
}

export type SlateNodeOptions = {
  url?: string
}

function serializeNode(node: SlateNode): string {
  if (Text.isText(node)) {
    var string = node.text
    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    if (node.italic) {
      string = `<em>${string}</em>`
    }
    if (node.code) {
      string = `<code>${string}</code>`
    }
    return string
  }

  const children = node.children.map(n => serializeNode(n as SlateNode)).join('')
  const sanitized = sanitize(children)

  switch (node.type) {
    case 'h1':
      return `<h1>${sanitized}</h1>`
    case 'h2':
      return `<h2>${sanitized}</h2>`
    case 'paragraph':
      return `<p>${sanitized}</p>`
    case 'bulleted-list':
      return `<ul>${sanitized}</ul>`
    case 'numbered-list':
      return `<ol>${sanitized}</ol>`
    case 'list-item':
      return `<li>${sanitized}</list>`
    case 'link':
      const url = sanitize(`<a href="${node.url}" />`)
      const match = url.match(/href="([^"]+)"/)
      if (!match) return sanitized
      return `<a href="${match[1]}" target="_blank" rel="noreferrer">${sanitized}</a>`
  }
}

export default function serializeSlateValue(value: SlateValue): string {
  return value.map(serializeNode).join('')
}
