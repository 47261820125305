import { FC, FormEvent, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import Button from 'components/atoms/Button'
import useSSORedirect from 'hooks/api/useSSORedirect'
import useActionTimer from 'hooks/useActionTimer'

import s from '../MagicLink/index.module.scss'

const AuthenticateSSO: FC = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string>()
  const [redirect] = useSSORedirect()

  const timer = useActionTimer()
  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (!email) return
      try {
        timer.start()
        setError(undefined)

        const { url } = await redirect(email)
        window.location.href = url
      } catch (err) {
        timer.reset()
        if (isAPIErrorResponse(err)) {
          if (err.errors && 'email' in err.errors) {
            setError(err.errors.email)
          } else {
            setError(err.error)
          }
        } else {
          setError(t('unable to initiate single sign-on'))
        }
      }
    },
    [email, timer, redirect]
  )

  return (
    <div className={s.Wrapper}>
      <div className={s.MagicLink}>
        <h1>{t('Please enter your email address')}</h1>
        <p>{t("You'll be redirect to your Single Sign-On provider.")}</p>
        <form onSubmit={handleSubmit}>
          <input
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus
            placeholder="email@example.com"
          />

          {error && <div className={s.Error}>{error}</div>}

          <div>
            <Button isLoading={timer.isLoading} type="submit" className={s.ConfirmBtn}>
              {t('Sign in')}
            </Button>

            <button className={s.CancelBtn} onClick={() => history.push(r.auth.login)}>
              {t('cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AuthenticateSSO
