import { FC } from 'react'

import KnowledgeBasedAuthenticationPreview from 'components/templates/stages/KnowledgeBasedAuthentication.Preview'
import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'

const KnowledgeBasedAuthentication: FC = () => {
  return (
    <NoSettingsStage
      stageType="knowledge-based-authentication"
      preview={KnowledgeBasedAuthenticationPreview}
    />
  )
}

export default KnowledgeBasedAuthentication
