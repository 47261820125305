import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import s from './StageList.module.scss'

const methods = ['sms', 'email', 'push-notification'] as const

type Method = typeof methods[number]

const methodTitleMap: Record<Method, string> = {
  email: 'Email',
  sms: 'Text Message',
  'push-notification': 'Push Notification',
}

const DeliveryMethodList: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { pipeline } = usePipelineSelector()

  const activeIdx = useMemo(() => {
    const step = location.pathname.split('/')
    return methods.findIndex(m => m === step[5])
  }, [location])

  return (
    <ul className={s.StageList}>
      {methods.map((method, idx) => (
        <li
          key={method}
          className={classNames(idx === activeIdx && s.Active, s.DeliveryMethod)}
          onClick={() => history.push(r.pipelines.create.delivery.method(pipeline.id, method))}
        >
          <label>{t(methodTitleMap[method])}</label>
        </li>
      ))}
    </ul>
  )
}

export default DeliveryMethodList
