import { useCallback, useState } from 'react'
import { usePipelineSelector } from 'store'

import { StageType } from '@journeyid/agent/types'

import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import { addStage } from 'thunks/api/pipelines/add-stage'
import { removeStage } from 'thunks/api/pipelines/remove-stage'

export default function useStages() {
  const { pipeline } = usePipelineSelector()

  const [addStageType, { timer: addTimer }] = useAPI(addStage)
  const [removeStageType, { timer: removeTimer }] = useAPI(removeStage)

  const [modalStageType, setModalStageType] = useState<StageType>()
  const toggleStage = useCallback(
    async (stageType: StageType) => {
      if (pipeline.stages.some(s => s.type === stageType)) {
        openModal('DeleteStage')()
        setModalStageType(stageType)
      } else {
        addTimer.setId(stageType)
        await addStageType({
          id: pipeline.id,
          stageType,
        })
      }
    },
    [pipeline, addStageType, addTimer]
  )

  const remove = useCallback(async () => {
    if (!modalStageType) return

    removeTimer.setId(modalStageType)
    await removeStageType({
      id: pipeline.id,
      stageType: modalStageType,
    })
    closeModal()
  }, [pipeline, modalStageType, removeStageType, removeTimer])

  return { pipeline, addTimer, removeTimer, toggleStage, removeStage: remove }
}
