import classNames from 'classnames'
import isHotkey from 'is-hotkey'
import { FC, KeyboardEvent, ReactNode, useCallback, useMemo, useState } from 'react'
import { createEditor, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react'

import { t } from 'translation'

import styles from 'components/organisms/Preview/SlateHTML/standard.module.scss'
import { SlateNodeType, SlateTextOptions, SlateValue } from 'util/slate'

import { ButtonFormat } from './_Button'
import { withLinks } from './links'
import Toolbar from './SlateToolbar'
import { toggleMark } from './util'

export const defaultText: SlateValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

export const emptySlateValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+`': 'code',
} as Record<string, ButtonFormat>

type Props = {
  defaultValue?: SlateValue
  placeholder?: string
  onChange: (value: SlateValue) => void
  children: (editable: JSX.Element) => ReactNode
}

const SlateEditor: FC<Props> = ({ defaultValue, placeholder, onChange, children }) => {
  const [value, setValue] = useState<SlateValue>(defaultValue || defaultText)
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
  const editor = useMemo(() => withLinks(withHistory(withReact(createEditor() as ReactEditor))), [])

  const handleChange = useCallback(
    (value: Descendant[]) => {
      const ops = editor.operations.filter(o => o.type !== 'set_selection')
      if (ops.length === 0) return

      setValue(value as SlateValue)
      onChange(value as SlateValue)
    },
    [editor, setValue, onChange]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      var hotkey: keyof typeof HOTKEYS
      for (hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event as any)) {
          event.preventDefault()
          const mark = HOTKEYS[hotkey]
          toggleMark(editor, mark)
        }
      }
    },
    [editor]
  )

  const el = (
    <Editable
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      placeholder={placeholder || t('Enter some text...')}
      spellCheck
      autoFocus
      onKeyDown={handleKeyDown}
      className={classNames(styles.Default, 'flex-1')}
    />
  )

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <Toolbar />
      {children(el)}
    </Slate>
  )
}

type ElementProps = {
  attributes: Record<string, any>
  element: {
    type?: SlateNodeType
  }
  children: SlateNodeType
}

const Element: FC<ElementProps> = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'h1':
      return <h1 {...attributes}>{children}</h1>
    case 'h2':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return (
        // @ts-ignore
        <a {...attributes} href={element.url} target="_blank" rel="noreferrer">
          {children}
        </a>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

type LeafProps = {
  attributes: Record<string, any>
  leaf: SlateTextOptions
  children: ReactNode
}

const Leaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  return <span {...attributes}>{children}</span>
}

export default SlateEditor
