import qs from 'qs'
import { FC } from 'react'

import { ReactComponent as GithubIcon } from 'pages/auth/shared/Auth/icons/github.svg'

type Props = {
  text: string
  state?: string
}

const GithubButton: FC<Props> = ({ state, text }) => {
  const handleClick = () => {
    const query: Record<string, string> = {
      client_id: _env_.GITHUB_OAUTH_ID || '',
      redirect_uri: `${window.location.origin}/api/app/oauth/github/callback`,
    }

    if (state) {
      query.state = state
    }

    window.location.href = `https://github.com/login/oauth/authorize?${qs.stringify(query)}`
  }

  if (!_env_.GITHUB_OAUTH_ID) {
    return null
  }

  return (
    <button onClick={handleClick}>
      <GithubIcon />
      {text}
    </button>
  )
}

export default GithubButton
