import { faBuilding } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './CondensedBottomMenu.module.scss'

const CondensedBottomMenu = () => (
  <div className={s.CompanyIcon}>
    <FontAwesomeIcon icon={faBuilding} />
  </div>
)

export default CondensedBottomMenu
