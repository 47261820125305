import classNames from 'classnames'
import { FC } from 'react'
import { useRouteMatch } from 'react-router-dom'
import routes from 'routes'
import { useIframeSelector } from 'store'

import { t } from 'translation'

import s from './index.module.scss'
import Steps from './Steps'

const CreateSidebar: FC = () => {
  const { iframe } = useIframeSelector()

  const r = routes.iframes.create.configure

  const isCondensed = useRouteMatch([r.test()])
  const className = classNames(s.CreateSidebar, {
    [s.CondensedSidebar]: isCondensed,
  })

  return (
    <div id="create-sidebar" className={className}>
      <div>
        <header>
          <h2 className="mb-4 std">{t('Create an iFrame')}</h2>
          <p className="mb-6 text-gray-500 std-base w-[80%]">
            {t('Follow the instructions to configure and test your custom iFrame.')}
          </p>
        </header>
        <Steps iframeId={iframe.id}>
          <Steps.Step path={id => r.title(id)} suffix="title">
            {t('iFrame Title')}
          </Steps.Step>

          <Steps.Step path={id => r.languages(id)} suffix="languages">
            {t('Languages')}
          </Steps.Step>

          <Steps.Step
            path={id => r.enrollmentAndAuthentication(id)}
            suffix="enrollment-and-authentication"
          >
            {t('Enrollment & Authentication')}
          </Steps.Step>

          <Steps.Step path={id => r.pipelines(id)} suffix="pipelines">
            {t('Pipelines')}
          </Steps.Step>

          <Steps.Step path={id => r.deliveryMethods(id)} suffix="delivery-methods">
            {t('Delivery Method')}
          </Steps.Step>

          <Steps.Step path={id => r.test(id)} suffix="test">
            {t('Test the iFrame')}
          </Steps.Step>

          <Steps.Step path={id => r.deploymentInstructions(id)} suffix="deployment-instructions">
            {t('Deployment Instructions')}
          </Steps.Step>
        </Steps>
      </div>
    </div>
  )
}

export { CreateSidebar }

export default CreateSidebar
