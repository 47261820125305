import classNames from 'classnames'
import { FC } from 'react'

import JourneyLogo from 'components/atoms/JourneyLogo'
import Loading from 'components/atoms/Loading'
import useListBrandStyles from 'hooks/api/branding/useListBrandStyles'
import { Branding } from 'types/account'

import s from './BrandStyleList.module.scss'

type Props = {
  brandStyleId?: string
  onChange: (branding: Branding | undefined) => void
}

const BrandStyleList: FC<Props> = ({ brandStyleId, onChange }) => {
  const [brandStyles] = useListBrandStyles()

  if (!brandStyles) return <Loading />

  return (
    <div className={s.Wrapper}>
      <ul className={s.List}>
        <li
          className={classNames('!h-12 font-semibold text-gray-600', {
            [s.Selected]: !brandStyleId,
          })}
          onClick={() => onChange(undefined)}
        >
          Use the account default style
        </li>
        {brandStyles.map((brandStyle, i) => (
          <li
            key={i}
            className={classNames(brandStyleId === brandStyle.id && s.Selected)}
            onClick={() => onChange(brandStyle)}
          >
            <div className="h-[64px] flex items-center">
              {brandStyle.logo ? (
                <img className="h-full max-w-[280px]" src={brandStyle.logo} alt="Logo" />
              ) : (
                <div className="flex space-x-8">
                  <JourneyLogo color="#0060AB" />
                  <div
                    className="w-8 h-8 rounded"
                    style={{ background: brandStyle.primaryColor }}
                  />
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BrandStyleList
