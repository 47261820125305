import { FC } from 'react'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import { ActionTimer } from 'hooks/useActionTimer'

import s from './Header.module.scss'

type Props = {
  timer: ActionTimer
  onBuildFromScratch: () => void
}

const Header: FC<Props> = ({ timer, onBuildFromScratch }) => {
  return (
    <header className={s.Header}>
      <div>
        <h5>{t('Use Case')}</h5>
        <h3 className="std">{t('Select your use case for the pipeline')}</h3>
        <p className="std-base">
          {t(
            'Get started with a pre-built pipeline that aligns with your use case or start from scratch. From there, you can add actions, configure screens, then name &amp; publish your pipeline.'
          )}
        </p>
      </div>
      <div>
        <Button
          variant="white"
          size="xl"
          isLoading={timer.isLoading}
          className="py-2.5 px-6 bg-white border border-gray-300"
          onClick={onBuildFromScratch}
        >
          {timer.isLoading ? t('Building a pipeline...') : t('Build from Scratch')}
        </Button>
        <p>{t('Have a custom use case? Start here.')}</p>
      </div>
    </header>
  )
}

export default Header
