import { format, parseISO } from 'date-fns'
import { FC } from 'react'
import { OAuthSecret } from 'types'

import { t } from 'translation'

import Button from 'components/atoms/Button'

import s from './ClientSecretList.module.scss'

type Props = {
  secret: OAuthSecret
  onRevoke: (secret: OAuthSecret) => void
}

const ClientSecretList: FC<Props> = ({ secret, onRevoke }) => (
  <ul className={s.ClientSecretList}>
    <li>
      <p className="mr-3">
        {secret.secretKey || `${secret.prefix || '****'}****-****-****-****-************`}
      </p>
      <div>
        <p>{t('created {{- date}}', { date: format(parseISO(secret.createdAt), 'M/d/yy') })}</p>
        <Button variant="link" onClick={() => onRevoke(secret)}>
          {t('revoke')}
        </Button>
      </div>
    </li>
  </ul>
)

export default ClientSecretList
