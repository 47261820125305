import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Address, { Props as AP } from './Address'
import Checkboxes, { Props as CP } from './Checkboxes'
import Date, { Props as DP } from './Date'
import Dropdown, { Props as DDP } from './Dropdown'
import Money, { Props as MP } from './Money'
import Radio, { Props as RP } from './Radio'
import Text, { Props as TP } from './Text'

type TextProps = { type: 'text' } & Omit<TP, 'value' | 'onChangeValue'>
type MoneyProps = { type: 'money' } & Omit<MP, 'value' | 'onChangeValue'>
type DateProps = { type: 'date' } & Omit<DP, 'value' | 'onChangeValue'>
type AddressProps = { type: 'address' } & Omit<AP, 'value' | 'onChangeValue'>
type DropdownProps = { type: 'dropdown' } & Omit<DDP, 'value' | 'onChangeValue'>
type RadioProps = { type: 'radio' } & Omit<RP, 'value' | 'onChangeValue'>
type CheckboxesProps = { type: 'checkboxes' } & Omit<CP, 'value' | 'onChangeValue'>

type Props =
  | TextProps
  | MoneyProps
  | DropdownProps
  | RadioProps
  | CheckboxesProps
  | DateProps
  | AddressProps

const Field: FC<Props> = f => {
  const { control } = useFormContext()

  return (
    <Controller
      name={f.name}
      control={control}
      defaultValue={['number', 'money'].includes(f.type) ? 0 : ''}
      // @ts-ignore
      render={({ value, onChange }) => {
        switch (f.type) {
          case 'text':
            return <Text {...f} value={value} onChangeValue={onChange} />
          case 'money':
            return <Money {...f} value={value} onChangeValue={onChange} />
          case 'dropdown':
            return <Dropdown {...f} value={value} onChangeValue={onChange} />
          case 'radio':
            return <Radio {...f} value={value} onChangeValue={onChange} />
          case 'checkboxes':
            return <Checkboxes {...f} value={value} onChangeValue={onChange} />
          case 'date':
            return <Date {...f} value={value} onChangeValue={onChange} />
          case 'address':
            return <Address {...f} value={value} onChangeValue={onChange} />
          default:
            return <></>
        }
      }}
    />
  )
}

export default Field
