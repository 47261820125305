import QueryString from 'qs'
import { AppState } from 'store'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { delay } from 'util/delay'
import { ErrorResponse } from 'util/error'
import { HTTPArchived, HTTPCreated, HTTPRetrieved, HTTPUpdated } from 'util/statuses'

export type ID = string

type Input<Request> = {
  name: string
  uri: ((req: Request) => string) | string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  successStatus?: number
  stripId?: boolean
  throttle?: boolean
  orchestrator?: boolean
}

export default function asyncThunk<Request, Reply = Record<string, never>>({
  name,
  method = 'GET',
  uri,
  successStatus,
  stripId = false,
  throttle = true,
  orchestrator = true,
}: Input<Request>) {
  return createAsyncThunk<Reply, Request, { rejectValue: ErrorResponse }>(
    name,
    async (req, api) => {
      const body = (() => {
        if (stripId) {
          // @ts-ignore
          const { id, ...rest } = req
          return rest
        }
        return req
      })()

      const headers = new Headers()

      const { auth } = api.getState() as AppState
      if (auth) {
        headers.append('Authorization', `Bearer ${auth.token}`)
      }

      const opts: RequestInit = {
        method,
        headers,
      }

      var appUri = typeof uri === 'function' ? uri(req) : uri
      if (body) {
        if (method === 'GET') {
          const query = QueryString.stringify(body, { arrayFormat: 'brackets' })
          appUri = `${appUri}?${query}`
        } else {
          opts.body = JSON.stringify(body)
        }
      }

      const prefix = orchestrator ? '/orchestrator' : ''
      const op = await fetch(`/api/app${prefix}${appUri}`, opts)

      // Don't execute faster than the delay to prevent flickering
      const p = throttle ? delay(400) : Promise.resolve()
      const [response] = await Promise.all([op, p])

      if (response.status >= 400 && response.status < 500) {
        const resp = await response.json()
        return api.rejectWithValue({
          ...resp,
          statusCode: response.status,
        })
      }

      const expectedStatus = ((): number => {
        if (successStatus) {
          return successStatus
        }

        switch (method) {
          case 'GET':
            return HTTPRetrieved
          case 'PUT':
            return HTTPUpdated
          case 'POST':
            return HTTPCreated
          case 'DELETE':
            return HTTPArchived
        }
      })()

      if (response.status !== expectedStatus) {
        return api.rejectWithValue({
          statusCode: response.status,
          error: `unexpected response status: ${response.status}`,
        })
      }

      const resp = await response.json()

      if (stripId) {
        // @ts-ignore
        resp.id = req.id
      }

      return resp
    }
  )
}
