import { forwardRef, useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { Pipeline } from 'types'

import Field, { Option } from 'components/molecules/Field'
import { useAPI } from 'hooks/useAPI'
import { listPipelines } from 'thunks/api/pipelines/list'

type Props = {
  label: string
  name: string
  error: FieldError | undefined
}

const PipelineSelector = forwardRef<HTMLInputElement, Props>(({ label, name, error }, ref) => {
  const [list] = useAPI(listPipelines)
  const [pipelines, setPipelines] = useState<Pipeline[]>()
  useEffect(() => {
    list({ published: true }).then(pipelines => {
      const filtered = pipelines.filter(p => p.stages.some(s => s.type === 'face-enrollment'))
      setPipelines(filtered)
    })
  }, [list])

  return (
    <Field
      key={pipelines?.length}
      ref={ref}
      as="select"
      label={label}
      name={name}
      placeholder=""
      error={error}
    >
      {(pipelines ?? []).map(pipeline => (
        <Option key={pipeline.id} value={pipeline.id}>
          {pipeline.title}
        </Option>
      ))}
    </Field>
  )
})

export default PipelineSelector
