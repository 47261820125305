import classNames from 'classnames'
import { FC } from 'react'
import { t } from 'translation'

import { faPencil } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Admin } from 'types/admin'

import s from './index.module.scss'

type Props = {
  admin: Admin
  onEditGroups: () => void
}

const SupervisorGroups: FC<Props> = ({ admin, onEditGroups }) => {
  if (admin.role !== 'supervisor') return <></>

  return (
    <ul className="inline-flex mt-2 space-x-2 cursor-pointer group" onClick={onEditGroups}>
      {admin.groups.map(group => (
        <li key={group.id} className={classNames(s.Group, 'text-gray-800 bg-gray-100')}>
          {group.name}
        </li>
      ))}
      {admin.groups.length === 0 && (
        <li className={classNames(s.Group, 'text-gray-800 bg-gray-100')}>
          {t('all agent groups')}
        </li>
      )}
      <li className="hidden group-hover:block">
        <FontAwesomeIcon icon={faPencil} className="text-gray-400 " />
      </li>
    </ul>
  )
}

export default SupervisorGroups
