import { HTTPUpdated } from '@journeyid/agent/common/statuses'

import asyncThunk, { ID } from 'util/asyncThunk'

export type FacialBiometricProvider = 'facetec' | 'paravision' | 'innovatrics'

export type Request = {
  id: ID
  facialBiometricProvider: FacialBiometricProvider
}

export type Response = Request

export const accountsAdvancedSettings = asyncThunk<Request, Response>({
  name: 'accounts/advanced-settings',
  method: 'PUT',
  uri: ({ id }) => `../../accounts/${id}/advanced`,
  successStatus: HTTPUpdated,
})
