import classNames from 'classnames'
import { ReactNode } from 'react'

import { StageType } from '@journeyid/agent/types'

import RemoveStage from 'components/templates/stages/shared/RemoveStage'

import s from './index.module.scss'

interface Props {
  alert?: ReactNode
  removeStage?: StageType
  actions: ReactNode
  children?: ReactNode
}

const Footer = ({ alert, removeStage, actions, children }: Props) => (
  <div className={classNames('__Footer', s.Footer)}>
    {alert && <div className={s.Alert}>{alert}</div>}
    <div>
      {removeStage && <RemoveStage stageType={removeStage} />}
      <div>{children}</div>
      <div>{actions}</div>
    </div>
  </div>
)

export default Footer
