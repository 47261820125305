import { FC } from 'react'

import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'
import WebauthnAuthenticationPreview from 'components/templates/stages/WebAuthnAuthentication.Preview'

const WebAuthnAuthentication: FC = () => {
  return (
    <NoSettingsStage stageType="webauthn-authentication" preview={WebauthnAuthenticationPreview} />
  )
}

export default WebAuthnAuthentication
