import qs from 'qs'
import { FC } from 'react'

import { ReactComponent as MicrosoftIcon } from 'pages/auth/shared/Auth/icons/microsoft.svg'

type Props = {
  text: string
  state?: string
}

const MicrosoftButton: FC<Props> = ({ state, text }) => {
  const handleClick = () => {
    const query: Record<string, string> = {
      client_id: _env_.AZURE_OAUTH_ID || '',
      redirect_uri: `${window.location.origin}/api/app/oauth/azure/callback`,
      scope: 'openid email profile',
      response_mode: 'query',
      response_type: 'code',
    }

    if (state) {
      query.state = state
    }

    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${qs.stringify(
      query
    )}`
  }

  if (!_env_.AZURE_OAUTH_ID) {
    return null
  }

  return (
    <button onClick={handleClick}>
      <MicrosoftIcon />
      {text}
    </button>
  )
}

export default MicrosoftButton
