import { useCallback, useEffect, useState } from 'react'

import { useAPI } from 'hooks/useAPI'
import { listEvents } from 'thunks/api/users/events/list'
import { EventWithPipeline } from 'types/event'

const COUNT = 15

export function useListEvents(id: string) {
  const [run, { timer }] = useAPI(listEvents)
  const [events, setEvents] = useState<EventWithPipeline[]>()
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    run({ id, limit: COUNT }).then(res => {
      setEvents(res)
      setHasMore(res.length === COUNT)
    })
  }, [id, run])

  const prepend = useCallback((events: EventWithPipeline[]) => {
    setEvents(curr => [...events, ...(curr || [])])
  }, [])

  const loadMore = useCallback(async () => {
    if (timer.isLoading) return
    const before = events && events.length > 0 ? events[events.length - 1].id : undefined
    const newEvents = await run({ id, limit: COUNT, before })
    setHasMore(newEvents.length === COUNT)
    setEvents([...(events || []), ...newEvents])
  }, [id, timer, events, setEvents, run])

  return [events, { timer, prepend, loadMore, hasMore }] as const
}
