import { FC } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import routes from 'routes'
import { useIframeSelector } from 'store'

import { faArchive } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import ArchiveIFrameModal from 'components/templates/ArchiveIFrameModal'
import { openModal } from 'hooks/useModal'

import s from './index.module.scss'
import Steps from './Steps'
import TitleForm from './TitleForm'
import classNames from 'classnames'

const EditSidebar: FC = () => {
  const history = useHistory()

  const { iframe } = useIframeSelector()

  const r = routes.iframes.edit.configure

  const isCondensed = useRouteMatch([r.test()])
  const className = classNames(s.EditSidebar, {
    [s.CondensedSidebar]: isCondensed,
  })

  return (
    <>
      <div id="create-sidebar" className={className}>
        <div>
          <header>
            <h2 className="mb-4 std">{t('Edit an iFrame')}</h2>
            <p className="mb-6 text-gray-500 std-base w-[80%]">
              {t('You can always update the iFrame as needed to fit your use case.')}
            </p>
            <TitleForm />
          </header>
          <Steps iframeId={iframe.id}>
            <Steps.Step path={id => r.title(id)} suffix="title">
              {t('iFrame Title')}
            </Steps.Step>

            <Steps.Step path={id => r.languages(id)} suffix="languages">
              {t('Languages')}
            </Steps.Step>

            <Steps.Step
              path={id => r.enrollmentAndAuthentication(id)}
              suffix="enrollment-and-authentication"
            >
              {t('Enrollment & Authentication')}
            </Steps.Step>

            <Steps.Step path={id => r.pipelines(id)} suffix="pipelines">
              {t('Pipelines')}
            </Steps.Step>

            <Steps.Step path={id => r.deliveryMethods(id)} suffix="delivery-methods">
              {t('Delivery Method')}
            </Steps.Step>

            <Steps.Step path={id => r.test(id)} suffix="test">
              {t('Test the iFrame')}
            </Steps.Step>

            <Steps.Step path={id => r.deploymentInstructions(id)} suffix="deployment-instructions">
              {t('Deployment Instructions')}
            </Steps.Step>
          </Steps>
          <ul>
            <li className={s.Archive}>
              <span onClick={openModal('ArchiveIFrame')}>
                <FontAwesomeIcon icon={faArchive} />
                <span>{t('Archive')}</span>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <ArchiveIFrameModal
        iframeId={iframe.id}
        onArchive={() => history.push(routes.iframes.root)}
      />
    </>
  )
}

export { EditSidebar }

export default EditSidebar
