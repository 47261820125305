import { FC, useState } from 'react'

import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { closeModal, openModal } from 'hooks/useModal'
import DeleteUser from 'pages/shared/Users/Show/UserInfo/DeleteUser'
import UserInfoDetails from 'pages/shared/Users/Show/UserInfo/Details'
import UserInfoForm from 'pages/shared/Users/Show/UserInfo/Form'
import UploadPhotoModal from 'pages/shared/Users/Show/UserInfo/UploadPhotoModal'
import { User } from 'types/user'

import s from './index.module.scss'

type Props = {
  back: string
  user: User
  onChange: (user: User) => void
}

const UserInfo: FC<Props> = ({ back, user, onChange }) => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <>
      <div>
        <div className={s.UserInfo}>
          <div className={s.Info}>
            <div className="cursor-pointer" onClick={openModal('UploadPhoto')}>
              {user.photoUrl ? (
                <img src={user.photoUrl} alt="" />
              ) : (
                <FontAwesomeIcon className={s.UserIcon} icon={faUser} />
              )}
            </div>

            {isEditing ? (
              <UserInfoForm user={user} onSaved={onChange} onCancel={() => setIsEditing(false)} />
            ) : (
              <UserInfoDetails user={user} onEdit={() => setIsEditing(true)} />
            )}
          </div>
        </div>

        <DeleteUser back={back} user={user} />
      </div>

      <UploadPhotoModal
        key={user.photoUrl}
        userId={user.id}
        onUpload={photoUrl => {
          onChange({ ...user, photoUrl })
          closeModal()
        }}
      />
    </>
  )
}

export default UserInfo
