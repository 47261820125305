import { FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useIframeSelector, usePipelinesSelector } from 'store'

import {
  faChevronLeft, faMagnifyingGlass, faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { useAPI } from 'hooks/useAPI'
import useQueryString from 'hooks/useQueryString'
import ls from 'pages/IFrames/Steps/shared/LayoutWithoutFooter.module.scss'
import { createIFramePipeline } from 'thunks/api/iframes/pipelines/create'
import { listPipelines } from 'thunks/api/pipelines/list'

import s from './index.module.scss'

type Params = {
  categoryId?: string
}

const AddPipeline: FC = () => {
  const history = useHistory()

  const { pipelines, isLoaded } = usePipelinesSelector()
  const { iframe } = useIframeSelector()
  const { categoryId } = useQueryString<Params>()

  const [pipelineList] = useAPI(listPipelines)

  const handleGetPipelines = useCallback(async () => {
    try {
      await pipelineList({
        published: true,
      })
    } catch (err) {
      console.error('Unable to get pipelines:', err)
    }
  }, [pipelineList])

  const category = useMemo(() => {
    return iframe.categories.find(c => c.id === categoryId)
  }, [categoryId, iframe])

  const [search, setSearch] = useState('')
  const filtered = useMemo(() => {
    if (search === '') {
      return pipelines
    }
    const s = search.toLowerCase()
    return pipelines.filter(p => p.title.toLowerCase().indexOf(s) > -1)
  }, [search, pipelines])

  const [activePipelineIds, setActivePipelineIds] = useState<Record<string, true>>({})
  const [add] = useAPI(createIFramePipeline)
  const handleAdd = useCallback(
    async (pipelineId: string) => {
      setActivePipelineIds({
        ...activePipelineIds,
        [pipelineId]: true,
      })
      await add({
        id: iframe.id,
        type: 'categories',
        pipelineId: pipelineId,
        categoryId,
      })
    },
    [categoryId, iframe, activePipelineIds, add]
  )

  const addedPipelines = useMemo(() => {
    const categoryMap: Record<string, string> = {}
    iframe.categories.forEach(category => {
      category.pipelines.forEach(pipeline => {
        categoryMap[pipeline.id] = category.title
      })
    })
    return categoryMap
  }, [iframe])

  useEffect(() => {
    handleGetPipelines()
  }, [handleGetPipelines, pipelineList])

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (filtered.length === 0) return
        handleAdd(filtered[0].id)
      }
    },
    [filtered, handleAdd]
  )

  const phase = history.location.pathname.split('/')[3]
  const route = r.iframes[phase === 'create' ? 'create' : 'edit'].configure

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center">
        <FontAwesomeIcon
          icon={faSpinnerThird}
          className="mx-auto mt-32 text-theme"
          size="5x"
          spin
        />
      </div>
    )
  }

  return (
    <div className={ls.Wrapper}>
      <div className={ls.ContentWrapper}>
        <div className={s.AddPipeline}>
          <div>
            <button
              className={s.BackButton}
              onClick={() => history.push(route.pipelines(iframe.id))}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>

          <div>
            <header>
              {t('Add pipelines to {{title}}', { title: category?.title ?? t('your IFrame') })}
            </header>
            <div className={s.Search}>
              <input
                placeholder={t('Search for pipelines')}
                autoFocus
                onChange={e => setSearch(e.target.value)}
                onKeyUp={handleKeyPress}
              />
              <div>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </div>
            </div>

            <ul className={s.PipelinesList}>
              {filtered.map(pipeline => (
                <li key={pipeline.id}>
                  <div>
                    <label>{pipeline.title}</label>
                    {pipeline.id in addedPipelines ? (
                      <button disabled className="text-sm">
                        {t('in {{category}}', {
                          category: addedPipelines[pipeline.id] || t('uncategorized'),
                        })}
                      </button>
                    ) : (
                      <button
                        disabled={pipeline.id in activePipelineIds}
                        onClick={() => handleAdd(pipeline.id)}
                      >
                        {pipeline.id in activePipelineIds ? t('Adding...') : t('Add')}
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPipeline
