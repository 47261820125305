import { FC } from 'react'
import { useAccountsSelector } from 'store'

import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Loading from 'components/atoms/Loading'
import Header from 'components/molecules/AdminHeader'
import useListAccounts from 'hooks/api/useListAccounts'
import { openModal } from 'hooks/useModal'

import Accounts from './AccountList'
import CreateAccountModal from './CreateAccountModal'
import s from './index.module.scss'

const AccountList: FC = () => {
  useListAccounts()
  const accounts = useAccountsSelector()

  return (
    <ContentWrapper>
      <Header title={t('Accounts')}>
        <button className={s.CreateAccountBtn} onClick={openModal('CreateAccount')}>
          <FontAwesomeIcon icon={faPlus} />
          {t('Create new')}
        </button>
      </Header>
      <main className={s.Main}>{accounts ? <Accounts accounts={accounts} /> : <Loading />}</main>
      <CreateAccountModal />
    </ContentWrapper>
  )
}

export default AccountList
