import { AppState } from 'store'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const authenticationsLogout = createAsyncThunk('authentications/logout', async (_, api) => {
  localStorage.removeItem('token')
  localStorage.removeItem('invitationToken')

  const { auth } = api.getState() as AppState
  if (!auth.token) return

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${auth.token}`)

  fetch('/api/app/authentications', {
    headers,
    method: 'DELETE',
  })
})
