import asyncThunk from 'util/asyncThunk'

export type Request = {
  accountId: string
  ttl: number
  type: 'key' | 'refresh'
}

type Response = {
  token: string
}

export const createToken = asyncThunk<Request, Response>({
  name: 'api_tokens/create',
  uri: '/api-tokens',
  method: 'POST',
  orchestrator: false,
})
