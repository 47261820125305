import { Pipeline } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  redirectURIs: string[]
}

type Response = Pipeline

export const updateOAuth = asyncThunk<Request, Response>({
  name: 'pipelines/oauth',
  method: 'PUT',
  uri: ({ id }) => `/pipelines/${id}/oauth`,
  stripId: true,
})
