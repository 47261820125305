import { Pipeline as PipelineType } from 'types'

import { StageType } from '@journeyid/agent/types'
import { createSlice } from '@reduxjs/toolkit'

import { archivePipeline } from 'thunks/api/pipelines/archive'
import { listPipelines } from 'thunks/api/pipelines/list'
import { publishPipeline } from 'thunks/api/pipelines/publish'
import { updatePipeline } from 'thunks/api/pipelines/update'

type Pipeline = Pick<PipelineType, 'id' | 'key' | 'title' | 'internalTitle' | 'success'> & {
  stages: { type: StageType }[]
}

export type PipelinesState = {
  isLoaded: boolean
  pipelines: Pipeline[]
}

const initialState: PipelinesState = {
  isLoaded: false,
  pipelines: [],
}

const slice = createSlice({
  name: 'pipelines',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listPipelines.fulfilled, (state, { payload }) => {
      state.isLoaded = true
      state.pipelines = payload.sort((l, r) =>
        l.title.toLowerCase() > r.title.toLowerCase() ? 1 : -1
      )
    })
    builder.addCase(publishPipeline.fulfilled, (state, { payload }) => {
      const idx = state.pipelines.findIndex(p => p.id === payload.id)
      if (idx > -1) {
        state.pipelines[idx].key = payload.key
      } else {
        const { id, title, key, stages, success } = payload
        state.pipelines.push({ id, title, key, stages, success })
      }
    })
    builder.addCase(updatePipeline.fulfilled, (state, { payload }) => {
      const idx = state.pipelines.findIndex(p => p.id === payload.id)
      if (idx === -1) return
      state.pipelines[idx].title = payload.title
      state.pipelines[idx].internalTitle = payload.internalTitle
    })
    builder.addCase(archivePipeline.fulfilled, (state, { payload }) => {
      state.pipelines = state.pipelines.filter(p => p.id !== payload.id)
    })
  },
})

export default slice
export const actions = slice.actions
