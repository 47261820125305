import { FC, useEffect, useMemo, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { t } from 'translation'

import { EnrollmentType, User } from 'types/user'
import phoneNumber from 'util/phoneNumber'

import s from './index.module.scss'

export const enrollmentTitles = {
  'facial-biometrics': t('Facial Biometrics'),
  'face-biometrics': t('Cloud Facial Biometrics'),
  'active-voice-biometrics': t('Active Voice Biometrics'),
  'mobile-app': t('Mobile App'),
  'phone-number': t('Verified Phone Number'),
  'one-time-password': t('One-Time Password'),
  webauthn: t('Secure Device Registration'),
} as Record<EnrollmentType, string>

type Props = {
  user: User
  onEdit: () => void
}

const UserInfoDetails: FC<Props> = ({ user, onEdit }): JSX.Element => {
  const [copied, setCopied] = useState(false)
  const emailRef = useRef<HTMLParagraphElement>(null)
  const [isEmailOverflow, setIsEmailOverflow] = useState(false)

  const email = useMemo(() => (user.email ? user.email : t('Unknown')), [user.email])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  }, [copied])

  useEffect(() => {
    if (!emailRef) return
    const e = emailRef.current
    if (e) {
      setIsEmailOverflow(e.offsetWidth < e.scrollWidth)
    }
  }, [emailRef])

  const displayName = useMemo(() => {
    if (user.firstName && user.lastName) {
      return user.firstName + ' ' + user.lastName
    } else if (user.firstName) {
      return user.firstName
    } else if (user.lastName) {
      return user.lastName
    } else {
      return ''
    }
  }, [user.firstName, user.lastName])

  return (
    <div className={s.FormInfo}>
      <h2>
        {user.firstName || user.lastName ? (
          displayName
        ) : (
          <em className="text-gray-400">{t('no name available')}</em>
        )}
      </h2>

      <button className={s.ManageBtn} onClick={onEdit}>
        {t('Manage Profile')}
      </button>

      <section>
        <h3>{t('Email address')}</h3>
        <p
          ref={emailRef}
          data-tip
          data-for={'ctc'}
          onClick={() => {
            navigator.clipboard.writeText(email)
            setCopied(true)
          }}
        >
          {email}
        </p>
        {isEmailOverflow && (
          <ReactTooltip effect="solid" id="ctc" className="max-w-[300px]">
            {copied ? t('Copied ✓') : t('Click to copy')}
          </ReactTooltip>
        )}
      </section>

      <section>
        <h3>{t('Enrollments')}</h3>
        {user.enrollments && user.enrollments.length > 0 ? (
          <ul>
            {user.enrollments.map((enrollment, i) => (
              <li key={i} className={s.Enrollment}>
                {enrollmentTitles[enrollment.type]}
              </li>
            ))}
          </ul>
        ) : (
          <p>{t('No enrollments')}</p>
        )}
      </section>

      <section>
        <h3>{t('Unique ID')}</h3>
        <p title={user.uniqueId}>{user.uniqueId}</p>
      </section>

      <section>
        <div className="flex flex-row items-center justify-between">
          <h3>{t('Phone numbers')}</h3>
        </div>
        {user.phoneNumbers && user.phoneNumbers.length > 0 ? (
          <ul>
            {user.phoneNumbers?.map((number, i) => (
              <li key={i} className={s.PhoneNumber}>
                {phoneNumber(number)}
              </li>
            ))}
          </ul>
        ) : (
          <p>{t('No known numbers')}</p>
        )}
      </section>

      {user.type === 'agent' && (
        <section>
          <h3>{t('Agent groups')}</h3>
          {user.groups && user.groups.length > 0 ? (
            <ul>
              {user.groups.map((group, i) => (
                <li key={group.id} className={s.Group}>
                  {group.name}
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('Not in any groups')}</p>
          )}
        </section>
      )}

      <section>
        <h3>{t('Known locations')}</h3>
        {user.locations && user.locations.length > 0 ? (
          <ul>
            {user.locations.map((loc, i) => (
              <li key={i} className={s.Location}>
                {loc}
              </li>
            ))}
          </ul>
        ) : (
          <p>{t('No known locations')}</p>
        )}
      </section>

      <div className="-mt-2" />
    </div>
  )
}

export default UserInfoDetails
