import { useAPI } from 'hooks/useAPI'
import { Request, retrieveUser } from 'thunks/api/users/retrieve'
import { User, UserType } from 'types/user'

type RetrieveFn<T extends UserType> = (req: Request) => Promise<User<T>>

export function useRetrieveUser<T extends UserType>() {
  const [retrieve, { timer }] = useAPI(retrieveUser)

  return [retrieve as RetrieveFn<T>, { timer }] as const
}
