import { useEffect, useState } from 'react'
import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Button from 'components/atoms/Button'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import Header from 'components/molecules/AdminHeader'
import { useAPI } from 'hooks/useAPI'
import { openModal } from 'hooks/useModal'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'
import InviteAdminModal from 'pages/Settings/tabs/AdminManagement/_InviteAdminModal'
import { listAdmins } from 'thunks/api/admins/list'
import { listGroups } from 'thunks/api/groups/list'
import { Admin } from 'types/admin'

import List from './_List'
import s from './index.module.scss'

const AdminManagement = () => {
  const [list, { timer }] = useAPI(listAdmins)
  const [admins, setAdmins] = useState<Admin[]>()
  useEffect(() => {
    list({}).then(admins => setAdmins(admins))
  }, [list])

  const [groupsList] = useAPI(listGroups)
  const [groups, setGroups] = useState<any[]>()
  useEffect(() => {
    groupsList({}).then(groups => setGroups(groups))
  }, [groupsList])

  return (
    <>
      <ContentWrapper>
        <Header title={t('Settings')} />
        <div className={s.Content}>
          <Menu />
          <div>
            <p className="flex justify-between mt-4 mb-8 text-4xl font-semibold">
              {t('Admin Management')}
              <Button onClick={openModal('InviteAdmin')}>{t('Invite an admin')}</Button>
            </p>

            {timer.isLoading ? (
              <Loading />
            ) : (
              <>
                {admins && admins.length > 0 && (
                  <SettingsCard className="divide-y">
                    <List
                      admins={admins}
                      groups={groups || []}
                      onUpdate={admin =>
                        setAdmins(admins => admins?.map(a => (a.id === admin.id ? admin : a)))
                      }
                      onDelete={admin =>
                        setAdmins(admins => admins?.filter(a => a.id !== admin.id))
                      }
                    />
                  </SettingsCard>
                )}
              </>
            )}
          </div>
        </div>
        <Footer />
      </ContentWrapper>
      {groups && (
        <InviteAdminModal
          groups={groups}
          onCreate={user => setAdmins(admins => [...(admins || []), user])}
        />
      )}
    </>
  )
}

export default AdminManagement
