import { FC, useMemo } from 'react'

import { CreditCardPaymentConfiguration } from '@journeyid/agent/types'

import { t } from 'translation'

import s from './BillingSummary.module.scss'

type Props = Pick<CreditCardPaymentConfiguration, 'currency' | 'lineItems'>

const BillingSummary: FC<Props> = ({ currency, lineItems }) => {
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      }),
    [currency]
  )

  const total = useMemo(
    () => lineItems.reduce((memo, item) => memo + item.quantity * item.amount, 0),
    [lineItems]
  )

  return (
    <section className={s.BillingSummary}>
      <h4 className="std">{t('Billing Summary')}</h4>
      <ul>
        {lineItems.map((item, idx) => (
          <li key={idx}>
            <span>{item.quantity}x</span>
            <span>
              {item.title}
              {item.quantity > 1 && (
                <em>{t('{{amount}}/ea.', { amount: formatter.format(item.amount / 100) })}</em>
              )}
            </span>
            <span>{formatter.format((item.quantity * item.amount) / 100)}</span>
          </li>
        ))}
        <li>
          <span>{t('Total')}</span>
          <span>{formatter.format(total / 100)}</span>
        </li>
      </ul>
    </section>
  )
}

export default BillingSummary
