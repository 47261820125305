import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

type Response = {
  token: string
}

export const morphIntoAgent = asyncThunk<Request, Response>({
  name: 'agents/morph',
  method: 'POST',
  uri: ({ id }) => `/agents/${id}/morph`,
  stripId: true,
  orchestrator: false,
})
