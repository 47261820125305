import classNames from 'classnames'
import { FC } from 'react'
import { FieldError } from 'react-hook-form'

import { t } from 'translation'

import styles from './index.module.scss'

type Props = {
  error?: FieldError
}

const ErrorMessage: FC<Props> = ({ error }) => {
  if (!error) {
    return null
  }

  return (
    <div className={classNames('__ErrorMessage', styles.ErrorMessage)}>
      {t(error.message || '')}
    </div>
  )
}

export default ErrorMessage
