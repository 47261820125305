import { useState } from 'react'
import { useIframeSelector } from 'store'
import { t } from 'translation'

import { faCircle, faDotCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AuthenticationToken from 'pages/IFrames/Steps/DeploymentInstructions/AuthenticationToken'
import GlobalAuthToken from 'pages/IFrames/Steps/DeploymentInstructions/GlobalAuthToken'
import LoadTheIFrame from 'pages/IFrames/Steps/DeploymentInstructions/LoadTheIFrame'
import SendPostMessage from 'pages/IFrames/Steps/DeploymentInstructions/SendPostMessage'
import ls from 'pages/IFrames/Steps/shared/LayoutWithoutFooter.module.scss'

import s from './index.module.scss'

const DeploymentInstructions = () => {
  const { iframe } = useIframeSelector()
  const [agentInfo, setAgentInfo] = useState(false)
  const [refresh, setRefresh] = useState<'loaded-once' | 'every-call'>('every-call')
  const [token, setToken] = useState<string>()

  return (
    <div className={ls.Wrapper}>
      <div className={ls.ContentWrapper}>
        <div>
          <h1 className={s.Title}>{t('Deployment Instructions')}</h1>
          <p>
            {t('Follow the instructions below to integrate the iFrame into your agent desktop.')}
          </p>

          <h3 className={s.Subheading}>
            {t(`Do you want to display the agent's name to the customer?`)}
          </h3>
          <ul className={s.Options}>
            <li onClick={() => setAgentInfo(true)}>
              <div className="!border-0">
                <FontAwesomeIcon icon={agentInfo ? faDotCircle : faCircle} />
              </div>

              <p>{t("Yes, show the agent's first name and the initial of their last name.")}</p>
            </li>
            <li onClick={() => setAgentInfo(false)}>
              <div className="!border-0">
                <FontAwesomeIcon icon={!agentInfo ? faDotCircle : faCircle} />
              </div>

              <p>{t("No, don't show agent info to the customer.")}</p>
            </li>
          </ul>

          {/* <h3 className={s.Subheading}>{t('When will the iFrame be loaded?')}</h3>
          <ul className={s.Options}>
            <li onClick={() => setRefresh('loaded-once')}>
              <div className="!border-0">
                <FontAwesomeIcon icon={refresh === 'loaded-once' ? faDotCircle : faCircle} />
              </div>

              <p>
                {t('Once when the agent loads the web application.')}{' '}
                <span className="ml-1 text-sm italic">{t('recommended')}</span>
              </p>
            </li>
            <li onClick={() => setRefresh('every-call')}>
              <div className="!border-0">
                <FontAwesomeIcon icon={refresh === 'every-call' ? faDotCircle : faCircle} />
              </div>

              <p>{t('Every time a call is answered by an agent.')}</p>
            </li>
          </ul> */}

          {agentInfo ? (
            <>
              <h3 className={s.Subheading}>{t('Step 1: Create an authentication token')}</h3>
              <p>
                {t(
                  "Using the secret key below, sign a JWT in the backend with the agent's unique ID."
                )}
              </p>
              <AuthenticationToken iframeId={iframe.id} onToken={setToken} />
            </>
          ) : (
            <>
              <h3 className={s.Subheading}>{t('Step 1: Generate an authentication token')}</h3>
              <p>{t("Select how many days you'd like the token to be valid for.")}</p>
              <GlobalAuthToken iframeId={iframe.id} onToken={setToken} />
            </>
          )}

          <h3 className={s.Subheading}>{t('Step 2. Load the iFrame')}</h3>
          <LoadTheIFrame token={token} showAllFields={refresh === 'every-call'} />
          {refresh === 'loaded-once' && (
            <>
              <h3 className={s.Subheading}>
                {t('Step 3. Send a Post Message when a call is received')}
              </h3>
              <SendPostMessage />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default DeploymentInstructions
