import { AppState } from 'store'

import { ErrorResponse } from '@journeyid/agent/common/errors'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { Account } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export const accountsRetrieve = createAsyncThunk<
  Required<Account>,
  {},
  { rejectValue: ErrorResponse }
>('accounts/retrieve', async (_, api) => {
  const { auth } = api.getState() as AppState
  if (!auth.token) {
    throw new Error('No auth token')
  }

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${auth.token}`)

  const opts = {
    method: 'GET',
    headers: headers,
  }

  const response = await fetch(`/api/app/accounts/${auth.accountId}`, opts)
  return await response.json()
})

export type Request = { id: string }

export const retrieveAccount = asyncThunk<Request, Account>({
  name: 'accounts/retrieve',
  method: 'GET',
  uri: ({ id }) => `/accounts/${id}`,
  orchestrator: false,
  stripId: true,
  throttle: false,
})
