import { FC } from 'react'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import DataPrivacy from 'components/organisms/Preview/DataPrivacy'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

const SocialSecurityNumberPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <Header>{t('Enter your full Social Security Number')}</Header>
      <main className="px-4 pt-8">
        <div>
          <Input value="###-##-####" disabled className="w-full mb-4" />
        </div>
      </main>
      <footer>
        <DataPrivacy />
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Verify')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default SocialSecurityNumberPreview
