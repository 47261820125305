import classNames from 'classnames'
import { ReactElement } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import r from 'routes'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Header from 'components/molecules/AdminHeader'
import useEnsureIframe from 'hooks/useEnsureIframe'
import { useScrollToTop } from 'hooks/useScrollToTop'

import s from './index.module.scss'

interface Props {
  sidebar?: ReactElement
  children: ReactElement
}

const IframeLayout = ({ sidebar, children }: Props) => {
  const location = useLocation()

  const isCorrect = useEnsureIframe()
  const ref = useScrollToTop()

  const isNewIframe = isCorrect || location.pathname.match(r.iframes.create.configure.new)
  const isTestIframe = useRouteMatch([
    r.iframes.create.configure.test(),
    r.iframes.edit.configure.test(),
  ])

  const className = classNames(s.ContentWrapper, {
    [s.CondensedWrapper]: isTestIframe,
  })

  return (
    <div className={classNames('__Layout', s.Layout)}>
      <Header title="IFrames" backRoute={r.iframes.root} />
      {isNewIframe ? (
        <div className={className}>
          {sidebar}
          <main>
            <div ref={ref}>{children}</div>
          </main>
        </div>
      ) : (
        <div className={s.Loading}>
          <FontAwesomeIcon icon={faSpinnerThird} size="4x" spin />
        </div>
      )}
    </div>
  )
}

export default IframeLayout
