import { FC, useCallback } from 'react'
import { useIframeSelector } from 'store'

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Modal from 'components/organisms/Modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal, useModal } from 'hooks/useModal'
import { deleteIFrameCategory } from 'thunks/api/iframes/categories/delete'

const DeleteCategoryModal: FC = () => {
  const [isOpen, categoryId] = useModal('DeleteCategory')
  const { iframe } = useIframeSelector()

  const [del, { timer }] = useAPI(deleteIFrameCategory)
  const handleDelete = useCallback(async () => {
    await del({
      id: iframe.id,
      categoryId,
    })
    closeModal()
  }, [iframe.id, categoryId, del])

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>
        <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 mr-3 text-red-600" />
        {t('The category will be permanently deleted')}
      </Modal.Header>
      <Modal.Body>{t('All pipelines in this category will be removed as well.')}</Modal.Body>
      <Modal.Actions>
        <Button size="xl" variant="red-outline" disabled={timer.isLoading} onClick={handleDelete}>
          {timer.isLoading ? t('Deleting...') : t('Delete the category')}
        </Button>
        <Button size="xl" onClick={closeModal}>
          {t('Cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteCategoryModal
