import { format } from 'date-fns'
import { useCallback } from 'react'

import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Button from 'components/atoms/Button'
import SettingsCard from 'components/atoms/SettingsCard'
import Header from 'components/molecules/AdminHeader'
import { useAPI } from 'hooks/useAPI'
import Menu from 'pages/Settings/Menu'
import { exportCustomers } from 'thunks/api/customers/export'
import { EnrollmentType } from 'types/user'
import downloadCSV from 'util/download-csv'

import s from './DataExport.module.scss'

const enrollmentTitles: Record<EnrollmentType, string> = {
  'active-voice-biometrics': 'Voice Biometrics',
  'facial-biometrics': 'Facial Biometrics',
  'mobile-app': 'Mobile App w/ Journey SDK',
  'one-time-password': 'One-Time Password',
  'phone-number': 'Verified Phone Number',
  webauthn: 'Device Registration (FIDO2)',
}

const DataExport = () => {
  const [doExport, { timer }] = useAPI(exportCustomers)

  const handleExport = useCallback(async () => {
    const customers = await doExport({})

    const data = customers.map(customer => [
      customer.id,
      customer.uniqueId,
      customer.firstName || '',
      customer.lastName || '',
      customer.email || '',
      customer.phoneNumbers.join(', '),
      customer.enrollments
        .filter(e => e.type !== 'phone-number')
        .map(e => t(enrollmentTitles[e.type]))
        .join(', '),
    ])

    data.unshift([
      'ID',
      'Unique ID',
      'First Name',
      'Last Name',
      'Email address',
      'Phone numbers',
      'Enrollments',
    ])

    const date = format(new Date(), 'yyyy-MM-dd')
    downloadCSV(`customer-export.${date}.csv`, data)
  }, [doExport])

  return (
    <ContentWrapper>
      <Header title="Settings" />
      <div className={s.Content}>
        <Menu />
        <div>
          <p className="mt-4 mb-8 text-4xl font-semibold">{t('Data export')}</p>
          <SettingsCard>
            <Button isLoading={timer.isLoading} size="lg" onClick={handleExport}>
              {timer.isLoading ? t('Generating export...') : t('Export customer data')}
            </Button>
          </SettingsCard>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default DataExport
