import { FC } from 'react'
import { usePipelineSelector } from 'store'

import { faArrowRight, faEye } from '@fortawesome/pro-regular-svg-icons'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import PhoneMockup from 'components/atoms/PhoneMockup'
import Field from 'components/molecules/Field'
import Footer from 'components/molecules/Footer'
import PageHeader from 'components/molecules/PageHeader'
import Gmail from 'components/templates/delivery/Email/Gmail'
import useDelivery from 'components/templates/delivery/useDelivery'
import { openModal } from 'hooks/useModal'
import PreviewModal from 'pages/Pipelines/Create/components/PreviewModal'
import { deliveryDefaults } from 'util/deliveryDefaults'

import s from './index.module.scss'

const DeliveryEmail: FC = () => {
  const { pipeline } = usePipelineSelector()
  const { email, timer, handleChange, handleSave, goToNext } = useDelivery('email')

  return (
    <div id="delivery-email" className={s.Wrapper}>
      <div className={s.Content}>
        <PageHeader title={t('Delivery Method: Email')}>
          {t(
            'Most commonly used with internal employees and through chatbot interactions, email delivery for pipelines is a very customizable delivery method. You can customize nearly all the text in the email below and see a preview of what the email will look like in mobile Gmail.'
          )}
        </PageHeader>
        <div className="flex pb-8 pr-8">
          <div className="flex-1 pt-4 pr-4 space-y-4 pl-7">
            <Field
              label={t('Subject')}
              name="subject"
              value={email?.subject}
              placeholder={deliveryDefaults.email.subject}
              onChange={handleChange('subject')}
            />
            <Field
              label={t('Sender')}
              name="subject"
              value={email?.from}
              placeholder={deliveryDefaults.email.from}
              onChange={handleChange('from')}
            />
            <Field
              label={t('Headline Greeting')}
              name="subject"
              value={email?.greeting}
              placeholder={deliveryDefaults.email.greeting}
              onChange={handleChange('greeting')}
            />
            <Field
              as="textarea"
              label={t('Main message')}
              name="subject"
              value={email?.message}
              placeholder={deliveryDefaults.email.message}
              onChange={handleChange('message')}
              rows={5}
            />
            <Field
              label={t('Button text')}
              name="action"
              value={email?.action}
              placeholder={deliveryDefaults.email.action}
              onChange={handleChange('action')}
            />
            <Field
              as="textarea"
              label={t('Footer')}
              name="subject"
              rows={4}
              value={email?.footer}
              placeholder={deliveryDefaults.email.footer}
              onChange={handleChange('footer')}
            />
          </div>
          <div>
            <PhoneMockup>
              <Gmail
                subject={email?.subject || deliveryDefaults.email.subject}
                from={email?.from || deliveryDefaults.email.from}
                className={s.Email}
              >
                <header>{email?.greeting || deliveryDefaults.email.greeting}</header>
                <div className="pt-2 text-base whitespace-pre-wrap">
                  {email?.message || deliveryDefaults.email.message}
                </div>
                <div className={s.Button}>{email?.action || deliveryDefaults.email.action}</div>
                <div className="text-base whitespace-pre-wrap">
                  {email?.footer || deliveryDefaults.email.footer}
                </div>
              </Gmail>
            </PhoneMockup>
          </div>
        </div>
      </div>
      <Footer
        actions={
          <>
            <Button variant="secondary" icon={faEye} onClick={openModal('Preview')}>
              {t('Preview Pipeline')}
            </Button>
            <Button
              variant={timer.didSucceed && pipeline.key ? 'green' : 'primary'}
              isLoading={timer.isLoading}
              iconRight={timer.isLoading || pipeline.key ? undefined : faArrowRight}
              onClick={email ? handleSave : goToNext}
            >
              {timer.didSucceed && pipeline.key
                ? t('Saved!')
                : timer.isLoading
                ? t('Saving...')
                : pipeline.key
                ? t('Save')
                : !email
                ? t('Skip')
                : t('Save & Continue')}
            </Button>
          </>
        }
      />
      <PreviewModal />
    </div>
  )
}

export default DeliveryEmail
