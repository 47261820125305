import { useCallback } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { sendOIDCEmail } from 'thunks/api/accounts/send-oidc-email'

export function useSendOIDCEmail() {
  const [run, { timer }] = useAPI(sendOIDCEmail)
  const { accountId } = useAuthSelector()

  const send = useCallback(
    async (email: string) => {
      if (!accountId) return
      await run({ email, id: accountId })
    },
    [accountId, run]
  )

  return [send, { timer }] as const
}
