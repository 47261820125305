import { FC } from 'react'

import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'
import SocialSecurityNumberPreview from 'components/templates/stages/SocialSecurityNumber.Preview'

const SocialSecurityNumber: FC = () => {
  return (
    <NoSettingsStage stageType="social-security-number" preview={SocialSecurityNumberPreview} />
  )
}

export default SocialSecurityNumber
