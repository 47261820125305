import classNames from 'classnames'
import { FC, useCallback, useMemo, useState } from 'react'

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Field from 'components/molecules/Field'
import Modal from 'components/organisms/Modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal, useModal } from 'hooks/useModal'
import { archiveIFrame } from 'thunks/api/iframes/archive'

type Props = {
  iframeId: string
  onArchive: () => void
}

const ArchiveIFrameModal: FC<Props> = ({ iframeId, onArchive }) => {
  const [isOpen] = useModal('ArchiveIFrame')
  const [confirmation, setConfirmation] = useState('')

  const [archive, { timer }] = useAPI(archiveIFrame)
  const handleArchive = useCallback(async () => {
    await archive({
      id: iframeId,
    })
    onArchive()
    closeModal()
  }, [iframeId, onArchive, archive])

  const archiveText = useMemo(() => t('ARCHIVE'), [])

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>
        <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 mr-3 text-red-600" />
        {t('The iFrame will be disabled immediately.')}
      </Modal.Header>
      <Modal.Body>
        <Field
          label={t('Enter "{{text}}" to archive this iFrame', { text: archiveText })}
          name="confirmation"
          autoFocus
          autoComplete="off"
          onChange={e => setConfirmation(e.target.value)}
          className="my-8"
        />
      </Modal.Body>
      <Modal.Actions>
        <Button
          size="xl"
          disabled={confirmation !== archiveText}
          variant="red-outline"
          isLoading={timer.isLoading}
          onClick={confirmation === archiveText ? handleArchive : undefined}
          className={classNames(
            confirmation !== archiveText && 'pointer-events-none text-gray-400'
          )}
        >
          {timer.isLoading ? t('Archiving...') : t('Archive the iFrame')}
        </Button>
        <Button size="xl" variant="secondary" onClick={closeModal}>
          {t('Cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ArchiveIFrameModal
