import classNames from 'classnames'
import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import r from 'routes'

import {
  faArrowRight,
  faEye,
  faInfoCircle,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Footer from 'components/molecules/Footer'
import { closeModal } from 'hooks/useModal'
import DeleteStageModal from 'pages/Pipelines/Create/ConfigureStages/DeleteStageModal'
import useStages from 'pages/Pipelines/Create/ConfigureStages/useStages'
import { stageDescriptionMap } from 'util/stage-descriptions'
import { stageTitleMap } from 'util/stage-titles'

import s from './index.module.scss'
import groups from './stages'

const ConfigureStages: FC = () => {
  const [search, setSearch] = useState('')

  const { pipeline, toggleStage, removeStage, addTimer, removeTimer } = useStages()

  /**
   * Select first when enter is pressed
   */
  const handleKeyPress = useCallback(
    async (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i]
        for (let j = 0; j < group.stageTypes.length; j++) {
          const stageType = group.stageTypes[j]
          if (!search || stageTitleMap[stageType].toLowerCase().includes(search.toLowerCase())) {
            await toggleStage(stageType)
            setSearch('')
            return
          }
        }
      }
    },
    [search, toggleStage]
  )

  const history = useHistory()
  const handleSaveEvent = () => {
    history.push(
      r.pipelines.create.configure.root(pipeline.id) +
        '/' +
        (pipeline.stages.length > 0 ? pipeline.stages[0].type : 'success-screen')
    )
  }
  const handlePreviewEvent = () => {
    history.push(
      r.pipelines.create.configure.root(pipeline.id) +
        '/' +
        (pipeline.stages.length > 0
          ? pipeline.stages[0].type + '#Preview:' + pipeline.stages[0].type
          : 'success-screen#Preview:success')
    )
  }
  useEffect(() => {
    ReactTooltip.rebuild()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <div id="configure-stages" className={s.Wrapper}>
      <div className={s.Content}>
        <div className={s.ConfigureStages}>
          <header>
            <h5>{t('Build your Pipeline')}</h5>
            <h3 className="std">{t('Add actions to your pipeline')}</h3>
            <fieldset>
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyUp={handleKeyPress}
                placeholder={t('Search for actions')}
                autoFocus
              />
              <aside>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </aside>
            </fieldset>
          </header>

          <main className={classNames(search !== '' && 'pt-6')}>
            {groups.map(group => (
              <section key={group.label}>
                {search === '' && <h4 className="std">{group.label}</h4>}
                <ul>
                  {group.stageTypes.map(
                    stageType =>
                      (search === '' ||
                        stageTitleMap[stageType].toLowerCase().includes(search.toLowerCase())) && (
                        <li key={stageType} data-stage-type={stageType}>
                          <div>
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              data-tip={stageDescriptionMap[stageType]}
                            />
                            <h6>{stageTitleMap[stageType]}</h6>
                          </div>
                          <button
                            data-added={pipeline.stages.some(s => s.type === stageType)}
                            onClick={() => toggleStage(stageType)}
                          >
                            {addTimer.isLoading && addTimer.id === stageType
                              ? t('Adding...')
                              : pipeline.stages.some(s => s.type === stageType)
                              ? t('Remove')
                              : t('Add')}
                          </button>
                        </li>
                      )
                  )}
                </ul>
              </section>
            ))}
          </main>
        </div>
        <ReactTooltip effect="solid" className="max-w-[300px]" />
        <DeleteStageModal timer={removeTimer} onDelete={removeStage} onCancel={closeModal} />
      </div>
      <Footer
        actions={
          <>
            <Button variant="secondary" icon={faEye} onClick={handlePreviewEvent}>
              {t('Preview Pipeline')}
            </Button>
            <Button variant="primary" icon={faArrowRight} onClick={handleSaveEvent}>
              {t('Finished adding')}
            </Button>
          </>
        }
      />
    </div>
  )
}

export default ConfigureStages
