import React, { FC } from 'react'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import s from './index.module.scss'
import { ReactComponent as Shield } from './shield.svg'

const DataPrivacy: FC = () => {
  return (
    <div className={s.DataPrivacy}>
      <div>
        <Shield />
      </div>
      <div>
        {t(
          `Your data will be fully encrypted and never shared with 3rd parties. It will only be used to verify your identity.`
        )}{' '}
        <span>{t('Learn more')}</span>
      </div>
      <div>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  )
}

export default DataPrivacy
