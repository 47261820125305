import { FC } from 'react'

import { t } from 'translation'

import DataPrivacy from 'components/organisms/Preview/DataPrivacy'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

import { ReactComponent as DriversLicenseFront } from './assets/drivers-license-front.svg'

const NationalIDCardPreview: FC = () => {
  return (
    <>
      <LogoHeader />

      <main>
        <Header headline={t('National ID Card verification')}>
          {t('Scan the front of your National ID Card')}
        </Header>

        <div className="flex items-center justify-center px-10 py-8 my-8 bg-gray-100">
          <DriversLicenseFront className="h-32" />
        </div>
      </main>

      <footer>
        <DataPrivacy />
      </footer>
    </>
  )
}

export default NationalIDCardPreview
