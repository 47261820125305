import asyncThunk from 'util/asyncThunk'

type Request = {
  name: string
}

type Response = {
  id: string
  name: string
}

export const accountsCreate = asyncThunk<Request, Response>({
  name: 'accounts/create',
  method: 'POST',
  uri: '../../accounts',
})
