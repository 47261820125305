import { FC } from 'react'

import { CodeResponse, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'

import { ReactComponent as GoogleIcon } from 'pages/auth/shared/Auth/icons/google.svg'

type Props = {
  text: string
  state?: string
}

const TheButton: FC<Props> = ({ state, text }) => {
  async function handleGoogleResponse(resp: CodeResponse) {
    let uri = `/api/app/oauth/google/callback?code=${resp.code}`
    if (state) {
      uri += `&state=${state}`
    }
    window.location.href = uri
  }

  const login = useGoogleLogin({
    onSuccess: handleGoogleResponse,
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    enable_serial_consent: false,
    overrideScope: true,
  })

  return (
    <>
      <button onClick={login}>
        <GoogleIcon />
        {text}
      </button>
    </>
  )
}

const GoogleButton: FC<Props> = props => (
  <GoogleOAuthProvider clientId={_env_.GOOGLE_OAUTH_ID || ''}>
    <TheButton {...props} />
  </GoogleOAuthProvider>
)

export default GoogleButton
