import classNames from 'classnames'
import { parseISO } from 'date-fns'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ListView from 'components/molecules/ListView'
import { Event, EventStatus, eventTitleMap } from 'types/event'
import { relativeDatePrecise } from 'util/relativeDate'

import s from './ActivityFeed.module.scss'

const statuses: Record<EventStatus, string> = {
  success: t('Success'),
  info: t('Info'),
  warning: t('Warning'),
  error: t('Error'),
  alert: t('Alert'),
}

type Props = {
  events: Event[]
  hasMoreEvents: boolean
  isLoading: boolean
  onLoadMore: () => Promise<void>
}

const ActivityFeed: FC<Props> = ({ events, hasMoreEvents, isLoading, onLoadMore }) => {
  const history = useHistory()

  const mapEvent = useCallback(
    (event: Event, i: number) => (
      <div
        key={i}
        data-id={event.id}
        className={s.Item}
        data-url={r[event.user.type === 'agent' ? 'agents' : 'customers'].show(
          event.user.id,
          event.id
        )}
        onClick={() =>
          history.push(
            r[event.user.type === 'agent' ? 'agents' : 'customers'].show(event.user.id, event.id)
          )
        }
      >
        <div>
          {event.user.photoUrl ? (
            <img src={event.user.photoUrl} alt="Profile img" />
          ) : (
            <FontAwesomeIcon className={s.UserIcon} icon={faUser} />
          )}
          <p className="text-lg">
            {event.user.firstName && event.user.lastName
              ? `${event.user.firstName} ${event.user.lastName}`
              : event.user.uniqueId}
          </p>
        </div>

        <div>
          <p className="text-sm text-gray-500">
            {event.execution?.pipeline.title || eventTitleMap[event.type]}
          </p>
        </div>

        <div className={s.RightSide}>
          <p className={s.Date}>{relativeDatePrecise(parseISO(event.createdAt))}</p>

          <div className={s.StatusWrapper}>
            <label
              className={classNames(s.Status, {
                'text-green-800 bg-green-100': event.status === 'success',
                'text-yellow-800 bg-yellow-100': event.status === 'warning',
                'text-red-800 bg-red-100': event.status === 'error',
                'text-white bg-red-600': event.status === 'alert',
                'text-blue-800 bg-blue-100': event.status === 'info',
              })}
            >
              {statuses[event.status]}
            </label>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    ),
    [history]
  )

  return (
    <ListView
      elements={events}
      emptyText={t('No recent activity')}
      hasMore={hasMoreEvents}
      isLoading={isLoading}
      loadMore={onLoadMore}
      title={t('Recent Activity')}
    >
      {mapEvent}
    </ListView>
  )
}

export default ActivityFeed
