import classNames from 'classnames'
import React, { FC, ReactNode, useState } from 'react'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { ActionTimer } from 'hooks/useActionTimer'

type Props = {
  loadingText: string
  timer: ActionTimer
  color?: string
  className?: string
  children: ReactNode
  onConfirm: () => void
}

const ConfirmButton: FC<Props> = ({
  loadingText,
  timer,
  color,
  className,
  children,
  onConfirm,
}) => {
  const [confirm, setConfirm] = useState(false)

  if (timer.isLoading || timer.didSucceed) {
    return (
      <span className="inline-flex items-center space-x-2 text-sm">
        <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin" />
        <span>{loadingText}</span>
      </span>
    )
  }
  if (!confirm) {
    return (
      <span
        onClick={() => setConfirm(true)}
        className={classNames('inline-flex items-center text-sm cursor-pointer', color, className)}
      >
        {children}
      </span>
    )
  }

  return (
    <span className="inline-flex items-center space-x-3">
      <span
        onClick={onConfirm}
        className={classNames('text-sm cursor-pointer', color ?? 'text-theme')}
      >
        {t('confirm')}
      </span>
      <span onClick={() => setConfirm(false)} className="text-sm cursor-pointer">
        {t('cancel')}
      </span>
    </span>
  )
}

export default ConfirmButton
