import asyncThunk from 'util/asyncThunk'
import { HTTPRetrievedMultiple } from 'util/statuses'

export type Request = {
  accountId: string
}

type Response = Gateway[]

export type Gateway = {
  id: string
  codename: string
  nickname: string
}

export const listGateways = asyncThunk<Request, Response>({
  name: 'gateways/list',
  uri: '/gateways',
  successStatus: HTTPRetrievedMultiple,
  orchestrator: false,
})
