import { FieldName } from 'react-hook-form'

export type ErrorResponse<T extends Record<string, any> = Record<string, any>> = {
  statusCode: number
} & APIErrorResponse<T>

export type APIErrorResponse<T extends Record<string, any> = Record<string, any>> = {
  error: string
  errors?: { [P in keyof T]: string }
  code?: string
}

export function setServerErrors<T extends Record<string, any>>(
  errors: Partial<Record<FieldName<T>, string>>,
  setError: (fieldName: FieldName<T>, error: { type: string; message: string }) => void
) {
  var key: FieldName<T>
  for (key in errors) {
    setError(key, {
      type: 'server',
      message: errors[key]!,
    })
  }
}
