import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  format,
  startOfMonth,
  startOfToday,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns'
import { FC, useState } from 'react'

import Button from 'components/atoms/Button'
import Radio from 'components/atoms/Radio'
import Modal from 'components/organisms/Modal'
import { closeModal, useModal } from 'hooks/useModal'
import objectKeys from 'util/object-keys'

import useDownloadAgentReport from './useDownloadAgentReport'

type Timeframe = 'today' | 'this-week' | 'previous-week' | 'this-month' | 'previous-month'

type Info = {
  title: string
  start: string
  end: string
}

const timeframes: Record<Timeframe, Info> = {
  today: {
    title: 'Today',
    start: format(startOfToday(), 'yyyy-MM-dd'),
    end: format(endOfToday(), 'yyyy-MM-dd'),
  },
  'this-week': {
    title: 'This week',
    start: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
    end: format(endOfWeek(new Date()), 'yyyy-MM-dd'),
  },
  'previous-week': {
    title: 'Last week',
    start: format(startOfWeek(subWeeks(new Date(), 1)), 'yyyy-MM-dd'),
    end: format(endOfWeek(subWeeks(new Date(), 1)), 'yyyy-MM-dd'),
  },
  'this-month': {
    title: 'This month',
    start: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    end: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  },
  'previous-month': {
    title: 'Last month',
    start: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
    end: format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
  },
}

type Props = {}

const AgentReportModal: FC<Props> = () => {
  const [isOpen] = useModal('AgentReport')
  const [timeframe, setTimeframe] = useState<Timeframe>('today')

  const [download, timer] = useDownloadAgentReport()

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Body>
        <div className="space-y-2">
          {objectKeys(timeframes).map(val => (
            <Radio key={val} checked={timeframe === val} onSelect={() => setTimeframe(val)}>
              {timeframes[val].title}
            </Radio>
          ))}
        </div>
      </Modal.Body>
      <Modal.Actions>
        <Button
          size="xl"
          isLoading={timer.isLoading}
          disabled={timer.isLoading}
          onClick={() => download(timeframes[timeframe].start, timeframes[timeframe].end)}
        >
          {timer.isLoading ? 'Generating report...' : 'Download report'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AgentReportModal
