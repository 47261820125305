import { FC } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'

import AgentDesktops from 'pages/Settings/tabs/AgentDesktops'
import DataExport from 'pages/Settings/tabs/DataExport'
import PaymentGateways from 'pages/Settings/tabs/PaymentGateways'
import Zoom from 'pages/Settings/tabs/Zoom'

import AdminManagement from './tabs/AdminManagement'
import Advanced from './tabs/Advanced'
import AgentAuth from './tabs/AgentAuth'
import CustomerRegistration from './tabs/CustomerRegistration'
import Developer from './tabs/Developer'
import General from './tabs/General'
import Groups from './tabs/Groups'
import Pricing from './tabs/Pricing'
import SSO from './tabs/SSO'

const Settings: FC = () => {
  const user = useAuthSelector()

  const history = useHistory()
  if (user.role !== 'owner') {
    history.push(r.root)
    return null
  }
  return (
    <Switch>
      <Route path={r.settings.general} exact component={General} />
      <Route path={r.settings.agentAuthentication} exact component={AgentAuth} />
      <Route path={r.settings.agentDesktops} exact component={AgentDesktops} />
      <Route path={r.settings.groups} exact component={Groups} />
      <Route path={r.settings.adminManagement} exact component={AdminManagement} />
      <Route path={r.settings.sso} exact component={SSO} />
      <Route path={r.settings.customerRegistration} exact component={CustomerRegistration} />
      <Route path={r.settings.developer} exact component={Developer} />
      <Route path={r.settings.advanced} exact component={Advanced} />
      <Route path={r.settings.pricing()} exact component={Pricing} />
      <Route path={r.settings.dataExport} exact component={DataExport} />
      <Route path={r.settings.paymentGateways} exact component={PaymentGateways} />
      <Route path={r.settings.zoom} exact component={Zoom} />
      <Redirect to={r.settings.general} />
    </Switch>
  )
}

export default Settings
