import { FC } from 'react'

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Modal from 'components/organisms/Modal'
import { ActionTimer } from 'hooks/useActionTimer'
import { closeModal, useModal } from 'hooks/useModal'

type Props = {
  timer: ActionTimer
  onRevoke: () => void
  onCancel: () => void
}

const RevokeClientSecretModal: FC<Props> = ({ timer, onRevoke, onCancel }) => {
  const [isOpen] = useModal('RevokeClientSecret')

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>
        <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 mr-3 text-red-600" />
        {t('The client secret will be permanently revoked')}
      </Modal.Header>
      <Modal.Body>{t('Are you sure you want to do this?')}</Modal.Body>
      <Modal.Actions className="flex justify-end">
        <Button
          size="xl"
          isLoading={timer.isLoading}
          disabled={timer.isLoading}
          variant="red-outline"
          onClick={onRevoke}
        >
          {timer.isLoading ? t('Revoking...') : t('Revoke')}
        </Button>
        <Button size="xl" onClick={onCancel}>
          {t('Cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RevokeClientSecretModal
