import classNames from 'classnames'
import React, { ChangeEvent, forwardRef } from 'react'

import styles from '../Input/index.module.scss'

type Props = {
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
} & Omit<React.HTMLProps<HTMLTextAreaElement>, 'onChange'>

const Textarea = forwardRef<HTMLTextAreaElement, Props>(({ className, ...rest }, ref) => {
  return (
    <textarea ref={ref} {...rest} className={classNames('__Textarea', styles.Input, className)} />
  )
})

export default Textarea
