const codeTemplate = (
  pipelineKey: string,
  deliveryMethod: string,
  phoneNumber: string,
  email: string,
  customerId: string,
  sessionRef: string,
  callbackUrl: string,
  language: string
) => {
  const formattedDeliveryMethod = () => {
    if (deliveryMethod === 'sms') {
      return `"phoneNumber": <span>"+${phoneNumber}"</span>`
    } else if (deliveryMethod === 'email') {
      return `"email": <span>"${email}"</span>`
    }
  }

  return `curl --request POST \\
  --url ${window.location.origin}/api/system/executions \\
  --header 'Accept: application/json' \\
  --header 'Authorization: Bearer <span>[Auth Token]</span>' \\
  --header 'Content-Type: application/json' \\
  --data '
  {
    "pipelineKey": <span>"${pipelineKey}"</span>,
    "delivery": {
      "method": <span>"${deliveryMethod}"</span>,
      ${formattedDeliveryMethod()}
    },
    "customer": {
      "uniqueId": <span>"${customerId}"</span>
    },
    "session": {
      "externalRef": <span>"${sessionRef}"</span>
    },
    "callbackUrls": [<span>"${callbackUrl}"</span>],
    "language": <span>"${language}"</span>
  }
'`
}

export default codeTemplate
