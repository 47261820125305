import { PaymentConfiguration } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = PaymentConfiguration & {
  id: string
}

export const updatePaymentConfiguration = asyncThunk<Request, PaymentConfiguration>({
  name: 'account/payment-configuration',
  uri: ({ id }) => `/accounts/${id}/payment-configuration`,
  method: 'PUT',
  orchestrator: false,
  stripId: true,
})
