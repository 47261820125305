import cogoToast from 'cogo-toast'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import SettingsCard from 'components/atoms/SettingsCard'
import Toggle from 'components/atoms/Toggle'
import Header from 'components/molecules/AdminHeader'
import { useAccount } from 'hooks/api/useAccount'
import { useAPI } from 'hooks/useAPI'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'
import {
  accountsAdvancedSettings,
  FacialBiometricProvider,
} from 'thunks/api/accounts/advanced-settings'

import s from './Developer/index.module.scss'

type FormData = {
  facialBiometricProvider: FacialBiometricProvider
  enableAniValidation: boolean
}

const Advanced = () => {
  const { accountId } = useAuthSelector()
  const { control, handleSubmit, setValue } = useForm<FormData>()

  const [account] = useAccount()

  useEffect(() => {
    if (!account?.configuration) return

    setValue('facialBiometricProvider', account.configuration.facialBiometricProvider)
    setValue('enableAniValidation', account.configuration?.enableAniValidation)
  }, [account, setValue])

  const [update, { timer }] = useAPI(accountsAdvancedSettings)
  const onSubmit = useCallback(
    async (data: FormData) => {
      if (!accountId) return

      await update({
        id: accountId,
        ...data,
      })

      cogoToast.success(t('Settings were updated successfully'))
    },
    [accountId, update]
  )

  return (
    <form className="h-full" onSubmit={handleSubmit(onSubmit)}>
      <ContentWrapper withFooter>
        <Header title="Settings" />
        <div className={s.Content}>
          <Menu />
          <div>
            <p className="mt-4 mb-8 text-4xl font-semibold">{t('Advanced Settings')}</p>
            {/* <SettingsCard>
              <h3>{t('Facial Biometric Provider')}</h3>
              <div className={s.TimeInputs}>
                <select
                  ref={register}
                  name="facialBiometricProvider"
                  className={classNames('w-[200px]', s.TimeInput)}
                >
                  <option value="" />
                  <option value="paravision">Paravision</option>
                  <option value="innovatrics">Innovatrics</option>
                </select>
              </div>
            </SettingsCard> */}

            <SettingsCard title={t('Agent iFrames')}>
              <Controller
                name="enableAniValidation"
                control={control}
                render={({ value, onChange }) => (
                  <>
                    <Toggle className={s.Toggle} enabled={value === true} onChange={onChange}>
                      {value === true
                        ? 'When iFrames sessions are started, a lookup will be performed on the incoming ANI.'
                        : 'An ANI lookup will not be performed when an iFrame session is started.'}
                    </Toggle>
                  </>
                )}
              />
            </SettingsCard>
          </div>
        </div>
        <Footer timer={timer} />
      </ContentWrapper>
    </form>
  )
}

export default Advanced
