import {
  faBadgeCheck, faCreditCard, faDriversLicense, faFileAlt, faIdBadge, faMicrophone, faMobile,
  faSignature, faUserPlus, IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { StageType } from '@journeyid/agent/types'

export function getPipelineIcon(stages: { type: StageType }[]): IconDefinition {
  const types = stages.map(s => s.type)

  if (types.includes('credit-card-payment')) return faCreditCard
  if (types.includes('facial-enrollment')) return faUserPlus
  if (types.includes('facial-authentication') || types.includes('facial-authentication-3d'))
    return faIdBadge
  if (types.includes('drivers-license') || types.includes('social-security-number'))
    return faDriversLicense
  if (types.includes('totp-registration') || types.includes('webauthn-registration'))
    return faUserPlus
  if (types.includes('document-signature')) return faSignature
  if (
    types.includes('phone-number-registration') ||
    types.includes('phone-number-verification') ||
    types.includes('device-authentication')
  )
    return faMobile
  if (types.includes('active-voice-enrollment') || types.includes('active-voice-authentication'))
    return faMicrophone
  if (types.includes('form')) return faFileAlt
  return faBadgeCheck
}
