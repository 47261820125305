import { useCallback } from 'react'
import { DeliveryMethod } from 'types'

import { Language } from '@journeyid/agent/common/types/language'
import { Configuration } from '@journeyid/agent/executions/create'

import { useAPI } from 'hooks/useAPI'
import { createExecution, Request } from 'thunks/api/executions/create'
import { User } from 'types/user'

function delivery(user: User, deliveryMethod: DeliveryMethod): Request['delivery'] {
  switch (deliveryMethod) {
    case 'sms':
      const phoneNumber = user.phoneNumbers ? user.phoneNumbers[0] : ''
      return { method: 'sms', phoneNumber }
    case 'email':
      const email = user.email
      return { method: 'email', email }
    case 'push-notification':
      const deviceId = user.devices ? user.devices[0].id : ''
      return { method: 'push-notification', deviceId }
    case 'url':
    default:
      return { method: 'url' }
  }
}

type Input = {
  pipelineId: string
  user: User
  deliveryMethod: DeliveryMethod
  ttl: number
  language: Language
  configuration?: Configuration
}

export function useInitiatePipeline() {
  const [execute, { timer }] = useAPI(createExecution)

  const initiate = useCallback(
    async (inp: Input) => {
      const { user, deliveryMethod, configuration, ...rest } = inp

      return await execute({
        ...rest,
        userId: user.id,
        delivery: delivery(user, deliveryMethod),
        configuration: configuration ?? {},
      })
    },
    [execute]
  )

  return [initiate, { timer }] as const
}
