import cogoToast from 'cogo-toast'
import { FC, useCallback, useState } from 'react'
import { t } from 'translation'

import { faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import ConfirmModal from 'components/organisms/ConfirmModal'
import { closeModal, openModal } from 'hooks/useModal'
import { Group } from 'types/group'

import useDelete from './_useDelete'

type Props = {
  groups: Group[]
  onDelete: (group: Group) => void
}

const List: FC<Props> = ({ groups, onDelete }) => {
  const [group, setGroup] = useState<Group>()

  const [archive, { timer }] = useDelete()
  const handleArchive = useCallback(async () => {
    if (!group) return
    try {
      await archive(group)
      onDelete(group)
      closeModal()
    } catch (err) {
      if (isAPIErrorResponse(err)) {
        cogoToast.error(err.error)
      } else {
        cogoToast.error('unable to remove the group')
      }
    }
  }, [group, onDelete, archive])

  return (
    <>
      <ul className="-my-4 divide-y">
        {groups.map((group, i) => (
          <li key={i} className="py-3">
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <p className="text-base font-bold">{group.name}</p>
              </div>
              <div className="pr-8 text-sm text-right text-gray-600">
                {group.userCount || 0} {group.userCount !== 1 ? t('agents') : t('agent')}
              </div>
              <div className="flex items-center justify-center w-8">
                <button
                  onClick={() => {
                    setGroup(group)
                    openModal('Confirm')()
                  }}
                >
                  <FontAwesomeIcon
                    className="text-gray-400 hover:text-gray-700 active:text-gray-900"
                    icon={faTrashCan}
                  />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {group && (
        <ConfirmModal
          modalId="Confirm"
          suffix={group.id}
          type="delete"
          title={t('Remove the group?')}
          text={t('Are you sure you want to remove {{name}}?', { name: group.name })}
          isLoading={timer.isLoading}
          onSubmit={handleArchive}
        />
      )}
    </>
  )
}

export default List
