import { FC } from 'react'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import PhoneNumberInput from 'components/atoms/PhoneNumberInput'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

const PhoneNumberRegistrationPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <Header headline={t('Phone number registration')}>
        {t('Use the flag to select your country code, then enter your telephone number')}
      </Header>
      <main className="px-4 space-y-4">
        <div className="pt-8">
          <PhoneNumberInput name="phoneNumber" disabled readOnly />
        </div>
        <p>{t('You will receive an SMS for verification. Message and data rates may apply.')}</p>
      </main>
      <footer>
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Send code')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default PhoneNumberRegistrationPreview
