import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { t } from 'translation'
import * as yup from 'yup'

import Button from 'components/atoms/Button'
import Checkbox from 'components/atoms/Checkbox'
import Input from 'components/atoms/Input'
import Loading from 'components/atoms/Loading'
import PhoneNumberInputConnected from 'components/atoms/PhoneNumberInputConnected'
import Field from 'components/molecules/Field'
import { useUpdateUser, ValidationErrorCallback } from 'hooks/api/useUpdateUser'
import { useAPI } from 'hooks/useAPI'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { enrollmentTitles } from 'pages/shared/Users/Show/UserInfo/Details'
import { listGroups } from 'thunks/api/groups/list'
import { Group } from 'types/group'
import { User } from 'types/user'
import phoneNumber from 'util/phoneNumber'

import s from './index.module.scss'

type FormData = {
  firstName?: string
  lastName?: string
  email?: string
  uniqueId: string
  phoneNumber?: string
  groupIds: string[]
}

const schema = yup.object().shape({
  firstName: yup.string().notRequired(),
  lastName: yup.string().notRequired(),
  email: yup.string().email().notRequired(),
  uniqueId: yup
    .string()
    .required(t('A Unique ID is required'))
    .min(4, t('Unique ID must be at least 4 characters')),
  phoneNumber: yup.string().notRequired(),
})

type Props = {
  user: User
  onSaved: (user: User) => void
  onCancel: () => void
}

const UserInfoForm: FC<Props> = ({ user, onSaved, onCancel }): JSX.Element => {
  const resolver = useYupValidationResolver(schema)
  const form = useForm<FormData>({
    resolver,
    shouldUnregister: false,
    defaultValues: {
      groupIds: [],
    },
  })
  const { errors, clearErrors, handleSubmit, register, setError, setValue } = form

  const [addPhone, setAddPhone] = useState<boolean>(false)
  const [phoneNumbers, setPhoneNumbers] = useState<User['phoneNumbers']>()
  const [locations, setLocations] = useState<User['locations']>()
  const [enrollments, setEnrollments] = useState<User['enrollments']>()
  const [devices, setDevices] = useState<User['devices']>()

  const [list] = useAPI(listGroups)
  const [groups, setGroups] = useState<Group[]>()
  useEffect(() => {
    if (user.type !== 'agent') return
    list({}).then(setGroups)
  }, [user.type, list])

  useEffect(() => {
    clearErrors()
    setValue('firstName', user.firstName)
    setValue('lastName', user.lastName)
    setValue('email', user.email)
    setValue('uniqueId', user.uniqueId)
    setValue('phoneNumber', '')
    setPhoneNumbers(user.phoneNumbers)
    setLocations(user.locations)
    setEnrollments(user.enrollments)
    setDevices(user.devices)
    if (user.type === 'agent') {
      setValue(
        'groupIds',
        user.groups!.map(g => g.id)
      )
    }
  }, [user, setValue, setLocations, setEnrollments, clearErrors, setDevices])

  const removePhoneNumber = useCallback(
    (idx: number) => {
      setPhoneNumbers(phoneNumbers?.filter((_, i) => i !== idx))
    },
    [phoneNumbers, setPhoneNumbers]
  )

  const removeLocation = useCallback(
    (idx: number) => {
      setLocations(locations?.filter((_, i) => i !== idx))
    },
    [locations]
  )

  const [update, { timer }] = useUpdateUser(setError as ValidationErrorCallback)

  const onSave = useCallback(
    async (data: FormData) => {
      const u = await update({
        ...data,
        id: user.id,
        email: data.email || '',
        enrollments,
        devices,
        locations,
        phoneNumbers: data.phoneNumber
          ? [`+${data.phoneNumber}`, ...(phoneNumbers || [])]
          : phoneNumbers,
      })
      if (timer.didSucceed && u) {
        onSaved(u)
        setTimeout(onCancel, 500)
      }
    },
    [
      user.id,
      locations,
      enrollments,
      devices,
      phoneNumbers,
      onSaved,
      onCancel,
      update,
      timer.didSucceed,
    ]
  )

  if (user.type === 'agent' && !groups) return <Loading size="2x" className="mt-2 mb-4" />

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSave)}>
        <div className={s.FormInfo}>
          <section>
            <h3>{t('Name')}</h3>
            <div className="flex w-full mb-2 space-x-4">
              <Input
                ref={register}
                name="firstName"
                defaultValue={user.firstName}
                placeholder={t('First name')}
                className="flex-1 w-full"
              />
              <Input
                ref={register}
                name="lastName"
                defaultValue={user.lastName}
                placeholder={t('Last name')}
                className="flex-1 w-full"
              />
            </div>
          </section>

          <section>
            <h3>{t('Email address')}</h3>
            <Field
              ref={register}
              className={s.Input}
              name="email"
              id="email"
              error={errors.email}
            />
          </section>

          <section>
            <h3>{t('Enrollments')}</h3>
            {enrollments && enrollments.length > 0 ? (
              <ul>
                {enrollments.map((enrollment, i) => (
                  <li key={i} className={s.Enrollment}>
                    {enrollmentTitles[enrollment.type]}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('No enrollments')}</p>
            )}
          </section>

          <section>
            <h3>{t('Unique ID')}</h3>
            <Field
              ref={register}
              className={s.Input}
              name="uniqueId"
              id="uniqueId"
              error={errors.uniqueId}
            />
          </section>

          <section>
            <div className="flex flex-row items-center justify-between">
              <h3>{t('Phone numbers')}</h3>
              <button
                className={s.AddBtn}
                onClick={e => {
                  e.preventDefault()
                  setAddPhone(true)
                }}
              >
                {t('+ Add')}
              </button>
            </div>
            {addPhone && (
              <Field
                id="phoneNumber"
                name="phoneNumber"
                error={errors.phoneNumber}
                className="mb-2"
              >
                <PhoneNumberInputConnected name="phoneNumber" />
              </Field>
            )}
            {phoneNumbers && phoneNumbers.length > 0 ? (
              <ul>
                {user.phoneNumbers?.map((number, i) => (
                  <li key={i} className={s.PhoneNumber}>
                    {phoneNumber(number)}
                    <button
                      type="button"
                      className={s.RemoveBtn}
                      onClick={e => {
                        e.preventDefault()
                        removePhoneNumber(i)
                      }}
                    >
                      {t('Remove')}
                    </button>
                  </li>
                ))}
              </ul>
            ) : addPhone ? (
              <p />
            ) : (
              <p>{t('No known numbers')}</p>
            )}
          </section>

          {user.type === 'agent' && (
            <section>
              <h3>{t('Agent groups')}</h3>
              {groups && (
                <Controller
                  control={form.control}
                  name="groupIds"
                  render={({ value, onChange }) => (
                    <>
                      {groups.length > 0 ? (
                        <ul>
                          {groups.map((group, i) => (
                            <li key={i} className={s.Group}>
                              <Checkbox
                                type="checkbox"
                                value={group.id}
                                name="groupIds"
                                checked={value.includes(group.id)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    onChange([...value, group.id])
                                  } else {
                                    onChange((value as string[]).filter(v => v !== group.id))
                                  }
                                }}
                              >
                                {group.name}
                              </Checkbox>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>{t('No agent groups have been created')}</p>
                      )}
                    </>
                  )}
                />
              )}
            </section>
          )}

          <section>
            <h3>{t('Known locations')}</h3>
            {locations && locations.length > 0 ? (
              <ul>
                {locations.map((loc, i) => (
                  <li key={i} className={s.Location}>
                    {loc}
                    <button
                      className={s.RemoveBtn}
                      onClick={e => {
                        e.preventDefault()
                        removeLocation(i)
                      }}
                    >
                      {t('Remove')}
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('No known locations')}</p>
            )}
          </section>

          <div className={s.Actions}>
            <Button type="submit" isLoading={timer.isLoading} block>
              {timer.didSucceed ? t('Saved!') : t('Save')}
            </Button>
            <button
              className={s.CancelBtn}
              onClick={e => {
                e.preventDefault()
                onCancel()
              }}
            >
              {t('Cancel')}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export default UserInfoForm
