import { Pipeline } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  webhookUrls: string[]
}

type Response = Pipeline

export const updateWebhookURLs = asyncThunk<Request, Response>({
  name: 'pipelines/update-webhook-urls',
  method: 'PUT',
  uri: ({ id }) => `/pipelines/${id}/webhook-urls`,
  stripId: true,
})
