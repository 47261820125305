import {
  AgentAuthentication, agentAuthenticationCosts, customerInteractionCosts, CustomerInteractions,
  DeploymentModel, deploymentModelCosts,
} from './costs'

export const MINIMUM_COST = 2000

export default function costCalculator(
  discount: number,
  customerInteractions: CustomerInteractions,
  agentAuthentication: AgentAuthentication,
  deploymentModel: DeploymentModel
): number {
  const stdCost = bucketsCost(customerInteractions.standard, customerInteractionCosts.standard)
  const advCost = bucketsCost(customerInteractions.advanced, customerInteractionCosts.advanced)
  const voiceEnrol = bucketsCost(
    customerInteractions.voiceBiometricEnrollments,
    customerInteractionCosts.voiceBiometricEnrollments
  )
  const voiceAuth = bucketsCost(
    customerInteractions.voiceBiometricAuthentications,
    customerInteractionCosts.voiceBiometricAuthentications
  )

  const interactionsCosts = stdCost + advCost + voiceEnrol + voiceAuth

  const agentCost =
    agentAuthenticationCosts['enrollments'] * agentAuthentication.enrollments +
    agentAuthenticationCosts['ssoAuthentications'] * agentAuthentication.ssoAuthentications +
    agentAuthenticationCosts['continuousAuthenticationAgents'] *
      agentAuthentication.continuousAuthenticationAgents

  const deploymentCost = deploymentModelCosts[deploymentModel]

  const totalCost = interactionsCosts + agentCost + deploymentCost
  return Math.max(totalCost, MINIMUM_COST) * (1 - discount)
}

function bucketsCost(value: number, buckets: [number, number][]): number {
  var totalCost = 0
  var remaining = value
  var counted = 0
  for (const [threshold, cost] of buckets) {
    if (remaining <= 0) break

    const count = Math.min(remaining, threshold - counted)
    counted += count

    totalCost += count * cost
    remaining -= count
  }

  return totalCost
}
