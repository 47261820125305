import { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAdminSelector, useAppDispatch } from 'store'
import { t } from 'translation'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from '@headlessui/react'

import { authenticationsLogout } from 'thunks/api/authentications/logout'
import { delay } from 'util/delay'

import Accounts from './Accounts'
import s from './index.module.scss'
import Logo from './Logo'

const BottomMenu: FC = () => {
  const history = useHistory()
  const admin = useAdminSelector()
  const dispatch = useAppDispatch()
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const handleLogout = useCallback(async () => {
    setIsLoggingOut(true)
    dispatch(authenticationsLogout())
    await delay(750)
    history.push(r.auth.login)
  }, [dispatch, history])

  return (
    <div className={s.Wrapper}>
      <Popover className="relative flex items-center w-full h-full">
        <Popover.Button className="w-[225px] flex justify-between items-center focus:outline-0 h-full">
          <Logo />
          <FontAwesomeIcon className={s.BtnOpen} icon={faChevronRight} />
        </Popover.Button>

        <Popover.Panel className={s.Panel}>
          {({ close }) => (
            <div className={s.Content}>
              <Accounts closeMenu={close} />

              <hr className={s.Divider} />

              <div>
                <h3 className={s.Name}>{admin && `${admin.firstName} ${admin.lastName}`}</h3>
                <p className={s.Email}>{admin && admin.email}</p>
                <ul className={s.Btns}>
                  <li>
                    <span>{t('Questions? Email support@journeyid.com')}</span>
                  </li>
                  <li>
                    <button onClick={handleLogout}>
                      {isLoggingOut ? t('Logging out...') : t('Logout')}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Popover>
    </div>
  )
}

export default BottomMenu
