import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { t } from 'translation'

import s from './index.module.scss'

type Props = {
  children: ReactNode
}

const Settings: FC<Props> = ({ children }) => {
  return (
    <main className={classNames('__Settings', s.Settings)}>
      <label>{t('Settings')}</label>
      <div>{children}</div>
    </main>
  )
}

export default Settings
