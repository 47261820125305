import { Agent } from 'types/agent'
import asyncThunk from 'util/asyncThunk'
import { HTTPRetrievedMultiple } from 'util/statuses'

export type Request = {
  accountId: string
}

type Response = Agent[]

export const listAgents = asyncThunk<Request, Response>({
  name: 'agents/list',
  uri: '/agents',
  successStatus: HTTPRetrievedMultiple,
  orchestrator: false,
})
