import { AccountSSO } from 'types/sso'
import asyncThunk from 'util/asyncThunk'

export type Request = AccountSSO['saml2'] & {
  id: string
}

export const updateSSOSaml = asyncThunk<Request, { id: string }>({
  name: 'account/sso',
  uri: ({ id }) => `/accounts/${id}/saml`,
  method: 'PUT',
  orchestrator: false,
  stripId: true,
})
