import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Iframe } from 'types'

import { faGripDotsVertical, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import useActionTimer, { ActionTimer } from 'hooks/useActionTimer'
import CategoryTitle from 'pages/IFrames/Steps/Pipelines/CategoryTitle'

import s from './DashboardCategory.module.scss'

type Props = {
  category: Iframe['categories'][number]
  className?: string
  categoryTimer: ActionTimer
  onAdd: () => void
  onRemove: (pipelineId: string) => Promise<any>
  onChange: (title: string) => void
  onDelete: () => void
}

const DashboardCategory: FC<Props> = ({
  category,
  className,
  categoryTimer,
  onAdd,
  onRemove,
  onChange,
  onDelete,
}) => {
  const timer = useActionTimer()
  const handleRemove = useCallback(
    async (pipelineId: string) => {
      timer.setId(pipelineId)
      timer.start()
      await onRemove(pipelineId)
      timer.succeeded()
    },
    [timer, onRemove]
  )

  return (
    <div className={classNames(s.DashboardCategory, className)}>
      <header>
        {category.title ? (
          <CategoryTitle
            category={category}
            timer={categoryTimer}
            onChange={onChange}
            onDelete={onDelete}
          />
        ) : (
          <label className={s.Uncategorized}>{t('Uncategorized')}</label>
        )}

        <button onClick={onAdd}>
          <FontAwesomeIcon icon={faPlus} /> {t('Add a pipeline')}
        </button>
      </header>

      <Droppable droppableId={category.id || 'uncategorized'} type="pipeline">
        {(provided, snapshot) => (
          <ul ref={provided.innerRef} {...provided.droppableProps} className={s.SubList}>
            {category.pipelines
              .filter(p => p !== undefined)
              .map((pipeline, idx) => (
                <Draggable key={pipeline.id} draggableId={pipeline.id} index={idx}>
                  {provided => (
                    <li key={pipeline.id} ref={provided.innerRef} {...provided.draggableProps}>
                      <div {...provided.dragHandleProps}>
                        <FontAwesomeIcon icon={faGripDotsVertical} />
                      </div>
                      <div>
                        <label>{pipeline.title}</label>
                      </div>
                      <div>
                        <button
                          disabled={timer.isLoading && timer.id === pipeline.id}
                          onClick={() => handleRemove(pipeline.id)}
                        >
                          {timer.isLoading && timer.id === pipeline.id
                            ? t('removing...')
                            : t('remove')}
                        </button>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
            {category.pipelines.length === 0 && !snapshot.isDraggingOver && (
              <div className={s.Empty}>{t('This category has no pipelines')}</div>
            )}
          </ul>
        )}
      </Droppable>
    </div>
  )
}

export default DashboardCategory
