import { t } from 'translation'

export function oxford(items: string[], op = '&') {
  const numItems = items.length

  let limitStr = ''
  if (numItems < 2) {
    return String(items)
  } else if (numItems === 2) {
    return items.join(` ${op} `)
  } else {
    limitStr = ', ' + t('and') + ' ' + items[numItems - 1]
  }

  return items.slice(0, numItems - 1).join(', ') + limitStr
}
