import cogoToast from 'cogo-toast'
import { FC, useCallback, useState } from 'react'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import { faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConfirmModal from 'components/organisms/ConfirmModal'
import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import { deleteAccountRole } from 'thunks/api/account_roles/delete'
import { Admin } from 'types/admin'
import { Group } from 'types/group'

import InvitationExpiration from './_InvitationExpiration'
import ListRole from './_ListRole'
import SupervisorGroups from './_SupervisorGroups'
import SupervisorGroupsModal from './_SupervisorGroupsModal'
import s from './index.module.scss'

type Props = {
  admins: Admin[]
  groups: Group[]
  onUpdate: (admin: Admin) => void
  onDelete: (admin: Admin) => void
}

const List: FC<Props> = ({ admins, groups, onUpdate, onDelete }) => {
  const { adminId, accountId } = useAuthSelector()
  const [admin, setAdmin] = useState<Admin>()

  const [remove, { timer }] = useAPI(deleteAccountRole)
  const handleDelete = useCallback(async () => {
    if (!admin || !accountId) return

    await remove({
      accountId,
      adminId: admin.id,
    })

    closeModal()
    setAdmin(undefined)
    onDelete(admin)
    cogoToast.success(t('{{email}} has been removed', { email: admin.email }))
  }, [admin, accountId, onDelete, remove])

  const handleConfirmArchive = useCallback((admin: Admin) => {
    setAdmin(admin)
    openModal('Confirm')()
  }, [])

  return (
    <>
      <ul className="-my-4 divide-y">
        {(admins || []).map(admin => (
          <li key={admin.id} data-admin-id={admin.id} className="py-3">
            <div className="flex items-center justify-between">
              <div className="flex-1">
                {(admin.firstName || admin.lastName) && (
                  <p className="text-base font-bold">
                    {admin.firstName} {admin.lastName}
                  </p>
                )}

                <p className="text-sm text-gray-500">{admin.email}</p>
              </div>
              <div className="flex items-center justify-end w-44">
                <ListRole admin={admin} onUpdate={onUpdate} />
              </div>
              <div className="flex items-center justify-end w-1/4 pr-4">
                {admin.id === adminId ? (
                  <span className="text-sm italic text-gray-400">{t('your account')}</span>
                ) : (
                  <button onClick={() => handleConfirmArchive(admin)}>
                    <FontAwesomeIcon className={s.DeleteBtn} icon={faTrashCan} />
                  </button>
                )}
              </div>
            </div>
            {admin.role === 'supervisor' && (
              <SupervisorGroups
                admin={admin}
                onEditGroups={() => {
                  setAdmin(admin)
                  openModal('SupervisorGroups')()
                }}
              />
            )}
            <InvitationExpiration admin={admin} />
          </li>
        ))}
      </ul>
      {admin && (
        <>
          <ConfirmModal
            modalId="Confirm"
            suffix={admin.id}
            type="delete"
            title={t('Remove admin?')}
            text={t(
              "Are you sure you want to remove {{name}} from the account? This action can't be undone.",
              { name: admin.firstName ? `${admin.firstName} ${admin.lastName}` : admin.email }
            )}
            isLoading={timer.isLoading}
            onSubmit={handleDelete}
          />
          <SupervisorGroupsModal
            key={admin.id}
            admin={admin}
            groups={groups}
            onUpdate={groups => onUpdate({ ...admin, groups })}
          />
        </>
      )}
    </>
  )
}

export default List
