import { useCallback } from 'react'
import { ErrorOption } from 'react-hook-form'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { Request, updateUser } from 'thunks/api/users/update'
import { APIErrorResponse } from 'util/error'

export type ValidationErrorCallback = (name: string, error: ErrorOption) => void

export function useUpdateUser(onError: ValidationErrorCallback) {
  const handleValidationError = useCallback(
    (statusCode: number, error: APIErrorResponse) => {
      if (error.errors) {
        Object.entries(error.errors).forEach(([key, val]) => {
          if (key.startsWith('phoneNumber')) {
            onError('phoneNumber', { type: 'server', message: val })
          } else {
            onError(key, { type: 'server', message: val })
          }
        })
      }
    },
    [onError]
  )

  const [run, { timer }] = useAPI(updateUser, { onError: handleValidationError })
  const { accountId } = useAuthSelector()

  const update = useCallback(
    async (req: Omit<Request, 'accountId'>) => {
      if (!accountId) return
      return await run({ ...req, accountId })
    },
    [run, accountId]
  )

  return [update, { timer }] as const
}
