import { useCallback } from 'react'
import { usePipelineSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { updateBrandStyle } from 'thunks/api/pipelines/update-brand-style'
import { Branding } from 'types/account'

export default function useBranding() {
  const { pipeline } = usePipelineSelector()
  const [update, { timer }] = useAPI(updateBrandStyle)

  const handleSave = useCallback(
    async (brandStyle: Branding | undefined) => {
      update({
        id: pipeline.id,
        brandStyleId: brandStyle?.id,
      })
    },
    [pipeline, update]
  )

  return [handleSave, { timer }] as const
}
