import { FC } from 'react'

import { t } from 'translation'

import IntegrationLayout from '../IntegrationLayout'
import AuthorizedRedirectUrls from './components/AuthorizedRedirectUrls'
import ClientSecrets from './components/ClientSecrets'
import OAuthRequestBuilder from './components/OAuthRequestBuilder'
import OidcIntegration from './components/OIDCIntegration'

const SSSOIDC: FC = () => (
  <IntegrationLayout
    id="sso-page"
    title={t('Single Sign-On: OIDC')}
    description={t(
      'In addition to being able to initiate pipelines using API requests, pipelines can be initiated using the OAuth (OIDC) protocol.'
    )}
  >
    <OidcIntegration />
    <hr />
    <AuthorizedRedirectUrls />
    <hr />
    <ClientSecrets />
    <hr />
    <OAuthRequestBuilder />
  </IntegrationLayout>
)

export default SSSOIDC
