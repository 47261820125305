import cogoToast from 'cogo-toast'
import { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import r from 'routes'
import { useAppDispatch } from 'store'

import { t } from 'translation'

import useQueryString from 'hooks/useQueryString'
import { actions } from 'slices/auth'

type Params = {
  token?: string
  invitation?: 'true'
}

const OAuthCallback: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const query = useQueryString<Params>()

  const authenticate = useCallback(
    async (token: string) => {
      if (query.invitation) {
        cogoToast.success(t(`You've accepted the invitation.`))
      }

      dispatch(actions.setJWT(token))
      history.push(r.root)
    },
    [query.invitation, history, dispatch]
  )

  useEffect(() => {
    if (query.token) {
      authenticate(query.token)
    } else {
      history.push(r.auth.login)
    }
  }, [query.token, history, authenticate])

  return null
}

export default OAuthCallback
