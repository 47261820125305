import { useCallback } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { useAuthSelector } from 'store'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import { useAPI } from 'hooks/useAPI'
import { createInvitation } from 'thunks/api/invitations/create'
import { AdminRole } from 'types/admin'

export type FormData = {
  email: string
  role: AdminRole
  groups: string[]
}

export const useInviteAdmin = function (setError: UseFormMethods['setError']) {
  const [run, { timer }] = useAPI(createInvitation)
  const { accountId } = useAuthSelector()

  const invite = useCallback(
    async (data: FormData) => {
      if (!accountId) throw new Error('accountId is not available')

      if (!data.groups) data.groups = []
      data.groups = Array.isArray(data.groups) ? data.groups : [data.groups]

      try {
        return await run({
          ...data,
          accountId,
          language: 'en',
        })
      } catch (err) {
        if (isAPIErrorResponse(err) && err.errors) {
          for (var key in err.errors) {
            setError(key, {
              type: 'server',
              message: err.errors[key],
            })
          }
        } else {
          setError('email', {
            type: 'server',
            message: `error: ${(err as any).message}`,
          })
        }
      }
    },
    [accountId, setError, run]
  )

  return [invite, { timer }] as const
}
