import cogoToast from 'cogo-toast'
import { FC, useCallback } from 'react'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import { useAPI } from 'hooks/useAPI'
import { updateAdminRole } from 'thunks/api/admins/role'
import { Admin } from 'types/admin'

import s from './index.module.scss'

const roleNames = {
  admin: t('Administrator'),
  supervisor: t('Agent Supervisor'),
  owner: t('Account owner'),
}

type Props = {
  admin: Admin
  onUpdate: (admin: Admin) => void
}

const ListRole: FC<Props> = ({ admin, onUpdate }) => {
  const { adminId } = useAuthSelector()

  const [updateRole, { timer: updateTimer }] = useAPI(updateAdminRole)
  const handleRoleChange = useCallback(
    async (adminId: string, role: 'supervisor' | 'admin' | 'owner') => {
      updateTimer.setId(adminId)
      const updated = await updateRole({
        role,
        adminId,
      })
      onUpdate({ ...admin, ...updated })
      cogoToast.success(t('Admin role has been updated'))
    },
    [admin, updateTimer, onUpdate, updateRole]
  )

  if (adminId === admin.id) {
    return <span className="text-sm italic text-gray-400 mr-9">{roleNames[admin.role]}</span>
  }

  return (
    <select
      defaultValue={admin.role}
      disabled={updateTimer.id === admin.id && updateTimer.isLoading}
      className={s.RoleDropdown}
      onChange={e => handleRoleChange(admin.id, e.target.value as any)}
    >
      <option value="supervisor">{t('Agent Supervisor')}</option>
      <option value="admin">{t('Administrator')}</option>
      <option value="owner">{t('Account owner')}</option>
    </select>
  )
}

export default ListRole
