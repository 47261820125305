import { FC, useEffect, useLayoutEffect } from 'react'
import ReactTooltip from 'react-tooltip'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import StageRow from 'components/atoms/StageRow'
import Modal from 'components/organisms/Modal'
import { ActionTimer } from 'hooks/useActionTimer'
import { closeModal, useModal } from 'hooks/useModal'
import { PipelineTemplate } from 'pages/Pipelines/Create/UseCase/pipeline-templates'

type Props = {
  pipeline: PipelineTemplate | undefined
  timer: ActionTimer
  onContinue: () => void
}

const PipelineTemplateModal: FC<Props> = ({ pipeline, timer, onContinue }) => {
  const [isOpen] = useModal('PipelineTemplate')

  useEffect(() => {
    if (!isOpen || !pipeline) return

    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 0)
  }, [isOpen, pipeline])

  if (!pipeline) return null

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>{pipeline.label}</Modal.Header>
      <Modal.Body>{pipeline.description}</Modal.Body>
      <ul className="max-w-xs mb-8 space-y-3">
        {pipeline.stageTypes.map(stageType => (
          <StageRow key={stageType} stageType={stageType} />
        ))}
      </ul>
      <p className="mb-8 text-gray-500 std-base">
        {t(
          "Pipeline templates are a great way to create a pipeline based on a use case. Once created, you can add actions to the pipeline, but you wont't be able to remove the actions listed above."
        )}
      </p>
      <Modal.Actions>
        <Button isLoading={timer.isLoading} size="xl" className="px-16" onClick={onContinue}>
          {timer.isLoading ? t('Creating...') : t('Okay!')}
        </Button>
        <Button variant="link" onClick={onContinue}>
          {t('Learn more')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default PipelineTemplateModal
