import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { t } from 'translation'

import Auth from 'pages/auth/shared/Auth'
import GithubButton from 'pages/auth/shared/buttons/GithubButton'
import GoogleButton from 'pages/auth/shared/buttons/GoogleButton'

const SignUp: FC = () => {
  const history = useHistory()

  return (
    <Auth
      info={t('Sign up below to create your Journey Elevate account.')}
      footer={
        <p>
          {t('Already have an account?')}{' '}
          <button onClick={() => history.push(r.auth.login)}>{t('Log in')}</button>
        </p>
      }
    >
      <GoogleButton text={t('Sign up with Google')} />
      <GithubButton text={t('Sign up with GitHub')} />
    </Auth>
  )
}

export default SignUp
