import { HTTPDeleted } from '@journeyid/agent/common/statuses'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

export const deleteUser = asyncThunk<Request, {}>({
  name: 'users/delete',
  method: 'DELETE',
  uri: ({ id }) => `/users/${id}`,
  orchestrator: false,
  stripId: true,
  successStatus: HTTPDeleted,
})
