import classNames from 'classnames'
import React, { FC, ReactElement } from 'react'

import s from './index.module.scss'
import Option, { Props as OptionProps } from './Item'

type Props<T extends string> = {
  name: string
  value: T
  children: ReactElement<OptionProps<T>>[]
  className?: string
  onChange: (value: T) => void
}

const OptionList = <T extends string>({ value, children, className, onChange }: Props<T>) => {
  return (
    <ul className={classNames(s.OptionList, className)}>
      {children.map(({ props }) => (
        <Option
          {...props}
          isSelected={props.value === value}
          onClick={() => onChange(props.value)}
        />
      ))}
    </ul>
  )
}

type OptionListType<T extends string = string> = typeof OptionList & {
  Item: FC<Omit<OptionProps<T>, 'name' | 'isSelected' | 'onClick'>>
}

Object.assign(OptionList, { Item: Option })

export default OptionList as OptionListType
