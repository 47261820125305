import classNames from 'classnames'
import { FC } from 'react'

import { t } from 'translation'

import styles from 'components/atoms/Settings/index.module.scss'

import { ReactComponent as Illustration } from './configurable.svg'
import s from './index.module.scss'

const ConfigurableStage: FC = () => {
  return (
    <div className={classNames('__ConfigurableStage', styles.Settings, s.ConfigurableStage)}>
      <div>
        <Illustration />
        <h5 className="std">
          {t('More settings are available when this action is sent to a customer.')}
        </h5>
        <p className="std-sm">
          {t(
            "When this pipeline is sent to a user, you'll have the opportunity to further customize the data."
          )}
        </p>
        <p className="std-sm">
          {t(
            'An example is the Credit Card Payment action, where you set which products or services are being purchased and their prices. These can be unique each time the pipeline is sent.'
          )}
        </p>
      </div>
    </div>
  )
}

export default ConfigurableStage
