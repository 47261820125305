import { useCallback } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { useAppDispatch } from 'store'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'
import { AsyncThunk, unwrapResult } from '@reduxjs/toolkit'

import useActionTimer from 'hooks/useActionTimer'
import { APIErrorResponse, ErrorResponse, setServerErrors } from 'util/error'

export type ErrorCallback = (statusCode: number, error: APIErrorResponse) => void

type Options = {
  setError?: UseFormMethods['setError']
  onError?: ErrorCallback
}

export function useAPI<Request extends Record<string, any>, Response>(
  thunk: AsyncThunk<Response, Request, { rejectValue: ErrorResponse }>,
  options?: Options
) {
  const timer = useActionTimer()
  const dispatch = useAppDispatch()

  const execute = useCallback(
    async (req: Request) => {
      try {
        timer.start()

        const resp = await dispatch(thunk(req)).then(unwrapResult)

        timer.succeeded()

        return resp
      } catch (err) {
        console.log(err)
        if (isAPIErrorResponse(err)) {
          if (options?.setError && err.errors) {
            const errors = err.errors as Record<keyof Request, string>
            setServerErrors<Request>(errors, options.setError)
          }
          if (options?.onError) {
            options.onError(err.statusCode, err)
          }
        }

        timer.failed()
        throw err
      }
    },
    [thunk, timer, options, dispatch]
  )

  return [execute, { timer }] as const
}
