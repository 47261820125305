import classNames from 'classnames'
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import Cropper, { Area, Point } from 'react-easy-crop'
import { t } from 'translation'

import Button from 'components/atoms/Button'
import Slider from 'components/atoms/Slider'
import Modal from 'components/organisms/Modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal, useModal } from 'hooks/useModal'
import getCroppedImg from 'pages/shared/Users/Show/UserInfo/UploadPhotoModal/util'
import { uploadUserPhoto } from 'thunks/api/users/upload-photo'

type Props = {
  userId: string
  onUpload: (imageUrl: string) => void
}

const UploadPhotoModal: FC<Props> = ({ userId, onUpload }) => {
  const [isOpen] = useModal('UploadPhoto')
  const ref = useRef<HTMLInputElement>(null)
  const [photo, setPhoto] = useState<string>()
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const [upload, { timer }] = useAPI(uploadUserPhoto)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

  const handleUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (!files?.length) return

    const reader = new FileReader()
    reader.addEventListener('load', event => {
      if (!event.target?.result) return

      setPhoto(event.target.result as string)
    })
    reader.readAsDataURL(files[0])
  }, [])

  const handleComplete = useCallback(async () => {
    if (!croppedAreaPixels || !photo) return

    const cropped = await getCroppedImg(photo, croppedAreaPixels, 0)
    if (!cropped) return

    const { photoUrl } = await upload({
      id: userId,
      photo: cropped.replace(/^data:image\/[^;]+;base64,/, ''),
    })
    if (!photoUrl) return

    onUpload(photoUrl)
  }, [userId, croppedAreaPixels, photo, upload, onUpload])

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      {photo && (
        <Modal.Body>
          <div className="relative h-96">
            <Cropper
              image={photo}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={(_, pixels) => setCroppedAreaPixels(pixels)}
              onZoomChange={setZoom}
            />
          </div>
          <Slider
            value={zoom * 25}
            min={25}
            max={100}
            step={1}
            filledClassName="bg-theme cursor-pointer"
            handleClassName="bg-white w-6 h-6 border border-theme cursor-pointer"
            unfilledClassName="bg-gray-300 cursor-pointer"
            className="mt-6 mb-16"
            onChangeValue={z => setZoom(z / 25)}
          />
        </Modal.Body>
      )}
      <Modal.Actions>
        {photo ? (
          <Button
            size="xl"
            disabled={!photo}
            isLoading={timer.isLoading}
            onClick={handleComplete}
            className={classNames(timer.isLoading && 'pointer-events-none text-gray-400')}
          >
            {timer.isLoading ? t('Saving...') : t('Save photo')}
          </Button>
        ) : (
          <>
            <Button size="xl" onClick={() => ref.current?.click()}>
              Select a photo
            </Button>
            <input ref={ref} type="file" className="absolute w-0 h-0" onChange={handleUpload} />
          </>
        )}
        <Button size="xl" variant="secondary" onClick={closeModal}>
          {t('Cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UploadPhotoModal
