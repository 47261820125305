import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import s from './DeliveryMethod.module.scss'

import type { DeliveryMethod as DeliveryMethodType } from 'types'

const METHOD_NAMES: Record<DeliveryMethodType, string> = {
  sms: 'SMS',
  email: 'Email',
  'push-notification': 'Push Notification',
  url: 'URL',
}

type Props = {
  method: DeliveryMethodType
  isActive: boolean
  isConfigured: boolean | undefined
  isAvailable: boolean
  notAvailableText: string
  children: ReactNode
  onClick: () => void
}

const DeliveryMethod: FC<Props> = ({
  method,
  isActive,
  isConfigured,
  isAvailable,
  notAvailableText,
  children,
  onClick,
}) => {
  return (
    <li
      className={classNames({
        'pointer-events-none': !isConfigured || !isAvailable,
      })}
      onClick={() => isConfigured && isAvailable && onClick()}
    >
      <div>{isActive && <FontAwesomeIcon icon={faCheck} />}</div>
      <p
        className={classNames(s.Name, {
          'text-gray-400': !isConfigured || !isAvailable,
        })}
      >
        {t(METHOD_NAMES[method])}
      </p>
      <p
        className={classNames(s.Value, {
          'text-gray-400': !isConfigured || !isAvailable,
        })}
      >
        {(() => {
          if (isConfigured === undefined) return null
          if (isConfigured === false) return t('not configured for this pipeline')
          if (isAvailable === false) return notAvailableText
          return children
        })()}
      </p>
    </li>
  )
}

export default DeliveryMethod
