import { useMemo } from 'react'
import { useAdminSelector, useAuthSelector } from 'store'

import { AdminState } from 'slices/admin'

type Account = AdminState['accounts'][number]

export default function useCurrentAccount() {
  const { accounts } = useAdminSelector()
  const { accountId } = useAuthSelector()

  return useMemo(() => {
    if (!accounts || !accountId) return

    return accounts.find((account: Account) => account.id === accountId)
  }, [accounts, accountId])
}
