import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import s from './index.module.scss'

type Props = {
  checked: boolean
  onSelect: () => void
  children: ReactNode
}

const Radio: FC<Props> = ({ checked, children, onSelect }) => {
  return (
    <div className={classNames(checked ? s.RadioChecked : s.RadioUnchecked)} onClick={onSelect}>
      <div>
        <input type="radio" checked={checked} readOnly className="form-radio" />
      </div>
      <div>
        <label>{children}</label>
      </div>
    </div>
  )
}

export default Radio
