const codeTemplate = (
  clientId: string,
  redirectUrl: string,
  nonce: string,
  state: string,
  customerId: string
) => `${window.location.origin}/oauth2/authorize?
    response_type=code&
    scope=openid&
    client_id=<span>${clientId}</span>&
    redirect_uri=<span>${redirectUrl}</span>&
    nonce=<span>${nonce}</span>&
    state=<span>${state}</span>&
    unique_id=<span>${customerId}</span>`

export default codeTemplate
