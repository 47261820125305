import { FC } from 'react'
import { t } from 'translation'

import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Toggle from 'components/atoms/Toggle'
import GovernmentIDSelfiePreview from 'components/templates/stages/GovernmentIDSelfie.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const GovernmentIDSelfie: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('government-id-selfie')

  return (
    <CreateSettings
      stageType="government-id-selfie"
      preview={<GovernmentIDSelfiePreview />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <Setting
          title={t('Name verification')}
          description={t(
            "We will extract the name on the government ID and compare it to the customer's profile. If there's no name on the customer's profile, this check will not be performed."
          )}
        >
          <Toggle
            enabled={stage.metadata.matchName}
            className="mt-2"
            onChange={enabled => handleMetadata('matchName', enabled)}
          >
            {t("Confirm customer's name matches ID")}
          </Toggle>
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default GovernmentIDSelfie
