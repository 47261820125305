import { parseISO } from 'date-fns'
import { useMemo } from 'react'

import { timeRange } from 'pages/shared/Users/Show/_util'
import { EventWithPipeline } from 'types/event'

export type Session = {
  id: string
  label: string
  events: EventWithPipeline[]
}

export default function useSessions(events: EventWithPipeline[] | undefined): Session[] {
  return useMemo(() => {
    const dates: Record<string, Date> = {}
    const sessions: Record<string, EventWithPipeline[]> = {}
    ;(events || []).forEach(event => {
      if (!(event.sessionId in sessions)) {
        sessions[event.sessionId] = []
      }
      sessions[event.sessionId].push(event)
      dates[event.id] = parseISO(event.execution ? event.execution.completedAt : event.createdAt)
    })

    for (var sessionId in sessions) {
      sessions[sessionId].sort((l, r) => dates[r.id].getTime() - dates[l.id].getTime())
    }

    // Sort groups by first event timestamp
    const sessionIds = Object.keys(sessions).sort(
      (l, r) => dates[sessions[r][0].id].getTime() - dates[sessions[l][0].id].getTime()
    )

    return sessionIds.map(id => ({
      id,
      label: timeRange(sessions[id].map(e => dates[e.id])),
      events: sessions[id],
    }))
  }, [events])
}
