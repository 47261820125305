import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

type Response = {
  id: string
  archivedAt: string
  archivedBy: string
}

export const archiveIFrame = asyncThunk<Request, Response>({
  name: 'iframes/archive',
  method: 'DELETE',
  uri: ({ id }) => `/iframes/${id}`,
  stripId: true,
})
