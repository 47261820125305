import { FC } from 'react'
import { usePipelineSelector } from 'store'

import Header from './Header'
import s from './index.module.scss'
import LinkList from './LinkList'
import SendTest from './SendTest'

const Published: FC = () => {
  const { pipeline } = usePipelineSelector()

  return (
    <div className={s.Published}>
      <div>
        <Header />
        <SendTest />
        {/* <SendInstructions /> */}
        <LinkList id={pipeline.id} />
      </div>
    </div>
  )
}

export default Published
