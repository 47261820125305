import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  type: 'enrollment' | 'authentication' | 'categories'
  categoryId?: string | undefined
  destinationCategoryId?: string | undefined
  fromIndex: number
  toIndex: number
}

type Response = Iframe

export const reorderIFramePipelines = asyncThunk<Request, Response>({
  name: 'iframes/pipelines/reorder',
  method: 'PUT',
  uri: ({ id }) => `/iframes/${id}/pipelines/reorder`,
  stripId: true,
  throttle: false,
})
