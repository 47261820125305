import classNames from 'classnames'
import { ChangeEvent, FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'
import { Pipeline } from 'types'

import { faArrowRight, faEye } from '@fortawesome/pro-regular-svg-icons'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import styles from 'components/atoms/Input/index.module.scss'
import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Toggle from 'components/atoms/Toggle'
import Footer from 'components/molecules/Footer'
import PageHeader from 'components/molecules/PageHeader'
import Preview from 'components/organisms/Preview'
import SlateEditor, { defaultText } from 'components/organisms/SlateEditor'
import { useAPI } from 'hooks/useAPI'
import { openModal } from 'hooks/useModal'
import PreviewModal from 'pages/Pipelines/Create/components/PreviewModal'
import { updateSuccessScreen } from 'thunks/api/pipelines/update-success-screen'

import s from './index.module.scss'
import SuccessScreenPreview from './Preview'

const SuccessScreen: FC = () => {
  const { pipeline } = usePipelineSelector()

  const [successScreen, setSuccessScreen] = useState<Pipeline['success']>(pipeline.success)

  const history = useHistory()
  const [hasAddlMessaging, setHasAddlMessaging] = useState(!!pipeline.success.text)

  const handleChange = useCallback(
    (field: keyof NonNullable<Pipeline['success']>) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
        if (!successScreen) return

        setSuccessScreen({
          ...successScreen,
          [field]: typeof e === 'string' ? e : e.target.value,
        })
      },
    [successScreen]
  )

  const toggleAddlMessaging = useCallback(
    (enabled: boolean) => {
      if (!enabled) {
        setSuccessScreen({
          title: successScreen.title,
          text: '',
        })
      }
      setHasAddlMessaging(enabled)
    },
    [successScreen]
  )

  const [update, { timer }] = useAPI(updateSuccessScreen)
  const handleSave = useCallback(async () => {
    await update({
      id: pipeline.id,
      ...successScreen,
    })

    if (!pipeline.key) {
      history.push(r.pipelines.create.delivery.method(pipeline.id, 'sms'))
    }
  }, [pipeline, successScreen, update, history])

  return (
    <div id="success-screen" className={s.Wrapper}>
      <div className={s.Content}>
        {!pipeline.key && (
          <div className="pt-8 pr-12 pl-7">
            <Alert
              actions={
                <Button
                  variant="white"
                  onClick={() =>
                    history.push(r.pipelines.create.delivery.method(pipeline.id, 'sms'))
                  }
                  className="text-blue-800"
                >
                  {t('Skip Success Screen')}
                </Button>
              }
            >
              {t(
                "If you're planning on ONLY using Single Sign On as a Delivery method, you may skip this step. The SSO process does not use a Success Screen."
              )}
            </Alert>
          </div>
        )}

        <PageHeader title={t('Success Screen')}>
          {t(
            "The user has completed the Pipeline successfully! Let's tell them how great they did or what their next steps are. If the Pipeline is being used for Single Sign-On, then you can skip the success screen since they will be automatically redirected rather than shown this screen."
          )}
        </PageHeader>

        <div className="flex pb-8 pr-8">
          <Settings>
            <Setting
              title={t('Completion Message')}
              description={t(
                "This message should be short and sweet. It's shown at the top of the success screen."
              )}
            >
              <Input
                placeholder={t('Great! Your identity has been verified!')}
                value={successScreen.title}
                onChange={handleChange('title')}
              />
            </Setting>

            <Setting
              title={t('Additional Messaging')}
              description={t(
                'Optionally, you can add additional text that will be shown near the bottom of the success screen. This can be used for legal disclaimers or simply more information for the user.'
              )}
            >
              <Toggle enabled={hasAddlMessaging} onChange={toggleAddlMessaging}>
                {t('Show additional text to the user.')}
              </Toggle>
              {hasAddlMessaging && (
                <div className="pt-2">
                  <SlateEditor
                    defaultValue={
                      pipeline.success.text ? JSON.parse(pipeline.success.text) : defaultText
                    }
                    onChange={text =>
                      handleChange('text')(JSON.stringify(text).replace(/onerror=/, ''))
                    }
                    placeholder={t('Enter the document content here...')}
                  >
                    {editable => (
                      <div className={classNames(styles.Input, 'h-[160px] overflow-y-auto flex')}>
                        {editable}
                      </div>
                    )}
                  </SlateEditor>
                </div>
              )}
            </Setting>
          </Settings>

          <div>
            <Preview>
              <SuccessScreenPreview {...successScreen} />
            </Preview>
          </div>
        </div>
      </div>
      <Footer
        actions={
          <>
            <Button variant="secondary" icon={faEye} onClick={openModal('Preview', 'success')}>
              {t('Preview Pipeline')}
            </Button>
            <Button
              iconRight={timer.isLoading || pipeline.key ? undefined : faArrowRight}
              variant={timer.didSucceed && pipeline.key ? 'green' : 'primary'}
              isLoading={timer.isLoading}
              onClick={handleSave}
            >
              {timer.didSucceed && pipeline.key
                ? t('Saved!')
                : timer.isLoading
                  ? t('Saving...')
                  : pipeline.key
                    ? t('Save')
                    : t('Save & Continue')}
            </Button>
          </>
        }
      />
      <PreviewModal />
    </div>
  )
}

export default SuccessScreen
