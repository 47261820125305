import classNames from 'classnames'
import { ChangeEvent, FC } from 'react'
import NumberFormat from 'react-number-format'
import { t } from 'translation'

import s from './AgentAuthentication.module.scss'
import { AgentAuthentication as AgentAuth, agentAuthenticationCosts } from './costs'

type Props = {
  value: AgentAuth
  discount: number | undefined
  onChange: (value: AgentAuth) => void
}

const AgentAuthentication: FC<Props> = ({ value, discount = 0, onChange }) => {
  return (
    <div className={s.OptionList}>
      <div
        className={classNames(value['enrollments'] ? s.SelectedOption : s.Option, 'cursor-default')}
      >
        <div>
          <header>{t('Agent Enrollment')}</header>
          <p>
            {t(
              'One time process for each new hire - Creates the biometric profile for future access and monitoring. This process can be executed at any point along the hiring process.'
            )}
            '
          </p>
          <div>
            $
            <NumberFormat
              value={agentAuthenticationCosts['enrollments'] * (1 - discount)}
              thousandSeparator=","
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
            />{' '}
            {t('one-time cost')}
          </div>
        </div>
        <div>
          <NumberFormat
            value={value['enrollments'] || ''}
            placeholder={t('enrollments / mo')}
            thousandSeparator=","
            className={classNames(
              'text-right !bg-white text-sm w-[160px] border-gray-300 focus:border-gray-400 focus:ring-0'
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange({ ...value, enrollments: +e.target.value.replaceAll(/[^0-9]+/g, '') })
            }
          />
        </div>
      </div>

      <div
        className={classNames(
          value['ssoAuthentications'] ? s.SelectedOption : s.Option,
          'cursor-default'
        )}
      >
        <div>
          <header>{t('SSO Agent Authentication')}</header>
          <p>
            {t(
              'Each time a user accesses their biometric profile to request system or application access through the SSO provider in the company. This process is typically used at the beginning of a shift for agents and any time they normally log in after breaks or lunch.'
            )}
            "
          </p>
          <div>
            $
            <NumberFormat
              value={agentAuthenticationCosts['ssoAuthentications'] * (1 - discount)}
              thousandSeparator=","
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
            />{' '}
            / {t('authentication')}
          </div>
        </div>
        <div>
          <NumberFormat
            value={value['ssoAuthentications'] || ''}
            placeholder={t('auths / mo')}
            thousandSeparator=","
            className={classNames(
              'text-right !bg-white text-sm w-[160px] border-gray-300 focus:border-gray-400 focus:ring-0'
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange({ ...value, ssoAuthentications: +e.target.value.replaceAll(/[^0-9]+/g, '') })
            }
          />
        </div>
      </div>

      <div
        className={classNames(
          value['continuousAuthenticationAgents'] ? s.SelectedOption : s.Option,
          'cursor-default'
        )}
      >
        <div>
          <header>{t('Continuous Authentication')}</header>
          <p>
            {t(
              'Service used to monitor agent engagement during the time they are logged in taking calls. The service will continously verify that the right person is behind the screen, that multiple faces are not detected at any point, that the location of the login is as expected, and that during a defined window of time they can be found behind the terminal.'
            )}
          </p>
          <div>
            $
            <NumberFormat
              value={agentAuthenticationCosts['continuousAuthenticationAgents'] * (1 - discount)}
              thousandSeparator=","
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
            />{' '}
            / {t('named agent')}
          </div>
        </div>
        <div>
          <NumberFormat
            value={value['continuousAuthenticationAgents'] || ''}
            placeholder={t('agents / mo')}
            thousandSeparator=","
            className={classNames(
              'text-right !bg-white text-sm w-[160px] border-gray-300 focus:border-gray-400 focus:ring-0'
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange({
                ...value,
                continuousAuthenticationAgents: +e.target.value.replaceAll(/[^0-9]+/g, ''),
              })
            }
          />
        </div>
      </div>

      <div className={classNames(s.Option, '!cursor-default')}>
        <div>
          <header>{t('SSO Integration')}</header>
          <p>
            {t(
              'A custom quote dependent on the SSO system and method of integration. This charge would scoped and applied for each SSO system the business uses.'
            )}
          </p>
          <div>{t('$0+ integration cost')}</div>
        </div>
      </div>
    </div>
  )
}

export default AgentAuthentication
