import { FC } from 'react'
import { Pipeline } from 'types'

import { t } from 'translation'

import DataPrivacy from 'components/organisms/Preview/DataPrivacy'
import Header from 'components/organisms/Preview/Header'
import Illustration from 'components/organisms/Preview/Illustration'
import LogoHeader from 'components/organisms/Preview/LogoHeader'
import SlateHTML from 'components/organisms/Preview/SlateHTML'

import s from './Preview.module.scss'

type Props = NonNullable<Pipeline['success']>

const SuccessScreenPreview: FC<Props> = ({ title, text }) => {
  return (
    <>
      <LogoHeader />
      <Header headline={t('Success!')} className={s.SuccessHeader}>
        {title || t('Great! Your identity has been verified!')}
      </Header>
      <Illustration type="success-screen" />
      <footer>
        {text && (
          <div className="text-xs">
            <SlateHTML value={JSON.parse(text)} />
          </div>
        )}
        <DataPrivacy />
      </footer>
    </>
  )
}

export default SuccessScreenPreview
