import { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useIframeSelector } from 'store'
import { IframeDeliveryMethods } from 'types'

import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import Footer from 'components/molecules/Footer'
import { useAPI } from 'hooks/useAPI'
import ls from 'pages/IFrames/Steps/shared/LayoutWithFooter.module.scss'
import { updateIframe } from 'thunks/api/iframes/update'

import DeliveryMethodList from './DeliveryMethodList'
import s from './index.module.scss'

const DeliveryMethods: FC = () => {
  const history = useHistory()

  const { iframe } = useIframeSelector()

  const [deliveryMethods, setDeliveryMethods] = useState<IframeDeliveryMethods>(
    iframe.deliveryMethods
  )

  const [error, setError] = useState<string>()

  const [update, { timer: languagesTimer }] = useAPI(updateIframe)
  const handleUpdateDeliveryMethods = useCallback(async () => {
    setError(undefined)
    if (!deliveryMethods || !iframe.id) return

    try {
      await update({
        id: iframe.id,
        deliveryMethods,
      })

      const phase = history.location.pathname.split('/')[3]
      const route = r.iframes[phase === 'create' ? 'create' : 'edit'].configure
      history.push(route.test(iframe.id))
    } catch (err) {
      console.error('Unable to update iframe delivery methods:', err)
    }
  }, [deliveryMethods, history, iframe.id, update])

  return (
    <div className={ls.Wrapper}>
      <div className={ls.ContentWrapper}>
        <div>
          <h1 className={s.Title}>{t('Delivery Methods')}</h1>
          <p className={s.Description}>
            {t('Configure which methods are available to agents for delivering pipeline requests.')}
          </p>

          <DeliveryMethodList
            deliveryMethods={deliveryMethods}
            setDeliveryMethods={setDeliveryMethods}
          />
        </div>
      </div>
      <div>
        <Footer
          alert={error && <Alert type="error">{error}</Alert>}
          actions={
            <>
              <Button
                isLoading={languagesTimer.isLoading}
                disabled={languagesTimer.isLoading}
                iconRight={languagesTimer.isLoading ? undefined : faArrowRight}
                onClick={handleUpdateDeliveryMethods}
              >
                {languagesTimer.isLoading ? t('Saving...') : t('Save & Continue')}
              </Button>
            </>
          }
        />
      </div>
    </div>
  )
}

export default DeliveryMethods
