import asyncThunk from 'util/asyncThunk'

export type Request = { id: string }

type Response = {
  clientId: string | undefined
}

export const retrieveClientCredentials = asyncThunk<Request, Response>({
  name: 'accounts/client-credentials',
  uri: ({ id }) => `/accounts/${id}/client-credentials`,
  orchestrator: false,
  stripId: true,
  throttle: false,
})
