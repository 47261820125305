import { HTTPArchived } from '@journeyid/agent/common/statuses'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

export type Response = {}

export const archiveBrandStyle = asyncThunk<Request, Response>({
  name: 'brand-styles/archive',
  method: 'DELETE',
  uri: ({ id }) => `/brand-styles/${id}`,
  successStatus: HTTPArchived,
  orchestrator: false,
  stripId: true,
})
