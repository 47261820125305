import { Pipeline } from 'types'

import { StageType } from '@journeyid/agent/types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  gatewayId: string
  stageType: StageType
}

type Response = Pipeline

export const addDestination = asyncThunk<Request, Response>({
  name: 'pipelines/add-destination',
  uri: ({ id }) => `/pipelines/${id}/destinations`,
  method: 'POST',
})
