import { FC } from 'react'

import { MetadataFor } from '@journeyid/agent/types'

import { t } from 'translation'

import s from './Header.module.scss'

type Props = Pick<MetadataFor<'credit-card-payment'>, 'headline' | 'subheading'>

const Header: FC<Props> = ({ headline, subheading }) => {
  return (
    <header className={s.Header}>
      <p className="std-sm-bold">{headline || t('Credit Card Purchase')}</p>
      <p className="std-xs">{subheading || t('Transaction #207169')}</p>
    </header>
  )
}

export default Header
