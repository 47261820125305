import { useCallback } from 'react'
import { UseFormMethods } from 'react-hook-form'

import { useAPI } from 'hooks/useAPI'
import { Response, updateBrandStyle } from 'thunks/api/branding/update'
import { Branding } from 'types/account'
import { APIErrorResponse } from 'util/error'

export type FormData = {
  primaryColor: string
  logo?: string
}

export default function useUpdateBrandStyle(
  brandStyleId: string,
  setError: UseFormMethods<FormData>['setError']
) {
  const onError = useCallback(
    (statusCode: number, error: APIErrorResponse) => {
      if (error.errors) {
        Object.entries(error.errors).forEach(([key, val]) => {
          setError(key as keyof FormData, { type: 'server', message: val })
        })
      }
    },
    [setError]
  )

  const [save, { timer }] = useAPI<Branding, Response>(updateBrandStyle, { onError })

  const update = useCallback(
    async (data: FormData) => {
      if (timer.isLoading) return
      if (data.logo === '') delete data.logo
      try {
        await save({
          id: brandStyleId,
          ...data,
        })
      } catch (err) {}
    },
    [brandStyleId, timer.isLoading, save]
  )

  return [update, { timer }] as const
}
