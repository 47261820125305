import asyncThunk from 'util/asyncThunk'

export type Request = {
  name: string
}

type Response = {
  id: string
  name: string
  slug: string
}

export const createGroup = asyncThunk<Request, Response>({
  name: 'groups/create',
  uri: '/groups',
  method: 'POST',
  orchestrator: false,
})
