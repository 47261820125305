import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch, useAuthSelector, useIframesSelector } from 'store'
import { t } from 'translation'

import { faPlus, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Header from 'components/molecules/AdminHeader'
import { useAPI } from 'hooks/useAPI'
import { listIFrames } from 'thunks/api/iframes/list'
import { resetIFrame } from 'thunks/reset-iframe'

import IframeList from './IframeList'
import s from './index.module.scss'

const IframeHomepage: FC = () => {
  const { accountId } = useAuthSelector()
  const { isLoaded, iframes } = useIframesSelector()
  const [search, setSearch] = useState('')

  const dispatch = useAppDispatch()
  const history = useHistory()
  const handleCreate = useCallback(async () => {
    await dispatch(resetIFrame())
    history.push(r.iframes.create.configure.new)
  }, [dispatch, history])

  const [iframeList] = useAPI(listIFrames)
  useEffect(() => {
    if (!accountId) return
    iframeList({ accountId })
  }, [accountId, iframeList])

  const filteredIframes = useMemo(
    () =>
      iframes.filter(
        ({ title, id }) =>
          id.startsWith(search) || title.toLowerCase().search(search.toLowerCase()) >= 0
      ),
    [iframes, search]
  )

  if (!isLoaded) {
    return (
      <div className="flex justify-center">
        <FontAwesomeIcon icon={faSpinnerThird} className="mt-32 text-gray-300" size="6x" spin />
      </div>
    )
  }

  return (
    <ContentWrapper>
      <Header title={t('IFrames')}>
        <button className={s.CreateUserBtn} onClick={handleCreate}>
          <FontAwesomeIcon icon={faPlus} />
          {t('Create new')}
        </button>
      </Header>

      <div className={s.ListWrapper}>
        <div>
          <div>
            {iframes.length ? (
              <IframeList iframes={filteredIframes} setSearch={setSearch} />
            ) : (
              <div className={s.EmptyIframes}>{t("You haven't created any iFrames yet.")}</div>
            )}
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default IframeHomepage
