import { FC, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import CodeBlock from 'components/molecules/CodeBlock'
import Field, { Option } from 'components/molecules/Field'
import randomString from 'util/random-string'

import codeTemplate from './codeTemplate'

type FormData = {
  redirectUrl: string
  customerId: string
  nonce: string
  state: string
}

const OAuthRequestBuilder: FC = () => {
  const { pipeline } = usePipelineSelector()

  const form = useForm<FormData>({
    defaultValues: {
      customerId: 'john-smith',
      nonce: randomString(10),
      state: 'my-state',
    },
  })
  const { handleSubmit, register, watch } = form

  const submit = (data: FormData) => {
    console.log(data)
  }

  const { redirectUrl, customerId, nonce, state } = watch([
    'redirectUrl',
    'customerId',
    'nonce',
    'state',
  ])

  const clientSecret = useMemo(() => {
    if (!pipeline.oauth?.secret) return `[${t('generate secret above')}]`
    if (pipeline.oauth.secret.secretKey) return pipeline.oauth.secret.secretKey
    return `${pipeline.oauth.secret.prefix}****-****-****-****-************`
  }, [pipeline.oauth])

  return (
    <>
      <h4 className="mb-4 std">{t('OAuth Request Builder')}</h4>
      <p className="mb-4">
        {t('Once the')}{' '}
        <a
          href="https://developer.journeyid.com/reference/agent-iframe-embedded"
          target="_blank"
          rel="noreferrer"
        >
          {t('Agent IFrame')}
        </a>{' '}
        {t(
          'is integrated into the agent desktop, this snippet of code is called when an agent answers a call.'
        )}
      </p>
      <FormProvider {...form}>
        <form onChange={handleSubmit(submit)}>
          <Field
            ref={register}
            name="redirectUrl"
            label={t('Redirect URL')}
            as="select"
            className="mb-4"
          >
            {pipeline.oauth?.redirectURIs && pipeline.oauth.redirectURIs.length ? (
              pipeline.oauth.redirectURIs.map((uri, idx) => (
                <Option key={`${idx}-${uri}`} value={uri}>
                  {uri}
                </Option>
              ))
            ) : (
              <Option value="https://webhook.site/xxxxxxxx">
                {t('No redirect URLs have been added above')}
              </Option>
            )}
          </Field>
          <Field
            ref={register}
            id="customerId"
            name="customerId"
            autoComplete="off"
            label={t('Customer Unique ID')}
            hint={t(
              '(optional) If you are initiating the OAuth flow for a specific user, specify their Unique ID here.'
            )}
            placeholder="john-smith"
            className="flex-grow mb-4"
            inputClassName="py-2 px-3"
          />
          <Field
            ref={register}
            id="nonce"
            name="nonce"
            autoComplete="off"
            label={t('Nonce')}
            placeholder="01e5d8f27da8"
            className="flex-grow mb-4"
            inputClassName="py-2 px-3"
          />
          <Field
            ref={register}
            id="state"
            name="state"
            autoComplete="off"
            label={t('State')}
            placeholder="my-state"
            className="flex-grow mb-8"
            inputClassName="py-2 px-3"
          />
        </form>
      </FormProvider>
      <CodeBlock
        template={codeTemplate(
          pipeline.key || '',
          redirectUrl ? redirectUrl : 'https://authorized.url/path',
          nonce,
          state,
          customerId
        )}
      />
    </>
  )
}
export default OAuthRequestBuilder
