import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import r from 'routes'
import { useAuthSelector } from 'store'

import { t } from 'translation'

import StageRow from 'components/atoms/StageRow'
import Header from 'components/molecules/AdminHeader'
import { idleTimer } from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import { openModal } from 'hooks/useModal'
import Nav from 'pages/Pipelines/Create/UseCase/components/Nav'
import PipelineTemplateModal from 'pages/Pipelines/Create/UseCase/components/PipelineTemplateModal'
import pipelineTemplates, {
  PipelineTemplate,
  TemplateCategory,
} from 'pages/Pipelines/Create/UseCase/pipeline-templates'
import { createPipeline } from 'thunks/api/pipelines/create'

import Column from './components/Column'
import LocalHeader from './components/Header'
import s from './index.module.scss'

type RouteParams = {
  id?: string
}

const UseCase: FC = () => {
  const history = useHistory()
  const { accountId } = useAuthSelector()

  const { id } = useParams<RouteParams>()
  const category: TemplateCategory = useMemo(() => {
    const category = pipelineTemplates.find(category => category.id === id)
    if (category) return category
    return pipelineTemplates[0]
  }, [id])

  const handleSelectCategory = useCallback(
    (categoryId: string) => {
      history.push(r.pipelines.create.useCase(categoryId))
    },
    [history]
  )

  const [pipeline, setPipeline] = useState<PipelineTemplate>()
  const handleSelectPipeline = useCallback((pipeline: PipelineTemplate) => {
    setPipeline(pipeline)
    openModal('PipelineTemplate')()
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [category])

  const [create, { timer }] = useAPI(createPipeline)
  const handleContinue = useCallback(async () => {
    if (!pipeline || !accountId) return
    const resp = await create({
      accountId,
      stageTypes: pipeline.stageTypes,
      title: pipeline.label,
      template: pipeline.id,
    })
    const uri = r.pipelines.create.configure.stage(resp.id, pipeline.stageTypes[0])
    history.push(uri)
  }, [accountId, pipeline, create, history])

  const handleBuildFromScratch = useCallback(async () => {
    if (!accountId) return
    const resp = await create({
      accountId,
      stageTypes: [],
    })
    const uri = r.pipelines.create.configure.stages(resp.id)
    history.push(uri)
  }, [accountId, create, history])

  return (
    <>
      <div id="use-case" className={s.UseCase}>
        <Header title={t('Pipelines')} backRoute={r.pipelines.root} />
        <div>
          <LocalHeader timer={timer} onBuildFromScratch={handleBuildFromScratch} />
          <Nav
            templates={pipelineTemplates}
            selected={id || pipelineTemplates[0].id}
            onSelect={handleSelectCategory}
          />
          <main>
            <ul>
              {category.pipelines.map(pipeline => (
                <Column
                  key={pipeline.id}
                  id={pipeline.id}
                  title={pipeline.label}
                  description={pipeline.description}
                  timer={timer.id === category.id ? timer : idleTimer}
                  onClick={() => handleSelectPipeline(pipeline)}
                >
                  {pipeline.stageTypes.map(stageType => (
                    <StageRow key={stageType} stageType={stageType} />
                  ))}
                </Column>
              ))}
            </ul>
          </main>
        </div>
      </div>

      <PipelineTemplateModal pipeline={pipeline} timer={timer} onContinue={handleContinue} />
      <ReactTooltip effect="solid" className="max-w-[300px]" />
    </>
  )
}

export default UseCase
