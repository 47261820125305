import { FC } from 'react'
import { Link } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import IntegrationLayout from '../IntegrationLayout'
import IncomingCallSimulation from './components/IncomingCallSimulator'
import SessionReference from './components/SessionReference'

const IVR: FC = () => {
  const { pipeline } = usePipelineSelector()

  return (
    <IntegrationLayout
      id="ivr-page"
      title={t('IVR or IVA with Agent IFrame')}
      description={
        <>
          {t('Integrating into an IVR or IVA is very similar to a general')}{' '}
          <Link to={r.pipelines.edit.integration.type(pipeline.id, 'api')} className="underline">
            {t('API integration')}
          </Link>
          {t(', but with the addition of being able to see pipeline results in the Agent IFrame.')}
        </>
      }
    >
      <SessionReference />
      <hr />
      <IncomingCallSimulation />
    </IntegrationLayout>
  )
}

export default IVR
