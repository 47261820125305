import { EnrollmentType } from 'types/user'
import asyncThunk from 'util/asyncThunk'
import { HTTPProcessed } from 'util/statuses'

export type Request = {}

export type Response = Array<{
  id: string
  type: 'customer'
  uniqueId: string
  firstName: string | undefined
  lastName: string | undefined
  phoneNumbers: string[]
  email: string | undefined
  enrollments: Array<{
    type: EnrollmentType
  }>
}>

export const exportCustomers = asyncThunk<Request, Response>({
  name: 'customers/export',
  uri: '/customers/export',
  successStatus: HTTPProcessed,
  orchestrator: false,
})
