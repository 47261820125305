import cogoToast from 'cogo-toast'
import { FC, useCallback, useState } from 'react'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import useLeaveAccount from 'hooks/api/authentication/useLeaveAccount'
import useSwitchAccount from 'hooks/api/authentication/useSwitchAccount'

import s from './AccountListItem.module.scss'

type Account = {
  id: string
  name: string
  isActive: boolean
}

type Props = {
  account: Account
}

const AccountListItem: FC<Props> = ({ account }) => {
  const [isShowLeavingConfirm, setIsShowLeavingConfirm] = useState(false)

  const [leave, { timer: leaveTimer }] = useLeaveAccount()
  const [switchAccount, { timer: switchTimer }] = useSwitchAccount()

  const handleSwitch = useCallback(async () => {
    await switchAccount(account)
    cogoToast.success(t('Switched to {{account}}', { account: account.name }))
  }, [account, switchAccount])

  return (
    <li>
      <p> {account.name}</p>
      <div className={s.Btns}>
        {account.isActive ? (
          <button disabled className="!bg-white">
            {t('Current account')}
          </button>
        ) : isShowLeavingConfirm ? (
          <>
            <button
              className={s.ConfirmBtn}
              disabled={leaveTimer.isLoading}
              onClick={() => {
                leave(account).then(() => setIsShowLeavingConfirm(false))
              }}
            >
              {leaveTimer.isLoading && (
                <FontAwesomeIcon icon={faSpinnerThird} spin className={s.LoadingIcon} />
              )}
              {t('Confirm')}
            </button>
            <button
              className={s.CancelBtn}
              disabled={leaveTimer.isLoading}
              onClick={() => setIsShowLeavingConfirm(false)}
            >
              {t('Cancel')}
            </button>
          </>
        ) : (
          <>
            <button className={s.SwitchBtn} disabled={switchTimer.isLoading} onClick={handleSwitch}>
              {switchTimer.isLoading && (
                <FontAwesomeIcon icon={faSpinnerThird} spin className={s.LoadingIcon} />
              )}
              {t('Switch')}
            </button>
            <button onClick={() => setIsShowLeavingConfirm(true)}>{t('Leave')}</button>
          </>
        )}
      </div>
    </li>
  )
}

export default AccountListItem
