import { forwardRef, useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { Iframe } from 'types'

import Field, { Option } from 'components/molecules/Field'
import { useAPI } from 'hooks/useAPI'
import { listIFrames } from 'thunks/api/iframes/list'

type Props = {
  label: string
  name: string
  error: FieldError | undefined
  value: string
  onChange: (value: string) => void
}

const IFrameSelector = forwardRef<HTMLInputElement, Props>(
  ({ label, name, error, value, onChange }, ref) => {
    const [list] = useAPI(listIFrames)

    const [iframes, setIframes] = useState<Pick<Iframe, 'id' | 'title'>[]>()
    useEffect(() => {
      list({ published: true }).then(iframes => {
        Object.values(iframes).sort((l, r) => l.title.localeCompare(r.title))
        setIframes(iframes)
      })
    }, [list])

    return (
      <Field
        key={iframes?.length}
        ref={ref}
        as="select"
        label={label}
        name={name}
        placeholder=""
        error={error}
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        {(iframes ?? []).map(iframe => (
          <Option key={iframe.id} value={iframe.id}>
            {iframe.title}
          </Option>
        ))}
      </Field>
    )
  }
)

export default IFrameSelector
