import { FC } from 'react'

import { t } from 'translation'

import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Toggle from 'components/atoms/Toggle'
import DriversLicensePreview from 'components/templates/stages/DriversLicense.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const DriversLicense: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('drivers-license')

  return (
    <CreateSettings
      stageType="drivers-license"
      preview={<DriversLicensePreview />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <p className="text-gray-500 std-sm">
          {t(
            "All Driver's License scans will include OCR (reading the text) for the data on the front and scanning the barcode on the back. The two scans are compared against each other to ensure data integrity. A basic level of physical document verification is also performed."
          )}
        </p>
        <Setting
          title={t('Document Verification')}
          description={t(
            'Machine learning algorithms will be applied which have a high probability of detecting fake IDs (printed or photographed for example) as well as manipulated IDs (the photo was changed for example).'
          )}
        >
          <Toggle
            enabled={stage.metadata.documentVerification}
            className="mt-2"
            onChange={enabled => handleMetadata('documentVerification', enabled)}
          >
            {t('Enable advanced document verification')}
          </Toggle>
        </Setting>
        <Setting
          title={t('Data Verification')}
          description={t(
            "For 39 US states, we can do a lookup on the data scanned off the Driver's License to ensure it's a valid driver's license.  For the complete list, please contact us. For the other 11 US states and for Driver's Licenses from other countries, we attempt to verify the data against public databases and other proprietary sources."
          )}
        >
          <Toggle
            enabled={stage.metadata.dataVerification}
            className="mt-2"
            onChange={enabled => handleMetadata('dataVerification', enabled)}
          >
            {t('Enable scanned data verification')}
          </Toggle>
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default DriversLicense
