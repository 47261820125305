import { FC } from 'react'

import { t } from 'translation'

import LogoHeader from 'components/organisms/Preview/LogoHeader'

const DocuSignPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <main className="px-12 pt-8 text-center">
        {t("DocuSign isn't previewable until Enterprise configurations are complete.")}
      </main>
    </>
  )
}

export default DocuSignPreview
