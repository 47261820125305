import classNames from 'classnames'
import React, { HTMLProps } from 'react'

import {
  faBold,
  faCode,
  faH1,
  faH2,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faUnderline,
  faUnlink,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './index.module.scss'

const icons = {
  bold: faBold,
  italic: faItalic,
  underline: faUnderline,
  code: faCode,
  link: faLink,
  unlink: faUnlink,
  h1: faH1,
  h2: faH2,
  'bulleted-list': faListUl,
  'numbered-list': faListOl,
} as const

export type ButtonFormat = keyof typeof icons

interface BaseProps {
  format: ButtonFormat
  className?: string
}

type ButtonProps = {
  active: boolean
} & BaseProps &
  Omit<HTMLProps<HTMLButtonElement>, 'type'>

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ format, active, className, ...rest }, ref) => (
    <button
      ref={ref}
      className={classNames(
        {
          [styles.ButtonActive]: active,
          [styles.ButtonInactive]: !active,
        },
        className
      )}
      {...rest}
    >
      <FontAwesomeIcon icon={icons[format]} />
    </button>
  )
)
