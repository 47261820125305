import { Pipeline } from 'types'

import { createSlice } from '@reduxjs/toolkit'

import { addDestination } from 'thunks/api/pipelines/add-destination'
import { addStage } from 'thunks/api/pipelines/add-stage'
import { clearPipeline } from 'thunks/api/pipelines/clear'
import { createPipeline } from 'thunks/api/pipelines/create'
import { createSecret } from 'thunks/api/pipelines/create-secret'
import { updateOAuth } from 'thunks/api/pipelines/oauth'
import { publishPipeline } from 'thunks/api/pipelines/publish'
import { removeDestination } from 'thunks/api/pipelines/remove-destination'
import { removeStage } from 'thunks/api/pipelines/remove-stage'
import { reorderStages } from 'thunks/api/pipelines/reorder-stages'
import { retrievePipeline } from 'thunks/api/pipelines/retrieve'
import { revokeSecret } from 'thunks/api/pipelines/revoke-secret'
import { updateSAML } from 'thunks/api/pipelines/saml'
import { deleteSAML } from 'thunks/api/pipelines/saml_delete'
import { updatePipeline } from 'thunks/api/pipelines/update'
import { updateBrandStyle } from 'thunks/api/pipelines/update-brand-style'
import { updateDeliveryMethods } from 'thunks/api/pipelines/update-delivery-methods'
import { updateStage } from 'thunks/api/pipelines/update-stage'
import { updateStageTypes } from 'thunks/api/pipelines/update-stage-types'
import { updateSuccessScreen } from 'thunks/api/pipelines/update-success-screen'
import { updateWebhookURLs } from 'thunks/api/pipelines/webhook-urls'

function defaultPipeline(): Pipeline {
  return {
    id: '',
    key: undefined,
    title: '',
    stages: [],
    success: {
      title: `Thanks! You're all done!`,
      text: '',
    },
  }
}

export type PipelineState = {
  pipeline: Pipeline
}

const initialState: PipelineState = {
  pipeline: defaultPipeline(),
}

const slice = createSlice({
  name: 'pipeline',
  initialState,
  reducers: {},
  extraReducers: builder => {
    ;[
      createPipeline,
      retrievePipeline,
      updateStageTypes,
      updatePipeline,
      updateBrandStyle,
      updateDeliveryMethods,
      updateStage,
      updateSuccessScreen,
      updateWebhookURLs,
      updateOAuth,
      updateSAML,
      deleteSAML,
      addStage,
      removeStage,
      reorderStages,
      publishPipeline,
      addDestination,
      removeDestination,
    ].map(thunk =>
      builder.addCase(thunk.fulfilled, (_, { payload }) => {
        return {
          pipeline: payload,
        }
      })
    )
    builder.addCase(createSecret.fulfilled, (state, { payload }) => {
      if (!state.pipeline.oauth) {
        state.pipeline.oauth = {
          continuousAuth: false,
          redirectURIs: [],
        }
      }
      state.pipeline.oauth.secret = payload.secret
    })
    builder.addCase(revokeSecret.fulfilled, state => {
      if (!state.pipeline.oauth) return
      state.pipeline.oauth.secret = undefined
    })
    builder.addCase(clearPipeline.fulfilled, state => {
      state.pipeline = defaultPipeline()
    })
  },
})

export default slice
export const actions = slice.actions
