import { Editor, Element as SlateElement, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { ButtonFormat } from './_Button'

export const LIST_TYPES = ['numbered-list', 'bulleted-list'] as ButtonFormat[]

export const toggleBlock = (editor: ReactEditor, format: ButtonFormat) => {
  const isActive = isBlockActive(editor, format)
  const isList = (LIST_TYPES as ButtonFormat[]).includes(format)

  Transforms.unwrapNodes(editor, {
    // @ts-ignore
    match: n => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  })
  const newProperties: Partial<SlateElement> = {
    // @ts-ignore
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const toggleMark = (editor: ReactEditor, format: ButtonFormat) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isBlockActive = (editor: ReactEditor, format: ButtonFormat) => {
  // @ts-ignore
  const [match] = Editor.nodes(editor, {
    // @ts-ignore
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

export const isMarkActive = (editor: ReactEditor, format: ButtonFormat) => {
  const marks = Editor.marks(editor)
  // @ts-ignore
  return marks ? marks[format] === true : false
}
