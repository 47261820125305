import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

type Response = {
  id: string
  archivedAt: string
  archivedBy: string
}

export const archivePipeline = asyncThunk<Request, Response>({
  name: 'pipelines/archive',
  method: 'DELETE',
  uri: ({ id }) => `/pipelines/${id}`,
  stripId: true,
})
