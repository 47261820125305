import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { retrievePipeline } from 'thunks/api/pipelines/retrieve'

type RouteParams = {
  id?: string
}

export default function useEnsurePipeline() {
  const { id } = useParams<RouteParams>()
  const { pipeline } = usePipelineSelector()

  const history = useHistory()
  const [retrieve] = useAPI(retrievePipeline)
  useEffect(() => {
    if (pipeline.id === id) return

    if (!id) {
      history.push(r.pipelines.create.useCase())
      return
    }

    retrieve({ id })
  }, [pipeline.id, id, retrieve, history])

  /**
   * Redirect based on whether or not the pipeline is published
   */
  const location = useLocation()
  useEffect(() => {
    if (!pipeline.id) return

    // Don't redirect when publishing
    const wasPublished = decodeURI(window.location.search).charAt(1) === '✓'
    if (wasPublished) return

    const phase = location.pathname.split('/')[2]
    if (phase === 'create' && pipeline.key) {
      history.push(r.pipelines.edit.root(pipeline.id))
    } else if (phase === 'edit' && !pipeline.key) {
      history.push(r.pipelines.create.root(pipeline.id))
    }
  }, [pipeline, location, history])

  return pipeline.id === id
}
