import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { faArchive, faCopy } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import NavItem from 'pages/Pipelines/Edit/components/EditSidebar/NavItem'
import TitleForm from 'pages/Pipelines/Edit/components/EditSidebar/TitleForm'
import ArchivePipelineModal from 'pages/Pipelines/Edit/settings/Archive/ArchivePipelineModal'
import DuplicatePipelineModal from 'pages/Pipelines/Edit/settings/DuplicatePipeline/DuplicatePipelineModal'
import { archivePipeline } from 'thunks/api/pipelines/archive'
import { stageTitleMap } from 'util/stage-titles'

import s from './index.module.scss'

const EditSidebar: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { pipeline } = usePipelineSelector()

  const activePage = location.pathname.split('/')[5]

  const [archive, { timer }] = useAPI(archivePipeline)
  const handleArchive = useCallback(async () => {
    await archive({
      id: pipeline.id,
    })
    history.push(r.pipelines.root)
  }, [pipeline, archive, history])

  if (!pipeline) return null

  return (
    <>
      <div id="edit-sidebar" className={s.Sidebar}>
        <header>
          <h2 className="std">{t('Edit a pipeline')}</h2>
          <p className="std-base">
            {t('Your Pipeline has been published, but you can still make some changes.')}
          </p>
          <TitleForm />
        </header>
        <ul>
          <h4 className="std">
            <label>{t('Pipeline Actions')}</label>
          </h4>
          {pipeline.stages.map(stage => (
            <NavItem
              key={stage.type}
              isActive={activePage === stage.type}
              onClick={() =>
                history.push(r.pipelines.edit.configure.stage(pipeline.id, stage.type))
              }
            >
              {stageTitleMap[stage.type]}
            </NavItem>
          ))}
          <NavItem
            isActive={activePage === 'success-screen'}
            onClick={() => history.push(r.pipelines.edit.configure.successScreen(pipeline.id))}
          >
            {t('Success Screen')}
          </NavItem>
          <li>
            <h4 className="std">
              <label>{t('Delivery')}</label>
            </h4>
          </li>
          <NavItem
            isActive={activePage === 'sms'}
            isComplete={!!pipeline.sms}
            onClick={() => history.push(r.pipelines.edit.delivery.method(pipeline.id, 'sms'))}
          >
            {t('Text Message')}
          </NavItem>
          <NavItem
            isActive={activePage === 'email'}
            isComplete={!!pipeline.email}
            onClick={() => history.push(r.pipelines.edit.delivery.method(pipeline.id, 'email'))}
          >
            {t('Email')}
          </NavItem>
          <NavItem
            isActive={activePage === 'push-notification'}
            isComplete={!!pipeline.pushNotification}
            onClick={() =>
              history.push(r.pipelines.edit.delivery.method(pipeline.id, 'push-notification'))
            }
          >
            {t('Push Notification')}
          </NavItem>
          <li>
            <h4 className="std">
              <label>{t('Settings')}</label>
            </h4>
          </li>
          <NavItem
            isActive={activePage === 'branding'}
            onClick={() => history.push(r.pipelines.edit.settings.setting(pipeline.id, 'branding'))}
          >
            {t('Branding')}
          </NavItem>
          <NavItem
            isActive={activePage === 'webhooks'}
            onClick={() => history.push(r.pipelines.edit.settings.setting(pipeline.id, 'webhooks'))}
          >
            {t('Webhook URLs')}
          </NavItem>
          <NavItem
            isActive={activePage === 'data-delivery'}
            onClick={() =>
              history.push(r.pipelines.edit.settings.setting(pipeline.id, 'data-delivery'))
            }
          >
            {t('Data Delivery')}
          </NavItem>
          <NavItem
            isActive={activePage === 'internal-title'}
            onClick={() =>
              history.push(r.pipelines.edit.settings.setting(pipeline.id, 'internal-title'))
            }
          >
            {t('Internal Title')}
          </NavItem>
          <li>
            <h4 className="std">
              <label>{t('Integration')}</label>
            </h4>
          </li>
          <NavItem
            isActive={activePage === 'api'}
            onClick={() => history.push(r.pipelines.edit.integration.type(pipeline.id, 'api'))}
          >
            {t('API')}
          </NavItem>
          <NavItem
            isActive={activePage === 'ivr'}
            onClick={() => history.push(r.pipelines.edit.integration.type(pipeline.id, 'ivr'))}
          >
            {t('IVR or IVA')}
          </NavItem>
          {/* <NavItem
          isActive={activePage === 'chatbot'}
          onClick={() => history.push(r.pipelines.edit.integration.type(pipeline.id, 'chatbot'))}
        >
          Chatbot
        </NavItem> */}
          <NavItem
            isActive={activePage === 'oidc'}
            onClick={() => history.push(r.pipelines.edit.integration.type(pipeline.id, 'oidc'))}
          >
            {t('Single Sign-On: OIDC')}
          </NavItem>
          <NavItem
            isActive={activePage === 'saml2'}
            onClick={() => history.push(r.pipelines.edit.integration.type(pipeline.id, 'saml2'))}
          >
            {t('Single Sign-On: SAML2')}
          </NavItem>
          <li className={classNames(s.Action, '!mt-4')}>
            <span onClick={openModal('DuplicatePipeline')}>
              <FontAwesomeIcon icon={faCopy} />
              <span>{t('Duplicate')}</span>
            </span>
          </li>
          <li className={classNames(s.Action, '!mt-0')}>
            <span onClick={openModal('ArchivePipeline')}>
              <FontAwesomeIcon icon={faArchive} />
              <span>{t('Archive')}</span>
            </span>
          </li>
        </ul>
      </div>
      <DuplicatePipelineModal />
      <ArchivePipelineModal
        pipeline={pipeline}
        timer={timer}
        onArchive={handleArchive}
        onCancel={closeModal}
      />
    </>
  )
}

export default EditSidebar
