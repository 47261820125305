import { FC } from 'react'

import AddressCollectionPreview from 'components/templates/stages/AddressCollection.Preview'
import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'

const AddressCollection: FC = () => {
  return <NoSettingsStage stageType="address-collection" preview={AddressCollectionPreview} />
}

export default AddressCollection
