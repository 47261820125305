import asyncThunk from 'util/asyncThunk'

type Request = {
  role: 'supervisor' | 'admin' | 'owner'
  adminId: string
}

type Response = {
  id: string
  role: 'supervisor' | 'admin' | 'owner'
  groups: {
    id: string
    name: string
  }[]
}

export const updateAdminRole = asyncThunk<Request, Response>({
  name: 'admins/role',
  uri: '/admins/role',
  method: 'PUT',
  orchestrator: false,
})
