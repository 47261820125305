import { FC } from 'react'

import { t } from 'translation'

const SessionReference: FC = () => (
  <>
    <h3 className="mb-1 std">{t('The Session Reference')}</h3>
    <p className="mb-8 std">
      {t(
        'In the IVR & Agent IFrame scenario, there are two channels involved: voice and digital. The way that these two channels are connected are through the Session Reference.'
      )}
      <br />
      <br />
      {t(
        'Imagine a customer calls into the call center and requests to speak to an agent about increasing the credit limit on one of their credit cards. While navigating the IVR, a request is sent to the user to authenticate themselves via facial biometrics, which the customer does successfully.'
      )}
      <br />
      <br />
      {t(
        "Now the call is transferred to a live agent. The agent must be able to see that the customer was already authenticated, otherwise they'd need to authenticate them twice, which is a bad customer experience."
      )}
      <br />
      <br />
      {t(
        'The Session Reference is how the facial authentication and the Agent IFrame are tied together. By using the same reference, the Agent will see the results of all prior pipeline executions when the incoming call is answered.'
      )}
      <br />
      <br />
      {t(
        "Furthermore, any pipelines executed with the live agent will also be associated to the same session reference. This faciliates two things: if the call is transferred to a new agent, they'll see a complete history of operations performed, as well as having a single audit trail for that customer's call."
      )}
      <br />
      <br />
      {t(
        'A good value for the session is the call identifier that already exists in the telephony stack. This way the Journey audit trail can be tied directly to the call.'
      )}
      <br />
      <br />
      {t('Full integration documentation can be found at')}{' '}
      <a href="https://developer.journeyid.com/" target="_blank" rel="noreferrer">
        https://developer.journeyid.com
      </a>
    </p>
  </>
)

export default SessionReference
