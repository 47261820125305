import { useCallback } from 'react'
import { ErrorOption } from 'react-hook-form'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { createAgent, Request } from 'thunks/api/agents/create'
import { APIErrorResponse } from 'util/error'

export type ValidationErrorCallback = (name: string, error: ErrorOption) => void

export function useCreateAgent(onError: ValidationErrorCallback) {
  const handleValidationError = useCallback(
    (statusCode: number, error: APIErrorResponse) => {
      if (error.errors) {
        Object.entries(error.errors).forEach(([key, val]) => {
          onError(key, { type: 'server', message: val })
        })
      }
    },
    [onError]
  )

  const [run, { timer }] = useAPI(createAgent, { onError: handleValidationError })
  const { accountId } = useAuthSelector()

  const create = useCallback(
    async (req: Omit<Request, 'accountId' | 'language'>) => {
      if (!accountId) return
      return await run({ ...req, language: 'en' })
    },
    [run, accountId]
  )

  return [create, { timer }] as const
}
