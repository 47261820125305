import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { Pipeline } from 'types'

import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { ActionTimer } from 'hooks/useActionTimer'

import s from './index.module.scss'

type Props = {
  pipelines: Pick<Pipeline, 'id' | 'title'>[]
  timer: ActionTimer
  className?: string
  onReorder: (fromIndex: number, toIndex: number) => void
  onRemove: (pipelineId: string) => void
}

const PipelineList: FC<Props> = ({ pipelines, timer, className, onReorder, onRemove }) => {
  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination || destination.index === source.index) {
        return
      }
      onReorder(source.index, destination.index)
    },
    [onReorder]
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <ul
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classNames(s.PipelineList, className)}
          >
            {pipelines.map((pipeline, idx) => (
              <Draggable key={pipeline.id} draggableId={pipeline.id} index={idx}>
                {provided => (
                  <li key={pipeline.id} ref={provided.innerRef} {...provided.draggableProps}>
                    <div {...provided.dragHandleProps}>
                      <FontAwesomeIcon icon={faGripDotsVertical} />
                    </div>
                    <div>
                      <label>{pipeline.title}</label>
                    </div>
                    <div>
                      <button
                        disabled={timer.isLoading && timer.id === pipeline.id}
                        onClick={() => onRemove(pipeline.id)}
                      >
                        {timer.isLoading && timer.id === pipeline.id
                          ? t('removing...')
                          : t('remove')}
                      </button>
                    </div>
                  </li>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default PipelineList
