import { FC } from 'react'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import DataPrivacy from 'components/organisms/Preview/DataPrivacy'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'
import Illustration from 'components/organisms/Preview/Illustration'
import { FaceAuthenticationStage } from '@journeyid/agent/types'

type Props = FaceAuthenticationStage

const FaceAuthenticationPreview: FC<Props> = () => {
  return (
    <>
      <LogoHeader />
      <Header headline="Face Scan Authentication">
        {t("Using the camera, we'll take a 3D scan of your face")}
      </Header>
      <Illustration type="facial-biometrics" />
      <footer>
        <DataPrivacy />
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Begin Authentication')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default FaceAuthenticationPreview
