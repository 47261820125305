import classNames from 'classnames'
import { FC, useState } from 'react'
import { t } from 'translation'

import { faFaceAngry, faFaceGrinWide, faFaceMeh, faSmile } from '@fortawesome/pro-regular-svg-icons'
import {
  faFaceAngry as faFaceAngrySolid,
  faFaceGrinWide as faFaceGrinWideSolid,
  faFaceMeh as faFaceMehSolid,
  faSmile as faSmileSolid,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PrimaryButton from 'components/atoms/Button'
import Textarea from 'components/atoms/Textarea'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

type Rating = 'angry' | 'meh' | 'happy' | 'thrilled'

const SurveyPreview: FC = () => {
  const [rating, setRating] = useState<Rating>()

  return (
    <>
      <LogoHeader />
      <Header>{t(`How was your experience?`)}</Header>
      <main className="flex items-center px-4 pt-16">
        <ul className="flex space-x-4">
          <li>
            <FontAwesomeIcon
              icon={rating === 'angry' ? faFaceAngrySolid : faFaceAngry}
              className={classNames(
                'h-12 cursor-pointer',
                rating === 'angry' ? 'text-red-600' : 'text-gray-500'
              )}
              onClick={() => setRating('angry')}
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={rating === 'meh' ? faFaceMehSolid : faFaceMeh}
              className={classNames(
                'h-12 cursor-pointer',
                rating === 'meh' ? 'text-blue-600' : 'text-gray-500'
              )}
              onClick={() => setRating('meh')}
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={rating === 'happy' ? faSmileSolid : faSmile}
              className={classNames(
                'h-12 cursor-pointer',
                rating === 'happy' ? 'text-green-600' : 'text-gray-500'
              )}
              onClick={() => setRating('happy')}
            />
          </li>
          <li>
            <FontAwesomeIcon
              icon={rating === 'thrilled' ? faFaceGrinWideSolid : faFaceGrinWide}
              className={classNames(
                'h-12 cursor-pointer',
                rating === 'thrilled' ? 'text-green-600' : 'text-gray-500'
              )}
              onClick={() => setRating('thrilled')}
            />
          </li>
        </ul>
      </main>
      <div className="px-4">
        <label className="block mb-1 text-base font-sm">{t('Any thoughts to share?')}</label>
        <Textarea rows={4} className="resize-none !border-solid" />
      </div>
      <footer>
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Submit Feedback')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default SurveyPreview
