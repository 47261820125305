import { ChangeEvent, forwardRef, HTMLProps, ReactNode, useMemo } from 'react'

import s from './index.module.scss'

type Props = {
  name: string
  type: 'radio' | 'checkbox'
  value?: string
  children: ReactNode
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
} & Omit<HTMLProps<HTMLInputElement>, 'label' | 'onChange'>

const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { name, type, value, children, ...rest } = props

  const id = useMemo(() => (value ? `${name}-${value}` : name), [name, value])

  return (
    <label htmlFor={id} className={s.RadioCheckbox}>
      <input ref={ref} type={type} id={id} name={name} value={value} {...rest} />
      {children}
    </label>
  )
})

export default Checkbox
