import { FC } from 'react'

import { t } from 'translation'

import LogoHeader from 'components/organisms/Preview/LogoHeader'

const FacialAuthenticationPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <main className="px-12 pt-8 text-center">
        {t('2D Facial Authentication is a desktop only pipeline action')}
      </main>
    </>
  )
}

export default FacialAuthenticationPreview
