import { Pipeline } from 'types'

import { StageType } from '@journeyid/agent/types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  stageTypes: StageType[]
}

type Response = Pipeline

export const updateStageTypes = asyncThunk<Request, Response>({
  name: 'pipelines/update-stage-types',
  method: 'PUT',
  uri: '/pipelines/stages/types',
})
