import { FC } from 'react'

import FacialEnrollmentPreview from 'components/templates/stages/FacialEnrollment.Preview'
import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'

const FacialEnrollment: FC = () => {
  return <NoSettingsStage stageType="facial-enrollment" preview={FacialEnrollmentPreview} />
}

export default FacialEnrollment
