import { FormEvent, useCallback, useRef } from 'react'
import { v4 as uuid } from 'uuid'

export type Payload = {
  event: 'start-session'
  session: {
    externalRef: string
  }
  user: {
    uniqueId: string
    firstName?: string
    lastName?: string
    phoneNumber?: string
    email?: string
  }
}

export default function useIFrame(phoneNumber: string | undefined) {
  const ref = useRef<HTMLIFrameElement>(null)

  const handlePostMessage = useCallback((payload: Payload) => {
    console.log(JSON.stringify(payload, null, 2))
    ref.current?.contentWindow?.postMessage(payload, `https://${_env_.IFRAME_ORIGIN}`)
  }, [])

  const handleStartCall = useCallback(
    (e: FormEvent) => {
      e.preventDefault()

      if (!phoneNumber) return

      handlePostMessage({
        event: 'start-session',
        session: {
          externalRef: uuid(),
        },
        user: {
          phoneNumber: `+${phoneNumber}`,
          uniqueId: phoneNumber,
        },
      })
    },
    [phoneNumber, handlePostMessage]
  )

  return { ref, phoneNumber, handleStartCall, handlePostMessage }
}
