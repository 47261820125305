import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { faChevronDown, faEllipsisH, faStar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { ReactComponent as ArrowIcon } from './gmail-left-arrows.svg'
import gmail from './gmail.png'
import styles from './index.module.scss'

type Props = {
  subject: string
  from: string
  className?: string
  children: ReactNode
}

const Gmail: FC<Props> = ({ subject, from, className, children }) => {
  return (
    <>
      <img src={gmail} alt="" className="rounded-[40px]" />
      <div className="absolute inset-0">
        <div className="absolute top-[80px] text-lg text-[#5F6369] tracking-tight leading-7 w-full px-4">
          <div className="flex flex-row">
            <div className={styles.Subject}>
              {subject}
              {/* <span>Inbox</span> */}
            </div>
            <div className="flex items-center pr-4">
              <FontAwesomeIcon icon={faStar} size="xs" />
            </div>
          </div>
          <div className="flex mt-2 space-x-4">
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 font-semibold text-white rounded-full bg-theme">
                {from[0]}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-base font-semibold">
                {from}
                {/* <span className="ml-2.5 text-xs text-[#7C7F84] whitespace-nowrap">
                  {format(new Date(), 'MMM d')}
                </span> */}
              </div>
              <div className="flex items-center text-sm">
                {t('to me')}
                <FontAwesomeIcon icon={faChevronDown} size="xs" className="relative ml-2 top-px" />
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <ArrowIcon />
              <FontAwesomeIcon icon={faEllipsisH} />
            </div>
          </div>
          <div className={classNames('flex flex-col p-4', className)}>{children}</div>
        </div>
      </div>
    </>
  )
}

export default Gmail
