import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useBrandingSelector } from 'store'
import { t } from 'translation'

import JourneyLogo from 'components/atoms/JourneyLogo'
import Loading from 'components/atoms/Loading'
import useListBrandStyles from 'hooks/api/branding/useListBrandStyles'
import useSetDefaultBrandStyle from 'hooks/api/branding/useSetDefaultBrandStyle'

import s from './BrandStyleList.module.scss'

const BrandStyleList: FC = () => {
  const history = useHistory()

  const { branding } = useBrandingSelector()
  const [brandStyles] = useListBrandStyles()

  const [setDefaultBrandStyle, { timer }] = useSetDefaultBrandStyle()

  const setDefaultBranding = useCallback(
    (id: string) => setDefaultBrandStyle({ id }),
    [setDefaultBrandStyle]
  )

  return (
    <div className={s.Wrapper}>
      <ul className={s.List}>
        {brandStyles ? (
          brandStyles.map((brandStyle, i) => (
            <li
              key={i}
              className={classNames('border-2', {
                'border-theme': brandStyle.id === branding?.id,
                'border-transparent': brandStyle.id !== branding?.id,
              })}
            >
              <div className="flex items-center h-16 py-3">
                {brandStyle.logo ? (
                  <img className="h-full" src={brandStyle.logo} alt="Logo" />
                ) : (
                  <JourneyLogo color={brandStyle.primaryColor} />
                )}
              </div>
              <br />
              <div className={s.Btns}>
                <button
                  className={classNames({
                    '!text-gray-600': brandStyle.id === branding?.id,
                  })}
                  onClick={() => {
                    !timer.isLoading &&
                      brandStyle.id !== branding?.id &&
                      setDefaultBranding(brandStyle.id)
                  }}
                >
                  {brandStyle.id === branding?.id
                    ? t('Default style')
                    : timer.id === brandStyle.id
                    ? t('Saving...')
                    : t('Set as default')}
                </button>
                <button
                  onClick={() => {
                    history.push(r.brandStyles.show(brandStyle.id))
                  }}
                >
                  {t('Edit')}
                </button>
              </div>
            </li>
          ))
        ) : (
          <Loading className="w-full mt-32" size="5x" />
        )}
      </ul>
    </div>
  )
}

export default BrandStyleList
