import cogoToast from 'cogo-toast'
import { FC, useCallback } from 'react'
import { t } from 'translation'

import { faSpinnerThird, faUserSecret } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAPIError } from '@journeyid/agent/common/errors'

import Button from 'components/atoms/Button'
import useCurrentAccount from 'hooks/api/useCurrentAccount'
import { useAPI } from 'hooks/useAPI'
import { morphIntoAgent } from 'thunks/api/agents/morph'

type Props = {
  agentId: string
}

const MorphButton: FC<Props> = ({ agentId }) => {
  const account = useCurrentAccount()

  const [morph, { timer }] = useAPI(morphIntoAgent)
  const handleMorph = useCallback(async () => {
    try {
      const { token } = await morph({ id: agentId })

      const url = `${_env_.AUTH_ORIGIN}/dashboard/sso?token=${token}`
      console.log('morph url', url)
      window.open(url, '_blank')
    } catch (err) {
      if (isAPIError(err)) {
        cogoToast.error(t(err.message))
      } else {
        cogoToast.error('Something went wrong.')
      }
    }
  }, [agentId, morph])

  if (!account?.hasSSO) return null

  return (
    <Button variant="secondary" className="ml-4" onClick={handleMorph}>
      {timer.isLoading ? (
        <FontAwesomeIcon icon={faSpinnerThird} spin />
      ) : (
        <>
          <FontAwesomeIcon icon={faUserSecret} className="mr-2" />
          <span>{t('Morph')}</span>
        </>
      )}
    </Button>
  )
}

export default MorphButton
