import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import s from './index.module.scss'

type Props = {
  title?: string
  description?: ReactNode
  children: ReactNode
}

const Setting: FC<Props> = ({ title, description, children }) => {
  return (
    <section className={classNames('__Setting', s.Setting)}>
      <h5 className="std">{title && <span>{title}</span>}</h5>
      {description && <p className="std-sm">{description}</p>}
      {children}
    </section>
  )
}

export default Setting
