import { useEffect, useState } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { Response, retrieveOIDC } from 'thunks/api/accounts/retrieve-oidc'

export function useRetrieveOIDC() {
  const [oidc, setOIDC] = useState<Response>()
  const [run, { timer }] = useAPI(retrieveOIDC)
  const { accountId } = useAuthSelector()

  useEffect(() => {
    if (!accountId) return
    run({ id: accountId }).then(setOIDC)
  }, [accountId, setOIDC, run])

  return [oidc, { timer }] as const
}
