import { FC } from 'react'

import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'
import WebAuthnRegistrationPreview from 'components/templates/stages/WebAuthnRegistration.Preview'

const WebAuthnRegistration: FC = () => {
  return <NoSettingsStage stageType="webauthn-registration" preview={WebAuthnRegistrationPreview} />
}

export default WebAuthnRegistration
