import { FC, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePipelineSelector } from 'store'
import * as yup from 'yup'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Field from 'components/molecules/Field'
import { idleTimer } from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { updateWebhookURLs } from 'thunks/api/pipelines/webhook-urls'

import SettingLayout from '../SettingLayout'
import UrlList from './components/UrlList'
import s from './index.module.scss'

type FormData = {
  url: string
}

const schema = yup.object().shape({
  url: yup
    .string()
    .matches(/^https:\/\//, 'The webhook URL must begin with https://')
    .required(t('A webhook URL is required')),
})

const Webhooks: FC = () => {
  const { pipeline } = usePipelineSelector()

  const [actionMode, setActionMode] = useState<'add' | 'remove'>()

  const resolver = useYupValidationResolver(schema)
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({ resolver })

  const [update, { timer }] = useAPI(updateWebhookURLs)
  const onSubmit = useCallback(
    async (data: FormData) => {
      setActionMode('add')
      await update({
        id: pipeline.id,
        webhookUrls: [...(pipeline.webhookUrls || []), data.url],
      })
      setValue('url', '')
    },
    [pipeline, update, setValue]
  )

  const handleRemoveWebhookUrl = useCallback(
    async (idx: number) => {
      const webhookUrls = (pipeline.webhookUrls || []).filter((_, i) => i !== idx)

      setActionMode('remove')
      await update({
        id: pipeline.id,
        webhookUrls,
      })
    },
    [pipeline, update]
  )

  return (
    <SettingLayout
      id="webhooks"
      title={t('Webhook URLs')}
      description={t(
        'Depending on your integration, you may need realtime notifications when a pipeline is completed.'
      )}
    >
      <p className="mb-2 std-base">
        {t('To see the format of the webhook notifications, please visit our')}{' '}
        <a
          href="https://developer.journeyid.com/reference/webhook-notifications"
          target="_blank"
          rel="noreferrer"
          className="text-theme"
        >
          {t('Webhook Documentation')}
        </a>
        .
      </p>
      <form className={s.Form} onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="label"
          label={t('URL')}
          className="flex-1"
          wrapperClassName="space-x-4"
          error={errors.url}
        >
          <Input ref={register} name="url" placeholder="https://your.webhook.url/with/path" />
          <Button
            type="submit"
            variant={actionMode === 'add' && timer.didSucceed ? 'green' : 'secondary'}
            size="xl"
          >
            {actionMode === 'add' && timer.didSucceed
              ? t('Added!')
              : actionMode === 'add' && timer.isLoading
              ? t('Adding...')
              : t('Add')}
          </Button>
        </Field>
      </form>

      <UrlList
        webhookUrls={pipeline.webhookUrls || []}
        timer={actionMode === 'remove' ? timer : idleTimer}
        onRemove={handleRemoveWebhookUrl}
      />
    </SettingLayout>
  )
}

export default Webhooks
