import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { ReactComponent as CloudCheckIcon } from 'pages/auth/shared/Auth/icons/cloud-check.svg'

type Props = {
  text: string
}

const SSOButton: FC<Props> = ({ text }) => {
  const history = useHistory()

  const handleClick = () => history.push(r.auth.sso.root)

  return (
    <button onClick={handleClick}>
      <CloudCheckIcon className="fill-blue-700" />
      {text}
    </button>
  )
}

export default SSOButton
