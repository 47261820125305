import { ChangeEvent, useCallback, useState } from 'react'
import { NonUndefined } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'
import { Pipeline } from 'types'

import { ActionTimer } from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import { updateDeliveryMethods } from 'thunks/api/pipelines/update-delivery-methods'
import { deliveryDefaults } from 'util/deliveryDefaults'

function reduce<T extends Object>(obj: T): Partial<T> {
  return (Object.keys(obj) as (keyof T)[]).reduce((memo: Partial<T>, key: keyof T) => {
    if (obj[key]) {
      memo[key] = obj[key]
    }
    return memo
  }, {})
}

type DeliveryMethod = 'sms' | 'email' | 'pushNotification'
type DeliveryField<T extends DeliveryMethod> = keyof NonUndefined<Pipeline[T]>

type Output<T extends DeliveryMethod> = {
  sms: T extends 'sms' ? Pipeline['sms'] : never
  email: T extends 'email' ? Pipeline['email'] : never
  pushNotification: T extends 'pushNotification' ? Pipeline['pushNotification'] : never
  timer: ActionTimer
  handleChange: (field: DeliveryField<T>) => (value: ChangeEvent<HTMLInputElement>) => void
  handleSave: () => void
  goToNext: () => void
}

export default function useDelivery<T extends DeliveryMethod>(method: T): Output<T> {
  const { pipeline } = usePipelineSelector()

  const [delivery, setDelivery] = useState<Pipeline[T]>(pipeline[method])

  const history = useHistory()
  const goToNext = useCallback(() => {
    switch (method) {
      case 'sms':
        history.push(r.pipelines.create.delivery.method(pipeline.id, 'email'))
        return
      case 'email':
        history.push(r.pipelines.create.delivery.method(pipeline.id, 'push-notification'))
        return
      case 'pushNotification':
        history.push(r.pipelines.create.publish(pipeline.id))
        return
    }
  }, [pipeline.id, method, history])

  const [update, { timer }] = useAPI(updateDeliveryMethods)
  const handleSave = useCallback(async () => {
    const { sms, email, pushNotification } = pipeline

    const payload: Pick<Pipeline, 'id' | 'sms' | 'email' | 'pushNotification'> = {
      id: pipeline.id,
      sms,
      email,
      pushNotification,
      [method]: delivery,
    }

    switch (method) {
      case 'sms':
        payload['sms'] = {
          ...deliveryDefaults['sms'],
          ...reduce(delivery || {}),
        }
        break
      case 'email':
        payload['email'] = {
          ...deliveryDefaults['email'],
          ...reduce(delivery || {}),
        }
        break
      case 'pushNotification':
        payload['pushNotification'] = {
          ...deliveryDefaults['pushNotification'],
          ...reduce(delivery || {}),
        }
        break
    }

    await update(payload)

    if (!pipeline.key) {
      goToNext()
    }
  }, [pipeline, delivery, method, update, goToNext])

  const handleChange = useCallback(
    (field: DeliveryField<T>) => (e: ChangeEvent<HTMLInputElement>) => {
      setDelivery({
        ...delivery,
        [field]: e.target.value,
      })
    },
    [delivery]
  )

  return {
    // @ts-ignore
    sms: delivery,
    // @ts-ignore
    email: delivery,
    // @ts-ignore
    pushNotification: delivery,
    timer,
    handleChange,
    handleSave,
    goToNext,
  }
}
