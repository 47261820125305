import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  accountId: string
  title: string
}

type Response = {
  id: string
}

export const duplicatePipeline = asyncThunk<Request, Response>({
  name: 'pipelines/duplicate',
  method: 'POST',
  uri: ({ id }) => `/pipelines/${id}/duplicate`,
  stripId: true,
})
