import classNames from 'classnames'
import { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { t } from 'translation'

import VoiceBiometrics from 'pages/Settings/tabs/Pricing/VoiceBiometrics'

import s from './AgentAuthentication.module.scss'
import { customerInteractionCosts, CustomerInteractions as CustInteractions } from './costs'
import Interactions from './Interactions'

type FeatureProps = {
  children: ReactNode
  onChange: (value: number) => void
}

const Feature: FC<FeatureProps> = ({ children, onChange }) => (
  <li className="flex justify-between py-2 border-t hover:bg-gray-100">
    <div className="!font-normal !text-gray-800 pl-2 flex flex-col justify-center">{children}</div>
    <div>
      <NumberFormat
        placeholder={t('/ mo')}
        thousandSeparator=","
        className={classNames(
          'text-right py-1 !bg-white text-sm w-[160px] text-gray-800 border-gray-300 focus:border-gray-400 focus:ring-0 placeholder:text-gray-400 placeholder:font-normal'
        )}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value ? parseInt(e.target.value.replaceAll(/,/g, '')) : 0)
        }
      />
    </div>
  </li>
)

type Props = {
  value: CustInteractions
  discount: number | undefined
  onChange: (value: CustInteractions) => void
}

const CustomerInteractions: FC<Props> = ({ value, discount = 0, onChange }) => {
  const std = customerInteractionCosts['standard']
  const standardIdx = std.findIndex(a => a[0] === value.standard)

  const adv = customerInteractionCosts['advanced']
  const advancedIdx = adv.findIndex(a => a[0] === value.advanced)

  const [standardListShown, setStandardListShown] = useState(false)
  const [advancedListShown, setAdvancedListShown] = useState(false)

  const [standardCounts, setStandardCounts] = useState<Record<number, number>>({})
  const [standardBucket, setStandardBucket] = useState<number>(0)

  useEffect(() => {
    const total = Object.values(standardCounts).reduce((a, b) => a + b, 0)
    const idx = customerInteractionCosts.standard.findIndex(([count]) => count > total)
    if (idx !== -1 && value.standard === customerInteractionCosts.standard[idx][0]) return

    onChange({
      ...value,
      standard: idx > -1 ? customerInteractionCosts.standard[idx][0] : -1,
    })
  }, [standardCounts, value, onChange])

  console.log('std', standardCounts, standardBucket)

  return (
    <div className={s.OptionList}>
      <Interactions
        title={t('Standard Interactions')}
        value={std[standardIdx === -1 ? std.length - 1 : standardIdx][0]}
        index={standardIdx}
        options={std.length - 1}
        priceMin={std[std.length - 1][1] * (1 - discount)}
        priceMax={std[1][1] * (1 - discount)}
        isListShown={standardListShown}
        onChange={idx =>
          onChange({
            ...value,
            standard: customerInteractionCosts['standard'][idx][0],
          })
        }
        onToggleList={() => setStandardListShown(!standardListShown)}
      >
        <main className="pt-1">
          {t(
            'Transactions covering a wide variety of the most common authentication methods and interactions including device based biometrics, document signatures, automated disclosures and more.'
          )}
        </main>
        {standardListShown && (
          <ul className="my-2 border-b">
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 0: count })}>
              {t('Inbound caller enrollment via SMS using FIDO')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 1: count })}>
              {t('Inbound caller authentication via SMS using FIDO')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 2: count })}>
              {t('Collect Social Security Number (does not include verification)')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 3: count })}>
              {t('eSign Permission or Terms and Conditions')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 4: count })}>
              {t('Automated disclosure with gesture signature')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 5: count })}>
              {t('Collect a Caller entered address')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 6: count })}>
              {t('Upload an image or file')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 7: count })}>
              {t('Document Capture using smartphone camera (no OCR)')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 8: count })}>
              {t('eForm delivery for capture of custom data')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 9: count })}>
              {t('Digital Payment using Braintree with Customer Merchant Account credit')}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 10: count })}>
              {t("Digital Payment using Customer's specified gateway")}
            </Feature>
            <Feature onChange={count => setStandardCounts({ ...standardCounts, 11: count })}>
              {t('One time gateway integration charge if not yet available')}
            </Feature>
          </ul>
        )}
      </Interactions>

      <Interactions
        title={t('Advanced Interactions')}
        value={adv[advancedIdx][0]}
        index={advancedIdx}
        options={adv.length - 1}
        priceMin={adv[adv.length - 1][1] * (1 - discount)}
        priceMax={adv[1][1] * (1 - discount)}
        isListShown={advancedListShown}
        onChange={idx =>
          onChange({
            ...value,
            advanced: customerInteractionCosts['advanced'][idx][0],
          })
        }
        onToggleList={() => setAdvancedListShown(!advancedListShown)}
      >
        <main>
          {t(
            'Transactions covering advanced features such as digital payments, document sharing, government issued ID capture, and 3D facial mapping for identity proof and authentication.'
          )}
        </main>
        '
      </Interactions>

      <VoiceBiometrics
        value={value}
        onChange={voiceBiometrics =>
          onChange({
            ...value,
            ...voiceBiometrics,
          })
        }
      />

      <div className={classNames(s.Option, '!cursor-default')}>
        <div>
          <header>{t('Specialized Interactions')}</header>
          <p>
            {t(
              'These include 3rd party verification servcies for identity proofing, specilized payment services using DTMF signanling, and any custom integrations of API calls between Journey and another system. Special pricing applies to each individual transaction type.'
            )}
          </p>
          <div>{t('contact us for pricing')}</div>
        </div>
      </div>
    </div>
  )
}

export default CustomerInteractions
