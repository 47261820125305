import { FC } from 'react'

import { t } from 'translation'

import s from './Header.module.scss'

const Header: FC = () => (
  <div className={s.Header}>
    <h1>{t('Send Pipeline Request')}</h1>
  </div>
)

export default Header
