import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

type Response = {
  secret: string
}

export const iframeSecret = asyncThunk<Request, Response>({
  name: 'iframes/secret',
  method: 'PUT',
  uri: ({ id }) => `/iframes/${id}/secret`,
  stripId: true,
})
