import { HTTPCreated } from '@journeyid/agent/common/statuses'

import { Branding } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = Omit<Branding, 'id'>

export const createBrandStyle = asyncThunk<Request, Branding>({
  name: 'brand-styles/create',
  method: 'POST',
  uri: '/brand-styles',
  successStatus: HTTPCreated,
  orchestrator: false,
})
