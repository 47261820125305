import { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector, useIframeSelector, usePipelinesSelector } from 'store'
import { t } from 'translation'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'components/atoms/Button'
import Footer from 'components/molecules/Footer'
import { useAPI } from 'hooks/useAPI'
import PipelineCategories from 'pages/IFrames/Steps/Pipelines/PipelineCategories'
import ls from 'pages/IFrames/Steps/shared/LayoutWithFooter.module.scss'
import { listPipelines } from 'thunks/api/pipelines/list'

import EmptyPipelines from './EmptyPipelines'
import s from './index.module.scss'

const Pipelines: FC = () => {
  const { iframe } = useIframeSelector()
  const { isLoaded } = usePipelinesSelector()
  const history = useHistory()

  const [pipelineList] = useAPI(listPipelines)

  const handleGetPipelines = useCallback(async () => {
    try {
      await pipelineList({
        published: true,
      })
    } catch (err) {
      console.error('Unable to get pipelines:', err)
    }
  }, [pipelineList])

  useEffect(() => {
    handleGetPipelines()
  }, [handleGetPipelines, pipelineList])

  const handleContinue = () => {
    if (!iframe.id) return
    const phase = history.location.pathname.split('/')[3]
    const route = r.iframes[phase === 'create' ? 'create' : 'edit'].configure
    history.push(route.deliveryMethods(iframe.id))
  }

  const categories = iframe.categories ? iframe.categories : []

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center">
        <FontAwesomeIcon
          icon={faSpinnerThird}
          className="mx-auto mt-32 text-theme"
          size="5x"
          spin
        />
      </div>
    )
  }

  return (
    <div className={ls.Wrapper}>
      <div className={ls.ContentWrapper}>
        <div>
          <h1 className={s.Title}>{t('Pipelines')}</h1>
          {categories.length === 0 ? <EmptyPipelines /> : <PipelineCategories iframe={iframe} />}
        </div>
      </div>

      <div>
        <Footer
          actions={<Button onClick={handleContinue}>{t('Finished adding Pipelines')}</Button>}
        />
      </div>
    </div>
  )
}

export default Pipelines
