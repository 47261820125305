import { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import r from 'routes'
import { useAppDispatch } from 'store'

import useQueryString from 'hooks/useQueryString'
import { actions } from 'slices/auth'

type Params = {
  token?: string
}

const SSOIDPInitiated: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const query = useQueryString<Params>()

  useEffect(() => {
    if (query.token) {
      dispatch(actions.setJWT(query.token))
      history.push(r.root)
    } else {
      history.push(r.auth.login)
    }
  }, [query.token, history, dispatch])

  return null
}

export default SSOIDPInitiated
