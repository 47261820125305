import {
  ChangeEvent, ClipboardEvent, FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useUpdate } from 'react-use'
import r from 'routes'
import { useBrandingSelector } from 'store'
import { t } from 'translation'

import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Button from 'components/atoms/Button'
import Loading from 'components/atoms/Loading'
import Header from 'components/molecules/AdminHeader'
import Preview from 'components/organisms/Preview'
import FacialEnrollmentPreview from 'components/templates/stages/FacialEnrollment.Preview'
import useArchiveBrandStyle from 'hooks/api/branding/useArchiveBrandStyle'
import useRetrieveBranding from 'hooks/api/branding/useRetrieveBrandStyle'
import useUpdateBrandStyle from 'hooks/api/branding/useUpdateBrandStyle'
import { Branding } from 'types/account'

import s from './index.module.scss'

type FormData = {
  logo?: string
  primaryColor: string
}

type RouteParams = {
  id: string
}

const BrandStylesShow: FC = () => {
  const { register, getValues, handleSubmit, setValue, setError } = useForm<FormData>({
    mode: 'onBlur',
    shouldUnregister: false,
  })

  const { branding } = useBrandingSelector()
  const history = useHistory()

  const [brandStyle, setBrandStyle] = useState<Branding>()
  const [deleting, setDeleting] = useState(false)
  const [primaryColor, setPrimaryColor] = useState('')

  const [archive, { timer: archiveTimer }] = useArchiveBrandStyle()
  const [retrieve, { timer: retrieveTimer }] = useRetrieveBranding()
  const { params } = useRouteMatch<RouteParams>()
  const [update, { timer: updateTimer }] = useUpdateBrandStyle(params.id, setError)

  const handleSetColor = useCallback((value: string) => {
    if (value.length > 7) return
    setPrimaryColor(value)
    if (value.length === 4 || value.length === 7) {
      document.body.style.setProperty('--preview-theme-primary', `${value}`)
    }
  }, [])

  useEffect(() => {
    if (!params.id) return
    retrieve({ id: params.id }).then(branding => {
      setBrandStyle(branding)
      handleSetColor(branding.primaryColor ?? '#0060AB')
    })
  }, [params.id, retrieve, setValue, handleSetColor])

  const logoRef = useRef<HTMLInputElement | null>(null)
  const render = useUpdate()

  const handleArchive = useCallback(() => {
    if (!brandStyle || archiveTimer.isLoading) return
    archive({ id: brandStyle.id }).then(() => history.push(r.brandStyles.root))
  }, [archive, archiveTimer.isLoading, history, brandStyle])

  const handleColor = useCallback(
    (color: string) => {
      setValue('primaryColor', `#${color}`)
      setPrimaryColor(`#${color}`)
      document.body.style.setProperty('--preview-theme-primary', `#${color}`)
    },
    [setValue]
  )

  const [logoUrl, setLogoUrl] = useState<string>()
  useEffect(() => {
    if (!brandStyle) return
    setLogoUrl(brandStyle.logo)
  }, [brandStyle])

  const handleLogoUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target
      if (!files?.length) return

      const reader = new FileReader()
      reader.addEventListener('load', event => {
        if (!event.target?.result) return

        const encoded = event.target.result as string
        const svg = atob(encoded.replace('data:image/svg+xml;base64,', ''))

        setValue('logo', svg)

        const url = URL.createObjectURL(files[0])
        setLogoUrl(url)
        render()
      })
      reader.readAsDataURL(files[0])
    },
    [setValue, render]
  )

  const handleClearFile = useCallback(() => {
    setValue('logo', undefined)
    setLogoUrl(undefined)
    if (logoRef.current) {
      logoRef.current.value = ''
    }
    render()
  }, [setValue, render])

  const logo = getValues('logo')
  const colors = useMemo(() => {
    if (!logo) return
    const colors = [...logo.matchAll(/#[a-fA-F0-9]{6}/g)].map(a => a[0])
    const nonWhite = colors.filter(
      (v, i, a) => a.indexOf(v) === i && v !== '#FFFFFF' && v !== '#ffffff'
    )
    const nonBlack = nonWhite.filter(v => v !== '#000000')
    if (nonBlack.length === 1) setPrimaryColor(nonBlack[0])
    return nonWhite.slice(0, 5)
  }, [logo])

  const handleColorPaste = useCallback(
    (e: ClipboardEvent) => {
      e.preventDefault()
      var text = e.clipboardData.getData('Text')
      if (text.match(/^[0-9a-fA-F]{6}$/)) {
        text = `#${text}`
      }
      handleSetColor(text.toUpperCase())
    },
    [handleSetColor]
  )

  return (
    <ContentWrapper className="bg-gray-100">
      <Header backRoute={r.brandStyles.root} title={t('Branding')} />

      <div className={s.ContentWrapper}>
        {retrieveTimer.isLoading ? (
          <Loading className="w-full mt-32" size="5x" />
        ) : (
          <div className={s.Content}>
            <div>
              <div>
                <h2 className={s.Title}>{t('Branding')}</h2>
                <p className={s.Info}>
                  {t(
                    'The logo and brand color you select below will be shown in the customer facing pipeline interface. You can see a preview of what the customer will see below.'
                  )}
                </p>

                <form className={s.FormWrapper}>
                  <p className={s.Label}>{t('Logo')}</p>
                  <button
                    type="button"
                    className={s.UploadLogoBtn}
                    onClick={logoUrl ? handleClearFile : () => logoRef.current?.click()}
                  >
                    {logoUrl ? (
                      <div className="flex items-center">
                        <img src={logoUrl} alt="" className="h-9" />
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faPlus} /> {t('Upload an SVG logo')}
                      </>
                    )}
                  </button>
                  <input
                    ref={logoRef}
                    type="file"
                    accept=".svg"
                    onChange={handleLogoUpload}
                    className="hidden"
                  />
                  <input type="hidden" ref={register} name="logo" />

                  <hr className={s.Divider} />

                  <p className={s.Label}>{t('Primary Brand Color')}</p>

                  <div className={s.ColorWrapper}>
                    <input
                      type="color"
                      ref={register}
                      name="primaryColor"
                      className={s.ColorPicker}
                      value={
                        primaryColor
                          ? primaryColor.match(/^#[0-9a-fA-F]{6}$/)
                            ? primaryColor
                            : undefined
                          : '#0060AB'
                      }
                      onChange={e => handleSetColor(e.target.value)}
                    />
                    <input
                      type="text"
                      ref={register}
                      name="primaryColor"
                      className={s.ColorInput}
                      value={primaryColor}
                      placeholder="#0060AB"
                      onChange={e => handleSetColor(e.target.value)}
                      onPaste={handleColorPaste}
                    />
                  </div>
                  <div>
                    {colors && (
                      <div className="flex items-center mt-2 space-x-2">
                        <span className="pr-2 text-sm">{t('or select from the logo:')}</span>
                        {colors.map(color => (
                          <div
                            key={color}
                            style={{ backgroundColor: color }}
                            className="w-6 h-6 rounded cursor-pointer"
                            onClick={() => handleColor(color.substring(1))}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </form>
              </div>

              <div>
                <Preview>
                  <FacialEnrollmentPreview
                    brandingLogoPreview={
                      (logoRef.current?.files &&
                        logoRef.current?.files[0] &&
                        URL.createObjectURL(logoRef.current?.files[0])) ||
                      undefined
                    }
                  />
                </Preview>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={s.Footer}>
        {deleting && brandStyle && (
          <>
            <Button
              variant="red"
              size="xl"
              isLoading={archiveTimer.isLoading}
              onClick={handleArchive}
            >
              {t('Confirm delete')}
            </Button>
            <Button
              variant="link"
              size="xl"
              disabled={archiveTimer.isLoading}
              onClick={() => setDeleting(false)}
            >
              {t('Cancel')}
            </Button>
          </>
        )}
        {brandStyle && !deleting && (
          <Button
            variant="link"
            disabled={branding?.id === brandStyle.id}
            onClick={() => setDeleting(true)}
          >
            {t('Delete brand style')}
          </Button>
        )}
        {!deleting && (
          <Button
            type="submit"
            size="xl"
            isLoading={updateTimer.isLoading}
            onClick={handleSubmit(update)}
          >
            {updateTimer.didSucceed ? t('Saved!') : t('Save this style')}
          </Button>
        )}
      </div>
    </ContentWrapper>
  )
}

export default BrandStylesShow
