import { useCallback, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { t } from 'translation'
import { DeliveryMethod, Pipeline } from 'types'

import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAPIErrorResponse } from '@journeyid/agent/common/errors'
import { Language } from '@journeyid/agent/common/types/language'
import { Configuration as ConfigurationType } from '@journeyid/agent/executions/create'
import { CreditCardPaymentConfiguration } from '@journeyid/agent/types'

import Alert from 'components/atoms/Alert'
import TimeInput from 'components/molecules/TimeInput'
import Modal from 'components/organisms/Modal'
import PipelineSelect from 'components/organisms/PipelineSelect'
import { useInitiatePipeline } from 'hooks/api/useInitiatePipeline'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { closeModal, useModal } from 'hooks/useModal'

import Configuration from './Configuration'
import DeliveryMethodList from './DeliveryMethodList'
import Footer from './Footer'
import Header from './Header'
import s from './index.module.scss'

import type { User, UserType } from 'types/user'
const DEFAULT_TTL = 3600
const DEFAULT_LANG: Language = 'en-US'
const DEFAULT_CONFIG: CreditCardPaymentConfiguration = {
  details: {
    heading: t('Premium Service'),
    subheading: t('Order #80216'),
  },
  currency: 'USD',
  lineItems: [{ amount: 799, quantity: 1, title: t('Premium Service') }],
  paymentGateway: 'braintree',
}

type Props<T extends UserType> = {
  user: User<T>
}

const SendPipelineModal = <T extends UserType>({ user }: Props<T>) => {
  const [isOpen] = useModal('SendPipeline')

  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline>()
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>()
  const [config, setConfig] = useState<CreditCardPaymentConfiguration>({ ...DEFAULT_CONFIG })
  const [ttl, setTTL] = useState<number>(DEFAULT_TTL)
  const [language, setLanguage] = useLocalStorage<Language>('pipeline-language', DEFAULT_LANG)

  const [initiate, { timer }] = useInitiatePipeline()

  const containsPayment = useCallback(
    () =>
      selectedPipeline && selectedPipeline.stages.map(s => s.type).includes('credit-card-payment'),
    [selectedPipeline]
  )

  const [, copyToClipboard] = useCopyToClipboard()
  const [error, setError] = useState<string>()
  const execute = useCallback(async () => {
    if (!selectedPipeline || !deliveryMethod) return
    try {
      setError(undefined)

      const configuration: ConfigurationType = containsPayment()
        ? { 'credit-card-payment': config }
        : {}

      const resp = await initiate({
        pipelineId: selectedPipeline.id,
        user,
        deliveryMethod,
        ttl,
        language,
        configuration,
      })
      if (deliveryMethod === 'url') copyToClipboard(resp.url || '')
    } catch (err) {
      if (isAPIErrorResponse(err)) {
        setError(err.error)
      }
    }
  }, [
    deliveryMethod,
    user,
    ttl,
    language,
    config,
    containsPayment,
    selectedPipeline,
    initiate,
    copyToClipboard,
  ])

  const handleClose = useCallback(() => {
    setSelectedPipeline(undefined)
    setConfig({ ...DEFAULT_CONFIG })
    setDeliveryMethod(undefined)
    setTTL(DEFAULT_TTL)
    setLanguage(DEFAULT_LANG)
    closeModal()
  }, [setLanguage])

  return (
    <Modal isOpen={isOpen} className="max-w-3xl !px-2 !py-2 rounded-lg" onClose={handleClose}>
      <div>
        <Header />
        <div className="p-6">
          <PipelineSelect
            autoFocus
            onSelect={p => {
              console.log(p)
              setError(undefined)
              setSelectedPipeline(p)
              !p && setDeliveryMethod(undefined)
            }}
          />
          {containsPayment() && <Configuration config={config} onChangeConfig={setConfig} />}
          <DeliveryMethodList
            user={user}
            delivery={deliveryMethod}
            pipeline={selectedPipeline}
            onChangeDelivery={setDeliveryMethod}
          />
          <TimeInput
            disableSecond
            initialDuration={DEFAULT_TTL}
            onChangeDuration={setTTL}
            minDuration={60}
          />
        </div>

        {error && (
          <Alert type="error" className="mx-6 mb-4">
            {error}
          </Alert>
        )}

        <Footer
          deliveryMethod={deliveryMethod}
          timer={timer}
          language={language}
          onChangeLanguage={setLanguage}
          onSubmit={execute}
        />

        <div className={s.CloseButton}>
          <button tabIndex={-1} onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SendPipelineModal
