import classNames from 'classnames'
import React, { FC, InputHTMLAttributes, useCallback } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import styles from '../Input/index.module.scss'

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue' | 'value' | 'onChange'>

type Props = {
  className?: string
  defaultValue?: number
  value: number | undefined
  onChangeValue?: (val?: number) => void
} & InputProps

const MoneyInput: FC<Props> = ({ value, onChangeValue, className, ...rest }) => {
  const handleChange = useCallback(
    ({ value }: NumberFormatValues) => {
      if (!onChangeValue) return

      const amt = parseFloat(value)
      if (isNaN(amt)) {
        onChangeValue(undefined)
      } else {
        onChangeValue(amt)
      }
    },
    [onChangeValue]
  )

  return (
    <div className={styles.InputGroup}>
      <span className={styles.Prefix}>$</span>
      <NumberFormat
        thousandSeparator={true}
        decimalScale={2}
        value={value}
        onValueChange={handleChange}
        className={classNames(styles.Input, styles.HasPrefix, className)}
        inputMode="numeric"
        {...rest}
        type="text"
      />
    </div>
  )
}

export default MoneyInput
