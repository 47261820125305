import asyncThunk from 'util/asyncThunk'

export type Request = {
  startDate: string
  endDate: string
  timezone: string
}

type Response = {
  filename: string
  content: string
}

export const exportAgentActivity = asyncThunk<Request, Response>({
  name: 'agents/export-activity',
  method: 'POST',
  uri: '/agents/export-activity',
  orchestrator: false,
})
