import classNames from 'classnames'
import React, { MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react'

import s from './index.module.scss'

type Props = {
  max: number
  min: number
  step: number
  value: number
  trackHeight?: number
  className?: string
  filledClassName?: string
  handleClassName?: string
  unfilledClassName?: string
  onChangeValue: (value: number) => void
}

type SliderSize = {
  height: number
  left: number
  width: number
}

type HandleSize = {
  height: number
  width: number
}

const Slider = (props: Props): JSX.Element => {
  const {
    max,
    min,
    step,
    value,
    trackHeight = 8,
    className,
    filledClassName,
    handleClassName,
    unfilledClassName,
    onChangeValue,
  } = props

  const [sliderSize, setSliderSize] = useState<SliderSize>({ height: 0, left: 0, width: 0 })

  const onSliderResize = (entries: ResizeObserverEntry[]) => {
    const rect = entries[0].target.getBoundingClientRect()
    setSliderSize({ height: rect.height, left: rect.left, width: rect.width })
  }

  const sliderObserver = useRef<ResizeObserver>(new ResizeObserver(onSliderResize))

  const sliderCallbackRef = useCallback((timeline: HTMLDivElement) => {
    if (timeline !== null) {
      sliderObserver.current.observe(timeline)
    } else {
      if (sliderObserver.current) sliderObserver.current.disconnect()
    }
  }, [])

  const [handleSize, setHandleSize] = useState<HandleSize>({ height: 0, width: 0 })

  const onHandleResize = (entries: ResizeObserverEntry[]) => {
    const rect = entries[0].target.getBoundingClientRect()
    setHandleSize({ height: rect.height, width: rect.width })
  }

  const handleObserver = useRef<ResizeObserver>(new ResizeObserver(onHandleResize))

  const handleCallbackRef = useCallback((handle: HTMLDivElement) => {
    if (handle !== null) {
      handleObserver.current.observe(handle)
    } else {
      if (handleObserver.current) handleObserver.current.disconnect()
    }
  }, [])

  const toPageX = useCallback(
    (x: number) => (sliderSize.width * (x - min)) / (max - min),
    [sliderSize, min, max]
  )

  const toValX = (x: number): number => {
    const trueX = Math.max(
      min,
      Math.min(max, Math.round(min + ((max - min) * (x - sliderSize.left)) / sliderSize.width))
    )
    if ((trueX - min) % step === 0) {
      return trueX
    } else {
      const index = (trueX - min) / step
      const prev = Math.floor(index) * step + min
      const next = Math.ceil(index) * step + min
      return trueX - prev < next - trueX ? prev : next
    }
  }

  const setValue = (val: number) => onChangeValue(Math.max(min, Math.min(max, val)))
  const [sliding, setSliding] = useState<boolean>(false)

  const onMouseDown: MouseEventHandler<HTMLDivElement> = event => {
    const val = toValX(event.clientX)
    setSliding(true)
    setValue(val)
  }

  const onMouseLeave: MouseEventHandler<HTMLDivElement> = _ => setSliding(false)

  const onMouseMove: MouseEventHandler<HTMLDivElement> = event => {
    if (!sliding) return
    const val = toValX(event.clientX)
    setValue(val)
  }

  const onMouseUp: MouseEventHandler<HTMLDivElement> = _ => setSliding(false)

  const rangeY = useMemo(
    () => Math.round((sliderSize.height - trackHeight) / 2),
    [sliderSize, trackHeight]
  )

  const rangeStyle = (low: number, high: number) => {
    const lowX = toPageX(low)
    return {
      height: trackHeight,
      left: lowX,
      top: rangeY,
      width: toPageX(high) - lowX,
    }
  }

  const handleY = useMemo(
    () => Math.round(sliderSize.height - handleSize.height) / 2,
    [sliderSize, handleSize]
  )

  const handleStyle = useMemo(
    () => ({
      left: toPageX(value) - Math.round(handleSize.width / 2),
      top: handleY,
    }),
    [value, handleSize, handleY, toPageX]
  )

  return (
    <div
      // @ts-ignore
      ref={sliderCallbackRef}
      className={classNames(s.Slider, className)}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div
        className={classNames('absolute rounded-l-theme bg-black', filledClassName)}
        style={rangeStyle(min, value)}
      />
      <div
        className={classNames('absolute rounded-r-theme bg-gray', unfilledClassName)}
        style={rangeStyle(value, max)}
      />
      <div
        ref={handleCallbackRef}
        className={classNames(s.Handle, handleClassName)}
        style={handleStyle}
      />
    </div>
  )
}

export default Slider
