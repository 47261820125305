import cogoToast from 'cogo-toast'
import { FC, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { t } from 'translation'

import Button from 'components/atoms/Button'
import SettingsCard from 'components/atoms/SettingsCard'
import Field from 'components/molecules/Field'
import { useAPI } from 'hooks/useAPI'
import { createGroup } from 'thunks/api/groups/create'
import { Group } from 'types/group'

type FormData = {
  name: string
}

type Props = {
  onCreate: (group: Group) => void
}

const Form: FC<Props> = ({ onCreate }) => {
  const form = useForm<FormData>({
    defaultValues: {
      name: '',
    },
  })

  const [create, { timer }] = useAPI(createGroup, {
    setError: form.setError,
  })
  const handleSubmit = useCallback(
    async (data: FormData) => {
      const group = await create(data)

      onCreate(group)
      cogoToast.success(`${group.name} was created`)
      form.reset()
    },
    [form, create, onCreate]
  )

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <SettingsCard className="space-y-6">
          <Field ref={form.register} name="name" label={t('Group name')} error={form.errors.name} />

          <Button type="submit" isLoading={timer.isLoading}>
            {t('Create group')}
          </Button>
        </SettingsCard>
      </form>
    </FormProvider>
  )
}

export default Form
