import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import useActionTimer from 'hooks/useActionTimer'
import { authenticationsSwitch, Request } from 'thunks/api/authentications/switch'

type Account = {
  id: string
  name: string
}

export default function useSwitchAccount() {
  const timer = useActionTimer()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const switchAccount = useCallback(
    async (account: Pick<Account, 'id' | 'name'>) => {
      try {
        timer.start()

        const req: Request = {
          accountId: account.id,
        }

        await dispatch(authenticationsSwitch(req)).then(unwrapResult)

        history.push(r.root)

        timer.succeeded()
      } catch (err) {
        console.error(err)
        timer.failed()
      }
    },
    [timer, dispatch, history]
  )

  return [switchAccount, { timer }] as const
}
