import { FC } from 'react'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import IntegrationLayout from '../IntegrationLayout'
import Form from './components/Form'
import Links from './components/Links'

const SSSOIDC: FC = () => {
  const { pipeline } = usePipelineSelector()

  return (
    <IntegrationLayout
      id="sso-page"
      title={t('Single Sign-On: SAML2')}
      description={t(
        'Use this pipeline as a way to authenticate agents or customers via SAML flow.'
      )}
    >
      <Links />
      <Form key={pipeline.saml ? 'present' : 'nil'} />
    </IntegrationLayout>
  )
}

export default SSSOIDC
