import { StageType } from '@journeyid/agent/types'

import { t } from 'translation'

export type PipelineTemplate = {
  id: string
  label: string
  description: string
  stageTypes: StageType[]
}

export type TemplateCategory = {
  id: string
  label: string
  pipelines: PipelineTemplate[]
}

const pipelineTemplates: TemplateCategory[] = [
  {
    id: 'onboarding-and-identity-verification',
    label: t('Onboarding & Identity Verification'),
    pipelines: [
      {
        id: 'ekyc-onboarding',
        label: t('Digital Onboarding & eKYC'),
        description: t(
          'Onboard new users effortlessly and stay compliant with Know Your Customer regulations. Create a new account, complete a liveness check, validate data and more. Our digital ID verifications coupled with biometrics will help reduce the cost of manual reviews and deliver higher pass rates.'
        ),
        stageTypes: [
          'document-signature',
          'face-enrollment',
          'drivers-license',
          'social-security-number',
        ],
      },
      {
        id: 'new-patient',
        label: t('Patient Onboarding & Registration'),
        description: t(
          "Streamline patients' onboarding experience and meet HIPAA compliance in healthcare. Journey's biometric verification paired with ID verification step will result in lower onboarding costs (go paperless!). Keep patient's data secure without compromising protected health information."
        ),
        stageTypes: ['document-signature', 'face-enrollment', 'social-security-number', 'form'],
      },
      {
        id: 'age-verification',
        label: t('Age Verification'),
        description: t(
          'Verify that the user creating an account or making an online purchase is over a specified age. Journey extracts the data from the presented ID and verifies it againts our trusted partner data. Adding biometics to the ID verification step will make it even more secure, so why not do due dilligence?'
        ),
        stageTypes: ['drivers-license'],
      },
      {
        id: 'customer-onboarding',
        label: t('Onboarding & Buy Now Pay Later'),
        description: t(
          'Onboard users for Buy Now Pay Later solutions using facial biometrics. Facial recognition with liveness detection ensures the person signing up for an account is a real person, not a spoof. Onboarding is secure and complies with KYC policies.'
        ),
        stageTypes: [
          'document-signature',
          'face-enrollment',
          'drivers-license',
          'social-security-number',
          'form',
        ],
      },
    ],
  },
  {
    id: 'authentication',
    label: t('Authentication'),
    pipelines: [
      {
        id: 'employee-auth-sso',
        label: t('Employee Authentication & SSO'),
        description: t(
          "Implement zero-trust philosophy by authenticating identities and user access, especially in remote work environment. Journey's authentication tools allow to securily access data and resources from different systems.  Add SSO integration and eliminate the need for password resets."
        ),
        stageTypes: ['face-authentication'],
      },
      {
        id: 'passwordless-user-auth',
        label: t('Passwordless Authentication'),
        description: t(
          'Streamline your users or agents authentication process by adding a secure biometric authentication across any interaction channel. The burden of entering complex passwords or changing them frequently for security reasons is gone. Improved productivity and enhanced security for your organization.'
        ),
        stageTypes: ['face-authentication'],
      },
      {
        id: 'device-auth',
        label: t('Face ID and Fingerprint Auth'),
        description: t(
          'Leverage your existing mobile app and add biometric authentication to authorize interactions throughout the customer journey. As a couple examples, they could authorize a withdrawal, access a secure room, or submit a prescription in seconds using Face ID or Touch ID on their phone.'
        ),
        stageTypes: ['webauthn-authentication'],
      },
      {
        id: 'mfa',
        label: t('Multi-Factor Authentication'),
        description: t(
          'Add an extra layer of security in healthcare with multi-factor authentication. Requiring users to enter a static password is no longer considered adequate to prevent fraudulent or unauthorized access to sensitive medical data records.  Protect your patients and stay HIPAA compliant.'
        ),
        stageTypes: ['knowledge-based-authentication'],
      },
    ],
  },
  {
    id: 'interactions',
    label: t('Interactions'),
    pipelines: [
      {
        id: 'outbound-collections',
        label: t('Outbound Collections'),
        description: t(
          'Strengthen your collection capabilities and collect outstanding payments in a non-aggressive way. The user will receive a push notification on the mobile app already installed prompting them to click a secure link to make a payment. The entire process will take a few minutes for the user to complete.'
        ),
        stageTypes: ['credit-card-payment', 'document-signature'],
      },
      {
        id: 'credit-check-verification',
        label: t('Credit Check Verification'),
        description: t(
          "Allow your customers to run credit check in real time. This use case is a great fit for morgage loan applications or BNPL onboarding. After user's identity is verified, the user will complete a short credit check history form and we submit it for verification to a trusted provider such as Experian."
        ),
        stageTypes: ['face-enrollment', 'form', 'document-signature'],
      },
      {
        id: 'digital-esignature',
        label: t('Digital e-Signature'),
        description: t(
          'Send any document to your users to be digitally signed. We collect a browser fingerprint and location data to ensure there is an audit trail in case the signature is questioned. For enhanced security, pair signatures with a facial biometric enrollment or authentication.'
        ),
        stageTypes: ['drivers-license', 'form'],
      },
      {
        id: 'money-transfer-verification',
        label: t('Money Transfer Verification'),
        description: t(
          'Deliver a more seamless, secure alternative for online banking transfers, inter-banking wire transfers & iternational money transfers. Mobile facial biometric authenticaion is a perfect solution for preventing fraud and building mutual trust between you and your customers.'
        ),
        stageTypes: ['webauthn-authentication'],
      },
    ],
  },
  {
    id: 'secure-payments',
    label: t('Secure Payments'),
    pipelines: [
      {
        id: 'digital-payments',
        label: t('Digital Payments'),
        description: t(
          'Prevent fraud by adding strong on-device biometric authentication when collecting card-not-present payments on your website or through a mobile app. Depending on the device, Face ID or Fingerprints will be used.'
        ),
        stageTypes: ['webauthn-authentication', 'credit-card-payment'],
      },
      {
        id: 'chargeback-prevention',
        label: t('Chargeback Prevention'),
        description: t(
          'Reduce chargebacks by requiring the user to perform a facial biometric authentication before the transaction is made. Reduce dispute fees and chargeback costs by tying biometric identification to the payment.'
        ),
        stageTypes: ['face-authentication', 'credit-card-payment'],
      },
      {
        id: 'otp-protected',
        label: t('One-Time Password Protected'),
        description: t(
          'If you receive payments from the same customer frequently, you may want to add an identity verification step with low friction. One-Time Passwords pair with credit card payments is the perfect solution.'
        ),
        stageTypes: ['totp-authentication', 'credit-card-payment'],
      },
      {
        id: 'verify-account-information',
        label: t('Verify Account Information'),
        description: t(
          'Have your customer or patient confirm details associated with their account before seeing their invoice. Once confirmed, the user will have access to saved payment methods to quickly make a payment.'
        ),
        stageTypes: ['knowledge-based-authentication', 'credit-card-payment'],
      },
    ],
  },
]

export default pipelineTemplates
