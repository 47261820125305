import asyncThunk from 'util/asyncThunk'
import { HTTPProcessed } from 'util/statuses'

export type Request = {
  url: string
}

export const testWebhook = asyncThunk<Request, {}>({
  name: 'webhooks/test',
  method: 'POST',
  uri: '/webhooks/test',
  orchestrator: false,
  successStatus: HTTPProcessed,
})
