import classNames from 'classnames'
import { FC, ReactElement, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './Steps.module.scss'

type StepProps = {
  path: (id: string) => string
  suffix: string
  children: string
}

type Props = {
  iframeId: string
  className?: string
  children: ReactElement<StepProps>[]
}

const Steps: FC<Props> = ({ iframeId, children, className }) => {
  const history = useHistory()

  const stepIdx = useMemo(() => {
    const parts = history.location.pathname.split('/')
    const secondToLast = parts[parts.length - 2]
    const last = parts[parts.length - 1]
    return children.findIndex(({ props }) => [secondToLast, last].includes(props.suffix))
  }, [history, children])

  const isNewIframe = history.location.pathname.match(r.iframes.create.configure.new)

  return (
    <ul className={s.Steps}>
      {children.map(({ props }, i) => (
        <li
          key={i}
          className={classNames({
            [s.Active]: i === stepIdx,
            [s.Ready]: i < stepIdx,
            '!cursor-not-allowed': isNewIframe,
          })}
          onClick={() => {
            if (!isNewIframe) {
              history.push(props.path(iframeId))
            }
          }}
        >
          {props.children}
          <div>
            {i < stepIdx && <FontAwesomeIcon className="absolute w-5 h-5" icon={faCircleCheck} />}
          </div>
        </li>
      ))}

      <div />
    </ul>
  )
}

Object.assign(Steps, {
  Step: (() => null) as FC<StepProps>,
})

export default Steps as FC<Props> & {
  Step: FC<StepProps>
}
