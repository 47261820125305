import { FC, ReactNode } from 'react'

import s from './index.module.scss'

type Props = {
  id: string
  title: string
  description: string
  children: ReactNode
}

const SettingLayout: FC<Props> = ({ id, title, description, children }) => (
  <div id={id} className={s.SettingLayout}>
    <h1>{title}</h1>
    <div>
      <p>{description}</p>
      {children}
    </div>
  </div>
)

export default SettingLayout
