import { pick, sortBy } from 'lodash'

import { createSlice } from '@reduxjs/toolkit'

import { accountsCreate } from 'thunks/api/accounts/create'
import { accountRolesDelete } from 'thunks/api/accounts/delete'
import { updateAccountName } from 'thunks/api/accounts/update-name'
import { adminBootstrap } from 'thunks/api/admin-bootstrap'
import { authenticationsSwitch } from 'thunks/api/authentications/switch'

type Account = {
  id: string
  name: string
  disablePipelines?: boolean
  isActive: boolean
  lastAuthenticatedAt?: string
  hasSSO: boolean
}

export type AdminState = {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  isRoot?: boolean
  accounts: Account[]
}

const initialState: AdminState = {
  accounts: [],
}

export default createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(adminBootstrap.fulfilled, (state, { payload }) => {
      Object.assign(state, pick(payload, 'id', 'firstName', 'lastName', 'email', 'isRoot'))
      state.accounts = sortBy(payload.accounts, 'name')
    })
    builder.addCase(accountsCreate.fulfilled, (state, { payload }) => {
      const accounts = [
        ...state.accounts,
        {
          ...payload,
          isActive: false,
          hasSSO: false,
        },
      ]
      state.accounts = sortBy(accounts, 'name')
    })
    builder.addCase(accountRolesDelete.fulfilled, (state, { payload }) => {
      if (state.id !== payload.adminId) return
      state.accounts = state.accounts.filter(a => a.id !== payload.accountId)
    })
    builder.addCase(authenticationsSwitch.fulfilled, (state, { payload }) => {
      state.accounts = state.accounts.map(a => ({
        ...a,
        isActive: a.id === payload.accountId,
      }))
    })
    builder.addCase(updateAccountName.fulfilled, (state, { payload }) => {
      state.accounts = state.accounts.map(account => {
        if (account.id !== payload.id) return account

        account.name = payload.name
        return account
      })
    })
  },
})
