import classNames from 'classnames'
import { FC, ReactElement } from 'react'
import { Link as DOMLink, useLocation } from 'react-router-dom'
import r from 'routes'

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './LinkList.module.scss'

type LinkProps = {
  icon: IconDefinition | FC
  path: string
  children: string
}

type Props = {
  isCondensed: boolean
  children: (ReactElement<LinkProps> | false)[]
}

const LinkList: FC<Props> = ({ isCondensed, children }) => {
  const location = useLocation()

  return (
    <ul className={classNames(s.List, { [s.CondensedList]: isCondensed })}>
      {(children.filter(c => !!c) as ReactElement<LinkProps>[]).map(({ props }, i) => (
        <li key={i}>
          <DOMLink
            to={props.path}
            className={classNames({
              [s.Active]:
                props.path === r.root
                  ? location.pathname === r.root
                  : location.pathname.match(props.path),
            })}
          >
            <div>
              {typeof props.icon === 'function' ? (
                <props.icon />
              ) : (
                <FontAwesomeIcon icon={props.icon} />
              )}
            </div>
            <p>{props.children}</p>
          </DOMLink>
        </li>
      ))}
    </ul>
  )
}

export const Link: FC<LinkProps> = () => null

export default LinkList
