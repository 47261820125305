import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePipelineSelector } from 'store'
import { t } from 'translation'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import Button from 'components/atoms/Button'
import ConfirmButton from 'components/atoms/ConfirmButton'
import Field from 'components/molecules/Field'

import useDelete from './useDelete'
import useSubmit from './useSubmit'

export type FormData = {
  entityId: string
  acsUrl: string
}

const SAMLForm: FC = () => {
  const { pipeline } = usePipelineSelector()

  const { handleSubmit, register, setError, errors } = useForm<FormData>()

  const [onDelete, { timer: deleteTimer }] = useDelete()

  const [onSubmit, { timer }] = useSubmit(pipeline.id, setError)

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)

  return (
    <>
      <h4 className="mt-12 mb-4 std">{t('Service Provider (SP) configuration')}</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          ref={register}
          id="entity_id"
          name="entityId"
          autoComplete="off"
          label={t('Entity ID')}
          placeholder="https://my.service.com/identifier"
          defaultValue={pipeline.saml?.entityId}
          error={errors.entityId}
          className="flex-grow mb-4"
          inputClassName="py-2 px-3"
        />
        <Field
          ref={register}
          id="acsUrl"
          name="acsUrl"
          autoComplete="off"
          label={t('Assertion Consumer Service (ACS) URL')}
          error={errors.acsUrl}
          placeholder="https://my.service.com/acs"
          defaultValue={pipeline.saml?.acsUrl}
          className="flex-grow"
          inputClassName="py-2 px-3"
        />

        {showAdvancedSettings && (
          <>
            <hr />
            <Field label="Custom SAML attributes" name="customAttributes" className="mt-4">
              &hellip;
            </Field>
            <hr />
          </>
        )}

        <div className="flex justify-between mt-8">
          <div className="flex items-center space-x-4">
            <Button type="submit" isLoading={timer.isLoading} size="lg">
              {timer.didSucceed ? 'Saved!' : 'Save'}
            </Button>
            <span
              className="text-xs text-gray-600 cursor-pointer"
              onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
            >
              {showAdvancedSettings ? 'basic settings' : 'advanced settings'}
            </span>
          </div>
          {pipeline.saml?.entityId && (
            <ConfirmButton
              color="text-red-600"
              loadingText={t('Deleting...')}
              timer={deleteTimer}
              onConfirm={onDelete}
            >
              {t('Clear settings')}
            </ConfirmButton>
          )}
        </div>
      </form>
    </>
  )
}
export default SAMLForm
