import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import r from 'routes'
import { useIframeSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { retrieveIFrame } from 'thunks/api/iframes/retrieve'

type RouteParams = {
  id?: string
}

export default function useEnsureIframe() {
  const { id } = useParams<RouteParams>()
  const { iframe } = useIframeSelector()

  const history = useHistory()
  const location = useLocation()

  const [retrieve] = useAPI(retrieveIFrame)
  useEffect(() => {
    if (iframe.id === id) return

    if (!id) {
      history.push(r.iframes.root)
      return
    }

    const isNewIframe = location.pathname.match(r.iframes.create.configure.new)
    if (isNewIframe) {
      return
    }

    retrieve({ id })
  }, [iframe.id, id, retrieve, history, location.pathname])

  // TODO
  /**
   * Redirect based on whether the iframe is published
   */
  useEffect(() => {
    if (!iframe.id) return

    const phase = location.pathname.split('/')[2]
    if (phase === 'create') {
      // history.push(r.iframes.create.root(iframe.id))
    } else if (phase === 'edit') {
      // history.push(r.iframes.edit.root(iframe.id))
    }
  }, [iframe, location, history])

  return iframe.id === id
}
