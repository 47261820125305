import { forwardRef, Fragment, useImperativeHandle, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { t } from 'translation'

import Loading from 'components/atoms/Loading'
import { useListEvents } from 'hooks/api/useListEvents'
import { Event, EventWithPipeline } from 'types/event'

import useSessions from './_useSessions'
import s from './Activity.module.scss'
import EventItem from './EventItem'

export type Interface = {
  prependEvents: (events: Event[]) => void
}

type Props = {
  userId: string
}

const Activity = forwardRef(({ userId }: Props, ref) => {
  const [events, { prepend, loadMore, hasMore }] = useListEvents(userId)
  const sessions = useSessions(events)

  const [newEventIds, setNewEventIds] = useState<string[]>([])
  useImperativeHandle(ref, () => ({
    prependEvents(events: EventWithPipeline[]) {
      prepend(events)
      setNewEventIds(events.map(e => e.id))
    },
  }))

  if (typeof events === 'undefined') {
    return <Loading className="mt-32" size="5x" />
  }

  return (
    <div className={s.List}>
      <h2 className={s.Title}>{t('Activity')}</h2>
      {events.length > 0 ? (
        <InfiniteScroll
          pageStart={0}
          hasMore={hasMore}
          loadMore={loadMore}
          loader={
            <div key={0} className="flex justify-center mt-8">
              <Loading />
            </div>
          }
          useWindow={false}
        >
          {sessions.map(session => (
            <Fragment key={session.id}>
              <p className={s.TimeInterval}>{session.label}</p>
              <ul>
                {session.events.map(event => (
                  <EventItem key={event.id} event={event} isNew={newEventIds.includes(event.id)} />
                ))}
              </ul>
            </Fragment>
          ))}
        </InfiniteScroll>
      ) : (
        <p>{t('No recent activity')}</p>
      )}
    </div>
  )
})

export default Activity
