import { FC } from 'react'

import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'
import TOTPRegistrationPreview from 'components/templates/stages/TOTPRegistration.Preview'

const TOTPRegistration: FC = () => {
  return <NoSettingsStage stageType="totp-registration" preview={TOTPRegistrationPreview} />
}

export default TOTPRegistration
