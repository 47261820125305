import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { updateBrandStyle } from 'thunks/api/branding/update'
import { adminBootstrap } from 'thunks/api/admin-bootstrap'
import { authenticationsSwitch } from 'thunks/api/authentications/switch'

export type Branding = {
  id?: string
  primaryColor: string
  logo?: string
}

export type BrandingState = {
  branding?: Branding
}

const initialState: BrandingState = {}

function handleBranding(branding: Branding) {
  const s = document.documentElement.style
  s.setProperty('--theme-primary', branding.primaryColor)
}

function resetBranding() {
  const s = document.documentElement.style
  s.setProperty('--theme-primary', 'var(--default-theme-primary)')
}

const slice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    updateLocalBranding: (state, action: PayloadAction<Branding>) => {
      handleBranding(action.payload)
      return {
        ...state,
        branding: action.payload,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(updateBrandStyle.fulfilled, (state, { payload }) => {
      if (state.branding && state.branding.id !== payload.id) return
      state.branding = payload
      handleBranding(payload)
    })
    builder.addCase(adminBootstrap.fulfilled, (state, { payload }) => {
      state.branding = payload.branding
      payload.branding ? handleBranding(payload.branding) : resetBranding()
    })
    builder.addCase(authenticationsSwitch.fulfilled, (state, { payload }) => {
      state.branding = payload.branding
      payload.branding ? handleBranding(payload.branding) : resetBranding()
    })
  },
})

export default slice
export const actions = slice.actions
