import { FC } from 'react'

import { t } from 'translation'

const OidcIntegration: FC = () => (
  <>
    <h3 className="mb-1 std">{t('OIDC Integration')}</h3>
    <p className="mb-8 std">
      {t('The configuration endpoint is:')}{' '}
      <a href={`${window.location.origin}/api/.well-known/openid-configuration`}>
        {window.location.origin}/api/.well-known/openid-configuration
      </a>
      <br />
      <br />
      {t(
        'The protocol is strictly followed, but there is some additional data added to the userinfo endpoint.'
      )}{' '}
      <a
        href="https://developer.journeyid.com/reference/system-oauth2-user-info"
        target="_blank"
        rel="noreferrer"
      >
        {t('See here for the response')}
      </a>{' '}
      {t('which augments the user data with info about the pipeline they just completed.')}
    </p>
  </>
)

export default OidcIntegration
