import classNames from 'classnames'
import { FC } from 'react'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import styles from 'components/atoms/Settings/index.module.scss'

import { ReactComponent as Illustration } from './enterprise.svg'
import s from './index.module.scss'

const EnterpriseStage: FC = () => {
  return (
    <div className={classNames('__EnterpriseStage', styles.Settings, s.EnterpriseStage)}>
      <div>
        <Illustration className="h-48" />
        <h4 className="std">{t('This is an Enterprise Feature')}</h4>
        <p className="std-sm">
          {t(
            `If you'd like to add this action to your pipeline, there are a few integration steps that are required. Please contact us at support@journeyid.com to complete this.`
          )}
        </p>
        <Button size="xl" onClick={() => (window.location.href = 'mailto:support@journeyid.com')}>
          {t('Contact Us')}
        </Button>
      </div>
    </div>
  )
}

export default EnterpriseStage
