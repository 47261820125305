import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'
import * as yup from 'yup'

import Button from 'components/atoms/Button'
import PhoneNumberInputConnected from 'components/atoms/PhoneNumberInputConnected'
import Header from 'components/molecules/AdminHeader'
import Field from 'components/molecules/Field'
import { useCreateCustomer, ValidationErrorCallback } from 'hooks/api/useCreateCustomer'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { Request } from 'thunks/api/customers/create'
import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'

import { useUniqueID } from './_useUniqueID'
import s from './index.module.scss'

type FormData = Omit<Request, 'accountId'>

const schema = yup.object().shape({
  uniqueId: yup
    .string()
    .required(t('A Unique ID is required'))
    .min(8, t('Unique ID must be at least 8 characters')),
  firstName: yup.string().required(t('A first name is required')),
  lastName: yup.string().required(t('A last name is required')),
  email: yup.string().when('phoneNumber', {
    is: null,
    then: yup.string().email().required(t('An email or phone number is required')),
    otherwise: yup.string().email().notRequired(),
  }),
  phoneNumber: yup.string().nullable().notRequired(),
})

const NewCustomer = () => {
  const history = useHistory()
  const resolver = useYupValidationResolver(schema)
  const form = useForm<FormData>({ resolver })
  const { handleSubmit, register, errors, watch, setValue, setError } = form

  useUniqueID({ setValue, watch })

  const [create, { timer }] = useCreateCustomer(setError as ValidationErrorCallback)

  const onCreate = useCallback(
    (req: Omit<Request, 'accountId'>) => {
      create({ ...req, phoneNumber: req.phoneNumber ? `+${req.phoneNumber}` : undefined }).then(
        res => {
          if (res) history.push(r.customers.show(res.id))
        }
      )
    },
    [create, history]
  )

  return (
    <ContentWrapper>
      <Header title={t('Customers')} backRoute={r.customers.root} />
      <div className={s.Content}>
        <div>
          <div>
            <div className={s.Form}>
              <p className="mb-2 text-lg font-semibold text-theme">{t('Add a customer')}</p>
              <hr className="mb-3 text-gray-500" />
              <FormProvider {...form}>
                <form onSubmit={handleSubmit(onCreate)}>
                  <div className="flex flex-col space-y-2 md:space-x-4 md:flex-row md:space-y-0">
                    <Field
                      ref={register}
                      name="firstName"
                      autoFocus
                      autoComplete="off"
                      id="firstName"
                      label={t('First Name')}
                      error={errors.firstName}
                      maxLength={25}
                      className="flex-grow md:w-[45%]"
                      inputClassName="py-2 px-3"
                    />

                    <Field
                      ref={register}
                      name="lastName"
                      autoComplete="off"
                      id="lastName"
                      label={t('Last Name')}
                      error={errors.lastName}
                      maxLength={25}
                      className="flex-grow xl:ml-5 md:w-[45%]"
                      inputClassName="py-2 px-3"
                    />
                  </div>

                  <Field
                    ref={register}
                    name="email"
                    autoComplete="off"
                    id="email"
                    label={t('Email')}
                    error={errors.email}
                    maxLength={50}
                    className="my-3"
                    inputClassName="py-2 px-3"
                  />

                  <Field label={t('Phone number')} name="phoneNumber" error={errors.phoneNumber}>
                    <PhoneNumberInputConnected name="phoneNumber" />
                  </Field>

                  <Field
                    ref={register}
                    name="uniqueId"
                    autoComplete="off"
                    id="uniqueId"
                    label={t('Unique ID')}
                    error={errors.uniqueId}
                    maxLength={28}
                    className="my-3"
                    inputClassName="py-2 px-3"
                  />

                  <hr className="my-3 text-gray-500" />
                  <Button type="submit" isLoading={timer.isLoading} className={s.SaveBtn}>
                    {t('Save customer')}
                  </Button>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default NewCustomer
