import { FC } from 'react'
import NumberFormat from 'react-number-format'

import { t } from 'translation'

import OptionList from 'components/organisms/OptionList'

import { DeploymentModel, deploymentModelCosts } from './costs'
import s from './index.module.scss'

type Props = {
  value: DeploymentModel
  discount: number | undefined
  onChange: (value: DeploymentModel) => void
}

const DeploymentModels: FC<Props> = ({ value, discount = 0, onChange }) => {
  return (
    <OptionList<DeploymentModel> name="deployment-model" value={value} onChange={onChange}>
      <OptionList.Item label={t('Global Cloud')} value="global-cloud" className={s.DeploymentModel}>
        <h2>
          {t(
            "There's no cost associated with using Journey's Global Cloud which is based in AWS datacenters in the US. This environment is fully managed by Journey, backed by an SLA."
          )}
        </h2>
        <div>{t('no additional cost')}</div>
      </OptionList.Item>
      <OptionList.Item label={t('Local Cloud')} value="local-cloud" className={s.DeploymentModel}>
        <h2>
          {t(
            'If your customer has data residency requirements, Journey will deploy our platform into any AWS datacenter in the world. The platform will mirror our US deployment and will be fully managed.'
          )}
        </h2>
        <div>
          $
          <NumberFormat
            value={deploymentModelCosts['local-cloud'] * (1 - discount)}
            thousandSeparator=","
            decimalScale={0}
            fixedDecimalScale
            displayType="text"
          />{' '}
          {t('/ month')}
        </div>
      </OptionList.Item>
      <OptionList.Item
        label={t('Single Tenant')}
        value="single-tenant"
        className={s.DeploymentModel}
      >
        <h2>
          {t(
            'If a customer has a requirement for single-tenancy, Journey will deploy our platform into any AWS datacenter in the world. Like the Local Cloud deployment, the platform will be a mirror of our Global Cloud deployment and will be fully managed. Service agreements can be negotiated.'
          )}
        </h2>
        <div>
          $
          <NumberFormat
            value={deploymentModelCosts['single-tenant'] * (1 - discount)}
            thousandSeparator=","
            decimalScale={0}
            fixedDecimalScale
            displayType="text"
          />{' '}
          {t('/ month')}
        </div>
      </OptionList.Item>
      <OptionList.Item label={t('On-Premise')} value="on-premise" className={s.DeploymentModel}>
        <h2>
          {t(
            "A fully custom solution, we're setup and train your internal teams to run a Journey environment in your existing environments. Cost depends on the size of the integration and time required of the Journey team. A service agreement is required in this deployment model."
          )}
        </h2>
        <div>
          $
          <NumberFormat
            value={deploymentModelCosts['on-premise'] * (1 - discount)}
            thousandSeparator=","
            decimalScale={0}
            fixedDecimalScale
            displayType="text"
          />
          {t('+ / month')}
        </div>
      </OptionList.Item>
    </OptionList>
  )
}

export default DeploymentModels
