import classNames from 'classnames'
import { FC, FormEvent, useCallback, useState } from 'react'
import { t } from 'translation'
import { Pipeline } from 'types'

import { StageType } from '@journeyid/agent/types'

import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import { ActionTimer } from 'hooks/useActionTimer'
import { Gateway } from 'thunks/api/gateways/list'
import { stageTitleMap } from 'util/stage-titles'

import s from './DeliveryMethodList.module.scss'

type Props = {
  pipeline: Pipeline
  gateways: Gateway[]
  addTimer: ActionTimer
  removeTimer: ActionTimer
  onAdd: (stageType: StageType, gateway: Gateway['codename']) => Promise<void>
  onRemove: (stageType: StageType, gateway: Gateway['codename']) => Promise<void>
}

const DeliveryMethodList: FC<Props> = ({
  pipeline,
  gateways,
  addTimer,
  removeTimer,
  onAdd,
  onRemove,
}) => {
  const [formStageType, setFormStageType] = useState<StageType>()
  const [codename, setCodename] = useState<Gateway['codename']>()

  const handleCancel = useCallback(() => {
    setFormStageType(undefined)
    setCodename(undefined)
  }, [])

  const handleSubmit = useCallback(
    (stageType: StageType) => async (e: FormEvent) => {
      e.preventDefault()
      if (!codename) return
      await onAdd(stageType, codename)
      handleCancel()
    },
    [codename, handleCancel, onAdd]
  )

  const getNickname = useCallback(
    (codename: string) => gateways.find(g => g.codename === codename)?.nickname,
    [gateways]
  )

  return (
    <ul className={s.UrlList}>
      {pipeline.stages.map(stage => (
        <li key={stage.type}>
          <div>
            <p>{stageTitleMap[stage.type]}</p>
            {stage.type !== formStageType ? (
              <Button variant="link" onClick={() => setFormStageType(stage.type)}>
                {t('Add')}
              </Button>
            ) : (
              <Button variant="link" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
            )}
          </div>
          {!!gateways.length &&
            pipeline.destinations &&
            Array.isArray(pipeline.destinations[stage.type]) &&
            pipeline.destinations[stage.type]!.length > 0 && (
              <ul className={classNames('__Destinations', s.Destinations)}>
                {pipeline.destinations[stage.type]?.map(destination => (
                  <li key={destination.gateway}>
                    <label>{getNickname(destination.gateway)}</label>
                    <Button
                      variant="link"
                      onClick={() => onRemove(stage.type, destination.gateway)}
                    >
                      {removeTimer.isLoading &&
                      removeTimer.id === `${stage.type}-${destination.gateway}`
                        ? t('removing...')
                        : t('remove')}
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          {stage.type === formStageType && (
            <form onSubmit={handleSubmit(stage.type)}>
              <Select value={codename} onChangeText={setCodename}>
                <option value="">{t('Select a Gateway')}</option>
                {gateways.map(gateway => (
                  <option key={gateway.codename} value={gateway.codename}>
                    {gateway.nickname}
                  </option>
                ))}
              </Select>
              <Button
                type="submit"
                isLoading={addTimer.isLoading}
                disabled={addTimer.isLoading}
                variant="secondary"
              >
                {addTimer.isLoading ? t('Saving...') : t('Save')}
              </Button>
            </form>
          )}
        </li>
      ))}
    </ul>
  )
}

export default DeliveryMethodList
