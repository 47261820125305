import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StageType } from '@journeyid/agent/types'

import { t } from 'translation'

import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import DeleteStageModal from 'pages/Pipelines/Create/ConfigureStages/DeleteStageModal'
import { removeStage } from 'thunks/api/pipelines/remove-stage'

type Props = {
  stageType: StageType
}

const RemoveStage: FC<Props> = ({ stageType }) => {
  const { pipeline } = usePipelineSelector()
  const history = useHistory()

  const [deleteStage, { timer }] = useAPI(removeStage)
  const handleDelete = useCallback(async () => {
    const idx = pipeline.stages.findIndex(s => s.type === stageType)
    const nextIdx = idx === pipeline.stages.length - 1 ? idx - 1 : idx + 1
    if (nextIdx >= 0) {
      const nextStageType = pipeline.stages[nextIdx].type
      history.push(r.pipelines.create.configure.stage(pipeline.id, nextStageType))
    } else {
      history.push(r.pipelines.create.configure.stages(pipeline.id))
    }

    deleteStage({
      id: pipeline.id,
      stageType,
    })
  }, [pipeline, stageType, deleteStage, history])

  if (pipeline.key) return null

  return (
    <>
      <button
        className="flex items-center text-gray-600 hover:text-gray-900"
        onClick={openModal('DeleteStage')}
      >
        <FontAwesomeIcon icon={faCircleXmark} className="h-5 w-5 mr-[7px]" />
        {t('Remove this action')}
      </button>
      <DeleteStageModal timer={timer} onDelete={handleDelete} onCancel={closeModal} />
    </>
  )
}

export default RemoveStage
