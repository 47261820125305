import { User } from 'types/user'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

export const retrieveUser = asyncThunk<Request, User>({
  name: 'users/retrieve',
  uri: ({ id }) => `/users/${id}/profile`,
  orchestrator: false,
  stripId: true,
})
