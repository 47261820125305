import { FC } from 'react'

import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu } from '@headlessui/react'

import { openModal } from 'hooks/useModal'
import AgentReportModal from 'pages/shared/Users/List/AgentReportModal'

const ExportAgentReport: FC = () => {
  return (
    <>
      <Menu as="div" className="relative h-full ml-4 text-left">
        <div className="flex items-center">
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white text-sm font-semibold text-gray-400 hover:text-gray-700">
            <FontAwesomeIcon icon={faEllipsisVertical} aria-hidden="true" className="w-5 h-5 " />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 z-10 mt-4 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
          <div className="py-1">
            <Menu.Item>
              <div
                onClick={openModal('AgentReport')}
                className="block px-4 py-2 text-sm text-gray-700 cursor-pointer data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-gray-100 hover:text-gray-900"
              >
                Export agent report
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>

      <AgentReportModal />
    </>
  )
}

export default ExportAgentReport
