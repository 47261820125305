import { PaymentConfiguration } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = { id: string }

export const retrievePaymentConfiguration = asyncThunk<Request, PaymentConfiguration>({
  name: 'accounts/payment-configuration',
  method: 'GET',
  uri: ({ id }) => `/accounts/${id}/payment-configuration`,
  orchestrator: false,
  stripId: true,
  throttle: false,
})
