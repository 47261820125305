import { FC } from 'react'
import { t } from 'translation'

import { PipelineResultsFor } from '@journeyid/agent/executions/results'
import { ConfigurationFor, StageType } from '@journeyid/agent/types'

import { Configuration } from 'types/execution'

import s from './StageData.module.scss'

type Stage<T extends StageType = StageType> = PipelineResultsFor<T> & {
  type: T
}

type Props = {
  stage: Stage
  configuration: Configuration
}

const StageData: FC<Props> = ({ stage, configuration }) => {
  return (
    <div className={s.StageData}>
      {(() => {
        switch (stage.type) {
          case 'document-signature':
            if (!configuration) return <></>
            const conf = configuration[stage.type] as ConfigurationFor<typeof stage.type>

            return (
              <>
                {conf?.variables && (
                  <ul>
                    {Object.keys(conf?.variables || {}).map((key, idx) => (
                      <li key={idx}>
                        <label>{key}:</label> {conf.variables[key]}
                      </li>
                    ))}
                  </ul>
                )}
                <img src={stage.results.signature} alt="" className="mt-2 ml-6 border rounded" />
              </>
            )

          case 'external-authentication':
            return (
              <ul>
                <li>
                  <label>{t('Source:')}</label> {stage.results.source}
                </li>
                <li>
                  <label>{t('Reference:')}</label> {stage.results.reference}
                </li>
              </ul>
            )
          default:
            return null
        }
      })()}
    </div>
  )
}

export default StageData
