import { FC, FormEvent, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector, useIframeSelector } from 'store'

import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import Footer from 'components/molecules/Footer'
import useActionTimer from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import ls from 'pages/IFrames/Steps/shared/LayoutWithFooter.module.scss'
import { createIFrame } from 'thunks/api/iframes/create'
import { updateIframe } from 'thunks/api/iframes/update'

import s from './index.module.scss'

const Title: FC = () => {
  const history = useHistory()
  const location = useLocation()

  const { accountId } = useAuthSelector()
  const { iframe } = useIframeSelector()

  const [title, setTitle] = useState(iframe.title)
  const [error, setError] = useState<string>()

  const [create] = useAPI(createIFrame)
  const [update] = useAPI(updateIframe)
  const timer = useActionTimer()

  const handleCreateIframe = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (!accountId) return

      try {
        if (title === '') {
          setError(t('Please enter Iframe title above.'))
          return
        }

        timer.start()
        const resp = await create({
          accountId,
          title,
        })

        const uri = r.iframes.create.configure.languages(resp.id)
        history.push(uri)
      } catch (err) {
        console.error('Unable to update iframe title:', err)
        timer.failed()
      }
    },
    [accountId, timer, create, title, history]
  )

  const handleUpdateTitle = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault()

        if (title === '') {
          setError(t('Please enter Iframe title above.'))
          return
        }

        timer.start()
        await update({
          id: iframe.id,
          title,
        })

        const route = r.iframes.edit.configure
        history.push(route.languages(iframe.id))
      } catch (err) {
        console.error('Unable to update iframe title:', err)
        timer.failed()
      }
    },
    [title, timer, update, iframe.id, history]
  )

  const isNew = location.pathname.match(r.iframes.create.configure.new)

  return (
    <form className={ls.Wrapper} onSubmit={isNew ? handleCreateIframe : handleUpdateTitle}>
      <div className={ls.ContentWrapper}>
        <div>
          <h1 className={s.Title}>{t('iFrame Title')}</h1>
          <p className={s.Description}>
            {t(
              'Create a title for your unique iFrame. This will be used to identify your iframe to implement in your call center software.'
            )}
          </p>
          <input
            type="text"
            className={s.Input}
            value={title}
            autoFocus
            onChange={e => {
              setError(undefined)
              setTitle(e.target.value)
            }}
          />
        </div>
      </div>
      <div>
        <Footer
          alert={error && <Alert type="error">{error}</Alert>}
          actions={
            <>
              <Button
                type="submit"
                isLoading={timer.isLoading}
                disabled={timer.isLoading}
                iconRight={timer.isLoading ? undefined : faArrowRight}
              >
                {timer.isLoading ? t('Saving...') : t('Save & Continue')}
              </Button>
            </>
          }
        />
      </div>
    </form>
  )
}

export default Title
