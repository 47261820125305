import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { faArrowRight, faEye } from '@fortawesome/pro-regular-svg-icons'
import { StageType } from '@journeyid/agent/types'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import TemplateAlert from 'components/atoms/TemplateAlert'
import Footer from 'components/molecules/Footer'
import NoSettings from 'components/molecules/NoSettings'
import PageHeader from 'components/molecules/PageHeader'
import Preview from 'components/organisms/Preview'
import { openModal } from 'hooks/useModal'
import PreviewModal from 'pages/Pipelines/Create/components/PreviewModal'
import { stageDescriptionMap } from 'util/stage-descriptions'
import { stageTitleMap } from 'util/stage-titles'

import s from './index.module.scss'

type Props = {
  stageType: StageType
  preview?: FC
}

const NoSettingsStage: FC<Props> = ({ stageType, preview: StagePreview }) => {
  const { pipeline } = usePipelineSelector()
  const history = useHistory()

  const handleContinue = useCallback(() => {
    const idx = pipeline.stages.findIndex(s => s.type === stageType)
    if (idx === -1) return
    const route = r.pipelines[pipeline.key ? 'edit' : 'create']
    if (idx < pipeline.stages.length - 1) {
      const stage = pipeline.stages[idx + 1]
      history.push(route.configure.stage(pipeline.id, stage.type))
    } else {
      history.push(route.configure.successScreen(pipeline.id))
    }
  }, [pipeline, stageType, history])

  return (
    <div id={`stage-${stageType}`} className={s.Wrapper}>
      <div className={s.Content}>
        <PageHeader
          title={stageTitleMap[stageType]}
          tip={<TemplateAlert stageType={stageType} templateId={pipeline.template} />}
        >
          {stageDescriptionMap[stageType]}
        </PageHeader>
        <div className="flex pb-8 pr-8">
          <NoSettings />
          {StagePreview && (
            <Preview>
              <StagePreview />
            </Preview>
          )}
        </div>
      </div>
      <Footer
        removeStage={stageType}
        actions={
          <>
            <Button variant="secondary" icon={faEye} onClick={openModal('Preview', stageType)}>
              {t('Preview Pipeline')}
            </Button>
            <Button iconRight={faArrowRight} onClick={handleContinue}>
              {t('Continue')}
            </Button>
          </>
        }
      />
      <PreviewModal />
    </div>
  )
}

export default NoSettingsStage
