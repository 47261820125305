import { FC, lazy, Suspense } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AdminLayout from 'components/atoms/AdminLayout'
import { useAuthMonitor } from 'hooks/useAuthMonitor'
import useBootstrap from 'hooks/useBootstrap'
import AccountList from 'pages/Accounts/List'
import ActivityFeedIndex from 'pages/ActivityFeed/Index'
import NewAgent from 'pages/Agents/New'
import BrandStylesList from 'pages/BrandStyles/List'
import BrandStylesNew from 'pages/BrandStyles/New'
import BrandStylesShow from 'pages/BrandStyles/Show'
import NewCustomer from 'pages/Customers/New'
import IFrames from 'pages/IFrames'
import Pipelines from 'pages/Pipelines'
import Settings from 'pages/Settings'
import ListUsers from 'pages/shared/Users/List'
import ShowUser from 'pages/shared/Users/Show'

const ImportAgents = lazy(() => import('pages/Agents/Import'))

const Admin: FC<RouteProps> = () => {
  const auth = useAuthSelector()
  useAuthMonitor()
  const isBootstrapped = useBootstrap()

  if (!isBootstrapped) {
    return (
      <div className="flex justify-center mt-32">
        <FontAwesomeIcon icon={faSpinnerThird} size="6x" spin className="text-gray-300" />
      </div>
    )
  }

  return (
    <AdminLayout>
      <Suspense fallback={<></>}>
        <Switch key={auth.accountId}>
          <Route path={r.root} exact component={ActivityFeedIndex} />
          <Route path={r.accounts.root} exact component={AccountList} />
          <Route path={r.agents.root} exact>
            <ListUsers type="agent" />
          </Route>
          <Route path={r.agents.new} exact component={NewAgent} />
          <Route path={r.agents.import} exact component={ImportAgents} />
          <Route path={r.agents.show()} exact>
            <ShowUser type="agent" />
          </Route>
          <Route path={r.customers.root} exact>
            <ListUsers type="customer" />
          </Route>
          <Route path={r.customers.new} exact component={NewCustomer} />
          <Route path={r.customers.show()} exact>
            <ShowUser type="customer" />
          </Route>
          <Route path={r.pipelines.root} component={Pipelines} />
          <Route path={r.iframes.root} component={IFrames} />
          <Route path={r.brandStyles.root} component={BrandStylesList} exact />
          <Route path={r.brandStyles.new} component={BrandStylesNew} exact />
          <Route path={r.brandStyles.show()} component={BrandStylesShow} exact />
          <Route path={r.settings.root} component={Settings} />
          <Redirect to={r.root} />
        </Switch>
      </Suspense>
    </AdminLayout>
  )
}

export default Admin
