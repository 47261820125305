import cogoToast from 'cogo-toast'
import { FC, KeyboardEvent, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import useSwitchAccount from 'hooks/api/authentication/useSwitchAccount'
import { Account } from 'types/account'

import useAccounts from './_useAccounts'
import AccountList from './AccountList'
import s from './Accounts.module.scss'
import Search from './Search'

type Props = {
  closeMenu: () => void
}

const Accounts: FC<Props> = ({ closeMenu }) => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const { accountId } = useAuthSelector()
  const accounts = useAccounts(search)
  const [switchAccount, { timer: switchTimer }] = useSwitchAccount()

  const handleSwitch = useCallback(
    async (account: Account) => {
      switchTimer.setId(account.id)
      await switchAccount(account)
      closeMenu()
      cogoToast.success(`Switched to ${account.name}`)
    },
    [closeMenu, switchAccount, switchTimer]
  )

  const handleKeyDown = useCallback(
    async (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter' || accounts.length === 0 || switchTimer.isLoading) {
        return
      }

      handleSwitch(accounts[0])
    },
    [accounts, switchTimer, handleSwitch]
  )

  return (
    <>
      <div className={s.Header}>
        <h2>{t('Accounts')}</h2>
        <button
          onClick={() => {
            closeMenu()
            history.push(r.accounts.root)
          }}
        >
          {t('View all')}
        </button>
      </div>

      <Search search={search} setSearch={setSearch} handleKeyDown={handleKeyDown} />

      <AccountList
        accounts={accounts}
        activeAccountId={accountId}
        timer={switchTimer}
        onSelect={handleSwitch}
      />
    </>
  )
}

export default Accounts
