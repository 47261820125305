import { FC, useCallback, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { t } from 'translation'
import { Pipeline } from 'types'
import * as yup from 'yup'

import blur10 from 'assets/etc/blur-examples/tmp.10.jpg'
import blur20 from 'assets/etc/blur-examples/tmp.20.jpg'
import blur30 from 'assets/etc/blur-examples/tmp.30.jpg'
import blur40 from 'assets/etc/blur-examples/tmp.40.jpg'
import blur50 from 'assets/etc/blur-examples/tmp.50.jpg'
import blur60 from 'assets/etc/blur-examples/tmp.60.jpg'
import blur70 from 'assets/etc/blur-examples/tmp.70.jpg'
import blur80 from 'assets/etc/blur-examples/tmp.80.jpg'
import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Button from 'components/atoms/Button'
import CopyButton from 'components/atoms/CopyButton'
import Input from 'components/atoms/Input'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import Slider from 'components/atoms/Slider'
import Toggle from 'components/atoms/Toggle'
import Header from 'components/molecules/AdminHeader'
import Field from 'components/molecules/Field'
import TimeInput from 'components/molecules/TimeInput'
import PipelineSelect from 'components/organisms/PipelineSelect'
import { useAccount } from 'hooks/api/useAccount'
import { useUpdateAgentAuth } from 'hooks/api/useUpdateAgentAuth'
import { useAPI } from 'hooks/useAPI'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'
import { testWebhook } from 'thunks/api/webhooks/test'
import objectKeys from 'util/object-keys'

import PipelineSelector from './_PipelineSelector'
import s from './index.module.scss'

type BlurType = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80

const BLUR_EXAMPLES: { [K in BlurType]: string } = {
  10: blur10,
  20: blur20,
  30: blur30,
  40: blur40,
  50: blur50,
  60: blur60,
  70: blur70,
  80: blur80,
}

type FormData = {
  enrollmentPipelineId?: string
  loginIdentifier?: string
  passiveAuthDuration: number
  webhookUrl: string
  imageRetentionPolicy: 'viewable' | 'uncollected'
  dontStoreSuccessImages: boolean
  blurAmount: BlurType
  isLivenessDisabled: boolean
  isMultipleFaceRedundancyDisabled: boolean
  samlEntityId: string
  samlMetadataUrl: string
  samlEnrollmentPipelineKey: string
  isCellphoneDetectionEnabled: boolean
}

const schema = yup.object().shape({
  passiveAuthDuration: yup
    .number()
    .integer()
    .default(30)
    .required(t('A valid passive auth duration must be provided'))
    .min(15, t('Duration must be at least 15 minutes'))
    .max(24 * 60 * 60, t('Duration must not exceed 24 hours')),
  // loginIdentifier: yup.string().required('A login URL identifier is required'),
})

const AgentAuth: FC = () => {
  const resolver = useYupValidationResolver(schema)
  const form = useForm<FormData>({
    resolver,
    shouldUnregister: false,
    defaultValues: {
      enrollmentPipelineId: '',
      loginIdentifier: '',
      passiveAuthDuration: 30,
      webhookUrl: '',
      imageRetentionPolicy: 'viewable',
      dontStoreSuccessImages: false,
      blurAmount: 40,
      isLivenessDisabled: false,
      isMultipleFaceRedundancyDisabled: false,
      isCellphoneDetectionEnabled: false,
    },
  })
  const { register, handleSubmit, errors, setValue, watch } = form

  const [account, { timer: accountTimer }] = useAccount()

  const pipelineFilter = useCallback(
    (p: Pipeline) => p.stages.some(s => s.type === 'face-enrollment') && !!p.email,
    []
  )

  useEffect(() => {
    if (!account?.agentAuthentication) return

    const config = account.agentAuthentication
    // @ts-ignore
    objectKeys(config).forEach(key => setValue(key, config[key]))
  }, [account, setValue])

  const [update, { timer }] = useUpdateAgentAuth()
  const onSubmit = useCallback(
    (data: FormData) => {
      if (!account) return

      update(data)
    },
    [account, update]
  )

  const [test, { timer: testTimer }] = useAPI(testWebhook)
  const webhookUrl = watch('webhookUrl')
  const handleSendTest = useCallback(async () => {
    test({ url: webhookUrl })
  }, [webhookUrl, test])

  const imageRetentionPolicy = watch('imageRetentionPolicy')

  // console.log('errors', imageRetentionPolicy, errors)
  // console.log('as', form.formState.errors, pipelineId)

  return (
    <FormProvider {...form}>
      <form
        className="h-full"
        onSubmit={e => {
          form.clearErrors()
          handleSubmit(onSubmit)(e)
        }}
      >
        <ContentWrapper withFooter>
          <Header title={t('Settings')} />
          <div className={s.Content}>
            <Menu />
            <div>
              <p className="mt-4 mb-8 text-4xl font-semibold">{t('Agent Authentication')}</p>

              {accountTimer.isLoading ? (
                <Loading />
              ) : (
                <>
                  <SettingsCard title={t('Agent Enrollment')}>
                    <Controller
                      control={form.control}
                      name="enrollmentPipelineId"
                      render={({ onChange, value }) => (
                        <PipelineSelect
                          filter={pipelineFilter}
                          initialPipelineId={value}
                          onSelect={p => onChange(p?.id)}
                          title="Enrollment Pipeline"
                        />
                      )}
                    />
                    <p className="text-sm text-gray-600">
                      {t(
                        'Note: only pipelines which include a facial enrollment stage and have email delivery configured can be used for agent enrollment.'
                      )}
                    </p>
                  </SettingsCard>

                  {/* <SettingsCard title={t('Login URL')}>
                    <Field name="loginIdentifier" error={errors.loginIdentifier}>
                      <InputGroup
                        ref={register}
                        name="loginIdentifier"
                        prefix={`${_env_.AUTH_ORIGIN}/`}
                      />
                    </Field>
                    {account?.agentAuthentication?.loginIdentifier && (
                      <a
                        href={`${_env_.AUTH_ORIGIN}/${account.agentAuthentication.loginIdentifier}`}
                      >
                        {_env_.AUTH_ORIGIN}/{account.agentAuthentication.loginIdentifier}
                      </a>
                    )}
                  </SettingsCard> */}
                  <SettingsCard title="Passive Authentication Window">
                    <Controller
                      name="passiveAuthDuration"
                      control={form.control}
                      render={({ value }) => (
                        <TimeInput
                          disableSecond
                          disableDay
                          error={errors.passiveAuthDuration}
                          initialDuration={value * 60}
                          onChangeDuration={duration =>
                            setValue('passiveAuthDuration', duration / 60)
                          }
                        />
                      )}
                    />
                  </SettingsCard>

                  <SettingsCard title={t('Webhook URL')}>
                    <p className="mb-2 text-sm text-gray-600">
                      {t(
                        'When agent authentication events occur, webhook notifications will be sent to this url.'
                      )}{' '}
                      <a
                        href="/etc/Webhook-Integration-Guide.2021-05-19.pdf"
                        target="_blank"
                        className="underline"
                      >
                        {t('Click here')}
                      </a>{' '}
                      {t('to view documentation on the webhook payloads.')}
                    </p>
                    <div className="flex w-full space-x-4">
                      <Input
                        ref={register}
                        name="webhookUrl"
                        placeholder="https://mycompany.com/path/to/webhook"
                        autoComplete="off"
                        className="w-full"
                      />
                      <Button
                        variant="secondary"
                        disabled={!webhookUrl.match(/^https:\/\//)}
                        onClick={handleSendTest}
                      >
                        {testTimer.isLoading
                          ? t('Sending...')
                          : testTimer.didSucceed
                          ? t('Sent!')
                          : t('Send Test')}
                      </Button>
                    </div>
                  </SettingsCard>

                  <SettingsCard title={t('Image Policies')}>
                    <Controller
                      name="imageRetentionPolicy"
                      control={form.control}
                      render={({ value, onChange }) => (
                        <>
                          <Toggle
                            className={s.Toggle}
                            enabled={value === 'viewable'}
                            onChange={value => onChange(value ? 'viewable' : 'uncollected')}
                          >
                            Image retention is Enabled / Disabled
                          </Toggle>
                          <p className="mb-4 text-sm text-gray-600 ml-14">
                            Images taken will be stored when "ON"
                          </p>
                        </>
                      )}
                    />
                    <Controller
                      name="dontStoreSuccessImages"
                      control={form.control}
                      render={({ value, onChange }) => (
                        <>
                          <Toggle
                            className={s.Toggle}
                            enabled={!value}
                            onChange={v => onChange(!v)}
                          >
                            {value
                              ? "Successful authentication images won't be retained"
                              : 'Successful authentication images will be retained'}
                          </Toggle>
                        </>
                      )}
                    />

                    <Controller
                      name="blurAmount"
                      control={form.control}
                      render={({ value, onChange }) => (
                        <>
                          {imageRetentionPolicy === 'viewable' && (
                            <>
                              <hr className="mt-6 mb-4" />
                              <p className="mb-2 text-xl font-bold">Blur amount</p>
                              <p className="text-sm text-gray-600">
                                You can configure how much the background is blurred when an image
                                is taken during passive authentications.
                              </p>
                              <div className="h-12 my-2">
                                <Slider
                                  value={value}
                                  min={10}
                                  max={80}
                                  step={10}
                                  className="mx-20"
                                  filledClassName="bg-theme"
                                  handleClassName={s.SliderHandle}
                                  unfilledClassName="bg-gray-300"
                                  onChangeValue={onChange}
                                />
                              </div>
                              <img alt="Blur Example" src={BLUR_EXAMPLES[value as BlurType]} />
                            </>
                          )}
                        </>
                      )}
                    />
                  </SettingsCard>

                  <SettingsCard title="Detection Settings" className="space-y-6">
                    <div>
                      <Controller
                        name="isLivenessDisabled"
                        control={form.control}
                        render={({ value, onChange }) => (
                          <>
                            <Toggle
                              className={s.Toggle}
                              enabled={!value}
                              onChange={value => onChange(!value)}
                            >
                              {value ? 'Liveness check disabled' : 'Liveness check enabled'}
                            </Toggle>
                            <p className="text-sm text-gray-600 ml-14">
                              {value
                                ? 'Only facial matching will be used during active and passive authentication'
                                : 'Liveness will be checked during active and passive authentication'}
                            </p>
                          </>
                        )}
                      />
                    </div>

                    <div>
                      <Controller
                        name="isMultipleFaceRedundancyDisabled"
                        control={form.control}
                        render={({ value, onChange }) => (
                          <>
                            <Toggle
                              className={s.Toggle}
                              enabled={!value}
                              onChange={value => onChange(!value)}
                            >
                              {value
                                ? 'Use a single method to detect multiple faces'
                                : 'Use a combination of methods to detect multiple faces'}
                            </Toggle>
                            <p className="text-sm text-gray-600 ml-14">
                              {value
                                ? 'Limiting the methods for detecting multiple faces can cause false positives in some agent environments.'
                                : "If too many multiple face detections aren't being caught in your agents' environments, you can disable this setting."}
                            </p>
                          </>
                        )}
                      />
                    </div>
                  </SettingsCard>

                  <SettingsCard title="Cellphone detection" className="space-y-6">
                    <div>
                      <Controller
                        name="isCellphoneDetectionEnabled"
                        control={form.control}
                        render={({ value, onChange }) => (
                          <>
                            <Toggle
                              className={s.Toggle}
                              enabled={value}
                              onChange={value => onChange(value)}
                            >
                              {value
                                ? 'Cellphone detection is enabled'
                                : 'Cellphone detection is disabled'}
                            </Toggle>
                            <p className="text-sm text-gray-600 ml-14">
                              With this setting enabled, the system will attempt to detect if a
                              cellphone is visible in the camera frame.
                            </p>
                          </>
                        )}
                      />
                    </div>
                  </SettingsCard>

                  <SettingsCard title="SSO SAML Configuration" className="space-y-6">
                    <p className="text-sm">
                      In your IDP, set the SP Entity ID and the Reply URL / ACS URL to{' '}
                      <code className="px-2 py-1 mr-2 text-xs bg-gray-100 rounded">
                        {window.location.origin}/api/agent/saml/acs
                      </code>
                      <CopyButton content={`${window.location.origin}/api/agent/saml/acs`} />
                    </p>
                    <Field
                      ref={register}
                      id="samlEntityId"
                      name="samlEntityId"
                      label={t('Entity ID')}
                      autoComplete="off"
                      error={errors.samlEntityId}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <Field
                      ref={register}
                      id="samleMetadataUrl"
                      name="samlMetadataUrl"
                      label={t('Metadata URL')}
                      autoComplete="off"
                      error={errors.samlMetadataUrl}
                      className={s.Input}
                      inputClassName="!text-xs"
                      wrapperClassName="mt-0"
                    />
                    <PipelineSelector
                      ref={register}
                      name="samlEnrollmentPipelineKey"
                      label={t('Enrollment pipeline')}
                      error={errors.samlEnrollmentPipelineKey}
                    />
                  </SettingsCard>
                </>
              )}
            </div>
          </div>
          <Footer showValidationError={Object.keys(errors).length > 0} timer={timer} />
        </ContentWrapper>
      </form>
    </FormProvider>
  )
}

export default AgentAuth
