import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { t } from 'translation'

import Button from 'components/atoms/Button'
import PhoneNumberInput from 'components/atoms/PhoneNumberInput'
import Select from 'components/atoms/Select'
import Field from 'components/molecules/Field'
import useListAgents from 'hooks/api/useListAgents'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { closeModal, openModal } from 'hooks/useModal'
import ls from 'pages/IFrames/Steps/shared/LayoutWithoutFooter.module.scss'
import useAgent from 'pages/IFrames/Steps/Test/useAgent'
import useIFrame, { Payload } from 'pages/IFrames/Steps/Test/useIFrame'

import AdvancedSimulatorModal from './AdvancedSimulator'
import s from './index.module.scss'

const TestIframe: FC = () => {
  const [agents] = useListAgents()
  const { uniqueId, agentToken, setUniqueId } = useAgent()
  const [phoneNumber, setPhoneNumber] = useLocalStorage('iframePhoneNumber')
  const { ref, handleStartCall, handlePostMessage } = useIFrame(phoneNumber)

  const handlePayload = useCallback(
    (payload: Payload) => {
      handlePostMessage(payload)
      closeModal()
    },
    [handlePostMessage]
  )

  const url = `https://${_env_.IFRAME_ORIGIN}/?token=${agentToken}&call[operator]=twilio`

  return (
    <div className={ls.Wrapper}>
      <div className={classNames(ls.ContentWrapper, s.Wrapper)}>
        <div>
          <h1 className={s.Title}>{t('Test the iFrame')}</h1>
          <p className={classNames(s.Description, 'w-[600px]')}>
            {t(
              "Select an agent below and enter a phone number to simulate a call coming into the call center. You'll be able to send pipeline requests to the phone number specified."
            )}
          </p>
          <form onSubmit={handleStartCall} className={s.Inputs}>
            <Field name="agentId" label={t('Select an agent')}>
              <Select
                name="agentId"
                label={t('Select an agent')}
                className="w-[342px]"
                disabled={!agents}
                value={uniqueId}
                onChangeText={setUniqueId}
              >
                <option key="" value=""></option>
                {agents &&
                  agents.map(agent => (
                    <option key={agent.id} value={agent.uniqueId}>
                      {agent.lastName}, {agent.firstName}
                    </option>
                  ))}
              </Select>
            </Field>
            <Field name="phoneNumber" label={t('Enter a phone number')} className={s.Phone}>
              <PhoneNumberInput name="phoneNumber" value={phoneNumber} onChange={setPhoneNumber} />
            </Field>
            <Button type="submit" variant="secondary">
              {t('Start Call')}
            </Button>
          </form>
        </div>
        <div className="w-full mb-6">
          {agentToken ? (
            <iframe
              ref={ref}
              title="Journey"
              src={url}
              allow="clipboard-write"
              className="w-full h-[500px]"
            />
          ) : (
            <div className={s.EmptyIframeWrapper}>
              <p>
                {t('Select an Agent &')} <br /> {t('Enter a phone number above')}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-between w-full mb-10">
          {agents && (
            <>
              <Button variant="secondary" onClick={openModal('AdvancedSimulator')}>
                {t('Advanced Simulator')}
              </Button>
              <div>
                <span
                  className="text-xs opacity-0 cursor-pointer hover:text-gray-400 hover:opacity-50"
                  onClick={() =>
                    ref.current?.contentWindow?.postMessage(
                      { event: 'end-session' },
                      `https://${_env_.IFRAME_ORIGIN}`
                    )
                  }
                >
                  {t('end call')}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <AdvancedSimulatorModal onSend={handlePayload} />
    </div>
  )
}
export default TestIframe
