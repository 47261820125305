import { FC } from 'react'

import { t } from 'translation'

import DataPrivacy from 'components/organisms/Preview/DataPrivacy'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

import { ReactComponent as PassportPhotoPage } from './assets/passport-photo-page.svg'

const PassportPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <main>
        <Header headline={t('Passport verification')}>
          {t('Scan the photo page of your Passport')}
        </Header>

        <div className="flex items-center justify-center px-10 py-8 my-8 bg-gray-100">
          <PassportPhotoPage className="h-32" />
        </div>
      </main>
      <footer>
        <DataPrivacy />
      </footer>
    </>
  )
}

export default PassportPreview
