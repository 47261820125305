import { FC, useMemo } from 'react'

import { StageType } from '@journeyid/agent/types'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import pipelineTemplates from 'pages/Pipelines/Create/UseCase/pipeline-templates'
import { stageTitleMap } from 'util/stage-titles'

type Props = {
  stageType: StageType
  templateId?: string
}

const TemplateAlert: FC<Props> = ({ stageType, templateId }) => {
  const template = useMemo(() => {
    if (!templateId) return
    for (let i = 0; i < pipelineTemplates.length; i++) {
      const category = pipelineTemplates[i]
      for (let j = 0; j < category.pipelines.length; j++) {
        const pipeline = category.pipelines[j]
        if (pipeline.id === templateId && pipeline.stageTypes.includes(stageType)) {
          return pipeline
        }
      }
    }
  }, [stageType, templateId])

  if (!template) return null

  const word = template.label.match(/^[aeio]/i) ? 'an' : 'a'

  return (
    <Alert type="info" className="mt-4">
      {t('{{stage}} is required for {{word}} {{label}} pipeline.', {
        stage: stageTitleMap[stageType],
        word,
        label: template.label,
      })}{' '}
      <a href="#!">{t('Learn more')}</a>
    </Alert>
  )
}

export default TemplateAlert
