import { FC, useCallback, useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'

import { t } from 'translation'

import ErrorMessage from 'components/molecules/ErrorMessage'

import s from './index.module.scss'

const DURATION_TYPES = {
  second: 1,
  minute: 60,
  hour: 3600,
  day: 86400,
}

type DurationType = keyof typeof DURATION_TYPES

const getDuration = (length: number, type: DurationType) => length * DURATION_TYPES[type]

type Props = {
  disableSecond?: boolean
  disableMinute?: boolean
  disableHour?: boolean
  disableDay?: boolean
  error?: FieldError
  initialDuration?: number
  initialLength?: number
  initialType?: DurationType
  maxDuration?: number
  minDuration?: number
  onChangeDuration: (duration: number) => void
}

const TimeInput: FC<Props> = ({
  disableSecond,
  disableMinute,
  disableHour,
  disableDay,
  error,
  initialDuration,
  initialLength,
  initialType,
  maxDuration,
  minDuration,
  onChangeDuration,
}) => {
  const [length, _setLength] = useState<number>(1)
  const [type, _setType] = useState<DurationType>('second')

  useEffect(() => {
    if (initialDuration) {
      if (!disableDay && initialDuration % DURATION_TYPES.day === 0) {
        _setLength(initialDuration / DURATION_TYPES.day)
        _setType('day')
      } else if (!disableHour && initialDuration % DURATION_TYPES.hour === 0) {
        _setLength(initialDuration / DURATION_TYPES.hour)
        _setType('hour')
      } else if (!disableMinute && initialDuration % DURATION_TYPES.minute === 0) {
        _setLength(initialDuration / DURATION_TYPES.minute)
        _setType('minute')
      } else if (!disableSecond) {
        _setLength(initialDuration)
        _setType('second')
      }
    } else if (initialLength && initialType) {
      _setLength(initialLength)
      _setType(initialType)
    } else {
      if (!disableSecond) {
        _setType('second')
      } else if (!disableMinute) {
        _setType('minute')
      } else if (!disableHour) {
        _setType('hour')
      } else {
        _setType('day')
      }
    }
  }, [
    disableDay,
    disableHour,
    disableMinute,
    disableSecond,
    initialDuration,
    initialLength,
    initialType,
  ])

  useEffect(() => {
    onChangeDuration(getDuration(length, type))
  }, [length, type, onChangeDuration])

  const setLengthType = useCallback(
    (length: number, type: DurationType) => {
      const duration = getDuration(length, type)
      if (maxDuration && maxDuration < duration) return
      if (minDuration && minDuration > duration) return
      _setLength(length)
      _setType(type)
    },
    [maxDuration, minDuration]
  )

  const setLength = useCallback(
    (length: number) => setLengthType(length, type),
    [setLengthType, type]
  )

  const setType = useCallback(
    (type: DurationType) => length && setLengthType(length, type),
    [setLengthType, length]
  )

  const pluralize = (text: string) => (length !== 1 ? `${text}s` : text)

  return (
    <div>
      <h3 className={s.Title}>Expiration</h3>
      <div className={s.InputWrapper}>
        <input
          type="number"
          className={`${s.Input} w-[106px]`}
          onChange={e => setLength(e.target.valueAsNumber)}
          value={isNaN(length) ? '' : length}
        />
        <select
          name="time"
          className={`${s.Input} w-[164px]`}
          onChange={e => setType(e.target.value as DurationType)}
          value={type}
        >
          {!disableSecond && <option value="second">{t(pluralize('Second'))}</option>}
          {!disableMinute && <option value="minute">{t(pluralize('Minute'))}</option>}
          {!disableHour && <option value="hour">{t(pluralize('Hour'))}</option>}
          {!disableDay && <option value="day">{t(pluralize('Day'))}</option>}
        </select>
      </div>
      <ErrorMessage error={error} />
    </div>
  )
}

export default TimeInput
