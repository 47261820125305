import { useCallback } from 'react'

import { useAPI } from 'hooks/useAPI'
import { exportAgentActivity } from 'thunks/api/agents/export-activity'
import { downloadFile } from 'util/download-csv'

export default function useDownloadAgentReport() {
  const [exportActivity, { timer }] = useAPI(exportAgentActivity)
  const handleDownload = useCallback(
    async (startDate: string, endDate: string) => {
      const { filename, content } = await exportActivity({
        startDate,
        endDate,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      downloadFile(filename, `data:text/csv;charset=utf-8,${content}`)
    },
    [exportActivity]
  )

  return [handleDownload, timer] as const
}
