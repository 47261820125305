import { useEffect, useState } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { retrieveSSOSaml } from 'thunks/api/accounts/retrieve-sso-saml'
import { AccountSSO } from 'types/sso'

type SAML = NonNullable<AccountSSO['saml2']>

export function useRetrieveSSOSaml() {
  const [saml, setSaml] = useState<SAML>()
  const [run, { timer }] = useAPI(retrieveSSOSaml)
  const { accountId } = useAuthSelector()

  useEffect(() => {
    if (!accountId) return
    run({ id: accountId }).then(res => setSaml(res.saml))
  }, [accountId, setSaml, run])

  return [saml, { timer }] as const
}
