import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

type Response = Iframe

export const retrieveIFrame = asyncThunk<Request, Response>({
  name: 'iframes/retrieve',
  method: 'GET',
  uri: ({ id }) => `/iframes/${id}`,
  stripId: true,
  throttle: false,
})
