import { User } from 'types/user'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  accountId: string
  uniqueId: string
  firstName: string
  lastName: string
  email?: string
  phoneNumber?: string
}

export const createCustomer = asyncThunk<Request, User<'customer'>>({
  name: 'customers/create',
  method: 'POST',
  uri: '/customers',
  orchestrator: false,
})
