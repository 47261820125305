import { FC, useCallback } from 'react'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import RevokeClientSecretModal from 'pages/Pipelines/Edit/integration/SSO-OIDC/components/RevokeClientSecretModal'
import { createSecret } from 'thunks/api/pipelines/create-secret'
import { revokeSecret } from 'thunks/api/pipelines/revoke-secret'

import ClientSecretList from './ClientSecretList'

const ClientSecrets: FC = () => {
  const { pipeline } = usePipelineSelector()

  const [create, { timer }] = useAPI(createSecret)
  const handleCreateSecret = useCallback(async () => {
    create({
      id: pipeline.id,
    })
  }, [pipeline, create])

  const [revoke, { timer: revokeTimer }] = useAPI(revokeSecret)
  const handleRevoke = useCallback(async () => {
    await revoke({
      id: pipeline.id,
    })
    closeModal()
  }, [pipeline, revoke])

  return (
    <>
      <h4 className="mb-4 std">{t('Client Secret')}</h4>

      {pipeline.oauth?.secret?.id ? (
        <>
          <ClientSecretList
            secret={pipeline.oauth.secret}
            onRevoke={openModal('RevokeClientSecret')}
          />
          {pipeline.oauth.secret.secretKey && (
            <div className="text-sm font-semibold text-red-600">
              {t('When the page is refreshed, the secret will be hidden, so be sure to copy it.')}
            </div>
          )}
          <p className="pt-2 pb-4 text-sm">
            {t('Need a new client secret? Revoke the existing one first.')}
          </p>
        </>
      ) : (
        <Button
          variant="secondary"
          disabled={timer.isLoading}
          className="mt-4 mb-8"
          onClick={handleCreateSecret}
        >
          {timer.isLoading ? t('Generating a new secret...') : t('Generate a client secret')}
        </Button>
      )}

      <RevokeClientSecretModal timer={revokeTimer} onRevoke={handleRevoke} onCancel={closeModal} />
    </>
  )
}

export default ClientSecrets
