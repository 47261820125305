import classNames from 'classnames'
import { FC } from 'react'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  className?: string
  size?: '1x' | '2x' | '3x' | '4x' | '5x'
}

const Loading: FC<Props> = ({ className, size = '5x' }) => (
  <FontAwesomeIcon
    icon={faSpinnerThird}
    className={classNames('mx-auto text-gray-300', className)}
    size={size}
    spin
  />
)

export default Loading
