import { FC } from 'react'

import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'
import TOTPAuthenticationPreview from 'components/templates/stages/TOTPAuthentication.Preview'

const TOTPAuthentication: FC = () => {
  return <NoSettingsStage stageType="totp-authentication" preview={TOTPAuthenticationPreview} />
}

export default TOTPAuthentication
