import classNames from 'classnames'
import { FC, useMemo, useState } from 'react'
import { usePipelineSelector } from 'store'
import { Controller } from 'swiper'
import { t } from 'translation'

import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StageType } from '@journeyid/agent/types'

import PhoneMockup from 'components/atoms/PhoneMockup'
import Modal from 'components/organisms/Modal'
import Safari from 'components/organisms/Preview/_Safari'
import styles from 'components/organisms/Preview/index.module.scss'
import AddressCollectionPreview from 'components/templates/stages/AddressCollection.Preview'
import CreditCardPaymentPreview from 'components/templates/stages/CreditCardPayment/Preview'
import DocumentCollectionPreview from 'components/templates/stages/DocumentCollection.Preview'
import DocumentSignaturePreview from 'components/templates/stages/DocumentSignature.Preview'
import DriversLicensePreview from 'components/templates/stages/DriversLicense.Preview'
import FaceAuthenticationPreview from 'components/templates/stages/FaceAuthentication.Preview'
import FaceEnrollmentPreview from 'components/templates/stages/FaceEnrollment.Preview'
import FacialAuthenticationPreview from 'components/templates/stages/FacialAuthentication.Preview'
import FacialAuthentication3DPreview from 'components/templates/stages/FacialAuthentication3D.Preview'
import FacialEnrollmentPreview from 'components/templates/stages/FacialEnrollment.Preview'
import FormPreview from 'components/templates/stages/Form/Preview'
import GovernmentIDPreview from 'components/templates/stages/GovernmentID.Preview'
import GovernmentIDSelfiePreview from 'components/templates/stages/GovernmentIDSelfie.Preview'
import KnowledgeBasedAuthenticationPreview from 'components/templates/stages/KnowledgeBasedAuthentication.Preview'
import NationalIDCardPreview from 'components/templates/stages/NationalIDCard.Preview'
import OCRPreview from 'components/templates/stages/OCR.Preview'
import PassportPreview from 'components/templates/stages/Passport.Preview'
import PhoneNumberRegistration from 'components/templates/stages/PhoneNumberRegistration.Preview'
import PhoneNumberVerificationPreview from 'components/templates/stages/PhoneNumberVerification.Preview'
import SchedulePreview from 'components/templates/stages/Schedule.Preview'
import SocialSecurityNumberPreview from 'components/templates/stages/SocialSecurityNumber.Preview'
import SurveyPreview from 'components/templates/stages/Survey.Preview'
import TOTPAuthenticationPreview from 'components/templates/stages/TOTPAuthentication.Preview'
import TOTPRegistrationPreview from 'components/templates/stages/TOTPRegistration.Preview'
import WebAuthnRegistrationPreview from 'components/templates/stages/WebAuthnRegistration.Preview'
import { closeModal, useModal } from 'hooks/useModal'
import { useRerender } from 'hooks/useRerender'
import SuccessScreenPreview from 'pages/shared/SuccessScreen/Preview'
import { PipelinesState } from 'slices/pipelines'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'

import s from './index.module.scss'

const previews: Partial<Record<StageType, FC<any>>> = {
  'address-collection': AddressCollectionPreview,
  'credit-card-payment': CreditCardPaymentPreview,
  'document-collection': DocumentCollectionPreview,
  'document-signature': DocumentSignaturePreview,
  'drivers-license': DriversLicensePreview,
  'face-authentication': FaceAuthenticationPreview,
  'face-enrollment': FaceEnrollmentPreview,
  'facial-authentication-3d': FacialAuthentication3DPreview,
  'facial-authentication': FacialAuthenticationPreview,
  'facial-enrollment': FacialEnrollmentPreview,
  form: FormPreview,
  'government-id': GovernmentIDPreview,
  'government-id-selfie': GovernmentIDSelfiePreview,
  'knowledge-based-authentication': KnowledgeBasedAuthenticationPreview,
  'national-id-card': NationalIDCardPreview,
  ocr: OCRPreview,
  passport: PassportPreview,
  'phone-number-registration': PhoneNumberRegistration,
  'phone-number-verification': PhoneNumberVerificationPreview,
  schedule: SchedulePreview,
  'social-security-number': SocialSecurityNumberPreview,
  survey: SurveyPreview,
  'totp-authentication': TOTPAuthenticationPreview,
  'totp-registration': TOTPRegistrationPreview,
  'webauthn-authentication': WebAuthnRegistrationPreview,
  'webauthn-registration': WebAuthnRegistrationPreview,
}

type Props = {
  pipeline?: PipelinesState['pipelines'][number]
}

const PreviewModal: FC<Props> = ({ pipeline: propPipeline }) => {
  const [isOpen, stageType] = useModal('Preview')

  const { pipeline: statePipeline } = usePipelineSelector()

  const pipeline = propPipeline || statePipeline

  const idx = useMemo(() => {
    const idx = pipeline.stages.findIndex(s => s.type === stageType)
    if (idx !== -1) return idx
    if (stageType === 'success') return pipeline.stages.length
    return 0
  }, [pipeline, stageType])

  const [swiper, setSwiper] = useState<SwiperType>()

  const rerender = useRerender()

  return (
    <Modal isOpen={isOpen} onClose={closeModal} className="!px-5 !pt-5 !pb-8 z-10">
      <div className="relative flex justify-center">
        <PhoneMockup>
          <Safari className={s.Preview}>
            <Swiper
              modules={[Controller]}
              onSwiper={setSwiper}
              onSlideChange={rerender}
              controller={{ control: swiper }}
              initialSlide={idx}
            >
              {pipeline.stages.map((stage, i) => (
                <SwiperSlide key={i}>
                  <div className={styles.Stage}>
                    <section>
                      {(() => {
                        const Preview = previews[stage.type]
                        if (!Preview)
                          return (
                            <div className="px-6 pt-12 tracking-wide text-center">
                              {t(
                                "This Pipeline action doesn't have a customer facing user interface."
                              )}
                            </div>
                          )
                        return <Preview {...stage} />
                      })()}
                    </section>
                  </div>
                </SwiperSlide>
              ))}
              <SwiperSlide>
                <div className={styles.Stage}>
                  <section>
                    <SuccessScreenPreview {...pipeline.success} />
                  </section>
                </div>
              </SwiperSlide>
            </Swiper>
          </Safari>
        </PhoneMockup>

        <button
          className={classNames('absolute left-0 swiper-button-prev', s.NavigationBtn, {
            'swiper-button-disabled': swiper?.activeIndex === 0,
          })}
          onClick={() => swiper?.slidePrev()}
        ></button>
        <button
          className={classNames('absolute right-0 swiper-button-next', s.NavigationBtn, {
            'swiper-button-disabled': swiper?.activeIndex === pipeline.stages.length,
          })}
          onClick={() => swiper?.slideNext()}
        ></button>

        <div
          className={`swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal ${s.PaginationWrapper}`}
        >
          {[...Array(pipeline.stages.length + 1)].map((_, i) => (
            <span
              key={i}
              className={classNames('swiper-pagination-bullet', s.PaginationBullet, {
                'swiper-pagination-bullet-active': swiper?.activeIndex === i,
              })}
              onClick={() => swiper?.slideTo(i)}
            />
          ))}
        </div>
      </div>
      <div className={s.CloseButton}>
        <button onClick={closeModal}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
    </Modal>
  )
}

export default PreviewModal
