import { FC, useEffect, useMemo } from 'react'

import { t } from 'translation'

import { User } from 'types/user'
import phoneNumber from 'util/phoneNumber'

import DeliveryMethod from './DeliveryMethod'
import s from './DeliveryMethodList.module.scss'

import type { DeliveryMethod as DeliveryMethodType, Pipeline } from 'types'

const priority: DeliveryMethodType[] = ['sms', 'email', 'push-notification', 'url']

function isAvailable(user: User): Record<DeliveryMethodType, boolean> {
  return {
    sms: !!user.phoneNumbers && user.phoneNumbers.length > 0,
    email: !!user.email,
    'push-notification': !!user.devices && user.devices.length > 0,
    url: true,
  }
}
function isConfigured(pipeline: Pipeline): Record<DeliveryMethodType, boolean> {
  return {
    sms: !!pipeline.sms,
    email: !!pipeline.email,
    'push-notification': !!pipeline.pushNotification,
    url: true,
  }
}

type Props = {
  user: User
  delivery?: DeliveryMethodType
  pipeline?: Pipeline
  onChangeDelivery: (delivery?: DeliveryMethodType) => void
}

const DeliveryMethodList: FC<Props> = ({ user, delivery, pipeline, onChangeDelivery }) => {
  const available = useMemo(() => isAvailable(user), [user])
  const configured = useMemo(() => (pipeline ? isConfigured(pipeline) : undefined), [pipeline])

  useEffect(() => {
    if (!configured) return
    const method = priority.find(m => available[m] && configured[m])
    onChangeDelivery(method)
  }, [user, pipeline, available, configured, onChangeDelivery])

  return (
    <div>
      <h3 className={s.Title}>{t('Delivery Method')}</h3>
      <ul className={s.List}>
        <DeliveryMethod
          isActive={delivery === 'sms'}
          isConfigured={configured ? configured['sms'] : undefined}
          isAvailable={available['sms']}
          notAvailableText={t('no phone numbers are available')}
          method="sms"
          onClick={() => onChangeDelivery('sms')}
        >
          {user.phoneNumbers && user.phoneNumbers.length > 0
            ? t('Send to {{number}}', { number: phoneNumber(user.phoneNumbers[0]) })
            : ''}
        </DeliveryMethod>

        <DeliveryMethod
          isActive={delivery === 'email'}
          isConfigured={configured ? configured['email'] : undefined}
          isAvailable={available['email']}
          notAvailableText={t('no email addresses are available')}
          method="email"
          onClick={() => onChangeDelivery('email')}
        >
          {t('Send to {{email}}', { email: user.email })}
        </DeliveryMethod>

        <DeliveryMethod
          isActive={delivery === 'push-notification'}
          isConfigured={configured ? configured['push-notification'] : undefined}
          isAvailable={available['push-notification']}
          notAvailableText={t('no devices are available')}
          method="push-notification"
          onClick={() => onChangeDelivery('push-notification')}
        >
          {!!user.devices && user.devices.length > 0 ? user.devices[0].nickname : ''}
        </DeliveryMethod>

        <DeliveryMethod
          isConfigured={configured ? configured['url'] : undefined}
          isAvailable={available['url']}
          isActive={delivery === 'url'}
          notAvailableText=""
          method="url"
          onClick={() => onChangeDelivery('url')}
        >
          {t('Generate shareable link')}
        </DeliveryMethod>
      </ul>
    </div>
  )
}

export default DeliveryMethodList
