import asyncThunk, { ID } from 'util/asyncThunk'

export type Request = {
  uniqueId: string
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
  sendEnrollmentInvitation: boolean
  language: 'en'
  groupIds?: string[]
}

export const createAgent = asyncThunk<Request, { id: ID }>({
  name: 'agents/create',
  method: 'POST',
  uri: '/agents',
  orchestrator: false,
})
