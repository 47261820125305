import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch, useAuthSelector } from 'store'

import { actions } from 'slices/auth'

import { useWatchLocalStorage } from './useWatchLocalStorage'

export function useAuthMonitor() {
  const dispatch = useAppDispatch()
  const { token, role } = useAuthSelector()

  const lsToken = useWatchLocalStorage('token')
  useEffect(() => {
    if (lsToken === token) return

    dispatch(actions.reloadJWT())
  }, [token, lsToken, dispatch])

  const history = useHistory()
  useEffect(() => {
    if (token) return
    if (history.location.pathname === r.auth.login) return

    history.push(r.auth.login)
  }, [token, history])

  return role
}
