import classNames from 'classnames'
import { forwardRef } from 'react'
import PhoneInputRaw from 'react-phone-input-2'

import styles from 'components/atoms/Input/index.module.scss'
import 'react-phone-input-2/lib/style.css'

import s from './index.module.scss'

// @ts-ignore
const PhoneInput = PhoneInputRaw.default ? PhoneInputRaw.default : PhoneInputRaw

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> & {
  name: string
  onChange?: (value: string) => void
}

const PhoneNumberInput = forwardRef<HTMLInputElement, Props>(
  ({ name, className, ...rest }, ref) => {
    return (
      <PhoneInput
        ref={ref}
        country="us"
        enableSearch
        // specialLabel="Incoming Phone Number"
        placeholder="+1 (###) ###-####"
        disableSearchIcon={true}
        inputProps={{
          type: '',
          className: classNames('__Input', styles.Input, 'w-full pl-16', className),
        }}
        buttonClass={s.DropdownButton}
        searchClass={s.DropdownSearch}
        {...rest}
      />
    )
  }
)

export default PhoneNumberInput
