import { useState } from 'react'
import { Link } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import CopyButton from 'components/atoms/CopyButton'
import SettingsCard from 'components/atoms/SettingsCard'
import Header from 'components/molecules/AdminHeader'
import { useCreateToken } from 'hooks/api/useCreateToken'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'
import ClientCredentials from 'pages/Settings/tabs/Developer/_ClientCredentials'

import s from './index.module.scss'

const Developer = () => {
  const { accountId } = useAuthSelector()

  const [keyType, setKeyType] = useState<'key' | 'refresh'>('key')
  const [tokenDuration, setTokenDuration] = useState<number>(30)
  const [tokenInterval, setTokenInterval] = useState<'hour' | 'day'>('day')

  const pluralize = (text: string) => (tokenDuration > 1 ? `${text}s` : text)

  const [createToken, { timer }] = useCreateToken()
  const [token, setToken] = useState<string>()

  const generate = () => {
    createToken({
      type: keyType,
      ttl: tokenDuration * (tokenInterval === 'hour' ? 3600 : 86400),
    }).then(res => res && setToken(res.token))
  }

  return (
    <ContentWrapper withFooter>
      <Header title={t('Settings')} />
      <div className={s.Content}>
        <Menu />
        <div>
          <p className="mt-4 mb-8 text-4xl font-semibold">
            <Link to={r.settings.advanced} className="cursor-text">
              {t('Developer')}
            </Link>
          </p>
          <SettingsCard title={t('API Access')}>
            <p className="mb-5 text-gray-500">
              {t('To access the System API, generate an API authentication token below.')}
            </p>

            <p className="mb-6 text-sm text-gray-500">
              {t('Your Account ID is:')}{' '}
              <span className={s.AccountId}>
                {accountId}
                <CopyButton content={accountId} />
              </span>
            </p>

            <h3>{t('Token type')}</h3>
            <div className="flex mb-3 cursor-pointer" onClick={() => setKeyType('key')}>
              <input
                className="mt-1 mr-3 text-theme"
                type="radio"
                checked={keyType === 'key'}
                readOnly
              />
              <div>
                <p className="font-bold text-gray-900">{t('API Key')}</p>
                <p className="text-sm text-gray-500">{t('Create an API key')}</p>
              </div>
            </div>
            <div className="flex cursor-pointer" onClick={() => setKeyType('refresh')}>
              <input
                className="mt-1 mr-3 text-theme"
                type="radio"
                checked={keyType === 'refresh'}
                readOnly
              />
              <div>
                <p className="font-bold text-gray-900">{t('Refresh Key')}</p>
                <p className="text-sm text-gray-500">
                  {t('Create a refresh key. Refresh keys are used to')}{' '}
                  <a
                    href="https://developer.journeyid.com/reference/system-token"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    {t('generate short-lived API keys')}
                  </a>{' '}
                  {t('while the long-lived refresh key is only used to generate keys when needed.')}
                </p>
              </div>
            </div>

            <h3 className="mt-8">{t('Expiration')}</h3>
            <div className={s.TimeInputs}>
              <input
                type="number"
                min="1"
                className={`${s.TimeInput} w-[106px]`}
                value={tokenDuration}
                onChange={e => setTokenDuration(e.target.valueAsNumber)}
              />
              <select
                onChange={e => setTokenInterval(e.target.value as 'hour' | 'day')}
                name="time"
                className={`${s.TimeInput} w-[164px]`}
                value={tokenInterval}
              >
                <option value="day">{t(pluralize('Day'))}</option>
                <option value="hour">{t(pluralize('Hour'))}</option>
              </select>
            </div>

            {token && (
              <div className={s.Code}>
                <CopyButton content={token} />
                <div className="pr-8 overflow-hidden text-xs leading-4 text-green-700 break-words">
                  {token}
                </div>
              </div>
            )}

            <button className={s.GenerateBtn} onClick={() => !timer.isLoading && generate()}>
              {timer.isLoading ? (
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  className="mx-auto text-white"
                  size="1x"
                  spin
                />
              ) : (
                t('Generate Token')
              )}
            </button>
          </SettingsCard>
          <ClientCredentials />
        </div>
      </div>
      <Footer />
    </ContentWrapper>
  )
}

export default Developer
