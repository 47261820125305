import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useAuthSelector } from 'store'
import { t } from 'translation'
import { Iframe } from 'types'
import * as yup from 'yup'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Loading from 'components/atoms/Loading'
import Select from 'components/atoms/Select'
import SettingsCard from 'components/atoms/SettingsCard'
import Textarea from 'components/atoms/Textarea'
import Header from 'components/molecules/AdminHeader'
import ErrorMessage from 'components/molecules/ErrorMessage'
import Field from 'components/molecules/Field'
import { useRetrieveSSOSaml } from 'hooks/api/useRetrieveSSOSaml'
import { useUpdateSSOSaml, ValidationErrorCallback } from 'hooks/api/useUpdateSSOSaml'
import { useAPI } from 'hooks/useAPI'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'
import { listIFrames } from 'thunks/api/iframes/list'

import s from './SSO.module.scss'

type FormData = {
  domain: string
  entityId: string
  loginUrl: string
  certificate: string
  adminGroupId: string
  agentGroupId: string
  attributeEmail: string
  attributeFirstName: string
  attributeLastName: string
  attributeGroups: string
  agentIframeId: string
}

const schema = yup.object().shape({
  certificate: yup.string().when('entityId', {
    is: null,
    then: yup.string().notRequired(),
    otherwise: yup.string().required(t('The certificate must be specified')),
  }),
  entityId: yup.string().required(t('The entity ID must be specified')),
})

const SSO: FC = () => {
  const resolver = useYupValidationResolver(schema)
  const form = useForm<FormData>({ resolver, shouldUnregister: false })
  const { handleSubmit, register, errors, setError, setValue } = form

  const [saml, { timer: retrieveTimer }] = useRetrieveSSOSaml()

  useEffect(() => {
    if (saml) {
      setValue('domain', saml.domain)
      setValue('entityId', saml.entityId)
      setValue('loginUrl', saml.loginUrl)
      setValue('certificate', saml.certificate)
      setValue('adminGroupId', saml.adminGroupId)
      setValue('agentGroupId', saml.agentGroupId)
      setValue('attributeEmail', saml.attributeEmail)
      setValue('attributeFirstName', saml.attributeFirstName)
      setValue('attributeLastName', saml.attributeLastName)
      setValue('attributeGroups', saml.attributeGroups)
      setValue('agentIframeId', saml.agentIframeId)
    }
  }, [saml, setValue])

  const [update, { timer: updateTimer }] = useUpdateSSOSaml(setError as ValidationErrorCallback)

  const [iframeList] = useAPI(listIFrames)
  const { accountId } = useAuthSelector()
  const [iframes, setIframes] = useState<Pick<Iframe, 'id' | 'title'>[]>()
  useEffect(() => {
    if (!accountId) return
    iframeList({ accountId }).then(iframes => setIframes(iframes))
  }, [accountId, iframeList])

  return (
    <FormProvider {...form}>
      <form className="h-full" onSubmit={handleSubmit(update)}>
        <ContentWrapper withFooter>
          <Header title={t('Settings')} />
          <div className={s.Content}>
            <Menu />
            <div>
              <p className="mt-4 mb-8 text-4xl font-semibold">{t('Single Sign-On')}</p>
              {retrieveTimer.isLoading ? (
                <Loading />
              ) : (
                <SettingsCard>
                  <p className="mb-4 text-gray-500">
                    {t(
                      'These settings are for configuring Single Sign-on access for account administrators - those with access to this administrative dashboard. To configure SSO with a Journey Pipeline, please go to that specific Pipeline and configure SAML2 or OAuth from there.'
                    )}
                  </p>

                  <p className="mt-6 mb-3 text-sm font-bold">{t('Email domain')}</p>
                  <Field
                    ref={register}
                    id="domain"
                    name="domain"
                    className={s.Input}
                    autoComplete="off"
                    error={errors.domain}
                    placeholder="example-corp.com"
                  />
                  <p className="mb-8 text-sm text-gray-500">
                    {t(
                      'Email addresses ending in this domain will be redirected to your SSO provider.'
                    )}
                  </p>

                  <hr className="mt-4 mb-4" />

                  <p className="font-bold mb-ext-sm">{t('SAML2 Entity ID')}</p>
                  <Field
                    ref={register}
                    id="entityId"
                    name="entityId"
                    className={s.Input}
                    autoComplete="off"
                    error={errors.entityId}
                    placeholder="https://my.company.com/saml/acs"
                  />

                  <p className="font-bold mb-ext-sm">{t('Login URL')}</p>
                  <Field
                    ref={register}
                    id="loginUrl"
                    name="loginUrl"
                    className={s.Input}
                    autoComplete="off"
                    error={errors.loginUrl}
                    placeholder="https://sso-provider.com/saml2/login"
                  />

                  <p className="mb-2 font-bold text-gray-900">{t('Certificate')}</p>
                  <Textarea
                    ref={register}
                    name="certificate"
                    className={s.TextArea}
                    onChange={e => setValue('certificate', e.target.value)}
                  />
                  <ErrorMessage error={errors.certificate} />

                  <hr className="mt-8 mb-4" />
                  <p className="mb-4 text-gray-500">
                    {t(
                      'These are the SAML assertion attribute "Name" fields used for the admin or agent profile.'
                    )}
                  </p>
                  <p className="mb-3 text-sm font-bold">{t('Email address')}</p>
                  <Field
                    ref={register}
                    id="attributeEmail"
                    name="attributeEmail"
                    className={s.Input}
                    placeholder="Leave blank to use Subject.NameID.Value"
                    autoComplete="off"
                    error={errors.attributeEmail}
                  />
                  <p className="mb-3 text-sm font-bold">{t('First name')}</p>
                  <Field
                    ref={register}
                    id="attributeFirstName"
                    name="attributeFirstName"
                    className={s.Input}
                    autoComplete="off"
                    error={errors.attributeFirstName}
                  />
                  <p className="mb-3 text-sm font-bold">{t('Last name')}</p>
                  <Field
                    ref={register}
                    id="attributeLastName"
                    name="attributeLastName"
                    className={s.Input}
                    autoComplete="off"
                    error={errors.attributeLastName}
                  />
                  <p className="mb-3 text-sm font-bold">{t('Groups')}</p>
                  <Field
                    ref={register}
                    id="attributeGroups"
                    name="attributeGroups"
                    className={s.Input}
                    autoComplete="off"
                    error={errors.attributeGroups}
                  />

                  <hr className="mt-8 mb-4" />
                  <p className="mb-4 text-gray-500 ">
                    {t(
                      'These are the group identifiers from the SAML assertion that will determine what role the authenticated user has.'
                    )}
                  </p>
                  <p className="mb-3 text-sm font-bold">{t('Admin group ID')}</p>
                  <Field
                    ref={register}
                    id="adminGroupId"
                    name="adminGroupId"
                    className={s.Input}
                    autoComplete="off"
                    error={errors.adminGroupId}
                  />
                  <p className="mb-3 text-sm font-bold">{t('Agent group ID')}</p>
                  <Field
                    ref={register}
                    id="agentGroupId"
                    name="agentGroupId"
                    className={s.Input}
                    autoComplete="off"
                    error={errors.agentGroupId}
                  />

                  <hr className="mt-8 mb-4" />
                  <p className="mb-3 text-sm font-bold">
                    {t('Which iFrame will agents be authenticating into?')}
                  </p>
                  {iframes && (
                    <Field
                      ref={register}
                      id="agentIframeId"
                      name="agentIframeId"
                      className={s.Input}
                      autoComplete="off"
                      as="select"
                      error={errors.agentIframeId}
                    >
                      {iframes.map(iframe => (
                        <option key={iframe.id} value={iframe.id}>
                          {iframe.title}
                        </option>
                      ))}
                    </Field>
                  )}
                </SettingsCard>
              )}
            </div>
          </div>
          <Footer timer={updateTimer} />
        </ContentWrapper>
      </form>
    </FormProvider>
  )
}

export default SSO
