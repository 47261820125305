const CSV_CELLS_DELIMITER = ','
const CSV_ROWS_DELIMITER = '\n'

export function createCSV(contentArray: string[][]) {
  return contentArray
    .map(row =>
      row
        .map(cell => `"${cell.toString().replace(/"/g, '\\"').replace(/^=/, '')}"`)
        .join(CSV_CELLS_DELIMITER)
    )
    .join(CSV_ROWS_DELIMITER)
}

export default function downloadCSV(filename: string, content: string[][]) {
  downloadFile(filename, `data:text/csv;charset=utf-8,${encodeURIComponent(createCSV(content))}`)
}

export function downloadFile(filename: string, href: string) {
  const a = document.createElement('a')

  a.textContent = 'download'
  a.download = filename
  a.href = href

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  })
  a.dispatchEvent(clickEvent)
}
