import { differenceInSeconds, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns'
import { i18nFormat, t } from 'translation'

export default function relativeDate(date: Date, precisePast = false) {
  if (!date) {
    return 'invalid date'
  }
  const time = i18nFormat(date, 'h:mmaaaaa')
  if (isToday(date)) {
    return t('Today at {{time}}', { time })
  }
  if (isYesterday(date)) {
    return t('Yesterday at {{time}}', { time })
  }
  if (isThisWeek(date)) {
    const day = i18nFormat(date, 'cccc')
    return t('{{day}} at {{time}}', { day, time })
  }
  if (!precisePast) {
    return i18nFormat(date, 'LLL do, yyyy')
  }
  if (isThisYear(date)) {
    const monthDay = i18nFormat(date, 'LLL do')
    return t('{{monthDay}} at {{time}}', { monthDay, time })
  }
  const fullDate = i18nFormat(date, 'LLL do, yyyy')
  return t('{{fullDate}} at {{time}}', { fullDate, time })
}

export function relativeDatePrecise(date: Date) {
  const seconds = differenceInSeconds(new Date(), date)
  if (seconds < 15) {
    return t('just now')
  }
  if (seconds < 90) {
    return t('a minute ago')
  }
  const minutes = Math.round(seconds / 60)
  if (minutes < 45) {
    return t('{{minutes}} minutes ago', { minutes })
  }
  const hours = Math.round(minutes / 60)
  if (hours === 1) {
    return t('1 hour ago')
  }
  if (hours <= 4) {
    return t('{{hours}} hours ago', { hours })
  }
  return relativeDate(date)
}
