import { HTTPRetrievedMultiple } from '@journeyid/agent/common/statuses'

import { Group } from 'types/group'
import asyncThunk from 'util/asyncThunk'

export type Request = {}

type Response = Group[]

export const listGroups = asyncThunk<Request, Response>({
  name: 'groups/list',
  method: 'GET',
  uri: '/groups',
  successStatus: HTTPRetrievedMultiple,
  orchestrator: false,
})
