import { FC, useCallback, useState } from 'react'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import { ActionTimer } from 'hooks/useActionTimer'

import s from './AuthorizedRedirectUrlList.module.scss'

type Props = {
  redirectUris: string[]
  timer: ActionTimer
  onRemove: (idx: number) => Promise<void>
}

const AuthorizedRedirectUrlList: FC<Props> = ({ redirectUris, timer, onRemove }) => {
  const [removeIdx, setRemoveIdx] = useState<number>()
  const handleRemove = useCallback(
    async (idx: number) => {
      setRemoveIdx(idx)
      await onRemove(idx)
      setRemoveIdx(undefined)
    },
    [onRemove]
  )

  return (
    <ul className={s.AuthorizedRedirectUrlList}>
      {redirectUris.map((redirectUri, idx) => (
        <li key={idx}>
          <p>{redirectUri}</p>
          <Button variant="link" onClick={() => handleRemove(idx)}>
            {timer.isLoading && removeIdx === idx ? t('removing...') : t('remove')}
          </Button>
        </li>
      ))}
    </ul>
  )
}

export default AuthorizedRedirectUrlList
