import { Iframe as IframeType } from 'types'

import asyncThunk from 'util/asyncThunk'
import { HTTPRetrievedMultiple } from 'util/statuses'

type Iframe = Pick<IframeType, 'id' | 'title'>

export type Request = {}

export const listIFrames = asyncThunk<Request, Iframe[]>({
  name: 'iframes/list',
  method: 'GET',
  uri: '/iframes',
  successStatus: HTTPRetrievedMultiple,
  throttle: false,
})
