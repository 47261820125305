import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useIframeSelector } from 'store'

import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { Language } from '@journeyid/agent/common/types/language'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import Footer from 'components/molecules/Footer'
import { useAPI } from 'hooks/useAPI'
import ls from 'pages/IFrames/Steps/shared/LayoutWithFooter.module.scss'
import { updateIframe } from 'thunks/api/iframes/update'

import CustomerLanguageList from './CustomerLanguageList'
import s from './index.module.scss'

type Lang = {
  id: Language
  name: string
}

const languages: Record<'agent' | 'customer', Lang[]> = {
  agent: [
    { id: 'en-US', name: t('English') },
    { id: 'es-MX', name: t('Spanish') },
    { id: 'pt-BR', name: t('Portuguese') },
    { id: 'ja-JP', name: t('Japanese') },
  ],
  customer: [
    { id: 'en-US', name: t('English') },
    { id: 'es-MX', name: t('Spanish') },
    { id: 'pt-BR', name: t('Portuguese') },
    { id: 'ja-JP', name: t('Japanese') },
  ],
}

const Languages = () => {
  const history = useHistory()

  const { iframe } = useIframeSelector()

  const [agentLanguage, setAgentLanguage] = useState(iframe.languages.agent)
  const [customerLanguages, setCustomerLanguages] = useState<string[]>(iframe.languages.customer)
  const [error, setError] = useState<string>()

  const [update, { timer: languagesTimer }] = useAPI(updateIframe)
  const handleUpdateLanguages = useCallback(async () => {
    setError(undefined)

    if (!iframe.id) {
      setError(t('Iframe id is empty.'))
      return
    }

    if (!agentLanguage) {
      setError(t('Agent language is empty.'))
      return
    }

    if (!customerLanguages.length) {
      setError(t('Customer languages is empty.'))
      return
    }

    try {
      await update({
        id: iframe.id,
        languages: {
          agent: agentLanguage,
          customer: customerLanguages,
        },
      })

      const phase = history.location.pathname.split('/')[3]
      const route = r.iframes[phase === 'create' ? 'create' : 'edit'].configure
      history.push(route.enrollmentAndAuthentication(iframe.id))
    } catch (err) {
      console.error('Unable to update iframe languages:', err)
    }
  }, [iframe.id, agentLanguage, customerLanguages, update, history])

  return (
    <div className={ls.Wrapper}>
      <div className={ls.ContentWrapper}>
        <div>
          <h1 className={s.Title}>{t('Languages')}</h1>
          <p className={s.Description}></p>
          <p className="mb-3 text-lg font-semibold">{t('Agent Language')}</p>
          <p className="mb-4 text-base text-gray-500">
            {t('This is the language that will be used in the iFrame when shown to agents.')}
          </p>

          <Select className="w-full mb-8" value={agentLanguage} onChangeText={setAgentLanguage}>
            {languages.agent.map(language => (
              <option key={language.id} value={language.id}>
                {language.name}
              </option>
            ))}
          </Select>

          <hr className="mb-4 border-gray-300 border-1" />

          <p className="mb-3 text-lg font-semibold">{t('Customer Languages')}</p>
          <p className="mb-4 text-base text-gray-500">
            {t(
              'Agents will be able to select any of the selected languages below when sending pipeline requests to users. You can select up to 3 languages.'
            )}
          </p>

          <CustomerLanguageList
            languages={languages.customer}
            selectedLanguages={customerLanguages ? customerLanguages : []}
            setSelectedLanguages={setCustomerLanguages}
          />
        </div>
      </div>
      <div>
        <Footer
          alert={error && <Alert type="error">{error}</Alert>}
          actions={
            <>
              <Button
                isLoading={languagesTimer.isLoading}
                disabled={languagesTimer.isLoading}
                iconRight={languagesTimer.isLoading ? undefined : faArrowRight}
                onClick={handleUpdateLanguages}
              >
                {languagesTimer.isLoading ? t('Saving...') : t('Save & Continue')}
              </Button>
            </>
          }
        />
      </div>
    </div>
  )
}

export default Languages
