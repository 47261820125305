import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'

import CopyButton from 'components/atoms/CopyButton'

import { Input } from './CodeInput'
import s from './index.module.scss'

const fields = [
  {
    field: 'session[externalRef]',
    required: true,
  },
  {
    field: 'user[uniqueId]',
    required: true,
  },
  {
    field: 'user[phoneNumber]',
    placeholder: '+10005550000',
  },
  {
    field: 'user[email]',
  },
  // {
  //   field: 'user[firstName]',
  // },
  // {
  //   field: 'user[lastName]',
  // },
]

type Props = {
  token: string | undefined
  showAllFields: boolean
}

const LoadTheIFrame: FC<Props> = ({ token, showAllFields }) => {
  const [data, setData] = useState<Record<string, string>>({})

  const handleChange = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setData({
        ...data,
        [field]: e.target.value,
      })
    },
    [data]
  )

  const payload = () => {
    let text = 'const params = new URLSearchParams()\n' + "params.set('token', token)\n"

    if (showAllFields) {
      text +=
        `params.set('session[externalRef]', "${data['session[externalRef]'] ?? ''}")\n` +
        `params.set('user[uniqueId]', "${data['user[uniqueId]'] ?? ''}")\n` +
        (data['user[phoneNumber]']
          ? `params.set('user[phoneNumber]', "${data['user[phoneNumber]']}")\n`
          : '') +
        (data['user[email]'] ? `params.set('user[email]', "${data['user[email]'] ?? ''}")\n` : '')
      // (data['user[firstName]']
      //   ? `params.set('user[firstName]', "${data['user[firstName]'] ?? ''}")\n`
      //   : '') +
      // (data['user[lastName]']
      //   ? `params.set('user[lastName]', "${data['user[lastName]'] ?? ''}")\n`
      //   : '')
    }

    text +=
      `\n` +
      `const base = 'https://${_env_.IFRAME_ORIGIN}/'\n` +
      `const url = base + '?' + params.toString()\n\n` +
      `<iframe id="journey" src={url} allow="clipboard-write" />\n`

    return text
  }

  const url = useMemo(() => {
    const params = new URLSearchParams()
    params.set('token', token ?? 'AUTH_TOKEN')
    params.set('session[externalRef]', data['session[externalRef]'] ?? 'SESSION_REFERENCE')
    params.set('user[uniqueId]', data['user[uniqueId]'] ?? 'UNIQUE_IDENTIFIER')
    if (data['user[phoneNumber]']) params.set('user[phoneNumber]', data['user[phoneNumber]'])
    if (data['user[email]']) params.set('user[email]', data['user[email]'])
    const base = `https://${_env_.IFRAME_ORIGIN}/`
    return base + '\n  ?' + params.toString().replace(/&/g, '\n  &')
  }, [data, token])

  return (
    <>
      <div className={s.Code}>
        <CopyButton content={payload()} />
        <div className="mt-1 mb-1">
          <span className="text-red-700">const</span>{' '}
          <span className="text-purple-800">params</span> ={' '}
          <span className="text-red-700">new</span>{' '}
          <span className="text-blue-800">URLSearchParams</span>()
        </div>
        <div className="mt-1 mb-1">
          <span className="text-purple-700">params</span>.<span className="text-blue-800">set</span>
          (<span className="text-green-800">'token'</span>,{' '}
          <span className="text-purple-800">token</span>){' '}
          <span className="mt-4 mb-1 text-gray-600">{'// from Step 1'}</span>
        </div>
        {showAllFields && (
          <>
            {fields.map(({ field, placeholder, required }) => (
              <div key={field} className="mt-1 mb-1">
                <span className="text-purple-700">params</span>.
                <span className="text-blue-800">set</span>(
                <span className="text-green-800">'{field}'</span>,{' '}
                <span className="text-green-700">"</span>
                <Input
                  required={required}
                  placeholder={placeholder}
                  className="border-red-500"
                  value={data[field] || ''}
                  onChange={handleChange(field)}
                />
                <span className="text-green-700">"</span>)
              </div>
            ))}
          </>
        )}
        <div className="mt-4 mb-1">
          <span className="text-red-700">const</span> <span className="text-purple-800">url</span> ={' '}
          <span className="text-green-800">'https://{_env_.IFRAME_ORIGIN}/?'</span> +{' '}
          <span className="text-purple-700">params</span>.
          <span className="text-blue-800">toString</span>()
        </div>

        <div className="my-4 border-t border-gray-300" />
        <div className="mt-1">
          <span className="text-red-700">
            &lt;iframe <span className="text-blue-800">id</span>=
            <span className="text-green-700">"journey"</span>{' '}
            <span className="text-blue-800">src</span>={'{'}
            <span className="text-purple-800">url</span>
            {'}'} <span className="text-blue-800">allow</span>=
            <span className="text-green-700">"clipboard-write"</span> /&gt;
          </span>
        </div>
      </div>
      <div className={s.Code}>
        <CopyButton content={url.replaceAll(/\n +/g, '')} />
        <div className="mt-1 mb-1">
          <span className="mt-4 mb-1 text-sm text-gray-600 break-all whitespace-pre-wrap">
            {url}
          </span>
        </div>
      </div>
    </>
  )
}

export default LoadTheIFrame
