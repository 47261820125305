import cogoToast from 'cogo-toast'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'
import * as yup from 'yup'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import Header from 'components/molecules/AdminHeader'
import Field from 'components/molecules/Field'
import { useAccount } from 'hooks/api/useAccount'
import { useUpdateAccountName } from 'hooks/api/useUpdateAccountName'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'

import s from './General.module.scss'

type FormData = {
  name: string
}

const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, t('Name must be at least 3 characters'))
    .required(t('A valid name is required')),
})

const General = () => {
  const resolver = useYupValidationResolver(schema)
  const form = useForm<FormData>({ resolver, shouldUnregister: false })
  const { handleSubmit, register, errors, setValue } = form

  const [account, { timer: accountTimer }] = useAccount()

  useEffect(() => {
    if (account) {
      setValue('name', account.name)
    }
  }, [account, setValue])

  const [update, { timer: updateTimer }] = useUpdateAccountName()
  const handleUpdate = useCallback(
    async (data: FormData) => {
      await update(data)
      cogoToast.success(t('Account settings have been saved!'))
    },
    [update]
  )

  const history = useHistory()
  const handleGoToPricing = useCallback(() => {
    history.push(r.settings.pricing('customer-interactions'))
  }, [history])

  return (
    <FormProvider {...form}>
      <form className="h-full" onSubmit={handleSubmit(handleUpdate)}>
        <ContentWrapper withFooter>
          <Header title={t('Settings')} />
          <div className={s.Content}>
            <Menu />
            <div>
              <p className="mt-4 mb-8 text-4xl font-semibold">
                <span onClick={handleGoToPricing}>{t('General')}</span> {t('Settings')}
              </p>
              {accountTimer.isLoading ? (
                <Loading />
              ) : (
                <>
                  <SettingsCard>
                    <Field
                      ref={register}
                      id="name"
                      name="name"
                      label={t('Account name')}
                      autoFocus
                      autoComplete="off"
                      error={errors.name}
                      maxLength={32}
                      className={s.Input}
                      wrapperClassName="mt-0"
                    />
                    <p className="text-sm text-gray-600">
                      {t(
                        "Invitation emails to users will include this name, so it should be something they'll recognize."
                      )}
                    </p>
                  </SettingsCard>
                </>
              )}
            </div>
          </div>
          <Footer timer={updateTimer} />
        </ContentWrapper>
      </form>
    </FormProvider>
  )
}

export default General
