import {
  ChangeEvent,
  ClipboardEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useUpdate } from 'react-use'

import r from 'routes'
import { t } from 'translation'

import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Button from 'components/atoms/Button'
import Header from 'components/molecules/AdminHeader'
import Preview from 'components/organisms/Preview'
import FacialEnrollmentPreview from 'components/templates/stages/FacialEnrollment.Preview'
import { Branding } from 'types/account'

import s from './index.module.scss'
import { useHistory } from 'react-router-dom'
import useCreateBrandStyle from 'hooks/api/branding/useCreateBrandStyle'

const BrandStylesNew: FC = () => {
  const { register, getValues, handleSubmit, setValue, setError } = useForm<Omit<Branding, 'id'>>({
    mode: 'onBlur',
    shouldUnregister: false,
  })

  const history = useHistory()

  const [primaryColor, setPrimaryColor] = useState('')

  const [create, { timer }] = useCreateBrandStyle()

  const handleCreate = useCallback(
    (data: Branding) => {
      if (timer.isLoading) return
      create(data).then(id => history.push(r.brandStyles.show(id)))
    },
    [history, create, timer.isLoading]
  )

  const handleSetColor = useCallback((value: string) => {
    if (value.length > 7) return
    setPrimaryColor(value)
    if (value.length === 4 || value.length === 7) {
      document.body.style.setProperty('--preview-theme-primary', `${value}`)
    }
  }, [])

  useEffect(() => handleSetColor('#0060AB'), [])

  const logoRef = useRef<HTMLInputElement | null>(null)
  const render = useUpdate()
  const handleUploadFile = () => {
    logoRef.current?.click()
  }

  const handleColor = useCallback(
    (color: string) => {
      setValue('primaryColor', `#${color}`)
      setPrimaryColor(`#${color}`)
      document.body.style.setProperty('--preview-theme-primary', `#${color}`)
    },
    [setValue]
  )

  const [logoFilename, setLogoFilename] = useState<string>()
  const handleLogoUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target
      if (!files?.length) return

      const reader = new FileReader()
      reader.addEventListener('load', event => {
        if (!event.target?.result) return

        const encoded = event.target.result as string
        const svg = atob(encoded.replace('data:image/svg+xml;base64,', ''))

        setValue('logo', svg)
        setLogoFilename(files[0].name)
        render()
      })
      reader.readAsDataURL(files[0])
    },
    [setValue, render]
  )

  const handleClearFile = useCallback(() => {
    setValue('logo', undefined)
    setLogoFilename(undefined)
    if (logoRef.current) {
      logoRef.current.value = ''
    }
    render()
  }, [setValue, render])

  const logo = getValues('logo')

  const colors = useMemo(() => {
    if (!logo) return
    const colors = [...logo.matchAll(/#[a-fA-F0-9]{6}/g)].map(a => a[0])
    const nonWhite = colors.filter(
      (v, i, a) => a.indexOf(v) === i && v !== '#FFFFFF' && v !== '#ffffff'
    )
    const nonBlack = nonWhite.filter(v => v !== '#000000')
    if (nonBlack.length === 1) setPrimaryColor(nonBlack[0])
    return nonWhite
  }, [logo])

  const handleColorPaste = useCallback(
    (e: ClipboardEvent) => {
      e.preventDefault()
      var text = e.clipboardData.getData('Text')
      if (text.match(/^[0-9a-fA-F]{6}$/)) {
        text = `#${text}`
      }
      handleSetColor(text.toUpperCase())
    },
    [handleSetColor]
  )

  return (
    <ContentWrapper className="bg-gray-100">
      <Header backRoute={r.brandStyles.root} title={t('Branding')} />

      <div className={s.ContentWrapper}>
        <div className={s.Content}>
          <div>
            <div>
              <h2 className={s.Title}>{t('Branding')}</h2>
              <p className={s.Info}>
                {t(
                  'The logo and brand color you select below will be shown in the customer facing pipeline interface. You can see a preview of what the customer will see below.'
                )}
              </p>

              <form className={s.FormWrapper}>
                <p className={s.Label}>{t('Logo')}</p>
                <button
                  type="button"
                  className={s.UploadLogoBtn}
                  onClick={logoFilename ? handleClearFile : handleUploadFile}
                >
                  {logoFilename ? (
                    <div>
                      <span>{logoFilename}</span>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faPlus} /> {t('Upload an SVG logo')}
                    </>
                  )}
                </button>
                <input
                  ref={logoRef}
                  type="file"
                  accept=".svg"
                  onChange={handleLogoUpload}
                  className="hidden"
                />
                <input type="hidden" ref={register} name="logo" />

                <hr className={s.Divider} />

                <p className={s.Label}>{t('Primary Brand Color')}</p>

                <div className={s.ColorWrapper}>
                  <input
                    type="color"
                    ref={register}
                    name="primaryColor"
                    className={s.ColorPicker}
                    value={
                      primaryColor
                        ? primaryColor.match(/^#[0-9a-fA-F]{6}$/)
                          ? primaryColor
                          : undefined
                        : '#0060AB'
                    }
                    onChange={e => handleSetColor(e.target.value)}
                  />
                  <input
                    type="text"
                    ref={register}
                    name="primaryColor"
                    className={s.ColorInput}
                    value={primaryColor}
                    placeholder="#0060AB"
                    onChange={e => handleSetColor(e.target.value)}
                    onPaste={handleColorPaste}
                  />
                </div>
                <div>
                  {colors && (
                    <div className="flex items-center mt-2 space-x-2">
                      <span className="pr-2 text-sm">{t('or select from the logo:')}</span>
                      {colors.map(color => (
                        <div
                          key={color}
                          style={{ backgroundColor: color }}
                          className="w-6 h-6 rounded cursor-pointer"
                          onClick={() => handleColor(color.substring(1))}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </form>
            </div>

            <div>
              <Preview>
                <FacialEnrollmentPreview
                  brandingLogoPreview={
                    (logoRef.current?.files &&
                      logoRef.current?.files[0] &&
                      URL.createObjectURL(logoRef.current?.files[0])) ||
                    undefined
                  }
                />
              </Preview>
            </div>
          </div>
        </div>
      </div>

      <div className={s.Footer}>
        <Button
          type="submit"
          size="xl"
          isLoading={timer.isLoading}
          onClick={handleSubmit(handleCreate)}
        >
          {timer.didSucceed ? t('Saved!') : t('Save this style')}
        </Button>
      </div>
    </ContentWrapper>
  )
}

export default BrandStylesNew
