import type { Pipeline } from 'types'
import asyncThunk from 'util/asyncThunk'
import { HTTPRetrievedMultiple } from 'util/statuses'

export type Request = {
  published?: boolean
}

type Response = Pipeline[]

export const listPipelines = asyncThunk<Request, Response>({
  name: 'pipelines/list',
  method: 'GET',
  uri: '/pipelines',
  successStatus: HTTPRetrievedMultiple,
  throttle: false,
})
