import { HTTPRetrievedMultiple } from '@journeyid/agent/common/statuses'

import { EventWithPipeline } from 'types/event'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  limit?: number
  before?: string
  after?: string
}

export const listEvents = asyncThunk<Request, EventWithPipeline[]>({
  name: 'events/list',
  uri: ({ id }) => `/users/${id}/events`,
  successStatus: HTTPRetrievedMultiple,
  orchestrator: false,
  stripId: true,
})
