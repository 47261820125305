import { NonUndefined } from 'react-hook-form'
import { Pipeline } from 'types'

import { t } from 'translation'

const sms: NonUndefined<Pipeline['sms']> = {
  message: t(
    'Please click here to complete this Journey pipeline: https://link.to/pipeline. Thanks!'
  ),
}

const email: NonUndefined<Pipeline['email']> = {
  subject: t("You've been asked to complete a Journey Pipeline"),
  from: t('Journey'),
  greeting: t('Hi there!'),
  message: t("You've been asked to complete this Journey Pipeline."),
  action: t('Start now'),
  footer: t(
    'If you have any questions regarding this process, please email us at info@journeyid.com\n\nJourney'
  ),
  signOff: '',
}

const pushNotification: NonUndefined<Pipeline['pushNotification']> = {
  title: t('Notification title'),
  body: t("The push notification's message."),
}

export const deliveryDefaults = { sms, email, pushNotification }
