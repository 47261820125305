import { FC, useEffect, useState } from 'react'
import { useBrandingSelector, usePipelineSelector } from 'store'
import { t } from 'translation'

import { faEye } from '@fortawesome/pro-regular-svg-icons'

import Button from 'components/atoms/Button'
import Footer from 'components/molecules/Footer'
import PageHeader from 'components/molecules/PageHeader'
import Preview from 'components/organisms/Preview'
import defaultLogo from 'components/organisms/Preview/journey-logo.svg'
import BrandStyleList from 'components/templates/Branding/components/BrandStyleList'
import useBranding from 'components/templates/Branding/useBranding'
import FacialEnrollmentPreview from 'components/templates/stages/FacialEnrollment.Preview'
import useListBrandStyles from 'hooks/api/branding/useListBrandStyles'
import { openModal } from 'hooks/useModal'
import PreviewModal from 'pages/Pipelines/Create/components/PreviewModal'
import { Branding as BrandingType } from 'types/account'

import s from './index.module.scss'

const Branding: FC = () => {
  const { pipeline } = usePipelineSelector()
  const [brandStyles] = useListBrandStyles()
  const [handleUpdate, { timer }] = useBranding()

  const { branding } = useBrandingSelector()

  const [activeBrandStyle, setActiveBrandStyle] = useState<BrandingType>()

  useEffect(() => {
    if (!brandStyles) return

    const bs = brandStyles.find(brandStyle => brandStyle.id === pipeline.brandStyleId)
    if (!bs) return

    setActiveBrandStyle(bs)
  }, [brandStyles, pipeline.brandStyleId])

  useEffect(() => {
    const color = activeBrandStyle?.primaryColor ?? branding?.primaryColor ?? '#0060AB'
    document.body.style.setProperty('--preview-theme-primary', color)
  }, [activeBrandStyle, branding])

  return (
    <div className={s.Wrapper}>
      <div className={s.Content}>
        <PageHeader title={t('Pipeline Branding')}>
          {t(
            "Choose the brand style that should be used for this pipeline. You can choose any brand style created on the 'Branding' page."
          )}
        </PageHeader>
        <div className="flex pb-8 pr-8">
          <div className="flex-1 pt-4 pr-4 space-y-4 pl-7">
            <BrandStyleList brandStyleId={activeBrandStyle?.id} onChange={setActiveBrandStyle} />
          </div>
          <div>
            <Preview>
              <FacialEnrollmentPreview
                key={activeBrandStyle?.id}
                brandingLogoPreview={activeBrandStyle?.logo || branding?.logo || defaultLogo}
              />
            </Preview>
          </div>
        </div>
      </div>
      <Footer
        actions={
          <>
            <Button variant="secondary" icon={faEye} onClick={openModal('Preview')}>
              {t('Preview Pipeline')}
            </Button>
            <Button
              variant={timer.didSucceed ? 'green' : 'primary'}
              isLoading={timer.isLoading}
              onClick={() => handleUpdate(activeBrandStyle)}
            >
              {timer.didSucceed ? t('Saved!') : timer.isLoading ? t('Saving...') : t('Save')}
            </Button>
          </>
        }
      />
      <PreviewModal />
    </div>
  )
}

export default Branding
