import { FC, useCallback } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { faGripDotsVertical, faPen } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field } from '@journeyid/agent/types'

import s from './FormFieldList.module.scss'

type Props = {
  fields: Field[]
  onChange: (fields: Field[]) => void
  openEditModal: (idx: number) => void
}

const reorder = (list: Field[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const FormFieldList: FC<Props> = ({ fields, onChange, openEditModal }) => {
  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination || destination.index === source.index) {
        return
      }

      const updated = reorder(fields, source.index, destination.index)
      onChange(updated)
    },
    [fields, onChange]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={s.FieldList}>
            {fields.map((field, idx) => (
              <Draggable key={idx} draggableId={`${idx}`} index={idx}>
                {provided => (
                  <div key={idx} ref={provided.innerRef} {...provided.draggableProps}>
                    <div>
                      <div {...provided.dragHandleProps}>
                        <FontAwesomeIcon className="mr-4 cursor-grab" icon={faGripDotsVertical} />
                      </div>

                      <p>{field.label}</p>
                    </div>
                    <button onClick={() => openEditModal(idx)}>
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default FormFieldList
