import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react'

import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './index.module.scss'

type Props = {
  placeholder: string
  onSearch: (query: string) => void
  onEnter?: () => void
}

const SearchBar: FC<Props> = ({ placeholder, onSearch, onEnter }) => {
  const [search, setSearch] = useState('')

  useEffect(() => {
    onSearch(search)
  }, [search, onSearch])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code !== 'Enter') return
      onEnter && onEnter()
    },
    [onEnter]
  )

  return (
    <div className={s.SearchBar}>
      <input
        type="text"
        placeholder={placeholder}
        value={search}
        autoFocus
        onChange={e => setSearch(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <div>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </div>
    </div>
  )
}

export default SearchBar
