import { useEffect, useState } from 'react'

import { useAPI } from 'hooks/useAPI'
import { listPipelines } from 'thunks/api/pipelines/list'

import type { Pipeline } from 'types'

export function useListPipelines(published?: boolean) {
  const [run, { timer }] = useAPI(listPipelines)

  const [pipelines, setPipelines] = useState<Pipeline[]>([])

  useEffect(() => {
    if (timer.isLoading) return
    run({ published }).then(setPipelines)
  }, [run, timer, published, setPipelines])

  return [pipelines, { timer }] as const
}
