import { User } from 'types/user'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  photo: string
}

export const uploadUserPhoto = asyncThunk<Request, User>({
  name: 'users/upload-photo',
  uri: ({ id }) => `/users/${id}/photo`,
  method: 'PUT',
  orchestrator: false,
  stripId: true,
})
