import { ReactNode, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import Loading from 'components/atoms/Loading'
import SearchBar from 'components/molecules/SearchBar'

import s from './index.module.scss'
import classNames from 'classnames'

type Props<T> = {
  elements: T[]
  emptyText?: string
  hasMore?: boolean
  isLoading?: boolean
  loadMore?: () => void
  searchPrompt?: string
  title?: string
  className?: string
  children: (t: T, i: number) => ReactNode
  onSearch?: (search: string) => void
  onEnter?: () => void
}

const ListView = <T,>({
  elements,
  emptyText,
  hasMore,
  isLoading,
  loadMore,
  searchPrompt = 'Search',
  title,
  className,
  children,
  onSearch,
  onEnter,
}: Props<T>) => {
  const onLoadMore = useCallback(() => {
    if (isLoading || !loadMore) return
    loadMore()
  }, [isLoading, loadMore])

  return (
    <div className={classNames(s.ListWrapper, className)}>
      {onSearch && <SearchBar onSearch={onSearch} placeholder={searchPrompt} onEnter={onEnter} />}
      <InfiniteScroll
        className={s.List}
        pageStart={0}
        hasMore={hasMore}
        loadMore={onLoadMore}
        loader={
          <div key={0} className="flex justify-center mt-8">
            <Loading />
          </div>
        }
        useWindow={false}
      >
        {title && <p className="mb-3 text-3xl font-semibold">{title}</p>}
        {elements.length === 0 && !isLoading && emptyText && (
          <p className="text-sm text-gray-500">{emptyText}</p>
        )}
        {elements.map(children)}
      </InfiniteScroll>
    </div>
  )
}

export default ListView
