import React, { FC, ReactNode, SVGProps } from 'react'

import s from './index.module.scss'

type Props = {
  highlighted?: boolean
  icon?: FC<SVGProps<SVGSVGElement>>
  children: ReactNode
  onClick: () => void
}

const SecondaryButton: FC<Props> = ({ highlighted = false, icon: Icon, children, onClick }) => {
  return (
    <button
      className={highlighted ? s.HighlightedSecondaryButton : s.SecondaryButton}
      onClick={onClick}
    >
      <span>
        {Icon && (
          <aside>
            <Icon />
          </aside>
        )}
        {children}
      </span>
    </button>
  )
}

export default SecondaryButton
