import classNames from 'classnames'
import { FC, useCallback, useEffect, useState } from 'react'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import Button from 'components/atoms/Button'
import ConfirmButton from 'components/atoms/ConfirmButton'
import CopyButton from 'components/atoms/CopyButton'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import { useAPI } from 'hooks/useAPI'
import { retrieveClientCredentials } from 'thunks/api/accounts/retrieve-client-credentials'
import { updateClientCredentials } from 'thunks/api/accounts/update-client-credentials'

import s from './index.module.scss'

type Credentials = {
  clientId: string | undefined
  clientSecret?: string
}

const ClientCredentials: FC = () => {
  const { accountId } = useAuthSelector()
  const [credentials, setCredentials] = useState<Credentials>()

  const [retrieve] = useAPI(retrieveClientCredentials)
  useEffect(() => {
    if (!accountId) return

    retrieve({ id: accountId }).then(creds => setCredentials(creds))
  }, [accountId, retrieve])

  const [update, { timer }] = useAPI(updateClientCredentials)
  const handleUpdate = useCallback(() => {
    if (!accountId) return

    setIsConfirming(false)
    update({ id: accountId }).then(creds => setCredentials(creds))
  }, [accountId, update])

  const [isConfirming, setIsConfirming] = useState(false)

  if (!credentials) return <Loading />

  return (
    <SettingsCard title={t('OAuth Authentication')}>
      <div className="mb-4 text-sm">
        Generate API authentication tokens dynamically using the credentials below. Read the API
        documentation{' '}
        <a
          href="https://developer.journeyid.com/reference/system-auth-token"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          here
        </a>
        .
      </div>
      {credentials.clientId ? (
        <div className={s.ClientCredentials}>
          <ul>
            <li>
              <label>Client ID</label>
              <span className={s.AccountId}>
                {credentials.clientId}
                <CopyButton content={credentials.clientId} />
              </span>
            </li>
            <li>
              <label>Client Secret</label>
              {credentials.clientSecret ? (
                <div className="flex flex-col space-y-1">
                  <span className={classNames(s.AccountId, 'w-fit')}>
                    {credentials.clientSecret}
                    <CopyButton content={credentials.clientSecret} />
                  </span>
                  <span className="pl-3 text-xs text-red-700">
                    {t('This secret is only shown once. Please save it securely.')}
                  </span>
                </div>
              ) : (
                <span className="text-gray-500">********-****-****-****-************</span>
              )}
            </li>
          </ul>

          {isConfirming ? (
            <div className="space-x-4">
              <Button variant="red" size="sm" onClick={handleUpdate}>
                {t('Confirm credential rotation')}
              </Button>
              <Button variant="secondary" size="sm" onClick={() => setIsConfirming(false)}>
                {t('Cancel')}
              </Button>
            </div>
          ) : (
            <Button
              variant="secondary"
              size="sm"
              isLoading={timer.isLoading}
              onClick={() => setIsConfirming(true)}
            >
              {t('Rotate credentials')}
            </Button>
          )}
        </div>
      ) : (
        <div>
          <div className="mb-4 text-sm text-gray-700">no credentials have been created yet</div>
          <Button variant="secondary" isLoading={timer.isLoading} onClick={handleUpdate}>
            {t('Generate credentials')}
          </Button>
        </div>
      )}
    </SettingsCard>
  )
}

export default ClientCredentials
