import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  title: string
}

type Response = Iframe

export const createIFrameCategory = asyncThunk<Request, Response>({
  name: 'iframes/categories/create',
  method: 'POST',
  uri: ({ id }) => `/iframes/${id}/categories`,
  stripId: true,
})
