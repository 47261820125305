import { Pipeline } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = Pick<Pipeline, 'id' | 'sms' | 'email' | 'pushNotification'>

type Response = Pipeline

export const updateDeliveryMethods = asyncThunk<Request, Response>({
  name: 'pipelines/update-delivery-methods',
  method: 'PUT',
  uri: ({ id }) => `/pipelines/${id}/delivery-methods`,
  stripId: true,
})
