import classNames from 'classnames'
import { FC, useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'

import { t } from 'translation'

import { MINIMUM_COST } from 'pages/Settings/tabs/Pricing/calculator'

import s from './index.module.scss'

type Props = {
  cost: number
}

const TotalPrice: FC<Props> = ({ cost }) => {
  const [term, setTerm] = useState<1 | 3>(1)

  const adjustedCost = useMemo(() => (term === 3 ? cost * 0.9 : cost), [cost, term])

  return (
    <div className="flex flex-col items-end justify-center space-y-1">
      <div className="font-semibold">
        $<NumberFormat value={adjustedCost} thousandSeparator="," displayType="text" /> / month{' '}
        {cost === MINIMUM_COST && t('minimum')}
      </div>
      <div className="flex items-end text-sm">
        <div>{t('contract term:')}</div>
        <div
          className={classNames('mx-1', term !== 1 && s.InactiveContractTerm)}
          onClick={() => setTerm(1)}
        >
          {t('1 year')}
        </div>
        <div
          className={classNames(term !== 3 && s.InactiveContractTerm)}
          onClick={() => setTerm(3)}
        >
          {t('3 years (-10%)')}
        </div>
      </div>
    </div>
  )
}

export default TotalPrice
