import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { ReactComponent as MagicIcon } from 'pages/auth/shared/Auth/icons/magic-wand.svg'

type Props = {
  text: string
}

const MagicLinkButton: FC<Props> = ({ text }) => {
  const history = useHistory()

  const handleClick = () => history.push(r.auth.magicLink)

  return (
    <button onClick={handleClick}>
      <MagicIcon className="fill-current fill-orange-600" />
      {text}
    </button>
  )
}

export default MagicLinkButton
