import { FC } from 'react'

import EnterpriseStage from 'components/atoms/EnterpriseStage'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const CustomID: FC = () => {
  const { timer, handleSave } = useStage('custom-id')

  return (
    <CreateSettings stageType="custom-id" timer={timer} onSave={handleSave}>
      <EnterpriseStage />
    </CreateSettings>
  )
}

export default CustomID
