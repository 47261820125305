import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Field, { Option } from 'components/molecules/Field'
import Modal from 'components/organisms/Modal'
import { useAPI } from 'hooks/useAPI'
import { closeModal, useModal } from 'hooks/useModal'
import { Account, listAccounts } from 'thunks/api/accounts/list'
import { duplicatePipeline } from 'thunks/api/pipelines/duplicate'

type FormData = {
  accountId: string
  title: string
}

const DuplicatePipelineModal: FC = () => {
  const [isOpen] = useModal('DuplicatePipeline')
  const { pipeline } = usePipelineSelector()

  const { register, watch, handleSubmit } = useForm<FormData>({
    defaultValues: {
      title: pipeline.title,
    },
  })

  const [accounts, setAccounts] = useState<Account[]>()
  const [list] = useAPI(listAccounts)
  useEffect(() => {
    list({}).then(accounts => setAccounts(accounts.sort((a, b) => (a.name > b.name ? 1 : -1))))
  }, [list])

  const [duplicate, { timer }] = useAPI(duplicatePipeline)
  const onSubmit = useCallback(
    async (data: FormData) => {
      if (!data.accountId || !data.title) return

      await duplicate({
        id: pipeline.id,
        ...data,
      })

      setTimeout(closeModal, timer.delay)
    },
    [pipeline, timer.delay, duplicate]
  )

  const { accountId, title } = watch(['accountId', 'title'])
  const isReady = !!accountId && !!title

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>{t('Duplicate Pipeline')}</Modal.Header>
        <Modal.Body>
          <p className="mb-4 text-sm">
            {t(
              'Duplicating a pipeline speeds up the process of creating a second pipeline that is similar to this one. If you have multiple accounts, you can duplicate a pipeline from this account into one of those.'
            )}
          </p>

          <div className="max-w-xs mb-6 space-y-4">
            <Field ref={register} label={t('Account')} as="select" name="accountId">
              {typeof accounts === 'undefined' ? (
                <Option value="">{t('loading...')}</Option>
              ) : (
                <>
                  {accounts.length > 1 && <option></option>}
                  {accounts.map((account, idx) => (
                    <Option key={idx} value={account.id}>
                      {account.name}
                    </Option>
                  ))}
                </>
              )}
            </Field>
            <Field ref={register} label={t('New pipeline title')} name="title" />
          </div>
        </Modal.Body>
        <Modal.Actions>
          <Button
            type="submit"
            variant={timer.didSucceed ? 'green' : 'secondary'}
            disabled={!isReady}
            size="xl"
          >
            {timer.didSucceed && pipeline.key
              ? t('Duplicated!')
              : timer.isLoading
              ? t('Duplicating...')
              : t('Duplicate Pipeline')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default DuplicatePipelineModal
