import React, { FC, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import { t } from 'translation'

import ErrorMessage from 'components/molecules/ErrorMessage'

import styles from './index.module.scss'

export type CommonProps = {
  label: string
  name: string
  hint?: string
  isOptional?: boolean
  error?: FieldError
  aside?: ReactNode
  className?: string
  children: ReactNode
}

const Wrapper: FC<CommonProps> = ({
  label,
  name,
  hint,
  isOptional,
  error,
  aside,
  className,
  children,
}) => {
  return (
    <div className={className}>
      <label htmlFor={name} className={styles.Label}>
        {label}
        {isOptional && <span className={styles.Optional}>{t('optional')}</span>}
        {!!aside && <aside className={styles.Aside}>{aside}</aside>}
      </label>
      <div className={styles.InputWrapper}>{children}</div>
      {error && <ErrorMessage error={error} />}
      {hint && <p className={styles.Hint}>{hint}</p>}
    </div>
  )
}

export default Wrapper
