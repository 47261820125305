import asyncThunk from 'util/asyncThunk'

type Template = {
  aspect: number
  key: string
  name: string
}

export type Request = {}

type Response = {
  templates: Template[]
}

export const ocrTemplates = asyncThunk<Request, Response>({
  name: 'templates',
  method: 'GET',
  uri: '/ocr/templates',
})
