import { ErrorResponse } from '@journeyid/agent/common/errors'
import { HTTPCreated } from '@journeyid/agent/common/statuses'
import { createAsyncThunk } from '@reduxjs/toolkit'

export type Request = {
  token: string
}

type Response = {
  token: string
}

export const magicLinksAuthenticate = createAsyncThunk<
  Response,
  Request,
  { rejectValue: ErrorResponse }
>('magic-links/authenticate', async (req, api) => {
  const response = await fetch('/api/app/magic-links/authenticate', {
    method: 'POST',
    body: JSON.stringify(req),
  })

  if (response.status !== HTTPCreated) {
    const err = (await response.json()) as ErrorResponse
    return api.rejectWithValue(err)
  }

  return await response.json()
})
