import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { t } from 'translation'

import PhoneNumberInputConnected from 'components/atoms/PhoneNumberInputConnected'
import CodeBlock from 'components/molecules/CodeBlock'
import Field from 'components/molecules/Field'

import codeTemplate from './codeTemplate'

type Inputs = {
  phoneNumber: string
  sessionRef: string
}

const IncomingCallSimulation: FC = () => {
  const form = useForm<Inputs>({
    defaultValues: {
      phoneNumber: '1',
      sessionRef: 'example-session-id',
    },
  })

  const { handleSubmit, register, watch } = form

  const { phoneNumber, sessionRef } = watch(['phoneNumber', 'sessionRef'])

  return (
    <>
      <h3 className="mb-2 std">{t('Incoming Call Simulator')}</h3>
      <p className="mb-4 std">
        {t('Once the')}{' '}
        <a href="pages/edit/Ivr/components/IncomingCallSimulator#">{t('Agent IFrame')}</a>{' '}
        {t(
          'is integrated into the agent desktop, this snippet of code is called when an agent answers a call.'
        )}
      </p>
      <FormProvider {...form}>
        <form onChange={handleSubmit(() => {})}>
          <div className="flex flex-1">
            <Field
              name="phoneNumber"
              label={t('Incoming Phone Number')}
              autoFocus
              className="w-full mb-4"
              hint={t(
                "Some countries must be specifically enabled. If you don't receive text messages, please contact support@journeyid.com."
              )}
            >
              <PhoneNumberInputConnected name="phoneNumber" className="pl-16" />
            </Field>
          </div>

          <Field
            ref={register}
            id="sessionRef"
            name="sessionRef"
            autoComplete="off"
            label={t('Session Reference')}
            placeholder="call-id-456"
            className="flex-grow mb-8"
            inputClassName="py-2 px-3"
          />
        </form>
      </FormProvider>
      <CodeBlock
        template={codeTemplate({
          phoneNumber: phoneNumber ? `+${phoneNumber}` : '+1##########',
          sessionRef,
        })}
      />
    </>
  )
}
export default IncomingCallSimulation
