import cogoToast from 'cogo-toast'
import { useCallback } from 'react'
import { usePipelineSelector } from 'store'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import { useAPI } from 'hooks/useAPI'
import { deleteSAML } from 'thunks/api/pipelines/saml_delete'

export default function useDelete() {
  const { pipeline } = usePipelineSelector()

  const [deleteSettings, { timer }] = useAPI(deleteSAML)
  const onDelete = useCallback(async () => {
    try {
      await deleteSettings({ id: pipeline.id })

      cogoToast.success('SAML settings have been deleted.')
    } catch (err) {
      if (isAPIErrorResponse(err)) {
        cogoToast.error(err.error)
      } else {
        console.log('[SAML DELETE ERROR]', err)
        cogoToast.error('Unable to delete SAML settings')
      }
    }
  }, [pipeline.id, deleteSettings])

  return [onDelete, { timer }] as const
}
