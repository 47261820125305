import { useMemo } from 'react'
import { useAccountsSelector } from 'store'

export default function useAccounts(search: string) {
  const accounts = useAccountsSelector()

  const lastAuthenticatedAccounts = useMemo(
    () =>
      accounts
        .map(obj => {
          return {
            ...obj,
            lastAuthenticatedAt: obj.lastAuthenticatedAt
              ? new Date(obj.lastAuthenticatedAt)
              : new Date(1995),
          }
        })
        .sort((objA, objB) => Number(objB.lastAuthenticatedAt) - Number(objA.lastAuthenticatedAt)),
    [accounts]
  )

  return useMemo(() => {
    if (search.length === 0) return lastAuthenticatedAccounts.slice(0, 4)

    return lastAuthenticatedAccounts
      .filter(({ name }) => name.toLowerCase().search(search.toLowerCase()) >= 0)
      .slice(0, 4)
  }, [lastAuthenticatedAccounts, search])
}
