import { FC } from 'react'
import { IframeDeliveryMethods } from 'types'

import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import s from './DeliveryMethodList.module.scss'

type DeliveryMethod = {
  id: keyof IframeDeliveryMethods
  name: string
  description: string
}

const deliveryMethodsConfig: DeliveryMethod[] = [
  {
    id: 'sms',
    name: 'Text Message',
    description: 'Deliver pipeline requests via SMS.',
  },
  {
    id: 'email',
    name: 'Email',
    description: 'Deliver pipeline requests via Email.',
  },
  {
    id: 'push-notification',
    name: 'Push Notification',
    description: 'Available when using the Journey mobile SDK.',
  },
  {
    id: 'whatsapp',
    name: 'WhatsApp',
    description: 'Not yet configured on your account.',
  },
  {
    id: 'url',
    name: 'Copy & Paste a URL',
    description: 'Enable the agent to copy and paste the pipeline request URL.',
  },
]

type Props = {
  deliveryMethods: IframeDeliveryMethods
  setDeliveryMethods: (deliveryMethods: IframeDeliveryMethods) => void
}

const DeliveryMethodList: FC<Props> = ({ deliveryMethods, setDeliveryMethods }) => {
  const setDeliveryMethod = (k: string, v: boolean) => {
    setDeliveryMethods({ ...deliveryMethods, [k]: v })
  }

  return (
    <div>
      <ul className={s.List}>
        {deliveryMethodsConfig.map((deliveryMethod, i) => (
          <li
            key={i}
            data-active={deliveryMethods[deliveryMethod.id] === true}
            className={
              deliveryMethods[deliveryMethod.id] == null
                ? 'opacity-50 !cursor-not-allowed'
                : 'hover:bg-gray-100'
            }
            onClick={() => {
              if (deliveryMethods[deliveryMethod.id] === null) return
              setDeliveryMethod(deliveryMethod.id, !deliveryMethods[deliveryMethod.id])
            }}
          >
            {deliveryMethods[deliveryMethod.id] === true ? (
              <div className={`${s.Check} !border-0`}>
                <FontAwesomeIcon icon={faSquareCheck} />
              </div>
            ) : (
              <div className={s.Check} />
            )}
            <div className={s.TextWrapper}>
              <h3>{t(deliveryMethod.name)}</h3>
              <p>{t(deliveryMethod.description)}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DeliveryMethodList
