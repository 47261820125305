import { Branding } from '@journeyid/agent/dashboards/retrieve'

import asyncThunk from 'util/asyncThunk'
import { HTTPRetrieved } from 'util/statuses'

export type Request = {}

type Account = {
  id: string
  name: string
  isActive: boolean
  hasSSO: boolean
}

type Response = {
  id: string
  accounts: Account[]
  firstName: string
  lastName: string
  email: string
  branding?: Branding
  token?: string
}

export const adminBootstrap = asyncThunk<Request, Response>({
  name: 'admin/bootstrap',
  uri: '/admins/bootstrap',
  successStatus: HTTPRetrieved,
  orchestrator: false,
})
