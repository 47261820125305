import classNames from 'classnames'
import { FC, useCallback, useEffect, useRef } from 'react'

import { DocumentSignatureMetadata } from '@journeyid/agent/types'

import { t } from 'translation'

import Input from 'components/atoms/Input'
import styles from 'components/atoms/Input/index.module.scss'
import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Textarea from 'components/atoms/Textarea'
import Toggle from 'components/atoms/Toggle'
import SlateEditor, { defaultText } from 'components/organisms/SlateEditor'
import DocumentSignaturePreview from 'components/templates/stages/DocumentSignature.Preview'
import DocumentSignatureVariables from 'components/templates/stages/DocumentSignatureVariables'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'
import { SlateValue } from 'util/slate'

const DocumentSignature: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('document-signature')
  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (stage.metadata.content !== '') return
    handleMetadata('content', JSON.stringify(defaultText))
  }, [stage, handleMetadata])

  const handleToggle = useCallback(
    (enabled: boolean) => {
      handleMetadata('agreement', enabled ? '' : undefined)
      setTimeout(() => {
        ref.current?.focus()
      }, 0)
    },
    [handleMetadata],
  )

  const handleContentChange = useCallback(
    (value: SlateValue) => {
      const content = JSON.stringify(value)
      handleMetadata('content', content)

      const templateVars = content.matchAll(/{{\.(\w+)}}/g)
      if (!templateVars) return
      const unique: any = []
      for (const v of templateVars) {
        if (!unique.some((value: Array<string>) => value[0] === v[0])) {
          unique.push(v)
        }
      }

      const variables: NonNullable<DocumentSignatureMetadata['variables']> = unique.map(
        (match: Array<string>) => ({
          key: match[1],
          label: match[1]
            .replace(/^([a-z])/, (_, p1) => p1.toUpperCase())
            .replaceAll(/([^])([A-Z])/g, (_, p1, p2) => `${p1} ${p2}`),
        }),
      )
      handleMetadata('variables', variables)
    },
    [handleMetadata],
  )

  const handleVariableUpdate = useCallback(
    (key: string, label: string) => {
      const variables = stage.metadata.variables?.map((variable) => {
        if (variable.key !== key) return variable
        return { ...variable, label }
      })
      handleMetadata('variables', variables)
    },
    [handleMetadata, stage.metadata.variables],
  )

  return (
    <CreateSettings
      stageType="document-signature"
      preview={<DocumentSignaturePreview {...stage} />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <Setting title={t('Document title')}>
          <Input
            value={stage.metadata.title}
            onChange={(e) => handleMetadata('title', e.target.value)}
          />
        </Setting>
        <Setting
          title={t('Content')}
          description={t(
            "This is the main content of the document at you're asking the user to sign. You can use the toolbar below to add formatted text such as bold, underlined, and italic text as well as lists and hyperlinks.",
          )}
        >
          <SlateEditor
            defaultValue={
              stage.metadata.content ? JSON.parse(stage.metadata.content) : defaultText
            }
            onChange={handleContentChange}
            placeholder={t('Enter the document content here...')}
          >
            {(editable) => (
              <div
                className={classNames(
                  styles.Input,
                  'min-h-[200px] max-h-[200px] overflow-y-auto flex',
                )}
              >
                {editable}
              </div>
            )}
          </SlateEditor>
          <DocumentSignatureVariables
            variables={stage.metadata.variables}
            onChange={handleVariableUpdate}
          />
        </Setting>
        <Setting
          title={t('Explicit Agreement')}
          description={t(
            'Often a document requires explicit agreement to certain terms before the signature can be accepted. If your use case requires this, you can configure that feature here.',
          )}
        >
          <Toggle
            enabled={typeof stage.metadata.agreement !== 'undefined'}
            onChange={handleToggle}
          >
            {t('Have the user check a box to agree.')}
          </Toggle>
          {typeof stage.metadata.agreement !== 'undefined' && (
            <Textarea
              ref={ref}
              rows={5}
              value={stage.metadata.agreement}
              placeholder={t('Enter text here')}
              className="mt-4"
              onChange={(e) => handleMetadata('agreement', e.target.value)}
            ></Textarea>
          )}
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default DocumentSignature
