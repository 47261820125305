import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import { faPlus, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Loading from 'components/atoms/Loading'
import Header from 'components/molecules/AdminHeader'
import ListView from 'components/molecules/ListView'
import useListUsers from 'hooks/api/useListUsers'
import ExportAgentReport from 'pages/shared/Users/List/_Export'
import { User, UserType } from 'types/user'

import s from './index.module.scss'

type Props = {
  type: UserType
}

const ListUsers = <T extends UserType>({ type }: Props): JSX.Element => {
  const { role } = useAuthSelector()
  const [users, { timer }] = useListUsers<T>(type)
  const history = useHistory()
  const [search, setSearch] = useState<string>('')
  const root = useMemo(() => (type === 'agent' ? r.agents : r.customers), [type])

  const filter = useCallback(
    (a: User<T>): boolean => {
      const s = search.toLowerCase()
      const fullName = `${a.firstName} ${a.lastName}`
      return (
        s.length === 0 ||
        fullName.toLowerCase().search(s) >= 0 ||
        a.uniqueId.toLowerCase().search(s) >= 0
      )
    },
    [search]
  )

  const filteredUsers = useMemo(() => users.filter(filter), [users, filter])
  const sortedUsers = useMemo(
    () =>
      filteredUsers.sort((l, r) => {
        if (!!l.lastName && !r.lastName) {
          return -1
        }
        if (!!r.lastName && !l.lastName) {
          return 1
        }
        if (!!l.firstName || !!l.lastName) {
          return `${l.firstName} ${l.lastName} ${l.uniqueId}`.toUpperCase() <
            `${r.firstName} ${r.lastName} ${r.uniqueId}`.toUpperCase()
            ? -1
            : 1
        }
        if (!!r.firstName || !!r.lastName) {
          return 1
        }
        return l.uniqueId > r.uniqueId ? 1 : -1
      }),
    [filteredUsers]
  )

  const handleClick = useCallback(
    (id: string) => {
      history.push(root.show(id))
    },
    [root, history]
  )

  const handleEnter = useCallback(() => {
    if (filteredUsers.length === 0) return
    handleClick(filteredUsers[0].id)
  }, [handleClick, filteredUsers])

  return (
    <ContentWrapper>
      <Header title={type === 'agent' ? t('Agents') : t('Customers')}>
        {role !== 'supervisor' && (
          <button className={s.CreateUserBtn} onClick={() => history.push(root.new)}>
            <FontAwesomeIcon icon={faPlus} />
            {t('Create new')}
          </button>
        )}
        {type === 'agent' && <ExportAgentReport />}
      </Header>
      {timer.isLoading ? (
        <Loading className="mt-32" size="5x" />
      ) : (
        <div className={s.ListWrapper}>
          <div>
            <div>
              <ListView
                elements={sortedUsers}
                emptyText={t('No {{users}}', { users: type === 'agent' ? 'agents' : 'customers' })}
                searchPrompt={
                  type === 'agent' ? t('Search for an agent') : t('Search for a customer')
                }
                onSearch={setSearch}
                onEnter={handleEnter}
              >
                {user => (
                  <div key={user.id} className={s.ListItem} onClick={() => handleClick(user.id)}>
                    {user.photoUrl ? (
                      <img src={user.photoUrl} alt="User profile img" />
                    ) : (
                      <FontAwesomeIcon className={s.UserIcon} icon={faUser} />
                    )}
                    <div className="flex-1">
                      {user.firstName || user.lastName ? (
                        `${user.firstName} ${user.lastName}`
                      ) : (
                        <em className="text-gray-400">{t('no name available')}</em>
                      )}
                    </div>
                    <div className="flex-1 text-sm text-gray-500">{user.uniqueId}</div>
                    <div className={s.Manage}>
                      <button>{t('Manage')}</button>
                    </div>
                  </div>
                )}
              </ListView>
            </div>
          </div>
        </div>
      )}
    </ContentWrapper>
  )
}

export default ListUsers
