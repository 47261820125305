import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import NumberFormat from 'react-number-format'

import { t } from 'translation'

import Slider from 'components/atoms/Slider'

import s from './AgentAuthentication.module.scss'

type Props = {
  title: string
  value: number
  index: number
  options: number
  priceMin: number
  priceMax: number
  isListShown: boolean
  children: ReactNode
  onChange: (value: number) => void
  onToggleList: () => void
}

const Interactions: FC<Props> = ({
  title,
  value,
  index,
  options,
  priceMin,
  priceMax,
  isListShown,
  children,
  onChange,
  onToggleList,
}) => {
  return (
    <div className={classNames(value > 0 ? s.SelectedOption : s.Option, 'cursor-default')}>
      <div>
        <header className="flex items-center justify-between mb-2">
          <span>{title}</span>
          <div className="flex items-center px-2 space-x-2">
            <span className="mr-1">
              <NumberFormat
                value={value}
                thousandSeparator=","
                decimalScale={0}
                fixedDecimalScale
                displayType="text"
                className="text-sm"
              />{' '}
              {t('/ mo')}
            </span>
            <Slider
              value={index}
              min={0}
              max={options}
              step={1}
              className="w-[200px] cursor-pointer"
              filledClassName="bg-theme"
              handleClassName="bg-theme border-theme"
              unfilledClassName="bg-gray-300"
              onChangeValue={onChange}
            />
          </div>
        </header>
        <hr />
        <p>{children}</p>
        <div>
          <NumberFormat
            value={priceMin * 100}
            thousandSeparator=","
            decimalScale={1}
            displayType="text"
          />
          &cent; -{' '}
          <NumberFormat
            value={priceMax * 100}
            thousandSeparator=","
            decimalScale={1}
            displayType="text"
          />
          &cent; / interaction <span className="px-1"></span>
          <span className="cursor-pointer" onClick={onToggleList}>
            {isListShown ? t('hide list') : t('show list')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Interactions
