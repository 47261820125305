import { useCallback, useRef, useState } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { accountsActivity, Request } from 'thunks/api/activity'

export function useAccountActivity() {
  const offset = useRef(0)
  const isLoading = useRef(false)
  const [hasMore, setHasMore] = useState(true)

  const { accountId } = useAuthSelector()

  const [run, { timer }] = useAPI(accountsActivity)

  const load = useCallback(
    async (req: Omit<Request, 'id' | 'offset'>) => {
      if (!accountId) return
      isLoading.current = true
      const events = await run({
        ...req,
        id: accountId,
        offset: offset.current,
      })
      isLoading.current = false
      offset.current += events.length
      setHasMore(events.length === req.limit)
      return events
    },
    [accountId, run]
  )

  return [load, { timer, hasMore, isLoading: isLoading.current }] as const
}
