import classNames from 'classnames'
import { FC, HTMLProps } from 'react'

export const Input: FC<
  HTMLProps<HTMLInputElement> & {
    required?: boolean
  }
> = ({ required = false, value, ...props }) => {
  return (
    <input
      {...props}
      value={value}
      className={classNames(
        'outline-none text-green-700 bg-transparent',
        required ? 'border-b-2' : 'border-b',
        required && !value ? 'border-red-600' : 'border-gray-300'
      )}
    />
  )
}
