import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useIframeSelector } from 'store'

import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { ReactComponent as BgImg } from './bgImg.svg'
import s from './EmptyPipelines.module.scss'

const EmptyPipelines: FC = () => {
  const history = useHistory()
  const { iframe } = useIframeSelector()

  const route = r.iframes[iframe.id ? 'edit' : 'create'].configure

  return (
    <div className={s.Wrapper}>
      <BgImg className={s.Img} />
      <p className={s.Text}>{t('No pipelines have been added yet.')}</p>
      <button className={s.Btn} onClick={() => history.push(route.addPipeline(iframe.id))}>
        <FontAwesomeIcon icon={faPlus} />
        {t('Add a pipeline')}
      </button>
    </div>
  )
}

export default EmptyPipelines
