import classNames from 'classnames'
import { FC, useCallback } from 'react'

import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './CustomerLanguageList.module.scss'

type Language = {
  id: string
  name: string
}

type Props = {
  languages: Language[]
  selectedLanguages: string[]
  setSelectedLanguages: (languages: string[]) => void
}

const MAX_LANG = 3

function isDisabled(selected: string[], language: string) {
  return !selected.some(l => l === language) && selected.length >= MAX_LANG
}

const CustomerLanguageList: FC<Props> = ({
  languages,
  selectedLanguages,
  setSelectedLanguages,
}) => {
  const selectLanguage = useCallback(
    (language: Language) => {
      selectedLanguages.some(l => l === language.id)
        ? setSelectedLanguages([...selectedLanguages.filter(lang => lang !== language.id)])
        : MAX_LANG !== selectedLanguages.length &&
          setSelectedLanguages([...selectedLanguages, language.id])
    },
    [selectedLanguages, setSelectedLanguages]
  )

  return (
    <div>
      <ul className={s.List}>
        {languages.map((lang, i) => (
          <li
            key={i}
            className={classNames(isDisabled(selectedLanguages, lang.id) && s.Disabled)}
            onClick={() => selectLanguage(lang)}
          >
            {selectedLanguages.some(l => l === lang.id) ? (
              <div className="!border-0">
                <FontAwesomeIcon icon={faSquareCheck} />
              </div>
            ) : (
              <div />
            )}

            <p>{lang.name}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CustomerLanguageList
