import cogoToast from 'cogo-toast'
import { useCallback } from 'react'
import { t } from 'translation'

import { useAPI } from 'hooks/useAPI'
import { closeModal } from 'hooks/useModal'
import { archiveGroup } from 'thunks/api/groups/archive'
import { Group } from 'types/group'

export default function useDelete() {
  const [remove, { timer }] = useAPI(archiveGroup)

  const archive = useCallback(
    async (group: Group) => {
      await remove({
        id: group.id,
      })

      closeModal()
      cogoToast.warn(t('{{name}} has been removed', { name: group.name }))
    },
    [remove]
  )

  return [archive, { timer }] as const
}
