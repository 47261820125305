import { FC, useState } from 'react'

import CopyButton from 'components/atoms/CopyButton'
import PostMessage from 'pages/IFrames/Steps/Test/PostMessage'
import { Payload } from 'pages/IFrames/Steps/Test/useIFrame'

import s from './index.module.scss'

type Props = {}

const SendPostMessage: FC<Props> = () => {
  const [payload, setPayload] = useState<Payload>({
    event: 'start-session',
    session: {
      externalRef: '',
    },
    user: {
      uniqueId: '',
    },
  })

  return (
    <div className={s.Code}>
      <CopyButton content={payload} />
      <PostMessage onChange={setPayload} />
    </div>
  )
}

export default SendPostMessage
