import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

type Props = {
  checked: boolean
  children: ReactNode
  onSelect: () => void
}

const RadioOption: FC<Props> = ({ checked, onSelect, children }) => {
  return (
    <div
      className={classNames('flex px-4 py-3 space-x-4 rounded cursor-pointer', {
        'border border-theme bg-white ring-1 ring-theme': checked,
        'border border-gray-300 bg-gray-50': !checked,
      })}
      onClick={onSelect}
    >
      <div className="flex items-center">
        <input type="radio" checked={checked} readOnly className="w-5 h-5 form-radio text-theme" />
      </div>
      <div>
        <header className="font-bold">{children}</header>
      </div>
    </div>
  )
}

export default RadioOption
