import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { t } from 'translation'

import SearchBar from 'components/molecules/SearchBar'

import s from './IframeList.module.scss'

type Iframe = {
  id: string
  title: string
}

type Props = {
  iframes: Iframe[]
  setSearch: (search: string) => void
}

const IframeList: FC<Props> = ({ iframes, setSearch }) => {
  const history = useHistory()

  const handleClick = useCallback(
    (iframeId: string) => {
      history.push(r.iframes.edit.configure.pipelines(iframeId))
    },
    [history]
  )

  const handleEnter = useCallback(() => {
    if (iframes.length === 0) return
    handleClick(iframes[0].id)
  }, [handleClick, iframes])

  return (
    <div>
      <SearchBar
        placeholder={t('Search for an iframe')}
        onSearch={setSearch}
        onEnter={handleEnter}
      />

      <ul className={s.List}>
        {iframes.map((iframe, i) => (
          <li key={i} onClick={() => handleClick(iframe.id)}>
            <p>{iframe.title}</p>
            <button>{t('Manage')}</button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default IframeList
