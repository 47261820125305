import { Pipeline } from 'types'

import asyncThunk from 'util/asyncThunk'
import { HTTPDeleted } from 'util/statuses'

export type Request = {
  id: string
}

type Response = Pipeline

export const deleteSAML = asyncThunk<Request, Response>({
  name: 'pipelines/saml-delete',
  method: 'DELETE',
  uri: ({ id }) => `/pipelines/${id}/saml`,
  successStatus: HTTPDeleted,
  stripId: true,
})
