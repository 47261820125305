import { useEffect, useState } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { listAgents } from 'thunks/api/agents/list'

import type { Agent } from 'types/agent'

export default function useListAgents() {
  const { accountId } = useAuthSelector()
  const [list, { timer }] = useAPI(listAgents)

  const [agents, setAgents] = useState<Agent[]>([])

  useEffect(() => {
    if (!accountId || timer.isLoading) return
    list({ accountId }).then(setAgents)
  }, [accountId, list, timer])

  return [agents, { timer }] as const
}
