import { FC, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { faChevronRight, faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import s from './LinkList.module.scss'

const { type } = r.pipelines.edit.integration

type Props = {
  id: string
}

const LinkList: FC<Props> = ({ id }) => {
  const history = useHistory()

  const links = useMemo(
    () => [
      {
        name: t('API'),
        hint: t(
          'The powerful Journey API enables you to manage your entire Journey account programmatically. This capability facilitates automatic integrations into existing systems.'
        ),
        path: type(id, 'api'),
        icon: faChevronRight,
      },
      {
        name: t('IVR'),
        hint: t(
          'Journey Pipelines can add powerful functionality into your existing call center tech stack.  A few examples include performing voice or facial biometric enrollment & authentication. Authenticate the user with high veracity before they even get connected to a live agent.'
        ),
        path: type(id, 'ivr'),
        icon: faChevronRight,
      },
      {
        name: t('Agent iFrame'),
        hint: t(
          "Enable your call center agents to initiate pipelines directly from their agent desktop. They're able to request the pipeline using any of the delivery methods configured in the Pipeline Orchestrator."
        ),
        path: type(id, 'api'),
        icon: faChevronRight,
      },
      {
        name: t('Single Sign-On: OIDC'),
        hint: t(
          'Using the OAuth (OIDC) protocol, use your Pipeline as a way to enroll or authenticate your users using facial biometrics. SSO integrations are not limited to enrollment and authentication however, you could use the same protocols to request a credit card payment, for example.'
        ),
        path: type(id, 'oidc'),
        icon: faChevronRight,
      },
      {
        name: t('Single Sign-On: SAML'),
        hint: t('Use the SAML protocol to authenticate users into your service.'),
        path: type(id, 'saml2'),
        icon: faChevronRight,
      },
      {
        name: t('Developer Documentation'),
        hint: t(
          "The Journey API has extensive development documentation, available by clicking here. Our documentation has a 'try it now' feature which enables you to make API calls directly in your browser."
        ),
        path: 'https://developer.journeyid.com/',
        icon: faUpRightFromSquare,
        target: 'blank',
      },
    ],
    [id]
  )

  return (
    <div>
      <h4 className="mb-3 std">{t('How do you plan to integrate this pipeline?')}</h4>
      {links.map(({ name, hint, path, icon, target }, i) => (
        <div
          key={i}
          className={s.Link}
          onClick={() => {
            if (target === 'blank') {
              window.open(path, '_blank')
              return
            }
            history.push(path)
          }}
        >
          <div>
            <h5 className="std">{name}</h5>
            <p className="max-w-lg mb-0 text-gray-500 std-sm">{hint}</p>
          </div>
          <FontAwesomeIcon className="text-gray-500" icon={icon} />
        </div>
      ))}
    </div>
  )
}

export default LinkList
