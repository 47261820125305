import { useEffect } from 'react'
import { useList, useToggle } from 'react-use'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { listGroups, Request } from 'thunks/api/groups/list'
import { Group } from 'types/group'

export function useListGroups(enabled: boolean = true) {
  const [groups, methods] = useList<Group>()
  const [hasLoaded, toggleLoaded] = useToggle(false)
  const [list, { timer }] = useAPI(listGroups)

  const { accountId } = useAuthSelector()
  useEffect(() => {
    if (!enabled) return

    async function run() {
      try {
        if (!accountId) return

        const req: Request = {
          accountId,
        }

        const groups = await list(req)
        methods.set(groups)
        toggleLoaded()
      } catch (err) {
        console.error(err)
      }
    }

    run()
  }, [accountId, enabled, methods, toggleLoaded, list])

  return [groups, { hasLoaded, ...methods }] as const
}
