import asyncThunk, { ID } from 'util/asyncThunk'
import { HTTPUpdated } from 'util/statuses'

export type Request = {
  id: ID
  name: string
}

type Response = {
  id: ID
  name: string
}

export const updateAccountName = asyncThunk<Request, Response>({
  name: 'accounts/name',
  method: 'PUT',
  uri: ({ id }) => `/accounts/${id}/name`,
  orchestrator: false,
  successStatus: HTTPUpdated,
  stripId: true,
})
