import React, { FC } from 'react'

import s from './index.module.scss'

const ScanFrame: FC = () => {
  return (
    <div className={s.ScanFrame}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default ScanFrame
