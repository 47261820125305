import { FC } from 'react'
import { t } from 'translation'

import { MetadataFor } from '@journeyid/agent/types'

import PrimaryButton from 'components/atoms/Button'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'
import SecondaryButton from 'components/organisms/Preview/SecondaryButton'

type Props = {
  metadata: MetadataFor<'outbound-scheduling'>
}

const OutboundSchedulingPreview: FC<Props> = ({ metadata }) => {
  return (
    <>
      <LogoHeader />

      <Header headline={metadata?.title || t(`Fraud Department`)}>
        {metadata?.text ||
          t(
            `Potential fraud has been detected on your credit card.  We'd like to review the transactions on your account to determine whether any of them were fraudulent`
          )}
      </Header>

      <main className="flex justify-center px-4"></main>

      <footer>
        <PrimaryButton block preview>
          {metadata?.button || t('Review transactions')}
        </PrimaryButton>
        <SecondaryButton onClick={() => {}}>{t('Schedule a callback')}</SecondaryButton>
      </footer>
    </>
  )
}

export default OutboundSchedulingPreview
