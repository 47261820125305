import { FC } from 'react'
import { t } from 'translation'

import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Toggle from 'components/atoms/Toggle'
import FaceEnrollmentPreview from 'components/templates/stages/FaceEnrollment.Preview'

import CreateSettings from './shared/CreateSettings'
import useStage from './useStage'

const FaceEnrollment: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('face-enrollment')

  return (
    <CreateSettings
      stageType="face-enrollment"
      preview={<FaceEnrollmentPreview {...stage} />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <Setting
          title={t('Enrollment device')}
          description={t(
            'By default, users will be redirected to their mobile device to take advantage of the higher quality camera.'
          )}
        >
          <Toggle
            enabled={stage.metadata.allowDesktopEnrollment}
            onChange={v => handleMetadata('allowDesktopEnrollment', v)}
          >
            {t('If enabled, users can enroll using their desktop cameras.')}
          </Toggle>
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default FaceEnrollment
