import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export function useScrollToTop() {
  const history = useHistory()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const unlisten = history.listen(() => {
      ref.current?.scrollTo(0, 0)
    })

    return () => {
      unlisten()
    }
  }, [history])

  return ref
}
