import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { Iframe } from 'types'

import { faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import { ActionTimer } from 'hooks/useActionTimer'

import s from './CategoryTitle.module.scss'

type Props = {
  category: Pick<Iframe['categories'][number], 'id' | 'title'>
  className?: string
  timer: ActionTimer
  onChange: (title: string) => void
  onDelete: () => void
}

const CategoryTitle: FC<Props> = ({ category, className, timer, onChange, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState(category.title)

  useEffect(() => {
    setIsEditing(false)
  }, [timer.didSucceed])

  return (
    <div className={classNames(s.CategoryTitle, className)} data-editing={isEditing}>
      {isEditing ? (
        <form
          className={s.Form}
          onSubmit={e => {
            e.preventDefault()
            onChange(title)
          }}
        >
          <input
            value={title}
            placeholder={t('Category title')}
            autoFocus
            onChange={e => setTitle(e.target.value)}
          />
          <Button disabled={timer.isLoading} onClick={() => onChange(title)}>
            {timer.isLoading ? t('Saving...') : t('Save')}
          </Button>
          <Button variant="link" onClick={() => setIsEditing(false)}>
            {t('Cancel')}
          </Button>
        </form>
      ) : (
        <>
          <span>{category.title}</span>
          <FontAwesomeIcon icon={faPencil} onClick={() => setIsEditing(true)} />
          <FontAwesomeIcon icon={faTrash} onClick={onDelete} />
        </>
      )}
    </div>
  )
}

export default CategoryTitle
