import { FC } from 'react'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'
import RadioOption from 'components/organisms/Preview/RadioOption'

const PhoneNumberVerificationPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <Header headline={t('Phone number verification')}>
        {t('Select which phone number to verify')}
      </Header>
      <main className="pointer-events-none">
        <ul className="px-4 mt-4 space-y-2">
          <li>
            <RadioOption checked onSelect={() => {}}>
              +1 (303) 555-1234
            </RadioOption>
          </li>
          <li>
            <RadioOption checked={false} onSelect={() => {}}>
              +1 (720) 555-9876
            </RadioOption>
          </li>
        </ul>
      </main>
      <footer>
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Send code')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default PhoneNumberVerificationPreview
