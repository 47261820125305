import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  uniqueId?: string
  hours: number
}

type Response = {
  token: string
}

export const iframeToken = asyncThunk<Request, Response>({
  name: 'iframes/token',
  method: 'POST',
  uri: ({ id }) => `/iframes/${id}/token`,
  stripId: true,
})
