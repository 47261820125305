import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'
import { t } from 'translation'
import * as yup from 'yup'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Button from 'components/atoms/Button'
import Loading from 'components/atoms/Loading'
import PhoneNumberInputConnected from 'components/atoms/PhoneNumberInputConnected'
import Header from 'components/molecules/AdminHeader'
import Field from 'components/molecules/Field'
import { useAccount } from 'hooks/api/useAccount'
import { useCreateAgent, ValidationErrorCallback } from 'hooks/api/useCreateAgent'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { Request } from 'thunks/api/agents/create'

import { useUniqueID } from './_useUniqueID'
import s from './index.module.scss'
import { useListGroups } from 'hooks/api/useListGroups'
import { Group } from 'types/group'
import Select from 'components/atoms/Select'

type FormData = Omit<Request, 'accountId' | 'language'>

const schema = yup.object().shape({
  uniqueId: yup
    .string()
    .required(t('A Unique ID is required'))
    .min(8, t('Unique ID must be at least 8 characters')),
  firstName: yup.string().required(t('A first name is required')),
  lastName: yup.string().required(t('A last name is required')),
  email: yup.string().when('phoneNumber', {
    is: null,
    then: yup.string().email().required(t('An email or phone number is required')),
    otherwise: yup.string().email().notRequired(),
  }),
  phoneNumber: yup.string().nullable().notRequired(),
  sendEnrollmentInvitation: yup.boolean().default(false).required(),
})

const NewAgent = () => {
  const history = useHistory()
  const { role } = useAuthSelector()

  const resolver = useYupValidationResolver(schema)
  const form = useForm<FormData>({ resolver })
  const { handleSubmit, register, errors, watch, setValue, setError } = form
  useUniqueID({ watch, setValue })

  const [create, { timer }] = useCreateAgent(setError as ValidationErrorCallback)
  const [groups, { hasLoaded }] = useListGroups()
  const [group, setGroup] = useState<string>()

  const onCreate = useCallback(
    (req: FormData) => {
      const groupIds =
        group && group.length > 0 ? groups.filter(g => g.name === group).map(g => g.id) : undefined
      create({
        ...req,
        phoneNumber: req.phoneNumber ? `+${req.phoneNumber}` : undefined,
        groupIds,
      }).then(res => {
        if (res) history.push(r.agents.show(res.id))
      })
    },
    [create, history, group, groups]
  )

  const [account] = useAccount()

  return (
    <ContentWrapper>
      <Header title={t('Agents')} backRoute={r.agents.root}>
        <Button variant="tertiary" onClick={() => history.push(r.agents.import)}>
          {t('Bulk import')}
        </Button>
      </Header>
      {!account || !hasLoaded ? (
        <Loading className="mt-32" size="5x" />
      ) : (
        <div className={s.Content}>
          <div>
            <div>
              <div className={s.Form}>
                <p className="mb-2 text-lg font-semibold text-theme">{t('Add an agent')}</p>
                <hr className="mb-3 text-gray-500" />
                <FormProvider {...form}>
                  <form onSubmit={handleSubmit(onCreate)}>
                    <div className="flex">
                      <Field
                        ref={register}
                        name="firstName"
                        autoFocus
                        autoComplete="off"
                        id="firstName"
                        label={t('First Name')}
                        error={errors.firstName}
                        maxLength={28}
                        className="flex-grow md:w-[45%]"
                        inputClassName="py-2 px-3"
                      />

                      <Field
                        ref={register}
                        name="lastName"
                        autoComplete="off"
                        id="lastName"
                        label={t('Last Name')}
                        error={errors.lastName}
                        maxLength={28}
                        className="flex-grow ml-5 md:w-[45%]"
                        inputClassName="py-2 px-3"
                      />
                    </div>

                    <Field
                      ref={register}
                      name="email"
                      autoComplete="off"
                      id="email"
                      label={t('Email')}
                      error={errors.email}
                      maxLength={50}
                      className="my-3"
                      inputClassName="py-2 px-3"
                    />

                    <Field
                      ref={register}
                      name="sendEnrollmentInvitation"
                      id="sendEnrollmentInvitation"
                      label={t('Facial Biometric Enrollment')}
                      error={errors.sendEnrollmentInvitation}
                      disabled={!account?.configuration?.enrollmentPipelineId}
                      as="checkbox"
                      className="my-3"
                    >
                      <span
                        className={classNames({
                          'text-gray-400': !account?.configuration?.enrollmentPipelineId,
                        })}
                      >
                        {t('Send an enrollment request by email')}
                      </span>
                    </Field>

                    {!account?.configuration?.enrollmentPipelineId && role !== 'supervisor' && (
                      <p className="mb-4 -mt-2 text-xs font-medium text-red-500">
                        {t(
                          'To send an enrollment request, you must first select the enrollment pipeline.'
                        )}{' '}
                        <Link to={r.settings.agentAuthentication} className="underline">
                          {t('Click here to do that.')}
                        </Link>
                      </p>
                    )}

                    <Field label={t('Phone number')} name="phoneNumber" error={errors.phoneNumber}>
                      <PhoneNumberInputConnected name="phoneNumber" />
                    </Field>

                    <Field
                      ref={register}
                      name="uniqueId"
                      autoComplete="off"
                      id="uniqueId"
                      label={t('Unique ID')}
                      error={errors.uniqueId}
                      maxLength={28}
                      className="my-3"
                      inputClassName="py-2 px-3"
                    />
                    {groups.length > 0 && (
                      <>
                        <label className={s.Label}>Agent Group</label>
                        <Select onChangeText={setGroup} value={group} style={{ width: `100%` }}>
                          <option value="" />
                          {groups.map(grp => (
                            <option key={grp.id} value={grp.name}>
                              {grp.name}
                            </option>
                          ))}
                        </Select>
                      </>
                    )}

                    <hr className="my-3 text-gray-500" />
                    <Button type="submit" isLoading={timer.isLoading} className={s.SaveBtn}>
                      {t('Save agent')}
                    </Button>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      )}
    </ContentWrapper>
  )
}

export default NewAgent
