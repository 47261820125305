type Input = {
  phoneNumber: string
  sessionRef: string
}

const codeTemplate = ({ phoneNumber, sessionRef }: Input) => `/*
 * React Example in Typescript
 */

startSession('<span>${phoneNumber}</span>', '<span>${sessionRef}</span>')

const ref = useRef&lt;HTMLIFrameElement>(null)

const startSession = useCallback((
  phoneNumber: string,
  sessionRef: string
) => {
  const payload = {
    'event': 'start-session',
    phoneNumber,
    sessionRef,
  }

  ref.contentWindow.postMessage(
    payload, '${window.location.origin}'
  )
}, [])

const query = useMemo(() => {
  const params = new URLSearchParams()
  params.set('dashboardId', <span>dashboardId</span>)
  params.set('uniqueId', <span>uniqueId</span>)
  const query = params.toString()
}, [dashboardId, uniqueId])

return (
  &lt;iframe
    title="journey"
    src={\`${window.location.origin}/desktop?\${query}\`}
  />
)`

export default codeTemplate
