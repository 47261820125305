import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { useDispatch, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk'

import { configureStore } from '@reduxjs/toolkit'

import { actions } from 'slices/auth'

import admin from './slices/admin'
import auth from './slices/auth'
import branding from './slices/branding'
import iframe from './slices/iframe'
import iframes from './slices/iframes'
import pipeline from './slices/pipeline'
import pipelines from './slices/pipelines'

const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  admin: admin.reducer,
  auth: auth.reducer,
  branding: branding.reducer,
  pipeline: pipeline.reducer,
  pipelines: pipelines.reducer,
  iframe: iframe.reducer,
  iframes: iframes.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({}).concat(routerMiddleware(history)).concat(thunk),
  devTools: { name: 'Journey' },
})

const token = localStorage.getItem('token')
if (token) {
  store.dispatch(actions.setJWT(token))
}

export { history, store }

export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export function useAppDispatch(): AppDispatch {
  return useDispatch()
}

export function useAuthSelector() {
  return useSelector((state: AppState) => state.auth)
}

export function useBrandingSelector() {
  return useSelector((state: AppState) => state.branding)
}

export function usePipelineSelector() {
  return useSelector((state: AppState) => state.pipeline)
}

export function usePipelinesSelector() {
  return useSelector((state: AppState) => state.pipelines)
}

export function useIframeSelector() {
  return useSelector((state: AppState) => state.iframe)
}

export function useIframesSelector() {
  return useSelector((state: AppState) => state.iframes)
}

export function useAccountsSelector() {
  return useSelector((state: AppState) => state.admin.accounts)
}

export function useActiveAccountSelector() {
  return useSelector((state: AppState) => state.admin.accounts.find(a => a.isActive))
}

export function useAdminSelector() {
  return useSelector((state: AppState) => state.admin)
}
