import { FC, ReactNode } from 'react'

import s from './index.module.scss'

type Props = {
  id: string
  title: string
  description: ReactNode
  children: ReactNode
}

const IntegrationLayout: FC<Props> = ({ id, title, description, children }) => (
  <div id={id} className={s.IntegrationLayout}>
    <div>
      <h1 className="std mb-4">{title}</h1>
      <p className="std mb-12">{description}</p>
      {children}
    </div>
  </div>
)

export default IntegrationLayout
