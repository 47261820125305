import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  fromIndex: number
  toIndex: number
}

type Response = Iframe

export const reorderIFrameCategories = asyncThunk<Request, Response>({
  name: 'iframes/categories/reorder',
  method: 'PUT',
  uri: ({ id }) => `/iframes/${id}/categories/reorder`,
  stripId: true,
})
