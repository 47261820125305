import classNames from 'classnames'
import { FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { Pipeline } from 'types'

import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Loading from 'components/atoms/Loading'
import { useListPipelines } from 'hooks/api/useListPipelines'
import { getPipelineIcon } from 'util/getPipelineIcon'
import { oxford } from 'util/oxford'
import { stageTitleMap } from 'util/stage-titles'

import s from './index.module.scss'

type Props = {
  autoFocus?: boolean
  filter?: (pipeline: Pipeline) => boolean
  initialPipelineId?: string
  title?: string
  className?: string
  onSelect: (pipeline?: Pipeline) => void
}

const PipelineSelect: FC<Props> = ({
  autoFocus,
  filter,
  initialPipelineId,
  title,
  className,
  onSelect,
}) => {
  const [pipelines, { timer }] = useListPipelines(true)

  const [focused, setFocused] = useState<boolean>(false)
  const [selected, _setSelected] = useState<Pipeline>()
  const [search, setSearch] = useState<string>('')

  const ref = useRef<HTMLDivElement>(null)
  const handleClickAway = useCallback(() => {
    setFocused(false)
  }, [])
  useClickAway(ref, handleClickAway)

  useEffect(() => {
    const matches = pipelines.filter(p => p.id === initialPipelineId)
    if (matches.length > 0) _setSelected(matches[0])
  }, [initialPipelineId, pipelines])

  const setSelected = useCallback(
    (pipeline?: Pipeline) => {
      onSelect(pipeline)
      _setSelected(pipeline)
      setSearch('')
    },
    [onSelect, _setSelected]
  )

  useEffect(() => {
    if (selected) setFocused(false)
  }, [selected])

  const searchFilter = useCallback(
    (pipeline: Pipeline): boolean => {
      const s = search.toLowerCase()
      const internal = pipeline.internalTitle
      const title = pipeline.title
      const key = pipeline.key
      return (
        s.length === 0 ||
        title.toLowerCase().search(s) >= 0 ||
        (internal !== undefined && internal.toLowerCase().search(s) >= 0) ||
        (key !== undefined && key.toLowerCase().search(s) >= 0)
      )
    },
    [search]
  )

  const filtered = useMemo(() => {
    let p = filter ? pipelines.filter(filter) : pipelines
    return p.filter(searchFilter)
  }, [filter, searchFilter, pipelines])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code !== 'Enter') return
      setSelected(filtered[0])
    },
    [filtered, setSelected]
  )

  return (
    <div ref={ref} className={classNames(s.PipelineSelect, className)}>
      {timer.isLoading ? (
        <Loading />
      ) : (
        <>
          {title && <h3 className="mb-2 text-base font-semibold">{title}</h3>}
          {selected ? (
            <div className={s.SelectedPipeline} onClick={() => setSelected()}>
              <div className={s.Info}>
                <div className={s.Icon}>
                  <FontAwesomeIcon icon={getPipelineIcon(selected.stages)} />
                </div>

                <div className={s.Text}>
                  <h3>{selected.title}</h3>
                  <p>{oxford(selected.stages.map(s => stageTitleMap[s.type]))}</p>
                </div>
              </div>

              <div className={s.CloseBtn}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          ) : (
            <div className={s.Input}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input
                type="text"
                tabIndex={-1}
                autoFocus={autoFocus}
                placeholder={t('Search for a pipeline')}
                onFocus={() => setFocused(true)}
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {focused && (
                <div className={s.ListWrapper}>
                  <ul className={s.List}>
                    {filtered.map((pipeline, i) => (
                      <li key={i} onClick={() => setSelected(pipeline)}>
                        <div className={s.ListIcon}>
                          <FontAwesomeIcon icon={getPipelineIcon(pipeline.stages)} />
                        </div>
                        <div className={s.ListText}>
                          <h3>{pipeline.title}</h3>
                          <p>{oxford(pipeline.stages.map(s => stageTitleMap[s.type]))}</p>
                        </div>
                      </li>
                    ))}
                    {filtered.length === 0 && (
                      <li className="text-gray-600 !mb-0 !bg-white pointer-events-none">
                        {t('No matching pipelines found')}
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default PipelineSelect
