import { FC } from 'react'

import { DocumentSignatureMetadata } from '@journeyid/agent/types'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import Input from 'components/atoms/Input'

type Props = {
  variables: DocumentSignatureMetadata['variables']
  onChange: (key: string, label: string) => void
}

const DocumentSignatureVariables: FC<Props> = ({ variables, onChange }) => {
  return (
    <>
      <Alert type="info" icon={false} className="mt-4">
        <span className="text-[13px]">
          {t('Add')} <code>{`{{.VariableName}}`}</code> {t('to add dynamic content.')}
        </span>
      </Alert>
      {variables && (
        <ul className="mt-4 space-y-2">
          <li className="flex pb-2 text-sm border-b">
            <div className="w-1/3">{t('API field')}</div>
            <div className="w-2/3">{t('Human readable label')}</div>
          </li>
          {variables.map(variable => (
            <li key={variable.key} className="flex text-sm">
              <div className="flex w-1/3 pt-2.5 text-xs">
                <code>{`{{.${variable.key}}}`}</code>
              </div>
              <div className="w-2/3">
                <Input
                  placeholder={t('Enter a label')}
                  defaultValue={variable.label}
                  onChange={e => onChange(variable.key, e.target.value)}
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default DocumentSignatureVariables
