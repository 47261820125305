import { Dashboard as IframeType } from '@journeyid/agent/dashboards/model'
import { createSlice } from '@reduxjs/toolkit'

import { listIFrames } from 'thunks/api/iframes/list'

type Iframe = Pick<IframeType, 'id' | 'title'>

export type IframesState = {
  isLoaded: boolean
  iframes: Iframe[]
}

const initialState: IframesState = {
  isLoaded: false,
  iframes: [],
}

const slice = createSlice({
  name: 'iframes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listIFrames.fulfilled, (state, { payload }) => {
      state.isLoaded = true
      state.iframes = payload
    })
    // builder.addCase(updateIframe.fulfilled, (state, { payload }) => {
    //   const idx = state.iframes.findIndex(p => p.id === payload.id)
    //   if (idx === -1) return
    //   state.iframes[idx].title = payload.title
    //   state.iframes[idx].internalTitle = payload.internalTitle
    // })
    // builder.addCase(archiveIframe.fulfilled, (state, { payload }) => {
    //   state.iframes = state.iframes.filter(p => p.id !== payload.id)
    // })
  },
})

export const actions = slice.actions

export default slice
