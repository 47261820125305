import classNames from 'classnames'
import { isBefore, parseISO } from 'date-fns'
import { FC } from 'react'
import { t } from 'translation'

import { Admin } from 'types/admin'

import s from './index.module.scss'

type Props = {
  admin: Admin
}

const InvitationExpiration: FC<Props> = ({ admin }) => {
  if (!admin.invitationExpiresAt) return <></>

  if (isBefore(parseISO(admin.invitationExpiresAt), new Date())) {
    return (
      <div>
        <span className={classNames(s.InvitationStatus, 'text-red-800 bg-red-100')}>
          {t('Invitation Expired')}
        </span>
      </div>
    )
  }

  return (
    <div>
      <span className={classNames(s.InvitationStatus, 'text-yellow-800 bg-yellow-100')}>
        {t('Invitation Pending')}
      </span>
    </div>
  )
}

export default InvitationExpiration
