import { t } from 'translation'

import { StageType } from '@journeyid/agent/types'

export const stageDescriptionMap: Record<StageType, string> = {
  'active-voice-authentication': t(
    `Active voice biometrics is a secure authentication method and requires only a few seconds of spoken words. Be aware that background noise or a bad connection could impact the user experience.`
  ),
  'active-voice-enrollment': t(
    `Active enrollment in voice biometrics requires the user to speak a pre-determined phrase  to create a voice biometric template. This step takes the user 2-4 seconds.`
  ),
  'address-collection': t(
    `Collect an address from the user and validate it, prompting the user to accept the cleaned up version.`
  ),
  blank: t(`For development use, this action will succeed without user interaction.`),
  'credit-card-payment': t(
    `The user can scan a credit or debit card, enter credit card number ( PAN) into a dial pad, or read credit card details out loud. `
  ),
  'custom-id': t(
    `This action will collect and verify custom IDs, such as employment badges or certifications. It uses machine learning algorithms to perform optical character recognition ( OCR) on photos that the user takes of the document. The data can then be validated against any back office system.`
  ),
  'device-authentication': t(
    `After a mobile app with the Journey SDK is registered, this action is used to authenticate that that same device was authenticated with Face ID or Touch ID.`
  ),
  'device-registration': t(
    `This pipeline action will install a certificate using the Journey SDK, embedded into a mobile app. Once installed, authentications can be performed using device biometrics (such as FaceID).`
  ),
  'document-collection': t(`Collect a document from the user such as an image or a PDF document.`),
  'document-signature': t(
    `This action allows the user to add any document to be electronically signed on a desktop, tablet or a mobile phone.`
  ),
  docusign: t(`This action allows to send a document to the user to be digitally signed.`),
  'drivers-license': t(
    `The user takes a photo of their driver's license and the photo & data are used to verify their identity.`
  ),
  'face-authentication': t(`Verifies the user via face scan and detects liveness`),
  'face-enrollment': t(`Enrolls the user for future face verification`),
  'external-authentication': t(
    'This action can be used to record authentications that are performed outside of the Journey platform. An example of this would be logging into a mobile application.'
  ),
  'facial-authentication-3d': t(
    `Video selfie compares to the 3D face scan created during onboarding. This level of authentication is the highest veracity currently available at 1: 125M FAR.`
  ),
  'facial-authentication': t(
    `2D selfies compare a photo with another facial template to make authenticating fast and highly secure, with a veracity of about 1:50K FAR.`
  ),
  'facial-enrollment': t(
    `This 3D topographical of a user's face creates the highest veracity biometric identity template for ID verification and future authentications. It takes about 5 seconds to complete. Liveness detection prevents spoofing attempts.`
  ),
  form: t(
    `An eForm can be configured to capture address details appropriate for any region of the world. The data entered can be verified against the internal database or an identity document, like a driver's license.`
  ),
  'government-id': t(
    `This action requires the user to scan their passport, Nation ID or Driver's License. The data will be verified against our trusted technology partners data sources.`
  ),
  'government-id-selfie': t(
    `This action requires the user to scan a Government ID and then take a Selfie. The photos will then be compared against each other, and optionally, the name will be compared to their cusotmer profile.`
  ),
  'knowledge-based-authentication': t(
    `Knowledge Based Authentication is a common tool for authentication, however it is not as strong as a biometric. It will often be used as a backup if the higher veracity options are not available.`
  ),
  'national-id-card': t(`This action requires the user to scan their National ID card.`),
  ocr: t(
    `This action enables the user to quickly scan their documents and collect the required information`
  ),
  'outbound-scheduling': t(
    `This action allows the user to schedule a call back at a later time. The user can select a time and date, and the selection will be sent to a campaign dialer.`
  ),
  passport: t(`Scan an international Passport and collect the data from the photo page.`),
  'payment-details': t(
    'Collect payment details from the user and deliver them to a back office system.'
  ),
  'phone-number-registration': t(
    `Actively verify the mobile number of the user by sending an SMS or one-time-password (OTP).`
  ),
  'phone-number-verification': t(
    `Phone number verification must be  performed after facial authentication; exposes phone numbers on account. The user verifies their phone number by SMS ( on mobile) or automated voice call ( on a landline).`
  ),
  'pindrop-enrollment': '',
  'pindrop-authentication': '',
  'pindrop-protect': t(
    `Pindrop Protect provides insights on each call, assessing the risk based on information fraudsters can't control - the audio of the call. Pindrop Protect uses patented technology to analyzes over 1,300 features of each call to detect the subtle anomalies that indicate fraud.`
  ),
  'pindrop-vericall': t(
    `Enterprise-grade call verification and spoof detection for contact centers. Lower handle time and associated costs for Green calls. Increase calls that self service in the IVR. Offer a personalized call, reduce friction, expand IVR options, and encourage callers to self-service.`
  ),
  'random-code': t(
    `This action will generate a random code used to authenticate  a voice call or otherwise.`
  ),
  schedule: t(
    `The user can simply select a date and time to schedule a call, appointment, appointment reminder or anything else.`
  ),
  survey: t(
    `The user can be asked to complete a survey, typically by selecting a face icon which represents their emotion.`
  ),
  'social-security-number': t(
    `This action requires the user to enter their Social Security Number. Optionally, the SSN can be verified against a credit bureau or back-office system.`
  ),
  'totp-authentication': t(
    `Typically involves a 6 digit code sent to a trusted device that was verified during onboarding.`
  ),
  'totp-registration': t(
    `The user is able to use a rotating one-time password/PIN in conjunction with multi-factor authentication. This password is only valid for a single session or transaction. An OTP can be delivered via email, SMS, push notification, Yubikey and more.`
  ),
  'webauthn-authentication': t(
    `FIDO2 is a passwordless login standard over the internet. It stands for Fast Identity Online with two factor authentication ( 2FA). Allow the user to perform a verification against a previously registered device. The method for verification must be the same as during registration - either biometric or secure key.`
  ),
  'webauthn-registration': t(
    `This action enables the user to leverage fingerprint and facial biometrics that are common in today's smart devices, such as Face ID and Touch ID for iPhone users.`
  ),
}
