import { FC } from 'react'
import { t } from 'translation'
import { DeliveryMethod } from 'types'

import { Language } from '@journeyid/agent/common/types/language'

import Button from 'components/atoms/Button'
import { ActionTimer } from 'hooks/useActionTimer'

import s from './Footer.module.scss'

const action: Record<DeliveryMethod, string> = {
  email: 'Send email',
  'push-notification': 'Send push notification',
  sms: 'Send SMS',
  url: 'Copy URL',
}

type Props = {
  timer: ActionTimer
  language: Language
  deliveryMethod?: DeliveryMethod
  url?: string
  onChangeLanguage: (language: Language) => void
  onSubmit: () => void
}

const Footer: FC<Props> = ({ timer, language, deliveryMethod, onChangeLanguage, onSubmit }) => (
  <div className={s.Footer}>
    <Button
      isLoading={timer.isLoading}
      className={s.Btn}
      onClick={() => !timer.isLoading && onSubmit()}
    >
      {deliveryMethod === 'url'
        ? timer.didSucceed
          ? t('Copied!')
          : t('Copy URL')
        : timer.didSucceed
        ? t('Sent!')
        : timer.isLoading
        ? t('Sending...')
        : deliveryMethod
        ? t(action[deliveryMethod])
        : t('Send')}
    </Button>
    <select
      name="lang"
      className={s.Input}
      onChange={e => onChangeLanguage(e.target.value as Language)}
      value={language}
    >
      <option value="en-US">{t('English (EN)')}</option>
      <option value="es-MX">{t('Spanish (MX)')}</option>
      <option value="pt-BR">{t('Portugese (BR)')}</option>
      <option value="ja-JP">{t('Japanese (JP)')}</option>
    </select>
  </div>
)

export default Footer
