import classNames from 'classnames'
import { FC } from 'react'

import { DocumentSignatureStage } from '@journeyid/agent/types'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Agreement from 'components/organisms/Preview/Agreement'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'
import Signature from 'components/organisms/Preview/Signature'
import SlateHTML from 'components/organisms/Preview/SlateHTML'
import { SlateValue } from 'util/slate'

export const defaultText: SlateValue = [
  {
    type: 'paragraph',
    children: [{ text: 'Document content' }],
  },
]

type Props = DocumentSignatureStage

const DocumentSignaturePreview: FC<Props> = ({ metadata }) => {
  return (
    <>
      <LogoHeader />

      <Header>{metadata.title || 'Document title'}</Header>

      <main className="flex flex-col mt-3">
        <div
          className={classNames(
            'flex flex-1 px-4 overflow-y-auto',
            typeof metadata.agreement === 'string' ? 'max-h-[232px]' : 'max-h-[286px]'
          )}
        >
          <SlateHTML value={metadata.content ? JSON.parse(metadata.content) : defaultText} />
        </div>
      </main>

      <footer className="pointer-events-none">
        {typeof metadata.agreement === 'string' && <Agreement>{metadata.agreement}</Agreement>}
        <Signature />
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Submit Signature')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default DocumentSignaturePreview
