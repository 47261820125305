import { FC, useState } from 'react'

import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'

type Props = {
  onSubmit: (accountId: string) => void
}

const AccountIDForm: FC<Props> = ({ onSubmit }) => {
  const [accountId, setAccountId] = useState('')
  const [showForm, setShowForm] = useState(false)

  return (
    <>
      {showForm ? (
        <form className="flex space-x-4 w-[400px]">
          <Input
            value={accountId}
            autoFocus
            className="font-mono"
            onChange={e => setAccountId(e.target.value)}
          />
          <Button variant="primary" size="sm" onClick={() => onSubmit(accountId)}>
            Save
          </Button>
          <Button variant="secondary" size="sm" onClick={() => setShowForm(false)}>
            Cancel
          </Button>
        </form>
      ) : (
        <div className="h-[42px] flex items-center">
          <span className="text-sm cursor-pointer text-theme" onClick={() => setShowForm(true)}>
            add an account
          </span>
        </div>
      )}
    </>
  )
}

export default AccountIDForm
