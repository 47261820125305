import classNames from 'classnames'
import { parseISO } from 'date-fns'
import { FC, useMemo, useState } from 'react'
import Zoom from 'react-medium-image-zoom'
import { Link } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'

import {
  faCheck,
  faChevronUp,
  faClose,
  faIdBadge,
  faMapLocation,
  faMobileAlt,
  faPersonToDoor,
  faQuestion,
  faUserGroup,
  faUserHeadset,
  faUserPlus,
  faWarning,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useQueryString from 'hooks/useQueryString'
import { browserName, deviceName, formTimestamp } from 'pages/shared/Users/Show/_util'
import StageData from 'pages/shared/Users/Show/StageData'
import 'react-medium-image-zoom/dist/styles.css'
import { eventTitleMap, EventWithPipeline } from 'types/event'
import { getPipelineIcon } from 'util/getPipelineIcon'
import { stageTitleMap } from 'util/stage-titles'

import s from './EventItem.module.scss'

const UnzoomButton: FC = () => (
  <div className="flex items-center justify-center w-12 h-12 border-4 border-white rounded-full bg-theme">
    <FontAwesomeIcon icon={faClose} size="2x" />
  </div>
)

type QueryParams = {
  eventId?: string
}

type Props = {
  event: EventWithPipeline
  isNew: boolean
}

const EventItem: FC<Props> = ({ event, isNew }) => {
  const { eventId } = useQueryString<QueryParams>()
  const [isOpen, setIsOpen] = useState(eventId === event.id)

  const timestamp = useMemo(
    () => parseISO(event.execution ? event.execution.completedAt : event.createdAt),
    [event]
  )

  const pipeline = event.execution?.pipeline

  const icon = useMemo(() => {
    if (event.execution) return getPipelineIcon(event.execution.stages)

    switch (event.type) {
      case 'active_auth':
        return faIdBadge
      case 'enrollment':
        return faUserPlus
      case 'multiple_faces':
        return faUserGroup
      case 'new_location':
        return faMapLocation
      case 'passive_auth':
        return faUserHeadset
      case 'session_ended':
        return faPersonToDoor
      case 'unable_to_passively_auth':
        return faWarning
      case 'cell_phone':
        return faMobileAlt
      default:
        return faQuestion
    }
  }, [event])

  return (
    <li data-event-id={event.id} className={s.EventItem}>
      <div className={s.Header} onClick={() => setIsOpen(!isOpen)}>
        <div className={s.ItemLeftSide}>
          <div>
            <FontAwesomeIcon icon={icon} />
          </div>
          <p>{pipeline ? pipeline.title : eventTitleMap[event.type]}</p>
        </div>
        <div className={s.ItemRightSide}>
          <p>
            {isNew && <label className={s.NewLabel}>{t('new')}</label>}
            {formTimestamp(timestamp)}
          </p>
          <FontAwesomeIcon
            className={classNames({ [s.RotateChevron]: isOpen })}
            icon={faChevronUp}
          />
        </div>
      </div>

      <div className={classNames(s.Info, { block: isOpen })}>
        <hr className={classNames(s.Divider, '!mt-3')} />

        {event.images && event.images.length > 0 && (
          <Zoom zoomMargin={48} IconUnzoom={UnzoomButton} classDialog="cursor-zoom-out">
            <ul className={s.Images}>
              {event.images.map((img, i) => (
                <img key={i} src={img} alt="" height={160} />
              ))}
            </ul>
          </Zoom>
        )}

        {event.execution && (
          <>
            <h3>{t('SUMMARY')}</h3>
            <ul className={s.Summary}>
              {event.execution.stages.map((stage, i) => (
                <li key={i}>
                  <div>
                    <FontAwesomeIcon icon={faCheck} />
                    <p>{stageTitleMap[stage.type]}</p>
                  </div>
                  {event.execution && (
                    <StageData stage={stage} configuration={event.execution.configuration} />
                  )}
                </li>
              ))}
            </ul>
            <hr className={s.Divider} />
          </>
        )}

        <h3>{t('DETAILS')}</h3>
        <ul className={s.Details}>
          {event.execution?.agent && (
            <li>
              <h4>{t('Requested by')}</h4>
              <p>
                <Link to={r.agents.show(event.execution.agent.id)}>
                  {event.execution.agent.firstName} {event.execution.agent.lastName}
                </Link>
              </p>
            </li>
          )}

          {/* <li>
            <h4>{t('Event type')}</h4>
            <p>{eventTitleMap[event.type]}</p>
          </li> */}

          {(event.type === 'passive_auth' || event.type === 'active_auth') && event.metadata && (
            <>
              <li>
                <h4>{t('Match quality score')}</h4>
                <p>{(+event.metadata['MatchQualityScore'] * 100).toFixed(1)}%</p>
              </li>
              <li>
                <h4>{t('Photo quality score')}</h4>
                <p>{(+event.metadata['PhotoQualityScore'] * 100).toFixed(1)}%</p>
              </li>
              <li>
                <h4>{t('Liveness confidence score')}</h4>
                <p>{(+event.metadata['LivenessConfidence'] * 100).toFixed(1)}%</p>
              </li>
            </>
          )}

          {event.type === 'cell_phone' && event.metadata && (
            <li>
              <h4>{t('Detection confidence score')}</h4>
              <p>{(+event.metadata['DetectionConfidence'] * 100).toFixed(1)}%</p>
            </li>
          )}

          {event.ipAddress && (
            <li>
              <h4>{t('IP Address')}</h4>
              <p>{event.ipAddress}</p>
            </li>
          )}

          {(event.browserDetails?.device?.brand || event.browserDetails?.device.model) && (
            <li>
              <h4>{t('Device')}</h4>
              <p>{deviceName(event.browserDetails.device)}</p>
            </li>
          )}

          {event.browserDetails?.browser && (
            <li>
              <h4>{t('Browser')}</h4>
              <p>{browserName(event.browserDetails.browser)}</p>
            </li>
          )}
        </ul>
      </div>
    </li>
  )
}

export default EventItem
