import { useCallback } from 'react'
import { useAdminSelector, useAppDispatch } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import useActionTimer from 'hooks/useActionTimer'
import { accountRolesDelete } from 'thunks/api/accounts/delete'

type Account = {
  id: string
  name: string
}

export default function useLeaveAccount() {
  const timer = useActionTimer()
  const admin = useAdminSelector()
  const dispatch = useAppDispatch()

  const leave = useCallback(
    async (account: Account | undefined) => {
      if (!account || !admin.id) {
        return
      }
      try {
        timer.start()

        const data = {
          accountId: account.id,
          adminId: admin.id,
        }

        await dispatch(accountRolesDelete(data)).then(unwrapResult)

        const timeout = setTimeout(() => timer.succeeded(), 500)
        return () => {
          clearTimeout(timeout)
        }
      } catch (err) {
        timer.failed()
        console.error(err)
      }
    },
    [admin.id, timer, dispatch]
  )

  return [leave, { timer }] as const
}
