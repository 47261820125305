import classNames from 'classnames'
import { FC, useState } from 'react'
import { Pipeline } from 'types'

import { t } from 'translation'

import Select from 'components/atoms/Select'
import { ActionTimer } from 'hooks/useActionTimer'

import s from './index.module.scss'

type Props = {
  showSelect: boolean
  pipelines: Pick<Pipeline, 'id' | 'title'>[]
  timer: ActionTimer
  className?: string
  onSelect: (pipelineId: string) => void
}

const PipelineSelect: FC<Props> = ({
  showSelect: defaultShow,
  pipelines,
  timer,
  className,
  onSelect,
}) => {
  const [showSelect, setShowSelect] = useState(defaultShow)

  return (
    <div className={classNames(s.PipelineSelect, className)}>
      {showSelect && (
        <Select disabled={timer.isLoading} className="w-full mb-4" onChangeText={onSelect}>
          <option key="" value="">
            {t('Select a Pipeline')}
          </option>
          {pipelines.map(pipeline => (
            <option key={pipeline.id} value={pipeline.id}>
              {pipeline.title}
            </option>
          ))}
        </Select>
      )}
      <button
        className={classNames(showSelect ? 'text-gray-400' : 'text-theme', 'text-sm font-medium')}
        onClick={() => setShowSelect(true)}
      >
        {t('+ Add a pipeline')}
      </button>
    </div>
  )
}

export default PipelineSelect
