import { HTTPUpdated } from '@journeyid/agent/common/statuses'

import { Branding } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = Branding

export type Response = Request

export const updateBrandStyle = asyncThunk<Request, Response>({
  name: 'brand-styles/update',
  method: 'PUT',
  uri: ({ id }) => `/brand-styles/${id}`,
  successStatus: HTTPUpdated,
  orchestrator: false,
})
