import classNames from 'classnames'
import React, { ChangeEvent, forwardRef } from 'react'

import styles from './index.module.scss'

type Props = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeText?: (text: string) => void
} & Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>

const Input = forwardRef<HTMLInputElement, Props>(
  ({ label, className, onChangeText, children, ...rest }, ref) => {
    if (!rest.onChange && onChangeText) {
      rest.onChange = (e: ChangeEvent<HTMLInputElement>) =>
        onChangeText && onChangeText(e.target.value)
    }

    return (
      <input
        ref={ref}
        type="text"
        {...rest}
        className={classNames('__Input', styles.Input, className)}
      />
    )
  }
)

export default Input
