import classNames from 'classnames'
import { FC } from 'react'

import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './NavItem.module.scss'

type Props = {
  isActive: boolean
  isComplete?: boolean
  children: string
  onClick: () => void
}

const NavItem: FC<Props> = ({ isActive, isComplete, children, onClick }) => {
  return (
    <li className={classNames(isActive ? s.Active : s.Inactive)} onClick={onClick}>
      <label>{children}</label>
      {isComplete && <FontAwesomeIcon icon={faCheck} />}
    </li>
  )
}

export default NavItem
