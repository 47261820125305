import { FC, ReactNode } from 'react'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Modal from 'components/organisms/Modal'
import { closeModal, useModal } from 'hooks/useModal'

type Props = {
  children: ReactNode
}

const AlertModal: FC<Props> = ({ children }) => {
  const [isOpen] = useModal('Alert')

  return (
    <Modal isOpen={isOpen} className="max-w-xl px-12 py-8" onClose={closeModal}>
      <Modal.Body>{children}</Modal.Body>
      <hr className="mb-6" />
      <Modal.Actions>
        <Button onClick={closeModal}>{t('Close')}</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AlertModal
