import { FC } from 'react'

import { ActionTimer } from 'hooks/useActionTimer'
import { Account } from 'types/account'

import s from './AccountList.module.scss'
import AccountListItem from './AccountListItem'

type Props = {
  accounts: Account[]
  activeAccountId: string | undefined
  timer: ActionTimer
  onSelect: (account: Account) => void
}

const AccountList: FC<Props> = ({ accounts, activeAccountId, timer, onSelect }) => (
  <ul className={s.List}>
    {accounts.map((account, i) => (
      <AccountListItem
        key={i}
        account={account}
        isActive={activeAccountId === account.id}
        isLoading={timer.isLoading && timer.id === account.id}
        onClick={() => onSelect(account)}
      />
    ))}
  </ul>
)

export default AccountList
