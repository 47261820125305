import { useCallback } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { createToken, Request } from 'thunks/api/tokens/create'

export function useCreateToken() {
  const { accountId } = useAuthSelector()
  const [run, { timer }] = useAPI(createToken)

  const create = useCallback(
    async (req: Omit<Request, 'accountId'>) => {
      if (!accountId) return
      return await run({
        ...req,
        accountId,
      })
    },
    [accountId, run]
  )

  return [create, { timer }] as const
}
