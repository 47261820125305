import { FC, HTMLProps, useRef } from 'react'

import { Address as AddressType } from '@journeyid/agent/types'

import Input from 'components/atoms/Input'

import Field from './'
import { CommonProps } from './_Wrapper'

type InputProps = Omit<HTMLProps<HTMLInputElement>, 'ref' | 'label' | 'name' | 'value' | 'onChange'>

export type Props = CommonProps &
  InputProps & {
    value: string
    onChangeValue: (value: string) => void
    onChangeAddress?: (address: AddressType) => void
  }

const Address: FC<Props> = ({ value, onChangeValue, onChangeAddress, ...rest }) => {
  const { label, name, hint, isOptional, error, aside, className, ...inputProps } = rest
  const fieldProps = { label, name, hint, isOptional, error, aside, className }

  const ref = useRef<HTMLInputElement>(null)

  return (
    <Field type="text" {...fieldProps}>
      <Input ref={ref} value={value} onChangeText={onChangeValue} {...inputProps} />
    </Field>
  )
}

export default Address
