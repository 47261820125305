import { HTTPAuthenticated } from '@journeyid/agent/common/statuses'

import { Branding } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  accountId: string
}

export type Response = {
  token: string
  accountId: string
  branding?: Branding
}

export const authenticationsSwitch = asyncThunk<Request, Response>({
  name: 'authentications/switch',
  method: 'POST',
  uri: '../../authentications/switch',
  successStatus: HTTPAuthenticated,
})
