import { Pipeline } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = { id: string } & Pipeline['success']

type Response = Pipeline

export const updateSuccessScreen = asyncThunk<Request, Response>({
  name: 'pipelines/update-success-screen',
  method: 'PUT',
  uri: ({ id }) => `/pipelines/${id}/success-screen`,
  stripId: true,
})
