import { FC, useCallback, useState } from 'react'
import { t } from 'translation'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import CopyButton from 'components/atoms/CopyButton'
import { useAPI } from 'hooks/useAPI'
import { iframeToken } from 'thunks/api/iframes/token'

import s from './index.module.scss'

type Props = {
  iframeId: string
  uniqueId: string
  onToken: (token: string) => void
}

const DevToken: FC<Props> = ({ iframeId, uniqueId, onToken }) => {
  const [token, setToken] = useState<string>()
  const [tokenError, setTokenError] = useState<string>()
  const [createToken, { timer: tokenTimer }] = useAPI(iframeToken)
  const handleCreateToken = useCallback(async () => {
    try {
      if (!uniqueId) {
        setTokenError(`Please enter an agent's unique ID below`)
        return
      }
      setTokenError(undefined)
      const { token } = await createToken({
        id: iframeId,
        uniqueId,
        hours: 90 * 24,
      })
      setToken(token)
      onToken(token)
    } catch (err) {
      console.log('err', err)
      if (isAPIErrorResponse(err)) {
        setTokenError(err.error)
      } else {
        setTokenError((err as Error).message)
      }
    }
  }, [iframeId, uniqueId, createToken, onToken])

  return token ? (
    <div className={s.Code}>
      <CopyButton content={token} />
      <div className="pr-8 overflow-hidden text-xs leading-4 text-green-700 break-words">
        {token}
      </div>
    </div>
  ) : tokenTimer.isLoading ? (
    <div className="pt-3 space-x-2 text-sm text-gray-600">
      <FontAwesomeIcon icon={faSpinnerThird} spin />
      <span>{t('Generating token...')}</span>
    </div>
  ) : (
    <>
      {tokenError && <div className="pt-2 text-sm font-semibold text-red-600">{t(tokenError)}</div>}
      <div className="pt-1 text-sm">
        <span
          className="text-gray-600 underline cursor-pointer underline-offset-2"
          onClick={handleCreateToken}
        >
          {t('Click here')}
        </span>{' '}
        {t('to generate a development token valid for 90 days.')}
      </div>
    </>
  )
}

export default DevToken
