import { useEffect, useState } from 'react'

import { useAPI } from 'hooks/useAPI'
import { listBrandStyles } from 'thunks/api/branding/list'
import { Branding } from 'types/account'

export default function useListBrandStyles() {
  const [list, { timer }] = useAPI(listBrandStyles)

  const [brandStyles, setBrandStyles] = useState<Branding[]>()

  useEffect(() => {
    if (timer.isLoading) return
    list({}).then(setBrandStyles)
  }, [list, timer])

  return [brandStyles, { timer }] as const
}
