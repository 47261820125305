import { Iframe } from 'types'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { createIFrameCategory } from 'thunks/api/iframes/categories/create'
import { deleteIFrameCategory } from 'thunks/api/iframes/categories/delete'
import { reorderIFrameCategories } from 'thunks/api/iframes/categories/reorder'
import { updateIframeCategory } from 'thunks/api/iframes/categories/update'
import { createIFrame } from 'thunks/api/iframes/create'
import { createIFramePipeline } from 'thunks/api/iframes/pipelines/create'
import { deleteIFramePipeline } from 'thunks/api/iframes/pipelines/delete'
import { reorderIFramePipelines } from 'thunks/api/iframes/pipelines/reorder'
import { retrieveIFrame } from 'thunks/api/iframes/retrieve'
import { updateIframe } from 'thunks/api/iframes/update'
import { resetIFrame } from 'thunks/reset-iframe'

function defaultIframe(): Iframe {
  return {
    id: '',
    hideSecureCallBanner: false,
    title: '',
    groupIds: [],
    authentication: [],
    enrollment: [],
    categories: [
      {
        id: '',
        title: '',
        pipelines: [],
      },
    ],
    languages: {
      agent: 'en',
      customer: ['en'],
    },
    deliveryMethods: {
      sms: true,
      email: true,
      'push-notification': true,
      whatsapp: null,
      url: true,
    },
  }
}

export type IframeState = {
  iframe: Iframe
}

const initialState: IframeState = {
  iframe: defaultIframe(),
}

const slice = createSlice({
  name: 'iframe',
  initialState,
  reducers: {
    updateLocalIframe: (state, action: PayloadAction<Iframe>) => ({
      ...state,
      iframe: action.payload,
    }),
  },
  extraReducers: builder => {
    ;[
      createIFrame,
      retrieveIFrame,
      updateIframe,
      createIFramePipeline,
      deleteIFramePipeline,
      createIFrameCategory,
      updateIframeCategory,
      deleteIFrameCategory,
      reorderIFramePipelines,
    ].map(thunk =>
      builder.addCase(thunk.fulfilled, (_, { payload }) => {
        return { iframe: payload }
      })
    )
    builder.addCase(reorderIFramePipelines.pending, (state, { meta }) => {
      var idx = -1
      var pipes = (() => {
        switch (meta.arg.type) {
          case 'enrollment':
            return state.iframe.enrollment
          case 'authentication':
            return state.iframe.authentication
          case 'categories':
            idx = state.iframe.categories.findIndex(c => c.id === meta.arg.categoryId)
            idx = idx === -1 ? 0 : idx
            return state.iframe.categories[idx].pipelines
        }
      })()
      const pipeline = pipes[meta.arg.fromIndex]
      pipes = [...pipes.slice(0, meta.arg.fromIndex), ...pipes.slice(meta.arg.fromIndex + 1)]
      const newPipelines = [...pipes.slice(0, meta.arg.toIndex)]
      newPipelines[meta.arg.toIndex] = pipeline
      pipes = [...newPipelines, ...pipes.slice(meta.arg.toIndex)]

      switch (meta.arg.type) {
        case 'enrollment':
          state.iframe.enrollment = pipes
          break
        case 'authentication':
          state.iframe.authentication = pipes
          break
        case 'categories':
          state.iframe.categories[idx].pipelines = pipes
      }
    })
    // TODO
    builder.addCase(reorderIFrameCategories.pending, (state, { meta }) => {
      var { categories } = state.iframe
      const category = categories[meta.arg.fromIndex]
      categories = [
        ...categories.slice(0, meta.arg.fromIndex),
        ...categories.slice(meta.arg.fromIndex + 1),
      ]
      const newCategories = [...categories.slice(0, meta.arg.toIndex)]
      newCategories[meta.arg.toIndex] = category
      state.iframe.categories = [...newCategories, ...categories.slice(meta.arg.toIndex)]
    })
    builder.addCase(resetIFrame.fulfilled, state => {
      state.iframe = defaultIframe()
    })
  },
})

export const { updateLocalIframe } = slice.actions

export default slice
