import { useEffect, useState } from 'react'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import Header from 'components/molecules/AdminHeader'
import { useAPI } from 'hooks/useAPI'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'
import { listGroups } from 'thunks/api/groups/list'
import { Group } from 'types/group'

import Form from './_Form'
import List from './_List'
import s from './index.module.scss'

const Groups = () => {
  const { accountId } = useAuthSelector()
  const [list, { timer }] = useAPI(listGroups)

  const [groups, setGroups] = useState<Group[]>()
  useEffect(() => {
    if (!accountId) return
    list({}).then(groups => setGroups(groups))
  }, [accountId, list])

  return (
    <>
      <ContentWrapper>
        <Header title={t('Settings')} />
        <div className={s.Content}>
          <Menu />
          <div>
            <p className="mt-4 mb-8 text-4xl font-semibold">{t('Agent Groups')}</p>

            {timer.isLoading ? (
              <Loading />
            ) : (
              <>
                {groups && groups.length > 0 && (
                  <SettingsCard>
                    <List
                      groups={groups}
                      onDelete={group =>
                        setGroups(groups => groups?.filter(g => g.id !== group.id))
                      }
                    />
                  </SettingsCard>
                )}
                <Form onCreate={group => setGroups(groups => [...(groups || []), group])} />
              </>
            )}
          </div>
        </div>
        <Footer />
      </ContentWrapper>
    </>
  )
}

export default Groups
