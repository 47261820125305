import classNames from 'classnames'
import { FC, useCallback, useState } from 'react'

import Button from 'components/atoms/Button'
import CopyButton from 'components/atoms/CopyButton'
import Select from 'components/atoms/Select'
import { useAPI } from 'hooks/useAPI'
import { iframeToken } from 'thunks/api/iframes/token'

import s from './index.module.scss'

type Props = {
  iframeId: string
  onToken: (token: string) => void
}

const GlobalAuthToken: FC<Props> = ({ iframeId, onToken }) => {
  const [days, setDays] = useState(30)
  const [token, setToken] = useState<string>('...')
  const [createToken, { timer }] = useAPI(iframeToken)
  const handleCreateToken = useCallback(async () => {
    const { token } = await createToken({
      id: iframeId,
      hours: days * 24,
    })
    setToken(token)
    onToken(token)
  }, [iframeId, days, createToken, onToken])

  return (
    <>
      <div className="flex mt-4 space-x-4">
        <Select value={days} className="flex-grow-0 pr-6" onChange={e => setDays(+e.target.value)}>
          <option value={30}>30 days</option>
          <option value={60}>60 days</option>
          <option value={90}>90 days</option>
          <option value={180}>180 days</option>
          <option value={365}>1 year</option>
          <option value={730}>2 years</option>
        </Select>
        <Button isLoading={timer.isLoading} onClick={handleCreateToken}>
          Generate
        </Button>
      </div>
      {token && (
        <div className={classNames(s.Code, 'font-mono break-all pr-12')}>
          <CopyButton content={`const token = '${token}'`} />
          <div>
            <span className="text-red-700">const</span>{' '}
            <span className="text-purple-800">token</span> ={' '}
            <span className="text-green-700">'{token}'</span>
          </div>
        </div>
      )}
    </>
  )
}

export default GlobalAuthToken
