import { FC, useState } from 'react'
import { t } from 'translation'

import Button from 'components/atoms/Button'
import Modal from 'components/organisms/Modal'
import useCopied from 'hooks/useCopied'
import { closeModal, useModal } from 'hooks/useModal'
import PostMessage from 'pages/IFrames/Steps/Test/PostMessage'
import { Payload } from 'pages/IFrames/Steps/Test/useIFrame'

type Props = {
  onSend: (payload: Payload) => void
}

const AdvancedSimulatorModal: FC<Props> = ({ onSend }) => {
  const [isOpen] = useModal('AdvancedSimulator')
  const [wasCopied, copy] = useCopied()
  const [payload, setPayload] = useState<Payload>({
    event: 'start-session',
    session: {
      externalRef: '',
    },
    user: {
      uniqueId: '',
    },
  })

  const isReady = payload.user.uniqueId && payload.session.externalRef

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>{t('Advanced Simulator')}</Modal.Header>
      <Modal.Body>
        <p>{t('Edit the JSON below to simulate a postMessage being sent to the IFrame.')}</p>
        <PostMessage onChange={setPayload} />
      </Modal.Body>
      <Modal.Actions className="flex pt-2">
        <Button disabled={!isReady} onClick={() => onSend(payload)}>
          {t('Send postMessage to IFrame')}
        </Button>
        <Button
          variant="link"
          disabled={!isReady}
          onClick={() => copy(JSON.stringify(payload, null, 2))}
        >
          {wasCopied ? (
            <span className="text-green-600">{t('Copied!')}</span>
          ) : (
            t('Copy to clipboard')
          )}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AdvancedSimulatorModal
