import { FC } from 'react'

import { CreditCardPaymentStage } from '@journeyid/agent/types'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Divider from 'components/organisms/Preview/Divider'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

import BillingSummary from './components/BillingSummary'
import Header from './components/Header'
import PaymentMethods from './components/PaymentMethods'

type Props = CreditCardPaymentStage

const CreditCardPaymentPreview: FC<Props> = ({ metadata }) => {
  return (
    <>
      <LogoHeader />
      <div className="px-4 -mt-4">
        <Header {...metadata} />
        <main>
          <Divider />
          <BillingSummary
            currency="USD"
            lineItems={[
              {
                quantity: 1,
                title: t('Express Installation'),
                amount: 11999,
              },
              {
                quantity: 1,
                title: t('Premium Service'),
                amount: 5999,
              },
            ]}
          />
          <Divider />
          <PaymentMethods
            methods={[
              {
                type: 'visa',
                last4: '1234',
              },
              {
                type: 'apple-pay',
              },
              {
                type: 'paypal',
              },
            ]}
            selected="visa"
            onChange={() => {}}
          />
        </main>
      </div>
      <footer className="std">
        <PrimaryButton block preview>
          {t('Continue')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default CreditCardPaymentPreview
