import classNames from 'classnames'
import { FC, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { faArchive, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { useAPI } from 'hooks/useAPI'
import { closeModal, openModal } from 'hooks/useModal'
import DeliveryMethodList from 'pages/Pipelines/Create/components/CreateSidebar/DeliveryMethodList'
import StageList from 'pages/Pipelines/Create/components/CreateSidebar/StageList'
import ArchivePipelineModal from 'pages/Pipelines/Edit/settings/Archive/ArchivePipelineModal'
import { archivePipeline } from 'thunks/api/pipelines/archive'

import s from './index.module.scss'

const steps = ['use-case', 'configure', 'delivery', 'publish']

const CreateSidebar: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { pipeline } = usePipelineSelector()

  const idx = useMemo(() => {
    const step = location.pathname.split('/')
    return steps.findIndex(s => s === step[4])
  }, [location])

  const [archive, { timer }] = useAPI(archivePipeline)
  const handleArchive = useCallback(async () => {
    await archive({
      id: pipeline.id,
    })
    history.push(r.pipelines.root)
  }, [pipeline, archive, history])

  return (
    <>
      <div id="create-sidebar" className={s.Sidebar}>
        <header>
          <h2 className="std">{t('Create a new pipeline')}</h2>
          <p className="std-base">
            {t('Follow the instructions to configure and publish your unique pipeline.')}
          </p>
        </header>
        <ul>
          <li className={s.Inactive}>
            <div>
              <h6 className="std">{t('Step 1')}</h6>
              <h5 className="std">
                <span>{t('Use Case')}</span>
              </h5>
            </div>
            <FontAwesomeIcon icon={faCheck} />
          </li>
          <li
            className={classNames(idx === 1 ? s.Active : s.Inactive)}
            onClick={() => history.push(r.pipelines.create.configure.root(pipeline.id))}
          >
            <div>
              <h6 className="std">{t('Step 2')}</h6>
              <h5 className="std">
                <span>{t('Configure')}</span>
              </h5>
            </div>
            {idx > 1 && <FontAwesomeIcon icon={faCheck} />}
          </li>
          {idx === 1 && (
            <li>
              <StageList
                key={pipeline.stages.length}
                stageTypes={pipeline.stages.map(s => s.type)}
              />
            </li>
          )}
          <li
            className={classNames(idx === 2 ? s.Active : s.Inactive)}
            onClick={() => history.push(r.pipelines.create.delivery.method(pipeline.id, 'sms'))}
          >
            <div>
              <h6 className="std">{t('Step 3')}</h6>
              <h5 className="std">
                <span>{t('Delivery')}</span>
              </h5>
            </div>
            {idx > 2 && <FontAwesomeIcon icon={faCheck} />}
          </li>
          {idx === 2 && <DeliveryMethodList />}
          <li
            className={classNames(idx === 3 ? s.Active : s.Inactive)}
            onClick={() => history.push(r.pipelines.create.publish(pipeline.id))}
          >
            <div>
              <h6 className="std">{t('Step 4')}</h6>
              <h5 className="std">
                <span>{t('Publish')}</span>
              </h5>
            </div>
            {idx > 3 && <FontAwesomeIcon icon={faCheck} />}
          </li>
          <li className={s.Archive}>
            <span onClick={openModal('ArchivePipeline')}>
              <FontAwesomeIcon icon={faArchive} />
              <span>{t('Archive')}</span>
            </span>
          </li>
        </ul>
      </div>
      <ArchivePipelineModal
        pipeline={pipeline}
        timer={timer}
        onArchive={handleArchive}
        onCancel={closeModal}
      />
    </>
  )
}

export default CreateSidebar
