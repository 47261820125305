import { FC, ReactNode, useState } from 'react'

import Button from 'components/atoms/Button'
import Field from 'components/molecules/Field'
import Modal from 'components/organisms/Modal'
import { ActionTimer } from 'hooks/useActionTimer'
import { closeModal, useModal } from 'hooks/useModal'

type Props = {
  title: string
  label: string
  placeholder?: string
  buttonText: string
  loadingText: string
  successText: string
  timer: ActionTimer
  children: ReactNode
  onClick: (value: string) => void
}

const InputModal: FC<Props> = ({
  title,
  label,
  placeholder,
  buttonText,
  loadingText,
  successText,
  timer,
  children,
  onClick,
}) => {
  const [isOpen] = useModal('Input')
  const [value, setValue] = useState('')

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        {children}

        <Field
          label={label}
          name="input"
          placeholder={placeholder}
          autoFocus
          onChange={e => setValue(e.target.value)}
          className="mt-4"
        />
      </Modal.Body>
      <Modal.Actions>
        <Button
          variant={timer.didSucceed ? 'green' : 'primary'}
          disabled={value.length === 0}
          size="xl"
          onClick={() => onClick(value)}
        >
          {timer.didSucceed ? successText : timer.isLoading ? loadingText : buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default InputModal
