import { Desktop } from 'types/desktop'
import asyncThunk from 'util/asyncThunk'
import { HTTPRetrievedMultiple } from 'util/statuses'

export type Request = {}

export type Response = Desktop[]

export const listDesktops = asyncThunk<Request, Response>({
  name: 'desktops/list',
  method: 'GET',
  uri: '/desktops',
  orchestrator: false,
  successStatus: HTTPRetrievedMultiple,
})
