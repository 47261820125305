import { HTTPRetrieved } from '@journeyid/agent/common/statuses'

import { Branding } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

export const retrieveBrandStyle = asyncThunk<Request, Branding>({
  name: 'brand-styles/retrieve',
  method: 'GET',
  uri: ({ id }) => `/brand-styles/${id}`,
  successStatus: HTTPRetrieved,
  orchestrator: false,
  stripId: true,
})
