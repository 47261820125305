import { FC } from 'react'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Header from 'components/organisms/Preview/Header'
import Illustration from 'components/organisms/Preview/Illustration'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

const WebAuthnRegistrationPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <Header headline={t('Register device')}>
        {t('Proceed below to register this device using onboard biometrics.')}
      </Header>
      <Illustration type="webauthn" />
      <footer>
        <PrimaryButton block preview>
          {t('Register device')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default WebAuthnRegistrationPreview
