import { HTTPCreated } from '@journeyid/agent/common/statuses'

import { Desktop } from 'types/desktop'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  pipelineId: string
  identifier: string
  loginPrompt: string
}

export type Response = Desktop

export const createDesktop = asyncThunk<Request, Response>({
  name: 'desktops/create',
  method: 'POST',
  uri: '/desktops',
  orchestrator: false,
  successStatus: HTTPCreated,
})
