import { HTTPUpdated } from '@journeyid/agent/common/statuses'

import { Branding } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

export const setDefaultBrandStyle = asyncThunk<Request, Branding>({
  name: 'brand-styles/default',
  method: 'PUT',
  uri: ({ id }) => `/brand-styles/${id}/default`,
  successStatus: HTTPUpdated,
  orchestrator: false,
  stripId: true,
})
