import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Field from 'components/molecules/Field'
import useYupValidationResolver from 'hooks/useYupValidationResolver'

import SelectOptionList from './SelectOptionList'

type Option = {
  label: string
  value: string
}

type Props = {
  defaultValues: Option[]
  setOptions: (options: Option[]) => void
}

type Inputs = {
  label: string
  value: string
}

const schema = yup.object().shape({
  label: yup.string().required(t('A label is required')),
  value: yup.string().required(t('A value is required')),
})

const AddOptionForm: FC<Props> = ({ defaultValues, setOptions }) => {
  const [options, setLocalOptions] = useState(defaultValues)

  const resolver = useYupValidationResolver(schema)
  const form = useForm<Inputs>({ resolver })
  const { register, errors, reset, handleSubmit } = form

  const onSubmit = (data: Inputs) => {
    setLocalOptions([...options, data])
    reset()
  }

  useEffect(() => {
    setOptions(options)
  }, [options, setOptions])

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="mr-1">
        <p className="mb-2 text-sm text-gray-500 uppercase">{t('Options')}</p>
        <div className="flex w-full justify-between">
          <div className="w-3/4 flex justify-between">
            <Field
              ref={register}
              id="label"
              name="label"
              placeholder={t('Label')}
              hint={t('What the user will see')}
              className="w-1/2 mb-4"
              autoComplete="off"
              error={errors.label}
            />
            <Field
              ref={register}
              id="value"
              name="value"
              placeholder={t('Value')}
              hint={t('Value stored in the database')}
              className="w-5/12 mb-4"
              autoComplete="off"
              error={errors.value}
            />
          </div>
          <Button type="submit" variant="secondary" size="lg" className="h-[38px] mt-2">
            {t('Add')}
          </Button>
        </div>

        <div>
          <SelectOptionList options={options} setOptions={setLocalOptions} />
        </div>
      </form>
    </FormProvider>
  )
}

export default AddOptionForm
