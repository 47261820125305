import { FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Field from 'components/molecules/Field'
import { useAPI } from 'hooks/useAPI'
import { updatePipeline } from 'thunks/api/pipelines/update'

import SettingLayout from '../SettingLayout'

type FormData = {
  internalTitle: string
}

const InternalTitle: FC = () => {
  const { pipeline } = usePipelineSelector()
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      internalTitle: pipeline.internalTitle || '',
    },
  })

  const [update, { timer }] = useAPI(updatePipeline)
  const onSubmit = useCallback(
    (data: FormData) => {
      update({
        id: pipeline.id,
        title: pipeline.title,
        internalTitle: data.internalTitle || undefined,
      })
    },
    [pipeline, update]
  )

  return (
    <SettingLayout
      id="internal-pipeline"
      title={t('Internal Title')}
      description={t(
        "If you'd like the pipeline's title in the admin dashboard to be different than the title the user will see, enter it below."
      )}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-xs mb-6 space-y-4">
          <Field ref={register} label={t('Title')} name="internalTitle" />
        </div>
        <Button type="submit" variant={timer.didSucceed ? 'green' : 'secondary'} size="xl">
          {timer.didSucceed ? t('Saved!') : timer.isLoading ? t('Saving...') : t('Save')}
        </Button>
      </form>
    </SettingLayout>
  )
}

export default InternalTitle
