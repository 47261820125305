import { FC, useCallback } from 'react'

import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import useActionTimer from 'hooks/useActionTimer'
import stripHTML from 'util/stripHTML'

import s from './index.module.scss'

type Props = {
  template: string
}

const CodeBlock: FC<Props> = ({ template }) => {
  const timer = useActionTimer()
  const handleCopy = useCallback(() => {
    const clean = stripHTML(template).replaceAll(/&lt;/g, '<')
    navigator.clipboard.writeText(clean)
    timer.succeeded()
  }, [template, timer])

  return (
    <div className={s.CodeBlock}>
      <code
        dangerouslySetInnerHTML={{
          __html: template,
        }}
      />
      <div>
        {timer.didSucceed ? (
          <span>{t('copied!')}</span>
        ) : (
          <button onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} />
          </button>
        )}
      </div>
    </div>
  )
}

export default CodeBlock
