import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  categoryId: string
  title: string
}

type Response = Iframe

export const updateIframeCategory = asyncThunk<Request, Response>({
  name: 'iframes/categories/update',
  method: 'PUT',
  uri: ({ id, categoryId }) => `/iframes/${id}/categories/${categoryId}`,
  stripId: true,
})
