import { useEffect, useState } from 'react'
import { useIframeSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { iframeToken } from 'thunks/api/iframes/token'

export default function useAgent() {
  const [uniqueId, setUniqueId] = useLocalStorage('iframeAgent')
  const { iframe } = useIframeSelector()

  const [agentToken, setAgentToken] = useState<string>()

  const [authenticate] = useAPI(iframeToken)
  useEffect(() => {
    if (!uniqueId || !iframe) return
    authenticate({
      id: iframe.id,
      uniqueId,
      hours: 7 * 24,
    }).then(({ token }) => setAgentToken(token))
  }, [iframe, uniqueId, authenticate])

  return { uniqueId, agentToken, setUniqueId } as const
}
