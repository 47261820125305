import { useCallback, useEffect, useState } from 'react'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import Header from 'components/molecules/AdminHeader'
import { useAPI } from 'hooks/useAPI'
import Menu from 'pages/Settings/Menu'
import { deleteDesktop } from 'thunks/api/desktops/delete'
import { listDesktops } from 'thunks/api/desktops/list'
import { Desktop } from 'types/desktop'

import s from '../../index.module.scss'
import AgentDesktopForm from './Form'
import List from './List'

const AgentDesktop = () => {
  const [desktops, setDesktops] = useState<Desktop[]>()
  const [listDesk] = useAPI(listDesktops)
  useEffect(() => {
    listDesk({}).then(setDesktops)
  }, [listDesk])

  const [deleteDesk, { timer: deleteTimer }] = useAPI(deleteDesktop)
  const handleDelete = useCallback(
    async (id: string) => {
      deleteTimer.setId(id)
      await deleteDesk({ id })
      setDesktops(desktops => desktops?.filter(d => d.id !== id))
    },
    [deleteTimer, deleteDesk]
  )

  return (
    <ContentWrapper>
      <Header title="Settings" />
      <div className={s.Content}>
        <Menu />
        <div>
          <p className="mt-4 mb-8 text-4xl font-semibold">Agent Desktops</p>
          <p className="max-w-4xl mb-4 text-gray-500">
            The Agent Desktop can be used to securely interact with customers outside of a call
            center integration. Using Journey pipelines as an SSO solution, agents can login and
            make requests of customers.
          </p>
          <p className="max-w-4xl mb-4 text-gray-500">
            If there are multiple SSO requirements, you can register multiple URLs.
          </p>

          <SettingsCard>
            {typeof desktops === 'undefined' ? (
              <Loading size="2x" />
            ) : desktops.length > 0 ? (
              <List desktops={desktops} timer={deleteTimer} onDelete={handleDelete} />
            ) : (
              <span className="text-gray-500">No desktops have been created yet.</span>
            )}
          </SettingsCard>

          <SettingsCard>
            <AgentDesktopForm
              onCreate={desktop => setDesktops(desktops => [...(desktops || []), desktop])}
            />
          </SettingsCard>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default AgentDesktop
