import cogoToast from 'cogo-toast'
import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { t } from 'translation'

import { faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConfirmModal from 'components/organisms/ConfirmModal'
import { useDeleteUser } from 'hooks/api/useDeleteUser'
import { openModal } from 'hooks/useModal'
import { User } from 'types/user'

import s from './index.module.scss'

type Props = {
  back: string
  user: User
}

const DeleteUser: FC<Props> = ({ back, user }) => {
  const history = useHistory()

  const [deleteUser, { timer: removeTimer }] = useDeleteUser()
  const onRemove = useCallback(async () => {
    await deleteUser(user.id)
    const identifier =
      user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.uniqueId
    cogoToast.success(t('{{id}} has been deleted', { id: identifier }))
    history.push(back)
  }, [back, user, deleteUser, history])

  return (
    <>
      <button className={s.DeleteBtn} onClick={openModal('ConfirmAction')}>
        <FontAwesomeIcon icon={faTrashCan} />
        {t('Delete user')}
      </button>

      <ConfirmModal
        modalId="ConfirmAction"
        type="delete"
        title={t('Delete user?')}
        text={t("Are you sure you want to delete {{user}}? This action can't be undone", {
          user:
            user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : 'this user',
        })}
        isLoading={removeTimer.isLoading}
        onSubmit={onRemove}
      />
    </>
  )
}

export default DeleteUser
