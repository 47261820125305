import { useCallback } from 'react'
import { useAppDispatch } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import useActionTimer from 'hooks/useActionTimer'
import { ssoRedirect } from 'thunks/api/sso/redirect'

export default function useSSORedirect() {
  const timer = useActionTimer()
  const dispatch = useAppDispatch()

  const create = useCallback(
    async (email: string) => {
      try {
        timer.start()

        const resp = await dispatch(
          ssoRedirect({
            email,
          })
        ).then(unwrapResult)

        timer.succeeded()

        return resp
      } catch (err) {
        timer.failed()
        throw err
      }
    },
    [timer, dispatch]
  )

  return [create, { timer }] as const
}
