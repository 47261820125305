import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { FormStage } from '@journeyid/agent/types'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Header from 'components/organisms/Preview/Header'

import Field from './Field'

type Props = Pick<FormStage, 'metadata'>

const FormPreview: FC<Props> = ({ metadata }) => {
  const methods = useForm()

  const form = metadata.pages[0]

  return (
    <>
      <Header headline={form.title || 'Short form title'}>
        {form.text || 'Form instructions'}
      </Header>
      <main className="px-4 mt-4 max-h-[386px] overflow-y-auto">
        <div className="w-full space-y-3">
          {form.fields.length === 0 ? (
            <div className="pt-2 text-sm italic text-gray-400">
              {t('no fields have been added yet')}
            </div>
          ) : (
            <FormProvider {...methods}>
              {form.fields.map(f => {
                switch (f.type) {
                  case 'dropdown':
                  case 'radio':
                  case 'checkboxes':
                    return (
                      <Field key={f.id} name={f.id} {...f}>
                        {f.options.map(({ label, value }) => (
                          <option key={label} value={value}>
                            {label}
                          </option>
                        ))}
                      </Field>
                    )

                  default:
                    // @ts-ignore
                    return <Field key={f.id} name={f.id} {...f} />
                }
              })}
            </FormProvider>
          )}
        </div>
      </main>
      <footer>
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Encrypt & Submit')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default FormPreview
