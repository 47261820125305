import { useCallback } from 'react'
import { useAppDispatch } from 'store'

import { useAPI } from 'hooks/useAPI'
import { actions } from 'slices/branding'
import { Request, setDefaultBrandStyle } from 'thunks/api/branding/default'

export default function useSetDefaultBrandStyle() {
  const dispatch = useAppDispatch()
  const [setDefault, { timer }] = useAPI(setDefaultBrandStyle)

  const setDefaultBranding = useCallback(
    async (req: Request) => {
      if (timer.isLoading) return

      timer.setId(req.id)

      const branding = await setDefault(req)

      dispatch(actions.updateLocalBranding(branding))
    },
    [dispatch, timer, setDefault]
  )

  return [setDefaultBranding, { timer }] as const
}
