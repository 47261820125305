import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import r from 'routes'

import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Header from 'components/molecules/AdminHeader'
import Menu from 'pages/Settings/Menu'

import s from './Pricing.module.scss'
import AgentAuthentication from './Pricing/AgentAuthentication'
import costCalculator from './Pricing/calculator'
import {
  AgentAuthentication as AgentAuth,
  CustomerInteractions as CustInteractions,
  DeploymentModel,
  partnerDiscount,
} from './Pricing/costs'
import CustomerInteractions from './Pricing/CustomerInteractions'
import DeploymentModels from './Pricing/DeploymentModels'
import TotalPrice from './Pricing/TotalPrice'

const tabs = [
  {
    label: 'Customer Interactions',
    path: 'customer-interactions',
  },
  {
    label: 'Agent Authentication',
    path: 'agent-authentication',
  },
  {
    label: 'Deployment Models',
    path: 'deployment-models',
  },
] as const

const Pricing = () => {
  const location = useLocation()
  const [deploymentModel, setDeploymentModel] = useState<DeploymentModel>('global-cloud')
  const [agentAuthentication, setAgentAuthentication] = useState<AgentAuth>({
    enrollments: 0,
    ssoAuthentications: 0,
    continuousAuthenticationAgents: 0,
  })
  const [customerInteractions, setCustomerInteractions] = useState<CustInteractions>({
    standard: 0,
    advanced: 0,
    voiceBiometricEnrollments: 0,
    voiceBiometricAuthentications: 0,
  })
  const [isPartner, setIsPartner] = useState(false)
  const discount = useMemo(() => (isPartner ? partnerDiscount : undefined), [isPartner])

  const cost = useMemo(
    () => costCalculator(discount || 0, customerInteractions, agentAuthentication, deploymentModel),
    [discount, customerInteractions, agentAuthentication, deploymentModel]
  )

  return (
    <ContentWrapper>
      <Header title={t('Settings')} />
      <div className={s.Content}>
        <Menu />
        <div>
          <div className="flex justify-between">
            <p
              className="mt-4 mb-8 text-4xl font-semibold"
              onClick={() => setIsPartner(!isPartner)}
            >
              {t('Pricing')}
            </p>
            <TotalPrice cost={cost} />
          </div>
          {isPartner && (
            <div className={s.Partner}>{t('20% partner pricing discount applied')}</div>
          )}
          <div className={s.PricingCategories}>
            {tabs.map(tab => (
              <Link
                key={tab.label}
                to={r.settings.pricing(tab.path)}
                className={classNames(
                  location.pathname === r.settings.pricing(tab.path)
                    ? s.ActiveCategoryBox
                    : s.CategoryBox
                )}
              >
                <h1>{t(tab.label)}</h1>
              </Link>
            ))}
          </div>
          <Switch>
            <Route
              path={r.settings.pricing('customer-interactions')}
              render={() => (
                <CustomerInteractions
                  value={customerInteractions}
                  discount={discount}
                  onChange={setCustomerInteractions}
                />
              )}
            />
            <Route
              path={r.settings.pricing('agent-authentication')}
              render={() => (
                <AgentAuthentication
                  value={agentAuthentication}
                  discount={discount}
                  onChange={setAgentAuthentication}
                />
              )}
            />
            <Route
              path={r.settings.pricing('deployment-models')}
              render={() => (
                <DeploymentModels
                  value={deploymentModel}
                  discount={discount}
                  onChange={setDeploymentModel}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default Pricing
