import React, { FC, HTMLProps } from 'react'

import Input from 'components/atoms/Input'

import Field, { CommonProps } from './_Wrapper'

type InputProps = Omit<HTMLProps<HTMLInputElement>, 'ref' | 'label' | 'name' | 'value' | 'onChange'>

export type Props = CommonProps &
  InputProps & {
    value: string
    onChangeValue: (value: string) => void
  }

const Date: FC<Props> = ({ value, onChangeValue, ...rest }) => {
  const { label, name, hint, isOptional, error, aside, className, ...inputProps } = rest
  const fieldProps = { label, name, hint, isOptional, error, aside, className }

  return (
    <Field {...fieldProps}>
      <Input value={value} onChangeText={onChangeValue} {...inputProps} />
    </Field>
  )
}

export default Date
