import qs from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function parseQueryString() {
  return qs.parse(window.location.search.substr(1))
}

const useQueryString = <T>() => {
  const location = useLocation()
  const [query, setQuery] = useState<qs.ParsedQs>(parseQueryString())

  useEffect(() => {
    const updated = parseQueryString()
    if (JSON.stringify(query) !== JSON.stringify(updated)) {
      setQuery(updated)
    }
  }, [location.search, query])

  return query as unknown as T
}

export default useQueryString
