import classNames from 'classnames'
import { FC, SVGProps } from 'react'

import { PaymentType } from '../common'
import { ReactComponent as AmexIcon } from '../payment-methods/american-express.svg'
import { ReactComponent as ApplePayIcon } from '../payment-methods/apple-pay.svg'
import { ReactComponent as CreditCardFront } from '../payment-methods/credit-card-front.svg'
import { ReactComponent as DinersClubIcon } from '../payment-methods/diners-club.svg'
import { ReactComponent as DiscoverIcon } from '../payment-methods/discover.svg'
import { ReactComponent as GooglePayIcon } from '../payment-methods/google-pay.svg'
import { ReactComponent as MastercardIcon } from '../payment-methods/mastercard.svg'
import { ReactComponent as PayPalIcon } from '../payment-methods/paypal.svg'
import { ReactComponent as VisaIcon } from '../payment-methods/visa.svg'
import s from './PaymentIcon.module.scss'

const knownCardTypes: (PaymentType | string)[] = [
  'american-express',
  'diners-club',
  'discover',
  'jcb',
  'maestro',
  'mastercard',
  'unionpay',
  'visa',
]

export function isKnownPaymentType(cardType: string | PaymentType): cardType is PaymentType {
  return knownCardTypes.includes(cardType as PaymentType)
}

const icons: Record<PaymentType, FC<SVGProps<SVGSVGElement>>> = {
  'apple-pay': ApplePayIcon,
  'generic-card': CreditCardFront,
  'google-pay': GooglePayIcon,
  'american-express': AmexIcon,
  'diners-club': DinersClubIcon,
  discover: DiscoverIcon,
  mastercard: MastercardIcon,
  paypal: PayPalIcon,
  visa: VisaIcon,
}

type Props = {
  type: PaymentType
  icon?: FC<SVGProps<SVGSVGElement>>
  outline?: boolean
  iconClassName?: string
  className?: string
}

const PaymentIcon: FC<Props> = ({ type, icon, outline = false, className, iconClassName }) => {
  const Icon = icon || icons[type]
  return (
    <div className={classNames(s.PaymentIcon, outline && s.Outline, className)}>
      {Icon && <Icon className={iconClassName} />}
    </div>
  )
}

export default PaymentIcon
