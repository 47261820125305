import { FC } from 'react'

type Props = {
  color?: string
}

const JourneyLogo: FC<Props> = ({ color = '#0060ab' }) => {
  return (
    <svg
      width="115"
      height="32"
      viewBox="0 0 115 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.715 12.7416C11.7616 12.0476 11.9847 11.3771 12.3634 10.7937L0.189453 0.278259V25.767L12.3661 15.2487C11.8823 14.506 11.6537 13.6258 11.715 12.7416Z"
        fill={color}
      />
      <path
        d="M15.2407 8.96834V4.95304L2.1875 0.517563L13.1009 9.94782C13.7028 9.41877 14.4469 9.07817 15.2407 8.96834Z"
        fill={color}
      />
      <path
        d="M11.3253 26.8104L13.6571 17.8838C14.169 18.0609 14.7008 18.174 15.2404 18.2205V17.0852C15.1569 17.0852 15.0762 17.049 14.9928 17.0324L14.862 17.0045C14.2111 16.8546 13.6072 16.5469 13.1034 16.1085L0.740234 26.7882L15.2404 31.7217V26.8104H11.3253Z"
        fill={color}
      />
      <path
        d="M19.2441 10.8049C19.6748 11.4675 19.904 12.2407 19.904 13.031C19.904 13.8212 19.6748 14.5945 19.2441 15.257L31.4181 25.7725V0.278259L19.2441 10.8049Z"
        fill={color}
      />
      <path
        d="M18.5072 9.95061L29.4261 0.517567L16.3701 4.95304V8.97113C17.1632 9.08053 17.9065 9.42121 18.5072 9.95061Z"
        fill={color}
      />
      <path
        d="M18.5072 16.1113C18.0015 16.5508 17.3957 16.8594 16.743 17.0101L16.6178 17.0379C16.5343 17.0546 16.4536 17.0797 16.3701 17.0908V18.2205C16.9086 18.1744 17.4395 18.0622 17.9506 17.8866L20.2825 26.8132H16.3701V31.7217L30.8703 26.7965L18.5072 16.1113Z"
        fill={color}
      />
      <path
        d="M43.5201 20.9781C43.5201 22.528 43.2028 23.6104 42.6074 24.2894C42.2692 24.6507 41.8569 24.9346 41.3986 25.1214C40.9404 25.3083 40.4471 25.3937 39.9528 25.3718C39.2106 25.3832 38.4717 25.2722 37.7656 25.0435L37.8936 22.5169C38.2614 22.6483 38.6496 22.7133 39.0401 22.7089C39.8748 22.7089 40.1002 22.0717 40.1002 21.0115V6.62539H43.5201V20.9781Z"
        fill={color}
      />
      <path
        d="M51.0557 21.5096C47.7444 21.5096 45.2373 19.4504 45.2373 16.0751C45.2373 12.6998 47.7416 10.6184 51.0557 10.6184C54.3698 10.6184 56.8519 12.6998 56.8519 16.0751C56.8519 19.4504 54.367 21.5096 51.0557 21.5096ZM51.0557 13.2925C49.5476 13.2925 48.6766 14.4612 48.6766 16.0111C48.6766 17.561 49.5476 18.7297 51.0557 18.7297C52.5639 18.7297 53.4349 17.561 53.4349 16.0111C53.4349 14.4612 52.5416 13.2925 51.0557 13.2925Z"
        fill={color}
      />
      <path
        d="M68.5494 21.2758H65.2798L65.2575 20.0877C64.8909 20.5439 64.424 20.9095 63.8932 21.1561C63.3623 21.4027 62.7818 21.5236 62.1967 21.5096C60.6885 21.5096 59.734 20.9781 59.2026 20.2769C58.6711 19.5757 58.4346 18.6435 58.4346 17.1353V10.8104H61.8321V16.6678C61.8321 17.8783 62.1716 18.7047 63.382 18.7047C64.5925 18.7047 65.1434 17.7503 65.1434 16.3701V10.8049H68.541L68.5494 21.2758Z"
        fill={color}
      />
      <path
        d="M77.6794 13.9297H77.5319C77.1465 13.7497 76.7244 13.6621 76.2992 13.6737C75.9963 13.6736 75.6972 13.7424 75.4248 13.8749C75.1524 14.0075 74.9138 14.2004 74.727 14.439C74.471 14.7784 74.1956 15.4574 74.1956 16.6261V21.2758H70.8008V10.8104H74.1983L74.1761 12.2963H74.2178C74.8133 11.1499 75.6815 10.6268 76.8084 10.6268C77.1717 10.6111 77.5339 10.6767 77.8686 10.8188L77.6794 13.9297Z"
        fill={color}
      />
      <path
        d="M89.2914 21.2758H85.8938V15.8191C85.8938 14.4278 85.6824 13.3983 84.2799 13.3983C82.8163 13.3983 82.4128 14.5864 82.4128 16.0946V21.2758H79.0264V10.8104H82.4239L82.4017 12.3186H82.4434C82.7493 11.7882 83.193 11.3504 83.7274 11.0516C84.2619 10.7528 84.8672 10.6041 85.4792 10.6212C88.5151 10.6212 89.2998 12.569 89.2998 15.1012L89.2914 21.2758Z"
        fill={color}
      />
      <path
        d="M101.71 16.8598H94.2579C94.4054 18.368 95.5518 19.047 96.932 19.047C98.0529 19.0197 99.1428 18.6732 100.074 18.048L101.412 19.8094C101.073 20.0654 99.6923 21.5068 96.445 21.5068C93.1977 21.5068 90.8604 19.5117 90.8604 16.0501C90.8604 12.5885 93.4092 10.6157 96.5063 10.6157C99.6033 10.6157 101.751 12.6748 101.751 15.7329C101.754 16.2449 101.71 16.8598 101.71 16.8598ZM98.5876 14.9287C98.5866 14.6493 98.53 14.373 98.4213 14.1157C98.3125 13.8583 98.1538 13.6252 97.9542 13.4297C97.7546 13.2343 97.5181 13.0804 97.2586 12.9771C96.9991 12.8738 96.7216 12.823 96.4423 12.8278C96.1621 12.8234 95.8838 12.8743 95.6234 12.9775C95.3629 13.0807 95.1253 13.2343 94.9242 13.4294C94.7232 13.6245 94.5626 13.8574 94.4516 14.1147C94.3406 14.3719 94.2814 14.6485 94.2774 14.9287H98.5876Z"
        fill={color}
      />
      <path
        d="M113.898 10.8104L109.226 22.2609C108.461 24.128 107.612 25.2744 105.829 25.2744C104.835 25.2951 103.848 25.1222 102.921 24.7652L103.6 22.2609C104.002 22.4116 104.423 22.5053 104.852 22.5391C105.233 22.5391 105.595 22.5391 105.87 21.8602L106.251 20.9475L102.122 10.8104H105.667L108.038 17.5304L110.395 10.7993L113.898 10.8104Z"
        fill={color}
      />
    </svg>
  )
}

export default JourneyLogo
