import { Route, Switch } from 'react-router-dom'
import r from 'routes'

import Login from 'pages/auth/Login'
import MagicLink from 'pages/auth/MagicLink'
import MagicLinkCallback from 'pages/auth/MagicLink/Callback'
import OAuthCallback from 'pages/auth/OAuthCallback'
import Register from 'pages/auth/Register'
import AuthenticateSSO from 'pages/auth/SSO'
import SSOIDPInitiated from 'pages/auth/SSO/IDPInitiated'
import InvitationsAccept from 'pages/Invitations/Accept'

import Admin from './Admin'

const App = () => {
  return (
    <Switch>
      <Route path={r.auth.login} exact component={Login} />
      <Route path={r.auth.register} exact component={Register} />
      <Route path={r.auth.oauthCallback} exact component={OAuthCallback} />
      <Route path={r.auth.magicLink} exact component={MagicLink} />
      <Route path={r.auth.magicLinkCallback} exact component={MagicLinkCallback} />
      <Route path={r.auth.acceptInvitation} exact component={InvitationsAccept} />
      <Route path={r.auth.sso.root} exact component={AuthenticateSSO} />
      <Route path={r.auth.sso.idpInitiated} exact component={SSOIDPInitiated} />

      <Admin />
    </Switch>
  )
}

export default App
