import { FC } from 'react'

import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import { paymentMethodTitles, PaymentType } from '../common'
import { ReactComponent as AddCardIcon } from '../payment-methods/add-card.svg'
import PaymentIcon from './PaymentIcon'
import s from './PaymentMethods.module.scss'

export type PaymentMethod = {
  type: PaymentType
  id?: string
  last4?: string
}

type Props = {
  methods: PaymentMethod[]
  selected: PaymentMethod['type']
  onChange: (type: PaymentMethod['type']) => void
}

const PaymentMethods: FC<Props> = ({ methods, selected, onChange }) => {
  return (
    <section className={s.PaymentMethods}>
      <h4 className="std">{t('Payment Method')}</h4>
      <ul>
        {methods.map((method, idx) => (
          <li key={idx}>
            <label htmlFor={method.type}>
              <PaymentIcon
                type={method.type}
                icon={method.type === 'generic-card' ? AddCardIcon : undefined}
                outline
              />
              <span>
                {method.type === 'generic-card'
                  ? t('Add a credit card')
                  : paymentMethodTitles[method.type]}
              </span>
              <input
                id={method.type}
                name="type"
                type="radio"
                checked={selected === method.type}
                onChange={() => onChange(method.type)}
              />
              <div>
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </label>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default PaymentMethods
