import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './index.module.scss'

type Props = {
  className?: string
  children: ReactNode
}

const Agreement: FC<Props> = ({ className, children }) => {
  return (
    <div className={classNames(s.Checked, className)} onClick={() => {}}>
      <label>
        <FontAwesomeIcon icon={faCheck} />
      </label>
      <p>{children}</p>
    </div>
  )
}

export default Agreement
