import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import r from 'routes'

import PipelinesCreate from 'pages/Pipelines/Create'
import UseCase from 'pages/Pipelines/Create/UseCase'
import PipelinesEdit from 'pages/Pipelines/Edit'
import PipelinesList from 'pages/Pipelines/List'

const Pipelines: FC = () => (
  <Switch>
    <Route path={r.pipelines.root} exact component={PipelinesList} />
    <Route path={r.pipelines.create.useCase(':id?')} component={UseCase} />
    <Route path={r.pipelines.create.root()} component={PipelinesCreate} />
    <Route path={r.pipelines.edit.root()} component={PipelinesEdit} />
  </Switch>
)

export default Pipelines
