import { Pipeline } from 'types'

import { PipelineStage } from '@journeyid/agent/types'

import asyncThunk from 'util/asyncThunk'

export type Request = { id: string } & Omit<PipelineStage, 'configuration'>

type Response = Pipeline

export const updateStage = asyncThunk<Request, Response>({
  name: 'pipelines/update-stage',
  method: 'PUT',
  uri: ({ id }) => `/pipelines/${id}/stage`,
  stripId: true,
})
