import { FC } from 'react'

import { FaceAuthenticationMetadata } from '@journeyid/agent/types'

import { t } from 'translation'

import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import OptionList from 'components/organisms/OptionList'
import FaceAuthenticationPreview from 'components/templates/stages/FaceAuthentication.Preview'

import CreateSettings from './shared/CreateSettings'
import useStage from './useStage'

const FaceAuthentication: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('face-authentication')

  return (
    <CreateSettings
      stageType="face-authentication"
      preview={<FaceAuthenticationPreview {...stage} />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <Setting title={t('Visual preview')}>
          <OptionList<FaceAuthenticationMetadata['previewMode']>
            name="previewMode"
            value={stage.metadata?.previewMode || 'mesh'}
            onChange={mode => handleMetadata('previewMode', mode)}
          >
            <OptionList.Item label={t('Face Mesh')} value="mesh">
              {t('A graphic representation will be displayed instead of the video feed.')}
            </OptionList.Item>
            <OptionList.Item label={t('Video Feed')} value="face">
              {t('The user will see the camera feed during enrollment.')}
            </OptionList.Item>
            <OptionList.Item label={t('Face Mesh & Video feed')} value="both">
              {t('A video feed will be shown with the face mesh overlaid on top of it.')}
            </OptionList.Item>
          </OptionList>
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default FaceAuthentication
