import classNames from 'classnames'
import { FC } from 'react'

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StageType } from '@journeyid/agent/types'

import { stageDescriptionMap } from 'util/stage-descriptions'
import { stageTitleMap } from 'util/stage-titles'

import s from './index.module.scss'

type Props = {
  stageType: StageType
}

const StageRow: FC<Props> = ({ stageType }) => {
  return (
    <li className={classNames('__StageRow', s.StageRow)}>
      <h6>{stageTitleMap[stageType]}</h6>
      <FontAwesomeIcon icon={faInfoCircle} data-tip={stageDescriptionMap[stageType]} />
    </li>
  )
}

export default StageRow
