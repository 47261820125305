import { HTTPRetrievedMultiple } from '@journeyid/agent/common/statuses'

import { Admin } from 'types/admin'
import asyncThunk from 'util/asyncThunk'

export type Request = {}

type Response = Admin[]

export const listAdmins = asyncThunk<Request, Response>({
  name: 'admins/list',
  uri: '/admins',
  successStatus: HTTPRetrievedMultiple,
  orchestrator: false,
})
