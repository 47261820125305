import { useEffect, useState } from 'react'
import { useAuthSelector } from 'store'

import { useAPI } from 'hooks/useAPI'
import { listUsers } from 'thunks/api/users/list'

import type { User, UserType } from 'types/user'

export default function useListUsers<T extends UserType>(userType: UserType) {
  const { accountId } = useAuthSelector()
  const [list, { timer }] = useAPI(listUsers)

  const [users, setUsers] = useState<User<T>[]>([])

  useEffect(() => {
    if (!accountId || timer.isLoading) return
    list({ accountId, userType }).then(res => setUsers(res as User<T>[]))
  }, [accountId, userType, list, setUsers, timer])

  return [users, { timer }] as const
}
