import { ChangeEvent, FC, useCallback, useState } from 'react'
import { t } from 'translation'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CopyButton from 'components/atoms/CopyButton'
import { useAPI } from 'hooks/useAPI'
import { Input } from 'pages/IFrames/Steps/DeploymentInstructions/CodeInput'
import DevToken from 'pages/IFrames/Steps/DeploymentInstructions/DevToken'
import { iframeSecret } from 'thunks/api/iframes/secret'

import s from './index.module.scss'

type Props = {
  iframeId: string
  onToken: (token: string) => void
}

const AuthenticationToken: FC<Props> = ({ iframeId, onToken }) => {
  const [uniqueId, setUniqueId] = useState('')

  const [fetchSecret, { timer }] = useAPI(iframeSecret)
  const [secret, setSecret] = useState<string>()
  const handleReveal = useCallback(async () => {
    const resp = await fetchSecret({
      id: iframeId,
    })
    setSecret(resp.secret)
  }, [iframeId, fetchSecret])

  const payload =
    "import jwt from 'jsonwebtoken'\n\n" +
    `const secret = '${secret ? secret : ''}'\n\n` +
    `const expiresIn = 24 // hours\n\n` +
    `const claims = {\n` +
    `  "sub": "${uniqueId}",\n` +
    `  "ifr": "${iframeId}",\n` +
    `  "exp": +new Date() + 3_600 * expiresIn,\n` +
    `}\n\n` +
    'const token = jwt.sign(claims, secret)'

  return (
    <>
      <DevToken iframeId={iframeId} uniqueId={uniqueId} onToken={onToken} />
      <div className={s.Code}>
        <CopyButton content={payload} />
        <div className="mb-1">
          <span className="text-red-700">import</span> jwt{' '}
          <span className="text-red-700">from</span>{' '}
          <span className="text-green-700">'jsonwebtoken'</span>
        </div>
        <div className="mt-4 mb-1">
          <span className="text-red-700">const</span>{' '}
          <span className="text-purple-800">secret</span> ={' '}
          {secret ? (
            <span className="text-green-700">'{secret}'</span>
          ) : timer.isLoading ? (
            <FontAwesomeIcon icon={faSpinnerThird} spin className="text-theme" />
          ) : (
            <span className="font-semibold text-red-700 cursor-pointer" onClick={handleReveal}>
              [{t('click to reveal')}]
            </span>
          )}
        </div>
        <div className="mt-4 mb-1">
          <span className="text-red-700">const</span>{' '}
          <span className="text-purple-800">expiresIn</span> = 24{' '}
          <span className="mt-4 mb-1 text-gray-600">{'// hours'}</span>
        </div>
        <div className="mt-4 mb-1">
          <span className="text-red-700">const</span>{' '}
          <span className="text-purple-800">claims</span> = {'{'}
        </div>
        <div className="pl-4 mt-1">
          <span className="text-green-700">"sub"</span>: <span className="text-green-700">"</span>
          <Input
            required
            className="border-red-500"
            value={uniqueId}
            placeholder="agent's unique ID"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUniqueId(e.target.value)}
          />
          <span className="text-green-700">"</span>,
        </div>
        <div className="pl-4 mt-1">
          <span className="text-green-700">"ifr"</span>:{' '}
          <span className="text-green-700">"{iframeId}"</span>,
        </div>
        <div className="pl-4 mt-1">
          <span className="text-green-700">"exp"</span>: +
          <span className="text-purple-700">new</span> <span className="text-blue-700">Date()</span>{' '}
          + 3_600 * <span className="text-purple-800">expiresIn</span>,
        </div>
        <div className="mt-1">{'}'}</div>
        <div className="mt-4">
          <span className="text-red-700">const</span> <span className="text-purple-800">token</span>{' '}
          = jwt.<span className="text-blue-800">sign</span>(
          <span className="text-purple-800">claims</span>,{' '}
          <span className="text-purple-800">secret</span>)
        </div>
      </div>
    </>
  )
}

export default AuthenticationToken
