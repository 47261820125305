import { useEffect } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { usePrevious } from 'react-use'

function clean(str: string): string {
  return str.replace(/[^a-zA-Z0-9]+/g, '')
}

type Input = Pick<UseFormMethods, 'setValue' | 'watch'>

export function useUniqueID({ setValue, watch }: Input): void {
  const email = watch('email')
  const phoneNumber = watch('phoneNumber')
  const uniqueId = watch('uniqueId')

  const previousEmail = usePrevious(email)
  const previousPhoneNumber = usePrevious(phoneNumber)

  useEffect(() => {
    const processedEmail = clean(email?.replace(/@.*/, '') || '')
    const processedPreviousEmail = clean(previousEmail?.replace(/@.*/, '') || '')

    // when email field is updated, update default unique id
    // (only if unique id isn't customized)
    if (email && processedPreviousEmail === uniqueId) {
      setValue('uniqueId', processedEmail)
      return
    }

    // when phone number field is updated, update default unique id
    // (only if unique id isn't customized)
    if (phoneNumber && previousPhoneNumber === uniqueId) {
      setValue('uniqueId', phoneNumber)
      return
    }

    if (uniqueId === '') {
      setValue('uniqueId', processedEmail || phoneNumber || '')
    }
  }, [email, phoneNumber, uniqueId, previousEmail, previousPhoneNumber, setValue])
}
