import { ChangeEvent, FC, useEffect, useState } from 'react'

import { t } from 'translation'

import Select from 'components/atoms/Select'
import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Field from 'components/molecules/Field'
import OCRPreview from 'components/templates/stages/OCR.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'
import { useAPI } from 'hooks/useAPI'
import { ocrTemplates } from 'thunks/api/ocr/templates'

export type Template = {
  aspect: number
  key: string
  name: string
}

const OCR: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('ocr')
  const [list] = useAPI(ocrTemplates)
  const [templates, setTemplates] = useState<Template[]>([])

  useEffect(() => {
    loadTemplates()
  }, [])

  const loadTemplates = async () => {
    const resp = await list({})
    setTemplates(
      Object.entries(resp.templates).map(template => ({
        aspect: template[1].aspect,
        key: template[1].key,
        name: template[1].name,
      }))
    )
  }

  const handleChangeEvent = (templateKey: string) => {
    const selectedTemplate = templates.find(x => x.key === templateKey)
    if (selectedTemplate) handleMetadata('template', selectedTemplate)
  }

  return (
    <CreateSettings
      stageType="ocr"
      preview={<OCRPreview {...stage} />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <Setting title="Form Details">
          <Field
            label={t('Title')}
            name="title"
            value={stage.metadata?.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleMetadata('title', e.target.value)}
            placeholder={t('Short form title')}
            className="mb-3"
          />
          <Field
            label={t('Instructions')}
            as="textarea"
            rows={2}
            name="text"
            value={stage.metadata?.text}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleMetadata('text', e.target.value)}
            placeholder={t('Form instructions')}
            inputClassName="resize-none"
          />
        </Setting>
        <Setting title={t('Template')}>
          <Select
            value={stage.metadata?.template?.key}
            onChangeText={s => handleChangeEvent(s)}
            style={{ width: `100%` }}
          >
            <option value="">{t('Select a Template')}</option>
            {templates.map(template => (
              <option key={template.key} value={template.key}>
                {template.name}
              </option>
            ))}
          </Select>
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default OCR
