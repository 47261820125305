import { i18nFormat } from 'translation'

import { BrowserDetails } from 'types/event'

export const browserName = (browser: BrowserDetails['browser']): string =>
  browser.version ? `${browser.name} ${browser.version}` : browser.name

export const deviceName = (device: BrowserDetails['device']): string | undefined => {
  if (device.brand && device.model) {
    return `${device.brand} ${device.model}`
  } else if (device.brand) {
    return device.brand
  } else if (device.model) {
    return device.model
  } else {
    return undefined
  }
}

export const osName = (os: BrowserDetails['os']): string | undefined => {
  if (os.name && os.version) {
    return `${os.name} ${os.version}`
  } else if (os.name) {
    return os.name
  } else if (os.version) {
    return os.version
  } else {
    return undefined
  }
}

export const fullDeviceName = (details: BrowserDetails): string | undefined => {
  const device = deviceName(details.device)
  const os = osName(details.os)
  if (device && os) {
    return `${device} ${os}`
  } else if (device) {
    return device
  } else if (os) {
    return os
  } else {
    return undefined
  }
}

const formDate = (date: Date): string => i18nFormat(date, 'LLLL do, yyyy h:mmaaaaa')
const formTimeRange = (date: Date): string => i18nFormat(date, 'h:mmaaaaa')
export const formTimestamp = (date: Date): string => i18nFormat(date, 'h:mm aa')

export const timeRange = (dates: Date[]): string => {
  if (dates.length === 1) {
    return formDate(dates[0])
  } else {
    const sorted = [...dates].sort((l, r) => l.getTime() - r.getTime())
    const min = sorted[0]
    const max = sorted[sorted.length - 1]
    const start = formDate(min)
    if (min.toDateString() === max.toDateString()) {
      return `${start} - ${formTimeRange(max)}`
    } else {
      return `${start} - ${formDate(max)}`
    }
  }
}
