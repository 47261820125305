import { Pipeline } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = Pick<Pipeline, 'id' | 'brandStyleId'>

type Response = Pipeline

export const updateBrandStyle = asyncThunk<Request, Response>({
  name: 'pipelines/update-brand-style',
  method: 'PUT',
  uri: ({ id }) => `/pipelines/${id}/brand-style`,
  stripId: true,
})
