import classnames from 'classnames'
import { FC, Fragment, ReactNode } from 'react'

import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'

import { closeModal } from 'hooks/useModal'

import s from './index.module.scss'

type Props = {
  isOpen: boolean
  closeButton?: boolean
  children: ReactNode
  className?: string
  onClose: () => void
}

const Modal: FC<Props> = ({ isOpen, closeButton, children, className, onClose }) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-50 bg-black/50" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 z-50 flex items-center justify-center mx-4">
            <Dialog.Panel
              className={classnames(
                `max-w-3xl w-full mx-auto bg-white rounded-[32px] py-12 px-16 relative`,
                className
              )}
            >
              {children}

              {closeButton && (
                <div className={s.CloseButton}>
                  <button tabIndex={-1} onClick={() => closeModal()}>
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              )}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

const Header: FC<{ children: ReactNode }> = ({ children }) => (
  <h3 className="mb-4 std">{children}</h3>
)

const Body: FC<{ className?: string; children: ReactNode }> = ({ className, children }) => (
  <div className={classnames('mb-5 text-gray-500 std-base', className)}>{children}</div>
)
const Actions: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
  <div className={classnames('space-x-8', className)}>{children}</div>
)

type ModalType = typeof Modal & {
  Header: typeof Header
  Body: typeof Body
  Actions: typeof Actions
}

Object.assign(Modal, { Header, Body, Actions })

export default Modal as ModalType
