import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

const ConfigureRedirect: FC = () => {
  const { pipeline } = usePipelineSelector()
  const history = useHistory()

  useEffect(() => {
    if (pipeline.stages.length === 0) {
      history.push(r.pipelines.create.configure.stages(pipeline.id))
      return
    }

    const stageType = pipeline.stages[0].type
    const route = pipeline.key ? r.pipelines.edit : r.pipelines.create
    const uri = route.configure.stage(pipeline.id, stageType)
    history.replace(uri)
  }, [pipeline, history])

  return null
}

export default ConfigureRedirect
