import { FC, useCallback } from 'react'

import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Field from 'components/molecules/Field'
import useActionTimer from 'hooks/useActionTimer'

type Props = {
  pipelineKey: string
}

const PipelineKey: FC<Props> = ({ pipelineKey }) => {
  const timer = useActionTimer()
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(pipelineKey)
    timer.succeeded()
  }, [pipelineKey, timer])

  return (
    <>
      <h4 className="mb-1 std">{t('Pipeline Key')}</h4>
      <p className="mb-1 std">
        {t('This key is used to uniquely identify the pipeline when making API requests.')}
      </p>
      <div className="flex gap-4 mb-8">
        <Field
          name="pipelineKey"
          id="pipelineKey"
          className="flex-grow"
          inputClassName="py-2 px-3"
          value={pipelineKey}
          readOnly
        />
        <button
          className="flex items-center justify-center px-4 mt-2 text-gray-500 bg-gray-100 border border-gray-300 rounded hover:text-gray-700"
          onClick={handleCopy}
        >
          {timer.didSucceed ? (
            <span className="text-green-700">{t('copied!')}</span>
          ) : (
            <FontAwesomeIcon icon={faCopy} />
          )}
        </button>
      </div>
    </>
  )
}

export default PipelineKey
