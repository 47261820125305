import { t } from 'translation'

export type PaymentType =
  | 'american-express'
  | 'apple-pay'
  | 'diners-club'
  | 'discover'
  | 'generic-card'
  | 'google-pay'
  | 'mastercard'
  | 'paypal'
  | 'visa'

export const paymentMethodTitles = {
  'american-express': t('AmEx'),
  'apple-pay': t('Apple Pay'),
  'diners-club': t('Diners Club'),
  discover: t('Discover'),
  'generic-card': '',
  'google-pay': t('Google Pay'),
  mastercard: t('Mastercard'),
  paypal: t('PayPal'),
  visa: t('Visa'),
} as const
