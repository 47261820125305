import { FC } from 'react'
import { t } from 'translation'

import Select from 'components/atoms/Select'
import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import RandomCodePreview from 'components/templates/stages/RandomCode.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const RandomCode: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('random-code')

  return (
    <CreateSettings
      stageType="random-code"
      preview={<RandomCodePreview metadata={stage.metadata} />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <Setting title={t('Number of digits in the random code')}>
          <Select
            value={stage.metadata.digits}
            className="w-[120px]"
            onChange={e => handleMetadata('digits', +e.target.value)}
          >
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </Select>
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default RandomCode
