import { FC } from 'react'

import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'
import SurveyPreview from 'components/templates/stages/Survey.Preview'

const Survey: FC = () => {
  return <NoSettingsStage stageType="survey" preview={SurveyPreview} />
}

export default Survey
