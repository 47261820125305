import { FC, ReactElement, ReactNode } from 'react'
import { usePipelineSelector } from 'store'

import { faArrowRight, faEye } from '@fortawesome/pro-regular-svg-icons'
import { StageType } from '@journeyid/agent/types'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import TemplateAlert from 'components/atoms/TemplateAlert'
import Footer from 'components/molecules/Footer'
import PageHeader from 'components/molecules/PageHeader'
import Preview from 'components/organisms/Preview'
import { ActionTimer } from 'hooks/useActionTimer'
import { openModal } from 'hooks/useModal'
import PreviewModal from 'pages/Pipelines/Create/components/PreviewModal'
import { stageDescriptionMap } from 'util/stage-descriptions'
import { stageTitleMap } from 'util/stage-titles'

import s from './index.module.scss'

type Props = {
  stageType: StageType
  timer: ActionTimer
  preview?: ReactElement
  children: ReactNode
  onSave: () => void
}

const CreateSettings: FC<Props> = ({ stageType, timer, preview, children, onSave }) => {
  const { pipeline } = usePipelineSelector()

  return (
    <>
      <div id={`stage-${stageType}`} className={s.Wrapper}>
        <div className={s.Content}>
          <PageHeader
            title={stageTitleMap[stageType]}
            tip={<TemplateAlert stageType={stageType} templateId={pipeline.template} />}
            children={stageDescriptionMap[stageType]}
          />
          <div className="flex pb-8 pr-8">
            {children}
            <div>
              <Preview>{preview}</Preview>
              {/* <CarouselDots current={dotIndex} count={3} onChange={setDotIndex} /> */}
            </div>
          </div>
        </div>
        <Footer
          removeStage={stageType}
          actions={
            <>
              <Button variant="secondary" icon={faEye} onClick={openModal('Preview', stageType)}>
                {t('Preview Pipeline')}
              </Button>
              <Button
                variant={timer.didSucceed && pipeline.key ? 'green' : 'primary'}
                isLoading={timer.isLoading}
                iconRight={pipeline.key || timer.isLoading ? undefined : faArrowRight}
                onClick={onSave}
              >
                {timer.didSucceed && pipeline.key
                  ? t('Saved!')
                  : timer.isLoading
                  ? t('Saving...')
                  : pipeline.key
                  ? t('Save')
                  : t('Save & Continue')}
              </Button>
            </>
          }
        />
      </div>
      <PreviewModal />
    </>
  )
}

export default CreateSettings
