export function useModal(id: string, suffix?: string) {
  const parts = window.location.hash.split(':')
  const hash = parts[0]
  const match = hash === `#${id}` || hash === `#${id}:${suffix}`
  return [match, parts[1]] as const
}

// This counter will be used as the key on the modal, reseting it each time it's opened
var counter = 0

export function openModal(id: string, suffix?: string) {
  return () => {
    window.location.hash = suffix ? `${id}:${suffix}` : id
    return true
  }
}

export function closeModal() {
  window.location.hash = ''
  counter++
}

export function modalKey(): number {
  return counter
}
