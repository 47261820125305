import cogoToast from 'cogo-toast'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'

import { faBoltLightning } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useQueryString from 'hooks/useQueryString'
import GithubButton from 'pages/auth/shared/buttons/GithubButton'
import GoogleButton from 'pages/auth/shared/buttons/GoogleButton'
import MicrosoftButton from 'pages/auth/shared/buttons/MicrosoftButton'
import parseJWT from 'util/parseJWT'

import s from './index.module.scss'

type Invitation = {
  account: string
  inviter: string
}

type Claims = {
  name: string
  inv: string
}

type QueryParams = {
  token?: string
}

const InvitationsAccept: FC = () => {
  const { token } = useQueryString<QueryParams>()

  const history = useHistory()
  useEffect(() => {
    if (!token) {
      history.push(r.auth.login)
      cogoToast.error(t('No invitation token found'))
      return
    }

    localStorage.setItem('invitationToken', token)
  }, [token, history])

  const invitation = useMemo((): Invitation | undefined => {
    if (!token) return

    const claims = parseJWT<Claims>(token)

    return {
      account: claims['name'],
      inviter: claims['inv'],
    }
  }, [token])

  const handleAcceptUnconnected = useCallback(async () => {
    window.location.href = `/api/app/oauth/none/callback?state=${token}`
  }, [token])

  if (!invitation) return null

  return (
    <div className={s.InvitationsAccept}>
      <main>
        <header>
          <h3>{t("You've been invited to join")}</h3>
          <h2>{invitation.account}</h2>
          <p>
            {t('You were invited by')} {invitation.inviter}
          </p>
          <ul>
            <li>
              <GoogleButton text={t('Accept invite with Google')} state={token} />
            </li>
            <li>
              <MicrosoftButton text={t('Accept invite with Microsoft')} state={token} />
            </li>
            <li>
              <GithubButton text={t('Accept invite with GitHub')} state={token} />
            </li>
            <li>
              <button onClick={handleAcceptUnconnected}>
                <FontAwesomeIcon icon={faBoltLightning} className="text-theme" />
                {t('Accept without a connected account')}
              </button>
              <p>{t("you'll be able to use one-time email links to sign in later")}</p>
            </li>
          </ul>
        </header>

        <hr />

        <footer>
          {t('By logging in, you agree to our')}{' '}
          <a
            href="https://journeyid.com/terms-of-service-agreement/"
            target="_blank"
            rel="noreferrer"
          >
            {t('Terms & Data Policy')}
          </a>
          .
        </footer>
      </main>
    </div>
  )
}

export default InvitationsAccept
