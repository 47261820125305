import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import s from './index.module.scss'

type Props = {
  children: ReactNode
  withFooter?: boolean
  className?: string
}

const ContentWrapper: FC<Props> = ({ children, withFooter = false, className = '' }) => (
  <div
    className={classNames(
      s.ContentWrapper,
      { [s.ContentWrapperWithFooter]: withFooter },
      className
    )}
  >
    {children}
  </div>
)

export default ContentWrapper
