import asyncThunk from 'util/asyncThunk'
import { HTTPArchived } from 'util/statuses'

export type Request = {
  id: string
}

export type Response = void

export const deleteDesktop = asyncThunk<Request, Response>({
  name: 'desktops/delete',
  method: 'DELETE',
  uri: ({ id }) => `/desktops/${id}`,
  orchestrator: false,
  successStatus: HTTPArchived,
})
