import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { t } from 'translation'

import s from './index.module.scss'
import { ReactComponent as StatusBar } from './status-bar.svg'

type Props = {
  statusBar?: 'light' | 'dark'
  children: ReactNode
}

const PhoneMockup: FC<Props> = ({ statusBar = 'dark', children }) => (
  <div className={classNames('__PhoneMockup', s.PhoneMockup)}>
    <StatusBar className={statusBar === 'light' ? s.StatusBarLight : s.StatusBarDark} />
    <i>{t('Speaker')}</i>
    <b>{t('Camera')}</b>
    <div className="h-full w-full rounded-[40px] bg-white">{children}</div>
  </div>
)

export default PhoneMockup
