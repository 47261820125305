import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  accountId: string
  title?: string
}

type Response = Iframe

export const createIFrame = asyncThunk<Request, Response>({
  name: 'iframes/create',
  method: 'POST',
  uri: '/iframes',
})
