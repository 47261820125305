import { FC } from 'react'

import { t } from 'translation'

import s from './Header.module.scss'
import { ReactComponent as Illustration } from './success.svg'

const Header: FC = () => (
  <div className={s.Header}>
    <p className="std-sm">{t('Success!')}</p>
    <h3 className="std">{t('Nice work, you published the pipeline!')}</h3>
    <Illustration className="mb-8 h-[216px] text-theme" />
  </div>
)

export default Header
