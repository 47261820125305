import classNames from 'classnames'
import React, { FC, InputHTMLAttributes, useCallback } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import styles from 'components/atoms/Input/index.module.scss'

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue' | 'value' | 'onChange'>

type Props = {
  defaultValue?: number
  value?: number
  className?: string
  onChangeValue?: (val?: number) => void
} & InputProps

const QuantityInput: FC<Props> = ({ value, className, onChangeValue, ...rest }) => {
  const handleChange = useCallback(
    ({ value }: NumberFormatValues) => {
      if (!onChangeValue) return

      const amt = parseInt(value)
      if (isNaN(amt)) {
        onChangeValue(undefined)
      } else {
        onChangeValue(amt)
      }
    },
    [onChangeValue]
  )

  return (
    <div className={styles.InputGroup}>
      <NumberFormat
        thousandSeparator={true}
        decimalScale={0}
        value={value}
        onValueChange={handleChange}
        className={classNames(styles.Input, styles.HasSuffix, className)}
        inputMode="numeric"
        {...rest}
        type="text"
      />
      <span className={styles.Suffix}>x</span>
    </div>
  )
}

export default QuantityInput
