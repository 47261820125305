import { FC, useCallback } from 'react'

import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useCopied from 'hooks/useCopied'

type Props = {
  content: any
}

const CopyButton: FC<Props> = ({ content }) => {
  const [wasCopied, copy] = useCopied()

  const handleCopy = useCallback(() => {
    if (typeof content === 'string') {
      copy(content)
    } else {
      copy(JSON.stringify(content, null, 2))
    }
  }, [content, copy])

  return wasCopied ? (
    <FontAwesomeIcon icon={faCheckCircle} className="!text-green-600" />
  ) : (
    <FontAwesomeIcon icon={faCopy} onClick={handleCopy} className="cursor-pointer" />
  )
}

export default CopyButton
