import asyncThunk from 'util/asyncThunk'
import { HTTPDeleted } from 'util/statuses'

export type Request = {
  id: string
}

type Response = {
  id: string
}

export const revokeSecret = asyncThunk<Request, Response>({
  name: 'pipelines/revoke-secret',
  method: 'DELETE',
  uri: ({ id }) => `/pipelines/${id}/secrets`,
  stripId: true,
  successStatus: HTTPDeleted,
})
