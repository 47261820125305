import { FC } from 'react'

import { t } from 'translation'

import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Toggle from 'components/atoms/Toggle'
import FacialAuthentication3DPreview from 'components/templates/stages/FacialAuthentication3D.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const FacialAuthentication3D: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('facial-authentication-3d')

  return (
    <CreateSettings
      stageType="facial-authentication-3d"
      preview={<FacialAuthentication3DPreview />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <Setting
          title={t('Scan Veracity')}
          description={
            <>
              {t(
                'When performing facial verifications, there are two competing statistics: False Acceptance Rate and False Rejection Rate. As False Acceptance goes down (the wrong face is considered a match), False Rejection goes up (the right face is considered not to be a match. Depending on your use case, you may want to enable high veracity facial verification.'
              )}
              <br />
              {t(
                'By default, the acceptance threshold is a 1 in 1,000,000 False Acceptance Rate with a 1% False Rejection Rate.'
              )}
            </>
          }
        >
          <h5 className="std">{t('High Veracity Facial Match')}</h5>
          <p className="std-sm">
            {t(
              'If your use case requires a hightened level of security, we encourage you to enable high veracity facial verification which decreases the False Acceptance Rate to 1 in 12,500,000. Keep in mind that the trade off is there may be an increased rate of False Rejections depending on lighting conditions.'
            )}
          </p>
          <Toggle
            enabled={stage.metadata.highVeracity}
            className="mt-2"
            onChange={enabled => handleMetadata('highVeracity', enabled)}
          >
            {t('Decrease FAR to 1 in 12,500,000')}
          </Toggle>
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default FacialAuthentication3D
