import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch, usePipelinesSelector } from 'store'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import SearchBar from 'components/molecules/SearchBar'
import { PipelinesState } from 'slices/pipelines'
import { clearPipeline } from 'thunks/api/pipelines/clear'
import { getPipelineIcon } from 'util/getPipelineIcon'

import s from './index.module.scss'

type Pipeline = PipelinesState['pipelines'][number]

const PipelineList: FC = () => {
  const history = useHistory()
  const { pipelines } = usePipelinesSelector()
  const [search, setSearch] = useState('')

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(clearPipeline({}))
  }, [dispatch])

  const filter = useCallback(
    (pipeline: Pipeline): boolean => {
      const s = search.toLowerCase()
      const internal = pipeline.internalTitle
      const title = pipeline.title
      const key = pipeline.key
      return (
        s.length === 0 ||
        title.toLowerCase().search(s) >= 0 ||
        (internal !== undefined && internal.toLowerCase().search(s) >= 0) ||
        (key !== undefined && key.toLowerCase().search(s) >= 0)
      )
    },
    [search]
  )

  const filteredPipelines = useMemo(() => pipelines.filter(filter), [pipelines, filter])

  const handleClick = useCallback(
    (pipeline: Pipeline) => {
      if (pipeline.key) {
        history.push(r.pipelines.edit.configure.stage(pipeline.id, pipeline.stages[0].type))
      } else {
        if (pipeline.stages.length > 0) {
          history.push(r.pipelines.create.configure.stage(pipeline.id, pipeline.stages[0].type))
        } else {
          history.push(r.pipelines.create.configure.stages(pipeline.id))
        }
      }
    },
    [history]
  )

  const handleEnter = useCallback(() => {
    if (filteredPipelines.length === 0) return
    handleClick(filteredPipelines[0])
  }, [handleClick, filteredPipelines])

  return (
    <div>
      <SearchBar
        placeholder={t('Search for a pipeline')}
        onSearch={setSearch}
        onEnter={handleEnter}
      />

      {filteredPipelines.length > 0 && (
        <ul className={s.List}>
          {filteredPipelines.map((pipeline, i) => (
            <li
              key={i}
              data-id={pipeline.id}
              className={s.ListItem}
              onClick={() => handleClick(pipeline)}
            >
              <div>
                <div>
                  <FontAwesomeIcon icon={getPipelineIcon(pipeline.stages)} />
                </div>
                <p>
                  {pipeline.internalTitle || pipeline.title || (
                    <span className={s.Untitled}>{t('untitled')}</span>
                  )}
                </p>
              </div>
              <div className={s.ItemLeftSide}>
                {!pipeline.key && <label className={s.Draft}>{t('draft')}</label>}
                <button>{t('Manage')}</button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default PipelineList
