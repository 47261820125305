import { FC } from 'react'

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import Modal from 'components/organisms/Modal'
import { ActionTimer } from 'hooks/useActionTimer'
import { closeModal, useModal } from 'hooks/useModal'

type Props = {
  timer: ActionTimer
  onDelete: () => void
  onCancel: () => void
}

const DeleteStageModal: FC<Props> = ({ timer, onDelete, onCancel }) => {
  const [isOpen] = useModal('DeleteStage')

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>
        <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 mr-3 text-red-600" />
        {t('The action will be permanently deleted')}
      </Modal.Header>
      <Modal.Body>
        Sed sed pharetra turpis. Vivamus diam diam, maximus non purus quis, vehicula imperdiet sem.
        Praesent consequat ante nec egestas porta.
      </Modal.Body>
      <Modal.Actions>
        <Button size="xl" variant="red-outline" disabled={timer.isLoading} onClick={onDelete}>
          {timer.isLoading ? t('Deleting...') : t('Delete the action')}
        </Button>
        <Button size="xl" onClick={onCancel}>
          {t('Cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteStageModal
