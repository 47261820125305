import classNames from 'classnames'
import cogoToast from 'cogo-toast'
import { FC, useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { t } from 'translation'

import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'components/atoms/Button'
import Field, { Option } from 'components/molecules/Field'
import Modal from 'components/organisms/Modal'
import { FormData, useInviteAdmin } from 'hooks/api/useInviteAdmin'
import { closeModal, useModal } from 'hooks/useModal'
import { Admin } from 'types/admin'
import { Group } from 'types/group'

import s from './_InviteAdminModal.module.scss'

type Props = {
  groups: Group[]
  onCreate: (admin: Admin) => void
}

const InviteAdminModal: FC<Props> = ({ groups, onCreate }) => {
  const [isOpen] = useModal('InviteAdmin')

  const form = useForm<FormData>({
    defaultValues: {
      email: '',
      role: 'owner',
      groups: [],
    },
  })

  const [invite, { timer }] = useInviteAdmin(form.setError)
  const handleSubmit = useCallback(
    async (data: FormData) => {
      const admin = await invite(data)
      if (!admin) return

      onCreate(admin)
      cogoToast.success('The admin was added')
      closeModal()
      setTimeout(() => form.reset(), 1_000)
    },
    [form, invite, onCreate]
  )

  const role = form.watch('role')
  useEffect(() => {
    if (role === 'supervisor' || form.getValues('groups')?.length === 0) return

    form.setValue('groups', [])
  }, [role, form])

  if (!groups) return null

  return (
    <Modal isOpen={isOpen} closeButton onClose={closeModal}>
      <FormProvider {...form}>
        <Modal.Header>{t('Invite an admin')}</Modal.Header>
        <Modal.Body className="py-4 space-y-6">
          <Field
            ref={form.register}
            name="email"
            label="Email address"
            autoFocus
            error={form.errors.email}
          />

          <Field ref={form.register} name="role" label="Role" type="radio" as="radio">
            <Option value="supervisor">
              {t('Agent supervisor')}
              <span className="ml-3 text-xs">
                {t(
                  'Supervisors are able to see the activity of agents in their associated groups.'
                )}
              </span>
            </Option>
            <Option value="admin">{t('Administrator')}</Option>
            <Option value="owner">{t('Account owner')}</Option>
          </Field>

          {groups.length > 0 && (
            <Field
              ref={form.register}
              name="groups"
              label={
                <span>
                  Agent groups{' '}
                  <span className="ml-2 text-xs font-normal">
                    select no groups to grant access to all agents
                  </span>
                </span>
              }
              type="checkbox"
              as="checkboxes"
              disabled={role !== 'supervisor'}
              className={classNames(role !== 'supervisor' && s.Disabled)}
            >
              {groups.map(group => (
                <Option key={group.id} value={group.id}>
                  {group.name}
                </Option>
              ))}
            </Field>
          )}
        </Modal.Body>
        <Modal.Actions>
          <Button disabled={timer.isLoading} onClick={form.handleSubmit(handleSubmit)}>
            {timer.isLoading ? t('Inviting...') : t('Send invitation')}
          </Button>
          <Button variant="tertiary" onClick={closeModal}>
            {t('Cancel')}
          </Button>
        </Modal.Actions>
      </FormProvider>
    </Modal>
  )
}

export default InviteAdminModal
