import { FC } from 'react'

import { t } from 'translation'

import ConfigurableStage from 'components/atoms/ConfigurableStage'
import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Toggle from 'components/atoms/Toggle'
import Field from 'components/molecules/Field'
import CreditCardPaymentPreview from 'components/templates/stages/CreditCardPayment/Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const CreditCardPayment: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('credit-card-payment')

  return (
    <CreateSettings
      stageType="credit-card-payment"
      preview={<CreditCardPaymentPreview {...stage} />}
      timer={timer}
      onSave={handleSave}
    >
      <div className="flex flex-col">
        <ConfigurableStage />
        <Settings>
          <Setting>
            <Field
              label={t('Headline')}
              name="headline"
              value={stage.metadata.headline}
              onChange={e => handleMetadata('headline', e.target.value)}
              className="mb-3"
            />
            <Field
              label={t('Subheading')}
              name="subheading"
              value={stage.metadata.subheading}
              onChange={e => handleMetadata('subheading', e.target.value)}
            />
          </Setting>
          <Setting
            title={t('Scan Credit Cards')}
            description={t(
              "Enable the customer to use their phone's camera to scan the credit card details."
            )}
          >
            <Toggle
              enabled={stage.metadata.scanCards}
              onChange={enabled => handleMetadata('scanCards', enabled)}
            >
              {t('Enable credit card scanning')}
            </Toggle>
          </Setting>
          <Setting
            title={t('Alternate Payment Methods')}
            description={t(
              'Allow Apple Pay, Google Pay, PayPal, & Venmo as payment methods.  Extra charge only applies to payments made with one of these methods.'
            )}
          >
            <Toggle
              // enabled={stage.metadata.paymentMethods && stage.metadata.paymentMethods.length > 1}
              enabled={true}
              onChange={enabled =>
                handleMetadata(
                  'paymentMethods',
                  enabled ? ['credit-card', 'apple-pay'] : ['credit-card']
                )
              }
            >
              {t('Enable alternate payment methods')}
            </Toggle>
          </Setting>
          <Setting
            title={t('Tokenize credit card details')}
            description={t(
              'When credit card details are tokenized, the user will be able to select from previously used payment methods to make payments in the future.  This is an especially useful feature when setting up recurring payments for subscriptions or bills.'
            )}
          >
            <Toggle
              enabled={(['yes', 'ask'] as string[]).includes(stage.metadata.tokenizeCards)}
              onChange={enabled => handleMetadata('tokenizeCards', enabled ? 'yes' : 'no')}
            >
              {t('Tokenize credit card details')}
            </Toggle>
          </Setting>
          <Setting
            title={t('Authorize Credit Card Payment')}
            description={t(
              'When enabled, the payment will be authorized but not captured.  This is useful when you want to verify the payment method before capturing the payment.'
            )}
          >
            <Toggle
              enabled={stage.metadata.authorizeOnly}
              onChange={enabled => handleMetadata('authorizeOnly', enabled ? true : false)}
            >
              {t('Authorize credit card payment')}
            </Toggle>
          </Setting>
        </Settings>
      </div>
    </CreateSettings>
  )
}

export default CreditCardPayment
