import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  pipelineId: string
  type: 'enrollment' | 'authentication' | 'categories'
  categoryId?: string
}

type Response = Iframe

export const createIFramePipeline = asyncThunk<Request, Response>({
  name: 'iframes/pipelines/create',
  method: 'POST',
  uri: ({ id }) => `/iframes/${id}/pipelines`,
  stripId: true,
})
