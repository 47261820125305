import { FC, useCallback } from 'react'

import { CreditCardPaymentConfiguration } from '@journeyid/agent/types'

import { t } from 'translation'

import Input from 'components/atoms/Input'
import MoneyInput from 'components/atoms/MoneyInput'

import s from './Configuration.module.scss'
import QuantityInput from './QuantityInput'

type LineItem = CreditCardPaymentConfiguration['lineItems'][0]
type LineItemField = keyof LineItem

type Props = {
  config: CreditCardPaymentConfiguration
  onChangeConfig: (config: CreditCardPaymentConfiguration) => void
}

const Configuration: FC<Props> = ({ config, onChangeConfig }) => {
  const handleChange = useCallback(
    <T extends LineItemField>(field: T, value: LineItem[T]) => {
      onChangeConfig({
        ...config,
        lineItems: [
          {
            ...config.lineItems[0],
            [field]: value,
          },
        ],
      })
    },
    [config, onChangeConfig]
  )

  return (
    <div>
      <h3 className={s.Header}>{t('Configuration')}</h3>
      <div className={s.InputWrapper}>
        <QuantityInput
          className={s.Quantity}
          value={config.lineItems[0].quantity}
          onChangeValue={quantity => quantity && handleChange('quantity', quantity)}
        />

        <Input
          value={config.lineItems[0].title}
          onChangeText={title => handleChange('title', title)}
        />

        <MoneyInput
          value={config.lineItems[0].amount / 100}
          onChangeValue={amount => {
            amount && handleChange('amount', Math.floor(amount * 100))
          }}
        />
      </div>
    </div>
  )
}

export default Configuration
