import { FC } from 'react'

import { DocumentCollectionStage } from '@journeyid/agent/types'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Header from 'components/organisms/Preview/Header'
import Illustration from 'components/organisms/Preview/Illustration'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

type Props = DocumentCollectionStage

const DocumentCollectionPreview: FC<Props> = ({ metadata }) => {
  return (
    <>
      <LogoHeader />

      <Header headline={metadata.fileTypes === 'images' ? t('Image Upload') : t('Document Upload')}>
        {metadata.prompt ||
          (metadata.fileTypes === 'images'
            ? t('Take a photo or select one from your photo library.')
            : t('Select an image or file to upload.'))}
      </Header>

      <Illustration type="document-collection" />

      <footer>
        <PrimaryButton block preview>
          {metadata.fileTypes === 'images'
            ? t('Take or Select a Photo')
            : t('Select an Image or File')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default DocumentCollectionPreview
