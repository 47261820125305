import { FC, ReactNode } from 'react'

import PhoneMockup from 'components/atoms/PhoneMockup'
import Safari from 'components/organisms/Preview/_Safari'

import s from './index.module.scss'

type Props = {
  children: ReactNode
}

const Preview: FC<Props> = ({ children }) => {
  return (
    <PhoneMockup>
      <Safari className={s.Preview}>
        <div className={s.Stage}>
          <section>{children}</section>
        </div>
      </Safari>
    </PhoneMockup>
  )
}

export default Preview
