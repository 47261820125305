import { FC } from 'react'

import EnterpriseStage from 'components/atoms/EnterpriseStage'
import DocuSignPreview from 'components/templates/stages/DocuSign.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const DocuSign: FC = () => {
  const { timer, handleSave } = useStage('docusign')

  return (
    <CreateSettings
      stageType="docusign"
      preview={<DocuSignPreview />}
      timer={timer}
      onSave={handleSave}
    >
      <EnterpriseStage />
    </CreateSettings>
  )
}

export default DocuSign
