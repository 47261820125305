import classNames from 'classnames'
import { FC } from 'react'

import { TemplateCategory } from 'pages/Pipelines/Create/UseCase/pipeline-templates'

import s from './Nav.module.scss'

type Props = {
  templates: TemplateCategory[]
  selected: string
  onSelect: (categoryId: string) => void
}

const Nav: FC<Props> = ({ templates, selected, onSelect }) => {
  return (
    <nav className={s.Nav}>
      <ul>
        {templates.map(template => (
          <li
            key={template.id}
            className={classNames(selected === template.id ? s.Selected : s.Unselected)}
            onClick={() => onSelect(template.id)}
          >
            <div>
              <span>{template.label}</span>
            </div>
            <aside>{template.label}</aside>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Nav
