import { FC } from 'react'

import { t } from 'translation'

import Input from 'components/atoms/Input'
import Radio from 'components/atoms/Radio'
import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import DocumentCollectionPreview from 'components/templates/stages/DocumentCollection.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const DocumentCollection: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('document-collection')

  return (
    <CreateSettings
      stageType="document-collection"
      preview={<DocumentCollectionPreview {...stage} />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <Setting title={t('What kind of files can be uploaded?')}>
          <div className="space-y-3">
            <Radio
              checked={stage.metadata.fileTypes === 'images'}
              onSelect={() => handleMetadata('fileTypes', 'images')}
            >
              {t('Only images are accepted')}
            </Radio>
            <Radio
              checked={stage.metadata.fileTypes === 'any'}
              onSelect={() => handleMetadata('fileTypes', 'any')}
            >
              {t('Any file type is accepted')}
            </Radio>
          </div>
        </Setting>
        <Setting
          title={t('Customized prompt')}
          description={t('You can override the prompt that will be shown to the user.')}
        >
          <Input
            value={stage.metadata.prompt}
            onChange={e => handleMetadata('prompt', e.target.value)}
            placeholder={t('(optional)')}
          />
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default DocumentCollection
