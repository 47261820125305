import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'

import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Header from 'components/molecules/AdminHeader'

import BrandStyleList from './BrandStyleList'
import s from './index.module.scss'

const BrandStylesList: FC = () => {
  const history = useHistory()

  const handleCreate = useCallback(() => {
    history.push(r.brandStyles.new)
  }, [history])

  return (
    <ContentWrapper className="bg-gray-100">
      <Header title={t('Branding')}>
        <button className={s.SendPipelineRequestBtn} onClick={handleCreate}>
          <FontAwesomeIcon icon={faPlus} />
          {t('Create new')}
        </button>
      </Header>

      <div className={s.Content}>
        <BrandStyleList />
      </div>
    </ContentWrapper>
  )
}

export default BrandStylesList
