import classNames from 'classnames'
import { FC, FormEvent, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import { useAPI } from 'hooks/useAPI'
import { updatePipeline } from 'thunks/api/pipelines/update'

import s from './TitleForm.module.scss'

const TitleForm: FC = () => {
  const { pipeline } = usePipelineSelector()
  const history = useHistory()
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState(pipeline.title)

  const [save, { timer }] = useAPI(updatePipeline)
  const handleSave = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      await save({
        id: pipeline.id,
        title,
        internalTitle: pipeline.internalTitle,
      })
      setIsEditing(false)
    },
    [pipeline, title, save]
  )

  return (
    <div className={classNames(s.TitleForm, isEditing && s.Open)}>
      {isEditing ? (
        <form onSubmit={handleSave}>
          <input value={title} onChange={e => setTitle(e.target.value)} autoFocus />
          <fieldset>
            <Button disabled={timer.isLoading} variant="link" onClick={handleSave}>
              {timer.isLoading ? t('saving') : t('save')}
            </Button>
            <Button variant="link" onClick={() => setIsEditing(false)} className="text-gray-600">
              {t('cancel')}
            </Button>
          </fieldset>
        </form>
      ) : (
        <div>
          <label className="">{pipeline.title}</label>
          <Button variant="link" onClick={() => setIsEditing(true)}>
            {t('edit')}
          </Button>
        </div>
      )}
      {pipeline.internalTitle && (
        <div>
          <span
            onClick={() =>
              history.push(r.pipelines.edit.settings.setting(pipeline.id, 'internal-title'))
            }
          >
            {t('Internal title: {{title}}', { title: pipeline.internalTitle })}
          </span>
        </div>
      )}
    </div>
  )
}

export default TitleForm
