import classNames from 'classnames'
import { FC } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import { faPencil } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './index.module.scss'

type Props = {
  className?: string
}

const Signature: FC<Props> = ({ className }) => {
  return (
    <div className={classNames(s.Signature, className)}>
      <div>
        <FontAwesomeIcon icon={faPencil} />
      </div>
      <div className={s.Triangle} />
      <div>
        <SignatureCanvas
          penColor="#111827"
          canvasProps={{
            width: 238,
            height: 60,
            style: {
              width: 238,
              height: 60,
              zIndex: 999,
            },
          }}
        />
      </div>
    </div>
  )
}

export default Signature
