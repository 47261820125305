import { HTTPCreated } from '@journeyid/agent/common/statuses'
import { Language } from '@journeyid/agent/common/types/language'
import { Configuration } from '@journeyid/agent/executions/create'
import { ExecutionDelivery } from '@journeyid/agent/executions/model'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  pipelineId: string
  userId: string
  delivery:
    | ExecutionDelivery
    | {
        method: 'url'
      }
  configuration: Configuration
  ttl: number // seconds
  language: Language
}

type Response = {
  url?: string
}

export const createExecution = asyncThunk<Request, Response>({
  name: 'executions/create',
  method: 'POST',
  uri: '/executions',
  successStatus: HTTPCreated,
  orchestrator: false,
})
