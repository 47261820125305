import { FC } from 'react'

import { t } from 'translation'

import ConfigurableStage from 'components/atoms/ConfigurableStage'
import Settings from 'components/atoms/Settings'
import Field from 'components/molecules/Field'
import SchedulePreview from 'components/templates/stages/Schedule.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const Schedule: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('schedule')

  return (
    <CreateSettings
      stageType="schedule"
      preview={<SchedulePreview {...stage} />}
      timer={timer}
      onSave={handleSave}
    >
      <div className="flex flex-col">
        <ConfigurableStage />
        <Settings>
          <Field
            label={t('Headline')}
            name="title"
            value={stage.metadata.title}
            onChange={e => handleMetadata('title', e.target.value)}
          />
          <Field
            label={t('Description of what is being scheduled')}
            name="description"
            value={stage.metadata.description}
            onChange={e => handleMetadata('description', e.target.value)}
            className="!border-t-0"
          />
        </Settings>
      </div>
    </CreateSettings>
  )
}

export default Schedule
