import { FC } from 'react'

import PhoneNumberVerificationPreview from 'components/templates/stages/PhoneNumberVerification.Preview'
import NoSettingsStage from 'components/templates/stages/shared/NoSettingsStage'

const PhoneNumberVerification: FC = () => {
  return (
    <NoSettingsStage
      stageType="phone-number-verification"
      preview={PhoneNumberVerificationPreview}
    />
  )
}

export default PhoneNumberVerification
