import { AccountSSO } from 'types/sso'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

type Response = {
  saml: NonNullable<AccountSSO['saml2']>
}

export const retrieveSSOSaml = asyncThunk<Request, Response>({
  name: 'account/sso',
  uri: ({ id }) => `/accounts/${id}/saml`,
  orchestrator: false,
  stripId: true,
})
