import { HTTPProcessed } from '@journeyid/agent/common/statuses'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  email: string
}

type Response = {}

export const sendOIDCEmail = asyncThunk<Request, Response>({
  name: 'accounts/sso/oidc/send-email',
  uri: ({ id }) => `/accounts/${id}/sso/oidc/send-email`,
  method: 'POST',
  orchestrator: false,
  successStatus: HTTPProcessed,
  stripId: true,
})
