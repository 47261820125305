import { FC } from 'react'
import { t } from 'translation'

import Setting from 'components/atoms/Setting'
import Settings from 'components/atoms/Settings'
import Toggle from 'components/atoms/Toggle'
import NationalIDCardPreview from 'components/templates/stages/NationalIDCard.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const NationalIDCard: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('national-id-card')

  return (
    <CreateSettings
      stageType="national-id-card"
      preview={<NationalIDCardPreview />}
      timer={timer}
      onSave={handleSave}
    >
      <Settings>
        <p className="text-gray-500 std-sm">
          {t(
            'All National ID Card scans will include OCR (reading the text) for the data on the front and scanning the barcode on the back. The two scans are compared against each other to ensure data integrity. A basic level of physical document verification is also performed.'
          )}
        </p>
        <Setting
          title={t('Document Verification')}
          description={t(
            'Machine learning algorithms will be applied which have a high probability of detecting fake IDs (printed or photographed for example) as well as manipulated IDs (the photo was changed for example).'
          )}
        >
          <Toggle
            enabled={stage.metadata.documentVerification}
            className="mt-2"
            onChange={enabled => handleMetadata('documentVerification', enabled)}
          >
            {t('Enable advanced document verification')}
          </Toggle>
        </Setting>
      </Settings>
    </CreateSettings>
  )
}

export default NationalIDCard
