import { ZoomConfiguration } from 'types/account'
import asyncThunk from 'util/asyncThunk'

export type Request = ZoomConfiguration & {
  id: string
}

export const updateZoomConfiguration = asyncThunk<Request, ZoomConfiguration>({
  name: 'account/zoom-configuration',
  uri: ({ id }) => `/accounts/${id}/zoom-configuration`,
  method: 'PUT',
  orchestrator: false,
  stripId: true,
})
