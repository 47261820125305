import classNames from 'classnames'
import { FC, forwardRef, InputHTMLAttributes } from 'react'

import Input from 'components/atoms/Input'

import styles from '../Input/index.module.scss'

type Props = {
  className?: string
  prefix?: string
  suffix?: string
} & InputHTMLAttributes<HTMLInputElement>

const InputGroup = forwardRef<HTMLInputElement, Props>(
  ({ prefix, suffix, className, ...rest }, ref) => {
    return (
      <div className={styles.InputGroup}>
        {prefix && <span className={styles.Prefix}>{prefix}</span>}
        <Input
          ref={ref}
          {...rest}
          className={classNames(
            {
              [styles.HasPrefix]: !!prefix,
              [styles.HasSuffix]: !!suffix,
            },
            className
          )}
        />
        {suffix && <span className={styles.Suffix}>{suffix}</span>}
      </div>
    )
  }
)

export default InputGroup
