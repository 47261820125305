import { Pipeline } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

type Response = {
  id: string
  secret: NonNullable<NonNullable<Pipeline['oauth']>['secret']> & {
    secretKey: string
  }
}

export const createSecret = asyncThunk<Request, Response>({
  name: 'pipelines/create-secret',
  method: 'POST',
  uri: ({ id }) => `/pipelines/${id}/secrets`,
  stripId: true,
})
