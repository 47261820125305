import classNames from 'classnames'
import { FC } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import r from 'routes'
import { t } from 'translation'

import s from './Menu.module.scss'

const LINKS = [
  { name: t('General Settings'), path: r.settings.general },
  { name: t('Admin Management'), path: r.settings.adminManagement },
  { name: t('Agent Desktops'), path: r.settings.agentDesktops },
  { name: t('Agent Authentication'), path: r.settings.agentAuthentication },
  { name: t('Agent Groups'), path: r.settings.groups },
  { name: t('Payment Gateways'), path: r.settings.paymentGateways },
  { name: t('Single Sign-On'), path: r.settings.sso },
  { name: t('Customer Registration'), path: r.settings.customerRegistration },
  { name: t('Data Export'), path: r.settings.dataExport },
  { name: t('Integration'), path: r.settings.zoom },
  { name: t('Developer'), path: r.settings.developer },
]

const Menu: FC = () => {
  const location = useLocation()
  const history = useHistory()

  return (
    <ul className={s.List}>
      {LINKS.map((link, i) => (
        <li
          key={i}
          className={classNames({
            'font-semibold border-l-4 !border-l-theme': location.pathname.match(link.path),
          })}
          onClick={() => history.push(link.path)}
        >
          <p>{link.name}</p>
        </li>
      ))}
      {history.location.pathname === r.settings.advanced && (
        <li className="font-semibold border-l-4 !border-l-theme">
          <p>{t('Advanced Settings')}</p>
        </li>
      )}
      {history.location.pathname.startsWith(r.settings.pricing('')) && (
        <li className="font-semibold border-l-4 !border-l-theme">
          <p>{t('Pricing')}</p>
        </li>
      )}
    </ul>
  )
}

export default Menu
