import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { faChevronLeft, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldWithOptions, Option } from '@journeyid/agent/types'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import ConfirmButton from 'components/atoms/ConfirmButton'
import Field from 'components/molecules/Field'
import Modal from 'components/organisms/Modal'
import { idleTimer } from 'hooks/useActionTimer'
import { openModal, useModal } from 'hooks/useModal'
import useYupValidationResolver from 'hooks/useYupValidationResolver'

import AddOptionForm from './AddOptionForm'
import s from './FormModal.module.scss'

const typeTitles = {
  dropdown: 'Select from a dropdown of options',
  radio: 'Select one option from a list of options',
  checkboxes: 'Select one or more options from a list',
} as const

const schema = yup.object().shape({
  label: yup.string().required(t('A label is required')),
})

type Props = {
  type: FieldWithOptions['type']
  defaultValues?: FieldWithOptions
  onRemove: () => void
  onSubmit: (field: FieldWithOptions) => void
  onClose: () => void
}

const SelectOptionsModal: FC<Props> = ({ type, defaultValues, onSubmit, onRemove, onClose }) => {
  const [isOpen] = useModal('SelectOptions')

  const resolver = useYupValidationResolver(schema)
  const form = useForm<FieldWithOptions>({
    defaultValues,
    resolver,
  })
  const { register, errors, reset, handleSubmit } = form
  const [options, setOptions] = useState<Option[]>(defaultValues?.options || [])

  const handleFormSubmit = useCallback(
    (field: FieldWithOptions) => {
      onSubmit({
        ...defaultValues,
        ...field,
        type,
        options: options ? options : [],
      })
    },
    [type, defaultValues, options, onSubmit]
  )

  const ref = useRef<HTMLFormElement>(null)
  const triggerSubmit = useCallback(() => {
    ref.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }, [ref])

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  return (
    <Modal
      isOpen={isOpen}
      className="max-w-3xl h-[90%] px-4 py-0 flex flex-col items-center"
      onClose={onClose}
    >
      <div className="w-3/4 pt-20 h-full">
        <button className={s.BackButton} onClick={openModal('FormFields')}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <div className="pb-8 h-[90%]">
          <Modal.Header>{t(typeTitles[type])}</Modal.Header>
          <Modal.Body>
            <p className="std-sm">
              {t(
                'This form field includes being able to select from a list of options. Be sure to add the options below before saving the field.'
              )}
            </p>
            <p className="mt-2 std-sm">
              {t(
                'The "Value" of the option is what will be stored when the pipeline is submitted. It\'s helpful to use system-friendly values such as "US" rather than "United States of America." In contrast, the label can be any text that is clear to the user.'
              )}
            </p>
          </Modal.Body>
          <div className="max-h-[70%] overflow-y-auto">
            <form ref={ref} onSubmit={handleSubmit(handleFormSubmit)}>
              <FormProvider {...form}>
                <Field
                  ref={register}
                  id="label"
                  name="label"
                  label={t('Field Label')}
                  placeholder={t('Label example')}
                  className="mb-4"
                  autoComplete="off"
                  error={errors.label}
                  autoFocus
                />

                <Field
                  ref={register}
                  id="hint"
                  name="hint"
                  label={t('Hint Text')}
                  placeholder={t('(optional)')}
                  className="mb-6"
                  autoComplete="off"
                  error={errors.hint}
                  autoFocus
                />
              </FormProvider>
            </form>

            <AddOptionForm defaultValues={options} setOptions={setOptions} />
          </div>
        </div>
        <div className={s.CloseButton}>
          <button tabIndex={-1} onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>

        <div className={s.FormFooter}>
          <Modal.Actions>
            <Button size="xl" className="px-16" onClick={triggerSubmit}>
              {t('Save')}
            </Button>
            {/* <Button variant="secondary" size="xl" onClick={saveAndAdd}>
            Save and add another
          </Button> */}
            {defaultValues?.id && (
              <ConfirmButton
                loadingText={t('deleting...')}
                timer={idleTimer}
                color="text-red-600"
                onConfirm={onRemove}
              >
                {t('delete field')}
              </ConfirmButton>
            )}
          </Modal.Actions>
        </div>
      </div>
    </Modal>
  )
}

export default SelectOptionsModal
