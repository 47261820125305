import { useCallback } from 'react'
import { useAppDispatch } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import useActionTimer from 'hooks/useActionTimer'
import { magicLinksCreate, Request } from 'thunks/api/magic_links/create'

export default function useMagicLinkCreate() {
  const timer = useActionTimer()
  const dispatch = useAppDispatch()

  const create = useCallback(
    async (req: Request) => {
      try {
        timer.start()

        const resp = await dispatch(magicLinksCreate(req)).then(unwrapResult)

        timer.succeeded()

        return resp
      } catch (err) {
        timer.failed()
        throw err
      }
    },
    [timer, dispatch]
  )

  return [create, { timer }] as const
}
