import classNames from 'classnames'
import { FC } from 'react'

import { faCircleCheck, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Account } from 'types/account'

type Props = {
  account: Account
  isActive: boolean
  isLoading: boolean
  onClick: () => void
}

const AccountListItem: FC<Props> = ({ account, isActive, isLoading, onClick }) => {
  return (
    <li>
      <button disabled={isLoading} onClick={onClick}>
        <div
          className={classNames(
            { '!border-0': isActive },
            {
              '!border-0 !bg-transparent': isLoading,
            }
          )}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          ) : isActive ? (
            <FontAwesomeIcon icon={faCircleCheck} />
          ) : null}
        </div>
        <p className={classNames({ '!text-gray-900 font-semibold': isActive })}>{account.name}</p>
      </button>
    </li>
  )
}

export default AccountListItem
