import classNames from 'classnames'
import React, { FC, useMemo } from 'react'

import serializeSlateValue, { SlateValue } from 'util/slate'

import styles from './standard.module.scss'

type Props = {
  legal?: boolean
  className?: string
} & (
  | {
      value: SlateValue
    }
  | {
      text: string
    }
)

const SlateHTML: FC<Props> = ({ legal = false, className, ...rest }) => {
  const html = useMemo(() => {
    var value: SlateValue
    if ('value' in rest) {
      value = rest.value
    } else {
      value = JSON.parse(rest.text)
    }
    return serializeSlateValue(value)
  }, [rest])

  return (
    <div
      className={classNames(legal ? styles.Legal : styles.Default, className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default SlateHTML
