import { FC, useCallback, useState } from 'react'

import { t } from 'translation'

import Header from 'components/molecules/AdminHeader'
import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import { useAccountActivity } from 'hooks/api/useAccountActivity'

import ActivityFeed from './ActivityFeed'
import s from './index.module.scss'
import NewEvents from './NewEvents'

import type { Event } from 'types/event'

const ActivityFeedIndex: FC = () => {
  const [events, setEvents] = useState<Event[]>([])
  const [load, { hasMore, isLoading }] = useAccountActivity()

  const loadMore = useCallback(async () => {
    if (isLoading) return
    const newEvents = await load({ limit: 12 })
    if (newEvents) setEvents([...events, ...newEvents])
  }, [events, setEvents, load, isLoading])

  const onImport = useCallback(
    (newEvents: Event[]) => {
      newEvents = newEvents.reverse()
      setEvents([...newEvents, ...events])
    },
    [events, setEvents]
  )

  return (
    <ContentWrapper>
      <Header title={t('Dashboard')} />
      <div className={s.ListWrapper}>
        <div>
          <div>
            <NewEvents onImport={onImport} />
            <ActivityFeed
              events={events}
              hasMoreEvents={hasMore}
              isLoading={isLoading}
              onLoadMore={loadMore}
            />
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default ActivityFeedIndex
