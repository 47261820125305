import { FC } from 'react'

import { t } from 'translation'

import { ReactComponent as Illustration } from './illustration.svg'
import s from './index.module.scss'

const NoSettings: FC = () => {
  return (
    <div id="no-settings" className={s.NoSettings}>
      <h6 className="std">{t('Good to go!')}</h6>
      <h4 className="std">{t('This action has no settings')}</h4>
      <Illustration />
    </div>
  )
}

export default NoSettings
