import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import r from 'routes'

import { StageType } from '@journeyid/agent/types'

import Layout from 'components/atoms/Layout'
import DeliveryEmail from 'components/templates/delivery/Email'
import DeliveryPushNotification from 'components/templates/delivery/PushNotification'
import DeliverySMS from 'components/templates/delivery/SMS'
import AddressCollection from 'components/templates/stages/AddressCollection'
import Blank from 'components/templates/stages/Blank'
import CreditCardPayment from 'components/templates/stages/CreditCardPayment'
import CustomID from 'components/templates/stages/CustomID'
import DeviceAuthentication from 'components/templates/stages/DeviceAuthentication'
import DeviceRegistration from 'components/templates/stages/DeviceRegistration'
import DocumentCollection from 'components/templates/stages/DocumentCollection'
import DocumentSignature from 'components/templates/stages/DocumentSignature'
import DocuSign from 'components/templates/stages/DocuSign'
import DriversLicense from 'components/templates/stages/DriversLicense'
import ExternalAuthentication from 'components/templates/stages/ExternalAuthentication'
import FaceAuthentication from 'components/templates/stages/FaceAuthentication'
import FaceEnrollment from 'components/templates/stages/FaceEnrollment'
import FacialAuthentication from 'components/templates/stages/FacialAuthentication'
import FacialAuthentication3D from 'components/templates/stages/FacialAuthentication3D'
import FacialEnrollment from 'components/templates/stages/FacialEnrollment'
import Form from 'components/templates/stages/Form'
import GovernmentID from 'components/templates/stages/GovernmentID'
import GovernmentIDSelfie from 'components/templates/stages/GovernmentIDSelfie'
import KnowledgeBasedAuthentication from 'components/templates/stages/KnowledgeBasedAuthentication'
import NationalIDCard from 'components/templates/stages/NationalIDCard'
import OCR from 'components/templates/stages/OCR'
import OutboundScheduling from 'components/templates/stages/OutboundScheduling'
import Passport from 'components/templates/stages/Passport'
import PaymentDetails from 'components/templates/stages/PaymentDetails'
import PhoneNumberRegistration from 'components/templates/stages/PhoneNumberRegistration'
import PhoneNumberVerification from 'components/templates/stages/PhoneNumberVerification'
import PindropDeepVoiceAuthentication from 'components/templates/stages/PindropDeepVoiceAuthentication'
import PindropDeepVoiceEnrollment from 'components/templates/stages/PindropDeepVoiceEnrollment'
import PindropProtect from 'components/templates/stages/PindropProtect'
import PindropVericall from 'components/templates/stages/PindropVericall'
import RandomCode from 'components/templates/stages/RandomCode'
import Schedule from 'components/templates/stages/Schedule'
import SocialSecurityNumber from 'components/templates/stages/SocialSecurityNumber'
import Survey from 'components/templates/stages/Survey'
import TOTPAuthentication from 'components/templates/stages/TOTPAuthentication'
import TOTPRegistration from 'components/templates/stages/TOTPRegistration'
import ActiveVoiceAuthentication from 'components/templates/stages/VoiceAuthentication'
import ActiveVoiceEnrollment from 'components/templates/stages/VoiceEnrollment'
import WebAuthnAuthentication from 'components/templates/stages/WebAuthnAuthentication'
import WebAuthnRegistration from 'components/templates/stages/WebAuthnRegistration'
import CreateSidebar from 'pages/Pipelines/Create/components/CreateSidebar'
import ConfigureRedirect from 'pages/Pipelines/Create/ConfigureRedirect'
import ConfigureStages from 'pages/Pipelines/Create/ConfigureStages'
import Publish from 'pages/Pipelines/Create/Publish'
import SuccessScreen from 'pages/shared/SuccessScreen'

const { stage } = r.pipelines.create.configure
const { method } = r.pipelines.create.delivery

const configurationMap: Record<StageType, FC> = {
  'active-voice-authentication': ActiveVoiceAuthentication,
  'active-voice-enrollment': ActiveVoiceEnrollment,
  'address-collection': AddressCollection,
  blank: Blank,
  'credit-card-payment': CreditCardPayment,
  'custom-id': CustomID,
  'device-authentication': DeviceAuthentication,
  'device-registration': DeviceRegistration,
  'document-collection': DocumentCollection,
  'document-signature': DocumentSignature,
  docusign: DocuSign,
  'drivers-license': DriversLicense,
  'external-authentication': ExternalAuthentication,
  'face-authentication': FaceAuthentication,
  'face-enrollment': FaceEnrollment,
  'facial-authentication-3d': FacialAuthentication3D,
  'facial-authentication': FacialAuthentication,
  'facial-enrollment': FacialEnrollment,
  form: Form,
  'government-id-selfie': GovernmentIDSelfie,
  'government-id': GovernmentID,
  'knowledge-based-authentication': KnowledgeBasedAuthentication,
  'national-id-card': NationalIDCard,
  ocr: OCR,
  'outbound-scheduling': OutboundScheduling,
  passport: Passport,
  'payment-details': PaymentDetails,
  'phone-number-registration': PhoneNumberRegistration,
  'phone-number-verification': PhoneNumberVerification,
  'pindrop-authentication': PindropDeepVoiceAuthentication,
  'pindrop-enrollment': PindropDeepVoiceEnrollment,
  'pindrop-protect': PindropProtect,
  'pindrop-vericall': PindropVericall,
  'random-code': RandomCode,
  schedule: Schedule,
  'social-security-number': SocialSecurityNumber,
  survey: Survey,
  'totp-authentication': TOTPAuthentication,
  'totp-registration': TOTPRegistration,
  'webauthn-authentication': WebAuthnAuthentication,
  'webauthn-registration': WebAuthnRegistration,
}

type Props = {}

const PipelinesCreate: FC<Props> = () => (
  <Layout sidebar={<CreateSidebar />}>
    <Switch>
      <Route path={r.pipelines.create.configure.root()} exact component={ConfigureRedirect} />
      <Route path={r.pipelines.create.configure.stages()} component={ConfigureStages} />

      {(Object.keys(configurationMap) as StageType[]).map(stageType => (
        <Route
          key={stageType}
          path={stage(':id', stageType)}
          component={configurationMap[stageType]}
        />
      ))}
      <Route path={r.pipelines.create.configure.successScreen()} component={SuccessScreen} />

      <Route path={method(':id', 'email')} component={DeliveryEmail} />
      <Route path={method(':id', 'sms')} component={DeliverySMS} />
      <Route path={method(':id', 'push-notification')} component={DeliveryPushNotification} />

      <Route path={r.pipelines.create.publish()} component={Publish} />

      <Redirect to={r.pipelines.create.configure.root()} />
    </Switch>
  </Layout>
)

export default PipelinesCreate
