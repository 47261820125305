import { FC } from 'react'

import s from './AccountList.module.scss'
import AccountListItem from './AccountListItem'

type Account = {
  id: string
  name: string
  isActive: boolean
}

type Props = {
  accounts: Account[]
}

const AccountList: FC<Props> = ({ accounts }) => (
  <div className={s.Wrapper}>
    <ul className={s.List}>
      {accounts.map((account, i) => (
        <AccountListItem key={i} account={account} />
      ))}
    </ul>
  </div>
)

export default AccountList
