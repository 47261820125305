import cogoToast from 'cogo-toast'
import { useCallback, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { t } from 'translation'

import ContentWrapper from 'components/atoms/AdminLayout/ContentWrapper'
import Loading from 'components/atoms/Loading'
import SettingsCard from 'components/atoms/SettingsCard'
import Header from 'components/molecules/AdminHeader'
import { useAccount } from 'hooks/api/useAccount'
import { useAPI } from 'hooks/useAPI'
import Footer from 'pages/Settings/Footer'
import Menu from 'pages/Settings/Menu'
import { updateZoomConfiguration } from 'thunks/api/accounts/update-zoom-configuration'

import s from '../General.module.scss'
import AccountIDs from './_AccountIDs'
import IFrameSelector from './_IFrameSelector'

export type FormData = {
  accounts: {
    accountId: string
    email: string
  }[]
  iframeId: string
}

const Zoom = () => {
  const form = useForm<FormData>({
    defaultValues: {
      accounts: [],
      iframeId: '',
    },
    shouldUnregister: false,
  })
  const { handleSubmit, errors, setValue } = form

  const [account, { timer: accountTimer }] = useAccount()

  useEffect(() => {
    if (!account) return

    if (account.zoom?.accounts) {
      const vals = account.zoom.accounts.map(acc => ({ accountId: acc.id, email: acc.email }))
      setValue('accounts', vals)
    }
    setValue('iframeId', account.zoom?.iframeId ?? '')
  }, [account, setValue])

  const [update, { timer: updateTimer }] = useAPI(updateZoomConfiguration)
  const handleUpdate = useCallback(
    async (data: FormData) => {
      await update({
        id: account!.id,
        iframeId: data.iframeId,
        accounts: data.accounts
          .map(acc => ({
            id: acc.accountId,
            email: acc.email,
          }))
          .filter(Boolean),
      })
      cogoToast.success(t('Integration settings have been saved!'))
    },
    [account, update]
  )

  return (
    <FormProvider {...form}>
      <form className="h-full" onSubmit={handleSubmit(handleUpdate)}>
        <ContentWrapper withFooter>
          <Header title={t('Settings')} />
          <div className={s.Content}>
            <Menu />
            <div>
              <p className="mt-4 mb-8 text-4xl font-semibold">{t('Integration Settings')}</p>
              {accountTimer.isLoading ? (
                <Loading />
              ) : (
                <>
                  <SettingsCard>
                    <AccountIDs />

                    <div className="w-96">
                      <Controller
                        name="iframeId"
                        control={form.control}
                        render={({ value, onChange }) => (
                          <IFrameSelector
                            label={t('IFrame')}
                            name="iframeId"
                            error={errors.iframeId}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </SettingsCard>
                </>
              )}
            </div>
          </div>
          <Footer timer={updateTimer} />
        </ContentWrapper>
      </form>
    </FormProvider>
  )
}

export default Zoom
