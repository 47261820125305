import { FC, ReactElement } from 'react'

import { t } from 'translation'

import { ReactComponent as Logo } from './icons/logo.svg'
import s from './index.module.scss'

type Props = {
  info: string
  footer: ReactElement
  children: ReactElement[]
}

const Auth: FC<Props> = ({ info, footer, children }) => (
  <div className={s.SignIn}>
    <div className={s.Content}>
      <div className={s.LeftSide}>
        <div>
          <Logo />
        </div>
        <div className="hidden md:block">
          <h2>
            {t('The')} <span>{t('ultimate no-code')}</span> {t('business solution')}
          </h2>
          <p>{t('Welcome to the peak of privacy, security, and customer experience')}</p>
        </div>
      </div>

      <div className={s.RightSide}>
        <div>
          <div className={s.Header}>
            <h2>{t('Welcome to Elevate')}</h2>
            <p>{info}</p>
            <ul className={s.LinkList}>
              {children.map((child, i) => (
                <li key={i}>{child}</li>
              ))}
            </ul>
          </div>

          <div className={s.SingUpText}>{footer}</div>

          <hr />

          <div className={s.Footer}>
            <p>
              {t('By logging in, you agree to our')}{' '}
              <a
                href="https://journeyid.com/terms-of-service-agreement/"
                target="_blank"
                rel="noreferrer"
              >
                {t('Terms & Data Policy')}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Auth
