import { Route, Switch } from 'react-router-dom'
import r from 'routes'

import IframeLayout from 'components/atoms/IframeLayout'
import CreateSidebar from 'pages/IFrames/components/CreateSidebar'
import { EditSidebar as EditIframeSidebar } from 'pages/IFrames/components/EditSidebar'
import IframeHomepage from 'pages/IFrames/Homepage'
import AddPipeline from 'pages/IFrames/Steps/AddPipeline'
import DeliveryMethods from 'pages/IFrames/Steps/DeliveryMethods'
import DeploymentInstructions from 'pages/IFrames/Steps/DeploymentInstructions'
import EnrollmentAndAuthentication from 'pages/IFrames/Steps/EnrollmentAndAuthentication'
import Languages from 'pages/IFrames/Steps/Languages'
import Pipelines from 'pages/IFrames/Steps/Pipelines'
import TestIframe from 'pages/IFrames/Steps/Test'
import Title from 'pages/IFrames/Steps/Title'

const IFrames = () => (
  <Switch>
    <Route path={r.iframes.root} exact component={IframeHomepage} />

    <Route path={r.iframes.create.root()}>
      <IframeLayout sidebar={<CreateSidebar />}>
        <Switch>
          <Route path={r.iframes.create.configure.new} exact component={Title} />
          <Route path={r.iframes.create.configure.title()} exact component={Title} />
          <Route path={r.iframes.create.configure.languages()} exact component={Languages} />
          <Route
            path={r.iframes.create.configure.enrollmentAndAuthentication()}
            exact
            component={EnrollmentAndAuthentication}
          />
          <Route path={r.iframes.create.configure.pipelines()} exact component={Pipelines} />
          <Route path={r.iframes.create.configure.addPipeline()} exact component={AddPipeline} />
          <Route
            path={r.iframes.create.configure.deliveryMethods()}
            exact
            component={DeliveryMethods}
          />
          <Route path={r.iframes.create.configure.test()} exact component={TestIframe} />
          <Route
            path={r.iframes.create.configure.deploymentInstructions()}
            exact
            component={DeploymentInstructions}
          />
        </Switch>
      </IframeLayout>
    </Route>

    <Route path={r.iframes.edit.root()}>
      <IframeLayout sidebar={<EditIframeSidebar />}>
        <Switch>
          <Route path={r.iframes.edit.configure.title()} exact component={Title} />
          <Route path={r.iframes.edit.configure.languages()} exact component={Languages} />
          <Route
            path={r.iframes.edit.configure.enrollmentAndAuthentication()}
            exact
            component={EnrollmentAndAuthentication}
          />
          <Route path={r.iframes.edit.configure.pipelines()} exact component={Pipelines} />
          <Route path={r.iframes.edit.configure.addPipeline()} exact component={AddPipeline} />
          <Route
            path={r.iframes.edit.configure.deliveryMethods()}
            exact
            component={DeliveryMethods}
          />
          <Route path={r.iframes.edit.configure.test()} exact component={TestIframe} />
          <Route
            path={r.iframes.edit.configure.deploymentInstructions()}
            exact
            component={DeploymentInstructions}
          />
        </Switch>
      </IframeLayout>
    </Route>
  </Switch>
)

export default IFrames
