import { Pipeline } from 'types'

import { StageType } from '@journeyid/agent/types'

import { PipelineTemplate } from 'pages/Pipelines/Create/UseCase/pipeline-templates'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  accountId: string
  stageTypes: StageType[]
  title?: string
  template?: PipelineTemplate['id']
}

type Response = Pipeline

export const createPipeline = asyncThunk<Request, Response>({
  name: 'pipelines/create',
  method: 'POST',
  uri: '/pipelines',
})
