import { KeyboardEvent, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { faArrowRight, faEye } from '@fortawesome/pro-regular-svg-icons'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Footer from 'components/molecules/Footer'
import PageHeader from 'components/molecules/PageHeader'
import useActionTimer from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import { openModal } from 'hooks/useModal'
import PreviewModal from 'pages/Pipelines/Create/components/PreviewModal'
import { publishPipeline } from 'thunks/api/pipelines/publish'
import { updatePipeline } from 'thunks/api/pipelines/update'

import s from './index.module.scss'

const Publish = () => {
  const { pipeline } = usePipelineSelector()
  const [title, setTitle] = useState(pipeline.title)

  // const cost = useMemo(
  //   () => pipeline.stages.reduce((memo, stage) => memo + getStageCost(stage), 0),
  //   [pipeline.stages]
  // )

  const [update] = useAPI(updatePipeline)
  const [publish] = useAPI(publishPipeline)
  const timer = useActionTimer()
  const history = useHistory()
  const [error, setError] = useState<string>()
  const handlePublish = useCallback(async () => {
    try {
      if (title === '') {
        setError(t('Please enter Pipeline title above.'))
        return
      }

      if (pipeline.stages.length === 0) {
        setError(t('Please add at least one stage to the pipeline before publishing.'))
        return
      }

      window.history.replaceState(null, '', '?✓')
      timer.start()
      await update({
        id: pipeline.id,
        title,
      })
      await publish({
        id: pipeline.id,
      })
      history.push(r.pipelines.edit.published(pipeline.id))
    } catch (err) {
      console.error('Unable to publish pipeline:', err)
      timer.failed()
    }
  }, [pipeline, title, timer, update, publish, history])

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handlePublish()
      }
    },
    [handlePublish]
  )

  return (
    <div id="publish" className={s.Wrapper}>
      <div className={s.Content}>
        <PageHeader title={t('Publish Your Pipeline')}>
          {t(
            "Congratulations! You've finished configuring the pipeline. The only thing left to do is to select a Pipeline title and click \"Publish.\" Once published, you'll be able to integrate the Pipeline via several methods such as IVR, API, and SSO protocols. You'll be able to send the Pipeline immediately through the admin dashboard to your customers & to yourself to test it."
          )}
        </PageHeader>
        {/* <div id="pricing" className={s.Pricing}>
        <header>{t('Pricing per pipeline execution:')}</header>
        <ul>
          <li>
            <h2>
              <FontAwesomeIcon icon={faDollarSign} />
              <span>{cost.toFixed(2)}</span>
            </h2>
            <p className="std-sm">first 1k</p>
          </li>
        </ul>
        <footer>
          <FontAwesomeIcon icon={faTag} />
          <span>25% Discount Applied</span>
        </footer>
      </div> */}
        <div id="pipeline-title" className={s.PipelineName}>
          <header>{t('Pipeline title')}</header>
          <p className="max-w-xl">
            {t(
              'This is the title of the Pipeline that the user will see. It should be short and descriptive of what the process they are completing is.'
            )}
          </p>
          <p className="mt-2">
            {t(
              "After you've published the Pipeline, you'll be able to select an internal title which can help keep your Pipeline list organized."
            )}
          </p>
          <Input
            autoFocus
            placeholder={t("Enter your pipeline's title here")}
            value={title}
            onChange={e => {
              setError(undefined)
              setTitle(e.target.value)
            }}
            onKeyUp={handleKeyUp}
          />
        </div>
      </div>
      <Footer
        alert={error && <Alert type="error">{error}</Alert>}
        actions={
          <>
            <Button variant="secondary" icon={faEye} onClick={openModal('Preview')}>
              {t('Preview Pipeline')}
            </Button>
            <Button
              isLoading={timer.isLoading}
              iconRight={timer.isLoading ? undefined : faArrowRight}
              onClick={handlePublish}
            >
              {timer.isLoading ? t('Publishing...') : t('Publish')}
            </Button>
          </>
        }
      />
      <PreviewModal />
    </div>
  )
}

export default Publish
