import { ChangeEvent, FC, FocusEvent, useCallback, useRef, useState } from 'react'
import { usePipelineSelector } from 'store'

import { faArrowRight, faEye } from '@fortawesome/pro-regular-svg-icons'

import { t } from 'translation'

import Alert from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import PhoneMockup from 'components/atoms/PhoneMockup'
import Field from 'components/molecules/Field'
import Footer from 'components/molecules/Footer'
import PageHeader from 'components/molecules/PageHeader'
import useDelivery from 'components/templates/delivery/useDelivery'
import { openModal } from 'hooks/useModal'
import PreviewModal from 'pages/Pipelines/Create/components/PreviewModal'
import { deliveryDefaults } from 'util/deliveryDefaults'

import { ReactComponent as IMessage } from './iMessage.svg'
import s from './index.module.scss'

const DeliverySMS: FC = () => {
  const { pipeline } = usePipelineSelector()
  const { sms, timer, handleChange, handleSave, goToNext } = useDelivery('sms')

  /**
   * Set the default message if the textarea is focused
   */
  const ref = useRef<HTMLInputElement>(null)
  const [noLinkError, setNoLinkError] = useState(false)
  const handlePreSave = useCallback(() => {
    if (!sms?.message.includes('https://link.to/pipeline')) {
      setNoLinkError(true)
      return
    }

    handleSave()
  }, [sms?.message, handleSave])

  const handleFocusEvent = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value) return

    handleChange('message')({
      target: { value: deliveryDefaults['sms'].message },
    } as ChangeEvent<HTMLInputElement>)
    setNoLinkError(false)
  }

  const handleChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const msg = e.target.value
    setNoLinkError(!msg.includes('https://link.to/pipeline'))

    handleChange('message')({
      target: { value: msg },
    } as ChangeEvent<HTMLInputElement>)
  }

  return (
    <div id="delivery-sms" className={s.Wrapper}>
      <div className={s.Content}>
        <PageHeader title={t('Delivery Method: Text Message')}>
          {t(
            'The most common of the delivery methods, SMS provides a quick & simple means to deliver a pipeline request to the user. A unique link will be included in the text message which will enable the user to start the pipeline in their mobile browser without any mobile app installed.'
          )}
        </PageHeader>
        <div className="flex pb-8 pr-8">
          <div className="flex-1 pt-4 pr-4 space-y-4 pl-7">
            <Field
              ref={ref}
              as="textarea"
              label={t('Text message content')}
              name="subject"
              rows={3}
              value={sms?.message}
              placeholder={deliveryDefaults.sms.message}
              onChange={handleChangeEvent}
              onFocus={handleFocusEvent}
            />
            {noLinkError ? (
              <Alert type="error">
                <span className="font-mono text-xs">https://link.to/pipeline</span>{' '}
                {t(
                  "must be in the text message content. It will be replaced with the user's link."
                )}
              </Alert>
            ) : (
              <Alert>{t("The URL will be replaced with a link that's unique for the user.")}</Alert>
            )}
          </div>
          <div>
            <PhoneMockup>
              <IMessage className="absolute rounded-[40px]" />
              <div className="pt-28">
                {(sms?.message || '').length > 140 && (
                  <div className={s.Warning}>
                    {t(
                      'This is longer than a standard text message. Some carriers have delays when sending combined messages.'
                    )}
                  </div>
                )}
                <div className={s.iMessage}>
                  <p className="from-them">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: (sms?.message || deliveryDefaults.sms.message).replace(
                          'https://link.to/pipeline',
                          '<span class="text-blue-500">https://link.to/pipeline</span>'
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
            </PhoneMockup>
          </div>
        </div>
      </div>
      <Footer
        actions={
          <>
            <Button variant="secondary" icon={faEye} onClick={openModal('Preview')}>
              {t('Preview Pipeline')}
            </Button>
            <Button
              variant={timer.didSucceed && pipeline.key ? 'green' : 'primary'}
              isLoading={timer.isLoading}
              iconRight={timer.isLoading || pipeline.key ? undefined : faArrowRight}
              onClick={sms ? handlePreSave : goToNext}
              disabled={noLinkError}
            >
              {timer.didSucceed && pipeline.key
                ? t('Saved!')
                : timer.isLoading
                ? t('Saving...')
                : pipeline.key
                ? t('Save')
                : !sms
                ? t('Skip')
                : t('Save & Continue')}
            </Button>
          </>
        }
      />
      <PreviewModal />
    </div>
  )
}

export default DeliverySMS
