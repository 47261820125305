import { createAsyncThunk } from '@reduxjs/toolkit'

export type Request = {}

type Response = {}

export const clearPipeline = createAsyncThunk<Response, Request>(
  'pipelines/clear',
  async req => req
)
