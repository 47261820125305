import { t } from 'translation'
import { Pipeline } from 'types'

import { Execution } from './execution'
import { User } from './user'

export type EventStatus = 'success' | 'warning' | 'info' | 'error' | 'alert'

export type EventType =
  | 'enrollment'
  | 'passive_auth'
  | 'active_auth'
  | 'session_ended'
  | 'new_location'
  | 'unable_to_passively_auth'
  | 'multiple_faces'
  | 'cell_phone'
  | 'pipeline'

export type Event = {
  id: string
  type: EventType
  status: EventStatus
  location?: string
  ipAddress: string
  browserDetails?: BrowserDetails
  metadata: Record<string, any>
  images?: string[]
  createdAt: string
  user: Pick<User, 'id' | 'type' | 'uniqueId' | 'firstName' | 'lastName' | 'photoUrl'>
  execution?: Execution
  sessionId: string
}

export type EventWithPipeline = Event & {
  execution?: Execution & {
    pipeline: Pipeline
  }
}

type TitleMap = {
  [type in EventType]: string
}

export const eventTitleMap: TitleMap = {
  enrollment: t('3D face scan enrollment'),
  passive_auth: t('Passive authentication'),
  active_auth: t('Active authentication'),
  session_ended: t('Session was ended'),
  new_location: t('New location detected'),
  unable_to_passively_auth: t('Unable to authenticate'),
  multiple_faces: t('Multiple faces detected'),
  cell_phone: t('Cell phone detected'),
  pipeline: t('Pipeline completed'),
}

export type BrowserDetails = {
  browser: {
    name: string
    version?: string
  }
  os: {
    name: string
    version?: string
  }
  device: {
    brand?: string
    model?: string
  }
}
