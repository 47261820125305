import classNames from 'classnames'
import { FC } from 'react'
import r from 'routes'
import { useAuthSelector } from 'store'
import { t } from 'translation'

import {
  faBrowser,
  faGear,
  faSwatchbook,
  faTableLayout,
  faUserGroup,
  faUserHeadset,
} from '@fortawesome/pro-regular-svg-icons'

import BottomMenu from './BottomMenu'
import CondensedBottomMenu from './CondensedBottomMenu'
import s from './index.module.scss'
import LinkList, { Link } from './LinkList'
import Logo from './Logo'
import { ReactComponent as PipelinesIcon } from './pipelines-icon.svg'

type Props = {
  isCondensed?: boolean
  isSidebarHovered: boolean
  setIsSidebarHovered: (isHovered: boolean) => void
}

const Sidebar: FC<Props> = ({ isCondensed = false, isSidebarHovered, setIsSidebarHovered }) => {
  const { role } = useAuthSelector()
  if (!role) return <></>

  return (
    <div
      className={classNames(s.Wrapper, { [s.CondensedWrapper]: isCondensed && !isSidebarHovered })}
      onMouseOver={() => {
        setIsSidebarHovered(true)
      }}
    >
      <div>
        <Logo isCondensed={!isSidebarHovered && isCondensed} />
        <LinkList isCondensed={!isSidebarHovered && isCondensed}>
          <Link icon={faTableLayout} path={r.root}>
            {t('Dashboard')}
          </Link>

          <Link icon={faUserHeadset} path={r.agents.root}>
            {t('Agents')}
          </Link>

          {['admin', 'owner'].includes(role) && (
            <Link icon={faUserGroup} path={r.customers.root}>
              {t('Customers')}
            </Link>
          )}

          {['admin', 'owner'].includes(role) && (
            <Link icon={PipelinesIcon} path={r.pipelines.root}>
              {t('Pipelines')}
            </Link>
          )}

          {['admin', 'owner'].includes(role) && (
            <Link icon={faBrowser} path={r.iframes.root}>
              {t('IFrames')}
            </Link>
          )}

          {['admin', 'owner'].includes(role) && (
            <Link icon={faSwatchbook} path={r.brandStyles.root}>
              {t('Branding')}
            </Link>
          )}

          {role === 'owner' && (
            <Link icon={faGear} path={r.settings.root}>
              {t('Settings')}
            </Link>
          )}
        </LinkList>
        {/* <hr />
      <p className="mt-3 mb-2 ml-5 text-xs uppercase">Apps</p>
      <LinkList links={appLinks} /> */}
      </div>

      <div>
        {/* <Search /> */}
        <hr />
        {!isSidebarHovered && isCondensed ? <CondensedBottomMenu /> : <BottomMenu />}
      </div>
    </div>
  )
}

export default Sidebar
