import { FC } from 'react'

import { OCRStage } from '@journeyid/agent/types'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import ScanFrame from 'components/atoms/ScanFrame'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

type Props = OCRStage

const OCRPreview: FC<Props> = ({ metadata }) => {
  return (
    <>
      <LogoHeader />
      <main>
        <Header headline={metadata?.title}>{metadata?.text}</Header>
        <br />
        <div className="px-10 py-24 bg-gray-300">
          <ScanFrame />
        </div>
      </main>
      <footer>
        <PrimaryButton block preview>
          {t('Take Photo')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default OCRPreview
