import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { ReactComponent as SafariBG } from './safari.svg'

type Props = {
  className?: string
  children: ReactNode
}

const Safari: FC<Props> = ({ className, children }) => {
  return (
    <>
      <SafariBG className="absolute z-0 rounded-b-[40px]" />
      <div className={classNames('relative pt-12 pb-28 h-full z-10 w-[324px]', className)}>
        <div className="w-full">
          <section>{children}</section>
        </div>
      </div>
    </>
  )
}

export default Safari
