import { FC } from 'react'

import { faChevronRight, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field } from '@journeyid/agent/types'

import { t } from 'translation'

import Modal from 'components/organisms/Modal'
import { closeModal, useModal } from 'hooks/useModal'

import s from './FormFieldsModal.module.scss'

const baseFormFields: {
  label: string
  type: Field['type']
}[] = [
  { label: 'Freeform Text', type: 'text' },
  { label: 'An amount of money', type: 'money' },
  { label: 'Select from a dropdown of options', type: 'dropdown' },
  { label: 'Select one option from a list of options', type: 'radio' },
  { label: 'Select one or more options from a list', type: 'checkboxes' },
]

const formattedFormFields: {
  label: string
  type: Field['type']
}[] = [
  { label: 'Date', type: 'date' },
  { label: 'Address', type: 'address' },
]

type Props = {
  onSelect: (type: Field['type']) => void
}

const FormFieldsModal: FC<Props> = ({ onSelect }) => {
  const [isOpen] = useModal('FormFields')

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>{t('Form Fields')}</Modal.Header>
      <Modal.Body>
        {t(`Select which type of field you'd like to add to your form below.`)}
      </Modal.Body>

      <ul className="mb-6">
        {baseFormFields.map(field => (
          <li key={field.type} className={s.FormFieldRow} onClick={() => onSelect(field.type)}>
            <h6>{t(field.label)}</h6>
            <FontAwesomeIcon icon={faChevronRight} />
          </li>
        ))}
      </ul>

      <p className="mb-2 text-lg font-semibold">{t('Formatted Input Fields')}</p>

      <ul>
        {formattedFormFields.map(field => (
          <li key={field.type} className={s.FormFieldRow} onClick={() => onSelect(field.type)}>
            <h6>{t(field.label)}</h6>
            <FontAwesomeIcon icon={faChevronRight} />
          </li>
        ))}
      </ul>

      <div className="absolute top-8 right-8">
        <button
          className="flex items-center justify-center w-6 h-6 text-white bg-gray-400 rounded-full"
          tabIndex={-1}
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
    </Modal>
  )
}

export default FormFieldsModal
