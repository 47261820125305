import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import s from './index.module.scss'

type Props = {
  title?: string
  children: ReactNode
  className?: string
}

const SettingsCard: FC<Props> = ({ title, children, className }) => {
  return (
    <div className={classNames(s.SettingsCard, className)}>
      {title && (
        <>
          <p className="mb-4 text-3xl font-semibold">{title}</p>
          <hr className="mb-6" />
        </>
      )}
      {children}
    </div>
  )
}

export default SettingsCard
