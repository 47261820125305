import asyncThunk from 'util/asyncThunk'

type Request = {
  adminId: string
  groups: string[]
}

type Response = {
  id: string
  groups: {
    id: string
    name: string
  }[]
}

export const updateAdminGroups = asyncThunk<Request, Response>({
  name: 'admins/groups',
  uri: '/admins/groups',
  method: 'PUT',
  orchestrator: false,
})
