import { HTTPDeleted } from '@journeyid/agent/common/statuses'

import asyncThunk from 'util/asyncThunk'

type Request = {
  accountId: string
  adminId: string
}

type Response = {
  accountId: string
  adminId: string
}

export const accountRolesDelete = asyncThunk<Request, Response>({
  name: 'account-roles/delete',
  method: 'DELETE',
  uri: '/account-roles',
  orchestrator: false,
  successStatus: HTTPDeleted,
})
