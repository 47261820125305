import cogoToast from 'cogo-toast'
import { useCallback } from 'react'
import { UseFormMethods } from 'react-hook-form'

import { isAPIErrorResponse } from '@journeyid/agent/common/errors'

import { useAPI } from 'hooks/useAPI'
import { updateSAML } from 'thunks/api/pipelines/saml'

type FormData = {
  entityId: string
  acsUrl: string
}

export default function useSubmit(pipelineId: string, setError: UseFormMethods['setError']) {
  const [update, { timer }] = useAPI(updateSAML)
  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        await update({
          id: pipelineId,
          ...data,
        })
        cogoToast.success('The SAML settings have been saved.')
      } catch (err) {
        if (isAPIErrorResponse(err)) {
          cogoToast.error(err.error)
          if (err.errors) {
            for (const field in err.errors) {
              setError(field as keyof FormData, { type: 'server', message: err.errors[field] })
            }
          }
        } else {
          cogoToast.error('Unable to persist SAML settings')
        }
      }
    },
    [pipelineId, update, setError]
  )

  return [onSubmit, { timer }] as const
}
