import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { t } from 'translation'

import { faSpinnerThird, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from 'components/organisms/Modal'
import { closeModal, useModal } from 'hooks/useModal'

import s from './index.module.scss'

type Props = {
  modalId: string
  suffix?: string
  type?: 'normal' | 'delete'
  title: string
  text?: string
  confirmBtnText?: string
  cancelBtnText?: string
  children?: ReactNode
  isLoading?: boolean | undefined
  disabled?: boolean
  onSubmit: () => void
}

const ConfirmModal: FC<Props> = ({
  modalId,
  suffix,
  type = 'normal',
  title,
  text,
  confirmBtnText,
  cancelBtnText,
  isLoading = false,
  disabled,
  children,
  onSubmit,
}) => {
  const [isOpen] = useModal(modalId, suffix)

  return (
    <Modal
      isOpen={isOpen}
      closeButton
      className="!max-w-2xl !px-0 !py-6"
      onClose={() => closeModal()}
    >
      <div className={s.ModalBody}>
        <div>
          <div className={s.TitleWrapper}>
            {type === 'delete' && (
              <FontAwesomeIcon className={s.WarningIcon} icon={faCircleExclamation} />
            )}
            <h2 className={s.Title}>{title}</h2>
          </div>

          {text && <p className={s.Text}>{text}</p>}
        </div>

        {children && <div>{children}</div>}

        <div className={s.Footer}>
          <button
            className={classNames(s.ConfirmBtn, { 'bg-red-600': type === 'delete' })}
            disabled={disabled ? disabled : false}
            onClick={onSubmit}
          >
            {isLoading && <FontAwesomeIcon icon={faSpinnerThird} spin className={s.LoadingIcon} />}
            {confirmBtnText ? confirmBtnText : t("Yes, I'm sure")}
          </button>
          <button className={s.CancelBtn} disabled={isLoading} onClick={() => closeModal()}>
            {cancelBtnText ? cancelBtnText : t('No, cancel')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
