import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { useAppDispatch } from 'store'

import { unwrapResult } from '@reduxjs/toolkit'

import { t } from 'translation'

import ConfirmModal from 'components/organisms/ConfirmModal'
import useActionTimer from 'hooks/useActionTimer'
import { closeModal } from 'hooks/useModal'
import { accountsCreate } from 'thunks/api/accounts/create'
import { authenticationsSwitch } from 'thunks/api/authentications/switch'

import s from './CreateAccountModal.module.scss'

type Request = {
  accountId: string
}

const CreateAccountModal: FC = () => {
  const [name, setName] = useState('')

  const timer = useActionTimer()
  const dispatch = useAppDispatch()
  const history = useHistory()

  async function createAccount() {
    try {
      timer.start()
      const account = await dispatch(accountsCreate({ name })).then(unwrapResult)

      const req: Request = {
        accountId: account.id,
      }

      await dispatch(authenticationsSwitch(req)).then(unwrapResult)

      history.push(r.root)
      closeModal()
      timer.succeeded()
    } catch (err) {
      timer.failed()
      console.error(err)
    }
  }

  return (
    <ConfirmModal
      modalId="CreateAccount"
      title={t('Create an account')}
      confirmBtnText={t('Create account')}
      isLoading={timer.isLoading}
      disabled={timer.isLoading || name.length === 0}
      cancelBtnText={t('Cancel')}
      onSubmit={createAccount}
    >
      <div className={s.ConfirmModal}>
        <label htmlFor="accountName">{t('Account Title')}</label>
        <input
          type="text"
          id="accountName"
          placeholder={t('Name')}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
    </ConfirmModal>
  )
}

export default CreateAccountModal
