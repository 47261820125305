import { FC } from 'react'

import { t } from 'translation'

import PrimaryButton from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Field from 'components/molecules/Field'
import Header from 'components/organisms/Preview/Header'
import LogoHeader from 'components/organisms/Preview/LogoHeader'

const AddressCollectionPreview: FC = () => {
  return (
    <>
      <LogoHeader />
      <Header>{t('Enter your address')}</Header>

      <main className="px-4 pt-4 space-y-3 pointer-events-none w-[324px]">
        <Field label={t('Address')} name="primaryLine">
          <div className="w-full space-y-2">
            <Input name="primaryLine" autoComplete="off" className="w-full" />
            <Input name="secondaryLine" className="w-full" />
          </div>
        </Field>

        <Field label={t('City')} name="city" />

        <Field label={t('Country')} name="country" />

        <div className="flex space-x-4">
          <Field label={t('State')} name="region" className="flex-1 w-[138px]" />
          <Field label={t('Postal Code')} name="postalCode" className="flex-1 w-[138px]" />
        </div>
      </main>

      <footer>
        <PrimaryButton block className="pointer-events-none" preview>
          {t('Submit')}
        </PrimaryButton>
      </footer>
    </>
  )
}

export default AddressCollectionPreview
