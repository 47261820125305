import asyncThunk from 'util/asyncThunk'
import { HTTPRetrievedMultiple } from 'util/statuses'

export type Request = {}

type Response = Account[]

export type Account = {
  id: string
  name: string
}

export const listAccounts = asyncThunk<Request, Response>({
  name: 'admin/accounts',
  uri: '/admins/accounts',
  successStatus: HTTPRetrievedMultiple,
  orchestrator: false,
})
