import { OIDCConfiguration } from 'types/oidc'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
}

export type Response = {
  id: string
  ssoId: string
  oidc?: OIDCConfiguration
}

export const retrieveOIDC = asyncThunk<Request, Response>({
  name: 'accounts/sso/oidc/retrieve',
  uri: ({ id }) => `/accounts/${id}/sso/oidc`,
  orchestrator: false,
  stripId: true,
})
