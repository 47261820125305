import classNames from 'classnames'
import { ReactElement } from 'react'
import r from 'routes'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Header from 'components/molecules/AdminHeader'
import useEnsurePipeline from 'hooks/useEnsurePipeline'
import { useScrollToTop } from 'hooks/useScrollToTop'

import s from './index.module.scss'

interface Props {
  sidebar?: ReactElement
  children: ReactElement
}

const Layout = ({ sidebar, children }: Props) => {
  const isCorrect = useEnsurePipeline()
  const ref = useScrollToTop()

  return (
    <div className={classNames('__Layout', s.Layout)}>
      <Header title="Pipelines" backRoute={r.pipelines.root} />
      {isCorrect ? (
        <div className={s.ContentWrapper}>
          <div>{sidebar}</div>
          <main>
            <div ref={ref}>{children}</div>
          </main>
        </div>
      ) : (
        <div className={s.Loading}>
          <FontAwesomeIcon icon={faSpinnerThird} size="4x" spin />
        </div>
      )}
    </div>
  )
}

export default Layout
