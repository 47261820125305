import { HTTPRetrievedMultiple } from '@journeyid/agent/common/statuses'

import { Event } from 'types/event'
import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  limit: number
  offset: number
}

export const accountsActivity = asyncThunk<Request, Event[]>({
  name: 'accounts/activity',
  method: 'GET',
  uri: ({ id }) => `/accounts/${id}/activity`,
  successStatus: HTTPRetrievedMultiple,
  orchestrator: false,
})
