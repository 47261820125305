import { FC, useCallback, useEffect, useState } from 'react'
import { useAuthSelector, usePipelineSelector } from 'store'
import { t } from 'translation'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StageType } from '@journeyid/agent/types'

import { useAPI } from 'hooks/useAPI'
import { Gateway, listGateways } from 'thunks/api/gateways/list'
import { addDestination } from 'thunks/api/pipelines/add-destination'
import { removeDestination } from 'thunks/api/pipelines/remove-destination'

import SettingLayout from '../SettingLayout'
import DeliveryMethodList from './components/DeliveryMethodList'

const DataDelivery: FC = () => {
  const { pipeline } = usePipelineSelector()
  const { accountId } = useAuthSelector()

  const [gateways, setGateways] = useState<Gateway[]>()
  const [list] = useAPI(listGateways)
  useEffect(() => {
    if (!accountId) return
    list({ accountId }).then(setGateways)
  }, [accountId, list])

  const [add, { timer: addTimer }] = useAPI(addDestination)
  const handleAdd = useCallback(
    async (stageType: StageType, gatewayId: string) => {
      await add({
        id: pipeline.id,
        stageType,
        gatewayId,
      })
    },
    [pipeline, add]
  )

  const [remove, { timer: removeTimer }] = useAPI(removeDestination)
  const handleRemove = useCallback(
    async (stageType: StageType, gateway: string) => {
      removeTimer.setId(`${stageType}-${gateway}`)
      await remove({
        id: pipeline.id,
        stageType,
        gateway,
      })
    },
    [pipeline, removeTimer, remove]
  )

  return (
    <SettingLayout
      id="data-delivery"
      title={t('Data Delivery')}
      description={t(
        "Journey uses our patented Zero Knowledge network to secure your customers' data from their device all the way to your backend systems. Between the two, the data is encrypted in such a way that even Journey can't read it."
      )}
    >
      <p className="mb-2 std-base">
        {t(
          `By configuring data destinations below, you can deliver data such as the information from a Driver's License scan to your CRM. Another user case would be sending credit card details to a payment procesor.`
        )}
      </p>
      <p className="mb-2 std-base">
        {t(
          `This is Enterprise functionality, so please reach out to your Journey sales engineer or support@journeyid.com to have your data destinations added to your account.`
        )}
      </p>
      {gateways ? (
        <DeliveryMethodList
          pipeline={pipeline}
          gateways={gateways}
          addTimer={addTimer}
          removeTimer={removeTimer}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      ) : (
        <div className="flex items-center space-x-3 text-base text-gray-500">
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </div>
      )}
    </SettingLayout>
  )
}

export default DataDelivery
