import { StageType } from '@journeyid/agent/types'

const r = {
  root: '/',
  accounts: {
    root: '/accounts',
  },
  agents: {
    root: '/agents',
    show: (id: string = ':id', eventId?: string) =>
      `/agents/${id}${eventId ? `?eventId=${eventId}` : ''}`,
    new: '/agents/new',
    import: '/agents/import',
  },
  customers: {
    root: '/customers',
    show: (id: string = ':id', eventId?: string) =>
      `/customers/${id}${eventId ? `?eventId=${eventId}` : ''}`,
    new: '/customers/new',
  },
  brandStyles: {
    root: '/brand-styles',
    show: (id: string = ':id') => `/brand-styles/${id}`,
    new: '/brand-styles/new',
  },
  settings: {
    root: '/settings',
    general: '/settings/general',
    developer: '/settings/developer',
    adminManagement: '/settings/admin-management',
    groups: '/settings/groups',
    agentAuthentication: '/settings/agent-authentication',
    agentDesktops: '/settings/agent-desktops',
    customerRegistration: '/settings/customer-registration',
    paymentGateways: '/settings/payment-gateways',
    zoom: '/settings/integration',
    sso: '/settings/sso',
    advanced: '/settings/advanced',
    dataExport: '/settings/data-export',
    pricing: (
      tab:
        | 'customer-interactions'
        | 'agent-authentication'
        | 'deployment-models'
        | ''
        | ':id' = ':id'
    ) => `/settings/pricing/${tab}`,
  },
  pipelines: {
    root: '/pipelines',
    create: {
      root: (id: string = ':id') => `/pipelines/create/${id}`,
      useCase: (id: string = '') => `/pipelines/create/use-case${id ? `/${id}` : ''}`,
      configure: {
        root: (id: string = ':id') => `/pipelines/create/${id}/configure`,
        stages: (id: string = ':id') => `/pipelines/create/${id}/configure/stages`,
        stage: (id: string, stage: StageType) => `/pipelines/create/${id}/configure/${stage}`,
        successScreen: (id: string = ':id') => `/pipelines/create/${id}/configure/success-screen`,
      },
      delivery: {
        method: (id: string = ':id', method: 'email' | 'sms' | 'push-notification') =>
          `/pipelines/create/${id}/delivery/${method}`,
      },
      publish: (id: string = ':id') => `/pipelines/create/${id}/publish`,
    },
    edit: {
      root: (id: string = ':id') => `/pipelines/edit/${id}`,
      published: (id: string = ':id') => `/pipelines/edit/${id}/published`,
      configure: {
        root: (id: string = ':id') => `/pipelines/edit/${id}/configure`,
        stage: (id: string = ':id', stage: StageType | ':stage' = ':stage') =>
          `/pipelines/edit/${id}/configure/${stage}`,
        successScreen: (id: string = ':id') => `/pipelines/edit/${id}/configure/success-screen`,
      },
      delivery: {
        method: (id: string = ':id', method: 'email' | 'sms' | 'push-notification') =>
          `/pipelines/edit/${id}/delivery/${method}`,
      },
      settings: {
        setting: (
          id: string = ':id',
          setting:
            | 'webhooks'
            | 'data-delivery'
            | 'internal-title'
            | 'duplicate'
            | 'archive'
            | 'branding'
        ) => `/pipelines/edit/${id}/settings/${setting}`,
      },
      integration: {
        type: (id: string = ':id', type: 'api' | 'ivr' | 'chatbot' | 'oidc' | 'saml2') =>
          `/pipelines/edit/${id}/integration/${type}`,
      },
    },
  },
  iframes: {
    root: '/iframes/',
    create: {
      root: (id: string = ':id') => `/iframes/create/${id}`,
      configure: {
        new: '/iframes/create/configure/title',
        title: (id: string = ':id') => `/iframes/create/${id}/configure/title`,
        languages: (id: string = ':id') => `/iframes/create/${id}/configure/languages`,
        enrollmentAndAuthentication: (id: string = ':id') =>
          `/iframes/create/${id}/configure/enrollment-and-authentication`,
        pipelines: (id: string = ':id') => `/iframes/create/${id}/configure/pipelines`,
        addPipeline: (id: string = ':id', categoryId?: string) =>
          `/iframes/create/${id}/configure/pipelines/add${
            categoryId ? `?categoryId=${categoryId}` : ''
          }`,
        deliveryMethods: (id: string = ':id') => `/iframes/create/${id}/configure/delivery-methods`,
        test: (id: string = ':id') => `/iframes/create/${id}/configure/test`,
        deploymentInstructions: (id: string = ':id') =>
          `/iframes/create/${id}/configure/deployment-instructions`,
      },
    },
    edit: {
      root: (id: string = ':id') => `/iframes/edit/${id}`,
      configure: {
        // TODO Compact to stages
        title: (id: string = ':id') => `/iframes/edit/${id}/configure/title`,
        languages: (id: string = ':id') => `/iframes/edit/${id}/configure/languages`,
        enrollmentAndAuthentication: (id: string = ':id') =>
          `/iframes/edit/${id}/configure/enrollment-and-authentication`,
        pipelines: (id: string = ':id') => `/iframes/edit/${id}/configure/pipelines`,
        addPipeline: (id: string = ':id', categoryId?: string) =>
          `/iframes/edit/${id}/configure/pipelines/add${
            categoryId ? `?categoryId=${categoryId}` : ''
          }`,
        deliveryMethods: (id: string = ':id') => `/iframes/edit/${id}/configure/delivery-methods`,
        test: (id: string = ':id') => `/iframes/edit/${id}/configure/test`,
        deploymentInstructions: (id: string = ':id') =>
          `/iframes/edit/${id}/configure/deployment-instructions`,
      },
    },
  },
  auth: {
    login: '/login',
    register: '/register',
    oauthCallback: '/login/callback',
    logout: '/logout',
    magicLink: '/magic-link',
    magicLinkCallback: '/magic-link/callback',
    acceptInvitation: '/invitations/accept',
    sso: {
      root: '/sso',
      idpInitiated: '/sso/idp-initiated',
    },
  },
}

export default r
