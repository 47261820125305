import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import r from 'routes'
import { usePipelineSelector } from 'store'

import { MetadataFor, StageFor, StageType } from '@journeyid/agent/types'

import { ActionTimer } from 'hooks/useActionTimer'
import { useAPI } from 'hooks/useAPI'
import { updateStage } from 'thunks/api/pipelines/update-stage'

type MetadataField<T extends StageType> = keyof MetadataFor<T>
type MetadataValue<T extends StageType, U extends MetadataField<T>> = MetadataFor<T>[U]

type Output<T extends StageType> = {
  stage: StageFor<T>
  timer: ActionTimer
  setStage: (stage: StageFor<T>) => void
  handleMetadata: <U extends MetadataField<T>>(field: U, value: MetadataValue<T, U>) => void
  handleSave: () => void
}

export default function useStage<T extends StageType>(stageType: T): Output<T> {
  const { pipeline } = usePipelineSelector()

  const initialState: StageFor<T> = useMemo(() => {
    const stage = pipeline.stages.find(s => s.type === stageType)
    if (!stage) throw new Error(`unable to find stage: ${stageType}`)
    return stage as StageFor<T>
  }, [stageType, pipeline.stages])

  const [stage, setStage] = useState<StageFor<T>>(initialState)

  const history = useHistory()
  const [update, { timer }] = useAPI(updateStage)
  const handleSave = useCallback(async () => {
    try {
      timer.start()

      await update({
        id: pipeline.id,
        ...stage,
      })

      if (pipeline.key) return

      const idx = pipeline.stages.findIndex(s => s.type === stageType)
      if (idx === -1) return
      if (idx < pipeline.stages.length - 1) {
        const stage = pipeline.stages[idx + 1]
        history.push(r.pipelines.create.configure.stage(pipeline.id, stage.type))
      } else {
        history.push(r.pipelines.create.configure.successScreen(pipeline.id))
      }
    } catch (err) {
      timer.failed()
      console.error('Unable to save stage metadata', err)
    }
  }, [pipeline, stageType, stage, timer, update, history])

  const handleMetadata = useCallback(
    <U extends MetadataField<T>>(field: U, value: MetadataValue<T, U>) => {
      setStage(stage => ({
        ...stage,
        metadata: {
          ...stage.metadata,
          [field]: value,
        },
      }))
    },
    []
  )

  return { stage, timer, setStage, handleMetadata, handleSave }
}
