import { FC } from 'react'
import { usePipelineSelector } from 'store'

import { faArrowRight, faEye } from '@fortawesome/pro-regular-svg-icons'

import { t } from 'translation'

import Button from 'components/atoms/Button'
import PhoneMockup from 'components/atoms/PhoneMockup'
import Field from 'components/molecules/Field'
import Footer from 'components/molecules/Footer'
import PageHeader from 'components/molecules/PageHeader'
import useDelivery from 'components/templates/delivery/useDelivery'
import { openModal } from 'hooks/useModal'
import PreviewModal from 'pages/Pipelines/Create/components/PreviewModal'
import { deliveryDefaults } from 'util/deliveryDefaults'

import appIcon from './AppIcon.png'
import s from './index.module.scss'
import notification from './Notification.png'

const DeliveryPushNotification: FC = () => {
  const { pipeline } = usePipelineSelector()
  const { pushNotification, timer, handleChange, handleSave, goToNext } =
    useDelivery('pushNotification')

  return (
    <div id="delivery-push-notification" className={s.Wrapper}>
      <div className={s.Content}>
        <PageHeader title={t('Delivery Method: Mobile App Push Notification')}>
          {t(
            "Journey suggests using mobile Push Notifications whenever possible because they are native integrations with the mobile device leading to more reliable and faster notification delivery, especially when compared with SMS delivery to certain non-US countries. If your company's mobile app (or a Journey app) is installed, clicking on the push notification will start the pipeline."
          )}
        </PageHeader>
        <div className="flex pb-8 pr-8">
          <div className="flex-1 pt-4 pr-4 space-y-4 pl-7">
            <Field
              label={t('Title')}
              name="title"
              value={pushNotification?.title}
              placeholder={deliveryDefaults.pushNotification.title}
              onChange={handleChange('title')}
            />
            <Field
              label={t('Message')}
              name="message"
              value={pushNotification?.body}
              placeholder={deliveryDefaults.pushNotification.body}
              onChange={handleChange('body')}
            />
          </div>
          <div>
            <PhoneMockup statusBar="light">
              <img src={notification} alt="" className="absolute rounded-[38px]" />
              <div className="pt-60">
                <div className="flex justify-center w-full">
                  <div className={s.Notification}>
                    <img src={appIcon} alt="" className="w-[30px] h-[30px]" />
                    <div>
                      <header>
                        {pushNotification?.title || deliveryDefaults.pushNotification.title}
                      </header>
                      <p>{pushNotification?.body || deliveryDefaults.pushNotification.body}</p>
                    </div>
                  </div>
                </div>
              </div>
            </PhoneMockup>
          </div>
        </div>
      </div>
      <Footer
        actions={
          <>
            <Button variant="secondary" icon={faEye} onClick={openModal('Preview')}>
              {t('Preview Pipeline')}
            </Button>
            <Button
              variant={timer.didSucceed && pipeline.key ? 'green' : 'primary'}
              isLoading={timer.isLoading}
              iconRight={timer.isLoading || pipeline.key ? undefined : faArrowRight}
              onClick={pushNotification ? handleSave : goToNext}
            >
              {timer.didSucceed && pipeline.key
                ? t('Saved!')
                : timer.isLoading
                ? t('Saving...')
                : pipeline.key
                ? t('Save')
                : !pushNotification
                ? t('Skip')
                : t('Save & Continue')}
            </Button>
          </>
        }
      />
      <PreviewModal />
    </div>
  )
}

export default DeliveryPushNotification
