import { FC } from 'react'
import { t } from 'translation'

import ConfigurableStage from 'components/atoms/ConfigurableStage'
import Settings from 'components/atoms/Settings'
import Field from 'components/molecules/Field'
import OutboundSchedulingPreview from 'components/templates/stages/OutboundScheduling.Preview'
import CreateSettings from 'components/templates/stages/shared/CreateSettings'
import useStage from 'components/templates/stages/useStage'

const OutboundScheduling: FC = () => {
  const { stage, timer, handleMetadata, handleSave } = useStage('outbound-scheduling')

  return (
    <CreateSettings
      stageType="outbound-scheduling"
      preview={<OutboundSchedulingPreview {...stage} />}
      timer={timer}
      onSave={handleSave}
    >
      <div className="flex flex-col">
        <ConfigurableStage />
        <Settings>
          <Field
            label={t('Headline')}
            name="title"
            value={stage.metadata.title}
            onChange={e => handleMetadata('title', e.target.value)}
          />
          <Field
            label={t('Additional context for the user')}
            as="textarea"
            rows={4}
            name="text"
            value={stage.metadata.text}
            className="!border-t-0"
            onChange={e => handleMetadata('text', e.target.value)}
          />
          <Field
            label={t('Button text')}
            name="button"
            value={stage.metadata.button}
            className="!border-t-0"
            onChange={e => handleMetadata('button', e.target.value)}
          />
        </Settings>
      </div>
    </CreateSettings>
  )
}

export default OutboundScheduling
