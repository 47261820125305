import classNames from 'classnames'
import { ChangeEvent, FC, HTMLProps, useCallback, useState } from 'react'

import { Payload } from 'pages/IFrames/Steps/Test/useIFrame'

const Input: FC<
  HTMLProps<HTMLInputElement> & {
    required?: boolean
  }
> = ({ required = false, value, ...props }) => {
  return (
    <input
      {...props}
      value={value}
      className={classNames(
        'outline-none text-green-700',
        required ? 'border-b-2' : 'border-b',
        required && !value ? 'border-red-600' : 'border-gray-300'
      )}
    />
  )
}

type Props = {
  onChange: (payload: Payload) => void
}

const PostMessage: FC<Props> = ({ onChange }) => {
  const [payload, setPayload] = useState<Payload>({
    event: 'start-session',
    session: {
      externalRef: '',
    },
    user: {
      uniqueId: '',
    },
  })

  const handleChange = useCallback(
    function update<T extends 'session' | 'user'>(obj: T, field: keyof Payload[T]) {
      return (e: ChangeEvent<HTMLInputElement>) => {
        const updated = JSON.parse(JSON.stringify(payload))
        updated[obj][field] = e.target.value
        setPayload(updated)
        onChange(updated)
      }
    },
    [payload, onChange]
  )

  return (
    <div className="font-mono">
      <div className="mt-4 mb-1">
        <span className="text-red-700">const</span> <span className="text-purple-800">payload</span>{' '}
        = {'{'}
      </div>
      <div className="pl-4 mt-1">
        <span className="text-green-700">"event"</span>:{' '}
        <span className="text-green-700">"start-session"</span>,
      </div>
      <div className="pl-4 mt-1">
        <span className="text-green-700">"session"</span>: {'{'}
      </div>
      <div className="pl-8 mt-1">
        <span className="text-green-700">"externalRef"</span>:{' '}
        <span className="text-green-700">"</span>
        <Input
          required
          className="border-red-500"
          value={payload.session.externalRef}
          onChange={handleChange('session', 'externalRef')}
        />
        <span className="text-green-700">"</span>,
      </div>
      <div className="pl-4 mt-1">{'}'},</div>
      <div className="pl-4 mt-1">
        <span className="text-green-700">"user"</span>: {'{'}
      </div>
      <div className="pl-8 mt-1">
        <span className="text-green-700">"uniqueId"</span>:{' '}
        <span className="text-green-700">"</span>
        <Input
          required
          className="border-red-500"
          value={payload.user.uniqueId}
          onChange={handleChange('user', 'uniqueId')}
        />
        <span className="text-green-700">"</span>,
      </div>
      <div className="pl-8 mt-1">
        <span className="text-green-700">"firstName"</span>:{' '}
        <span className="text-green-700">"</span>
        <Input value={payload.user.firstName} onChange={handleChange('user', 'firstName')} />
        <span className="text-green-700">"</span>,
      </div>
      <div className="pl-8 mt-1">
        <span className="text-green-700">"lastName"</span>:{' '}
        <span className="text-green-700">"</span>
        <Input value={payload.user.lastName} onChange={handleChange('user', 'lastName')} />
        <span className="text-green-700">"</span>,
      </div>
      <div className="pl-8 mt-1">
        <span className="text-green-700">"phoneNumber"</span>:{' '}
        <span className="text-green-700">"</span>
        <Input value={payload.user.phoneNumber} onChange={handleChange('user', 'phoneNumber')} />
        <span className="text-green-700">"</span>,
      </div>
      <div className="pl-8 mt-1">
        <span className="text-green-700">"email"</span>: <span className="text-green-700">"</span>
        <Input value={payload.user.email} onChange={handleChange('user', 'email')} />
        <span className="text-green-700">"</span>,
      </div>
      <div className="pl-4 mt-1">{'}'}</div>
      <div className="mt-1">{'}'}</div>

      <div className="mt-4">
        <span className="text-red-700">const</span> <span className="text-purple-800">iFrame</span>{' '}
        = document.
        <span className="text-blue-800">getElementById</span>('
        <span className="text-orange-800">journey</span>').
        <span className="text-blue-800">contentWindow</span>
      </div>
      <div className="mt-1">
        <span className="text-purple-800">iFrame</span>.
        <span className="text-blue-800">postMessage</span>(
        <span className="text-purple-800">payload</span>,{' '}
        <span className="text-green-700 ">"https://{_env_.IFRAME_ORIGIN}"</span>)
      </div>
    </div>
  )
}

export default PostMessage
