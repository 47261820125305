import { Iframe } from 'types'

import asyncThunk from 'util/asyncThunk'

export type Request = {
  id: string
  title?: string
  languages?: { agent: string; customer: string[] }
  deliveryMethods?: {
    sms: null | boolean
    email: null | boolean
    'push-notification': null | boolean
    whatsapp: null | boolean
    url: null | boolean
  }
}

type Response = Iframe

export const updateIframe = asyncThunk<Request, Response>({
  name: 'iframes/update',
  method: 'PUT',
  uri: ({ id }) => `/iframes/${id}`,
  stripId: true,
})
